import { Component } from '@angular/core';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.css']
})
export class BenefitsComponent {
  activeTab: string = 'health';
  currentHealthCard: number = 0;
  currentLuggageCard: number = 0;
  currentTravelCard: number = 0;

  healthCards = [
    { icon: '../../../../assets/muvitBAM/Beneficios/gastos-medicos.svg', title: 'Gastos médicos por accidente o enfermedad', description: 'Durante tu viaje, tu salud esta protegida en caso te accidentes o enfermes en el extranjero.' },
    { icon: '../../../../assets/muvitBAM/Beneficios/traslado-emergencia.svg', title: 'Traslado en caso de emergencia', description: 'Cuentas con traslado de ambulancia, terrestre aérea o marítima' },
    { icon: '../../../../assets/muvitBAM/Beneficios/repatriacion.svg', title: 'Repartición de restos mortales', description: 'En caso de fallecimiento trasladamos losrestos del asegurado por tí' },
    { icon: '../../../../assets/muvitBAM/Beneficios/medicamentos.svg', title: 'Medicamentos', description: 'Contamos con una extensa red de proveedores para brindarte los mejores medicamentos según lo  necesites.' },
    
  ];

  luggageCards = [
    { icon: '../../../../assets/muvitBAM/Tarjetas/equipaje-1.png', title: 'Demora de equipaje', description: 'En caso tu equipaje demore llegar, bienes una cobertura para poder comprar bienes necesarios hasta recibirlo.' },
    { icon: '../../../../assets/muvitBAM/Tarjetas/equipaje-2.png', title: 'Pérdida de equipaje', description: 'Si en tu viaje se extravía tu equipaje, te cubrimos un monto dependiendo del plan escogido.' }
  ];
  

  travelCards = [
    { icon: '../../../../assets/muvitBAM/Tarjetas/Demora.png', title: 'Demora de viaje', description: 'Si tu viaje se demora más de lo usual, tienes un monto disponible como compensación.' },
    { icon: '../../../../assets/muvitBAM/Tarjetas/interrupcion.png', title: 'Interrupción de viaje', description: 'Te respaldamos con un monto si tu viaje se viera interrumpido por cualquier percance' },
    { icon: '../../../../assets/muvitBAM/Tarjetas/Asistencia.png', title: 'Asistencia de vida', description: 'Brindamos un monto para utilizar en caso de fallecimiento del asegurado durante el viaje' }
  ];

  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }
  prevCard(tab: string): void {
    const current = this.getCurrentCard(tab);
    const newIndex = Math.max(0, current - 2); // Retrocede 2 elementos como máximo
    this.setCurrentCard(tab, newIndex);
    this.syncPagination(tab);
  }
  
  nextCard(tab: string): void {
    const cardsArray = this.getCardsArray(tab);
    const current = this.getCurrentCard(tab);
    const maxIndex = Math.max(0, cardsArray.length - 2); // No excedas el total de páginas
    const newIndex = Math.min(maxIndex, current + 2); // Avanza 2 elementos como máximo
    this.setCurrentCard(tab, newIndex);
    this.syncPagination(tab);
  }

  syncPagination(tab: string): void {
    const currentPage = Math.floor(this.getCurrentCard(tab) / 2);
    this.goToPage(currentPage, tab); // Sincroniza el índice de la página
  }
  
  goToPage(pageIndex: number, tab: string): void {
    const totalPages = this.getPaginationArray(tab).length;
  
    // Asegúrate de que la página no exceda los límites válidos
    if (pageIndex >= 0 && pageIndex < totalPages) {
      this.setCurrentCard(tab, pageIndex * 2);
    }
  }
  

  getCurrentCard(tab: string): number {
    if (tab === 'health') return this.currentHealthCard;
    if (tab === 'luggage') return this.currentLuggageCard;
    if (tab === 'travel') return this.currentTravelCard;
    return 0;
  }

  setCurrentCard(tab: string, index: number): void {
    if (tab === 'health') this.currentHealthCard = index;
    if (tab === 'luggage') this.currentLuggageCard = index;
    if (tab === 'travel') this.currentTravelCard = index;
  }

  getPaginationArray(tab: string): number[] {
    const cardsLength = this.getCardsArray(tab).length;
    return Array(Math.ceil(cardsLength / 2)).fill(0);
  }

  getCurrentPage(tab: string): number {
    return Math.floor(this.getCurrentCard(tab) / 2);
  }


  private getCardsArray(tab: string): any[] {
    if (tab === 'health') return this.healthCards;
    if (tab === 'luggage') return this.luggageCards;
    if (tab === 'travel') return this.travelCards;
    return [];
  }
}
