import { Component, OnInit } from '@angular/core';
import { ImageLoadingService } from 'src/app/services/image-loading.service';
import { ComponentLoaderService } from 'src/app/services/component-loader.service';
import { NavbarServiceService } from '../navbar-service.service';
import { DataConstructorService } from 'src/app/services/data-constructor.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { ApiService } from 'src/app/api/api.service';
import * as forge from 'node-forge';
//import { getMicroFormError } from 'src/app/global/cybersource';
import { MatDialog } from '@angular/material/dialog';
import { PolicyDialogDesktopComponent } from '../policy-dialog-desktop/policy-dialog-desktop.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import Swal from 'sweetalert2';
import { Title } from '@angular/platform-browser';
import { InsurancePayment } from 'src/app/models/insurance-payment';
import { HttpClient } from '@angular/common/http';
import {getMicroFormError} from '../../global/cybersource'

declare var Flex: any;
let myStyles = {
  'input':{'font-size':'1rem',
  'font-family':'system-ui',
  'font-weight':'400'},
  '::placeholder':{
    'color':'#000000'
  },
  ':hover':{
    'border-color':'#3dbfef'
  }
}

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.css']
})

export class PaymentFormComponent implements OnInit {

  initialDate: any;
  finalDate: any;

  range0_64 = {'quantity': 0, 'price': 0.00}
  range65_85 = {'quantity': 0, 'price': 0.00}

  microform:any;
  // token:String;
  cardFlexComponentIsLoaded:boolean = false;

  isCardFilled = false
  isCVVFilled = false

  visa = false
  master = false
  amex = false

  cardName = ""
  cardExpDate = ""
  invalidDate = true

  disBtn: boolean = false;
  pk: any;

  errorMessage: string | string[];

  constructor(
    public gs: GlobalService,
    private api: ApiService,
    private router: Router
  ) {
    let age = this.calcYearsOld(this.gs.currentInsuranceInfo.holder.birthday);
    if(age < 65){
      this.range0_64['quantity'] += 1;
      this.range0_64['price'] += this.gs.priceHolder;
    } else {
      this.range65_85['quantity'] += 1;
      this.range65_85['price'] += this.gs.priceHolder;
    }

    if(this.gs.currentInsuranceInfo.accompanisttable?.length) {
      for (let i = 0; i < this.gs.currentInsuranceInfo.accompanisttable.length; i++) {
        this.gs.currentInsuranceInfo.accompanisttable[i].address = this.gs.currentInsuranceInfo.holder.address;
        age = this.calcYearsOld(this.gs.currentInsuranceInfo.accompanisttable[i].birthday);
        if(age < 65){
          this.range0_64['quantity'] += 1;
          this.range0_64['price'] += this.gs.prices[i];
        } else {
          this.range65_85['quantity'] += 1;
          this.range65_85['price'] += this.gs.prices[i];
        }
      }
    }

    this.api.cybersource.getFlex().promise().then(
      res => {
        let flex = new Flex(res.jwt)
        this.microform = flex.microform({styles: myStyles });
        var number = this.microform.createField('number', { placeholder: 'Número de tarjeta de crédito *' });
        var securityCode = this.microform.createField('securityCode', { placeholder: 'Código *', maxLength:4 });
        let self = this;
        number.on('change',(data)=>{
          this.isCardFilled = data.couldBeValid
          if(data.card)
            this.verifyCybersourceCardBrand(data.card)
        })
        number.on('load',(data)=>{
          this.cardFlexComponentIsLoaded = true
        })
        securityCode.on('change',(data)=>{
          this.isCVVFilled = data.couldBeValid
        })
        securityCode.on('load',(data)=>{
          this.cardFlexComponentIsLoaded = true
        })
        number.load('#creditCardNumber');
        securityCode.load('#securityCode');
        
      }
    )
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.initialDate = this.convertirFecha(this.gs.currentInsuranceInfo.travel_starting_date);
    this.finalDate = this.convertirFecha(this.gs.currentInsuranceInfo.travel_end_date);
  }

  verifyCybersourceCardBrand(cards){
    this.visa = false
    this.master = false
    this.amex = false
    cards.forEach(card => {
      if(card.name==='visa'){
        this.visa = true
        this.master=false
        this.amex = false
      }else if (card.name=='mastercard'){
        this.visa = false
        this.master = true
        this.amex = false
      }else if (card.name=='amex'){
        this.visa = false
        this.master = false
        this.amex = true
      }
    });
  }

  onKey(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
  
    let cleanInput = input.value.replace(/\D/g, '');
  
    if (cleanInput.length > 4) {
      cleanInput = cleanInput.substring(0, 4);
    }
  
    const groups = cleanInput.match(/.{1,2}/g);
  
    if (groups) {
      let formattedInput = groups.join('/');
  
      if (formattedInput.endsWith('/') && formattedInput.length > 3) {
        formattedInput = formattedInput.slice(0, -1);
      }
  
      input.value = formattedInput;
    }
  }

  verifyExpiration(){
    console.log("Exp, ", this.cardExpDate)
    let today = new Date();
    let year =  today.getFullYear();
    let month = today.getMonth() + 1;
    let curYear = year % 100

    console.log("Mes ", month, " a;o ", curYear)
    let strSplit = this.cardExpDate.split('')
    let strMonth = strSplit[0]+strSplit[1]
    let strYear = strSplit[3]+strSplit[4]
    let numMonth = Number(strMonth)
    let numYear = Number(strYear)
    console.log(typeof(numYear))
    if(this.cardExpDate.length > 4){
      console.log("Es mayor a 4")
      if(numMonth > 12){
        console.log("MAS DE 12")
        this.invalidDate = true
        return
      }
      if(curYear == numYear){
        if(numMonth >= month){
          console.log("valido Mes ", strMonth, " a;o ", strYear)
          this.invalidDate = false
        }
        else{
          this.invalidDate = true
          console.log("invalido Mes ", strMonth, " a;o ", strYear)
        }
      }
      else if(curYear < numYear){
        console.log("valido Mes ", strMonth, " a;o ", strYear)
          this.invalidDate = false
      }
      else{
        console.log("Mes ", strMonth, " a;o ", strYear)
        console.log("invalido")
        this.invalidDate = true
      }
    }
    else{
      this.invalidDate = true
    }

  }

  isPaymentValid(){
    return this.isCVVFilled && this.isCardFilled
  }

  calcYearsOld(birthday){
    const today = new Date();
    let age = today.getFullYear() - birthday.getFullYear();
    const monthDifference = today.getMonth() - birthday.getMonth();
    
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }

    return age;
  }

  convertirFecha(fecha: any) {
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const fechaConvertida = new Date(fecha);
    const dia = fechaConvertida.getDate();
    const mes = meses[fechaConvertida.getMonth()];
    const ano = fechaConvertida.getFullYear();
    return `${dia} ${mes} ${ano}`;
  }

  encryptData(text) {
    this.pk = this.gs.SC_public_PEM_key
    let public_key = forge.pki.publicKeyFromPem(this.pk);
    let encrypted = public_key.encrypt(text, "RSA-OAEP", {
      md: forge.md.sha256.create(),
      mgf1: forge.mgf.mgf1.create(forge.md.sha256.create()) // ---> Cambio ---> Pendiente
    });
    var base64 = forge.util.encode64(encrypted);
    return base64
  }

  onSubmit(){
    this.disBtn = true
    
    let payment_name = this.cardName
    let expiration_date = this.encryptData(this.cardExpDate)
    let formattedExpDateForCybersource = this.cardExpDate.split('/')
    let expirationDateYearFormattedForCybersource = '20'+formattedExpDateForCybersource[1]
    var options = {
      expirationMonth: formattedExpDateForCybersource[0],
      expirationYear: expirationDateYearFormattedForCybersource
    }

    // console.log('---> VARIABLES')
    // console.log(payment_name)
    // console.log(expiration_date)
    // console.log(options)

    let createCybersourceCardToken = new Promise((res,rej)=>{
      this.microform.createToken(options, function (err, token) {
        if (err) {
          

          rej(err)
        } else {
          // At this point you may pass the token back to your server as you wish.
          // In this example we append a hidden input to the form and submit it.
          

          res(token)
          }
          })
        }
      )
      createCybersourceCardToken.then(
        cardToken => {
          let payment_token = cardToken.toString()

          // Holder
          this.gs.currentInsuranceInfo.holder.phone1 = this.gs.currentInsuranceInfo.holder.prefix + this.gs.currentInsuranceInfo.holder.phone1;
          this.gs.currentInsuranceInfo.holder.name = this.gs.currentInsuranceInfo.holder.first_name + this.gs.currentInsuranceInfo.holder.last_name;

          // Insured
          this.gs.currentInsuranceInfo.insured = this.gs.currentInsuranceInfo.holder;

          // Destinations
          this.gs.currentInsuranceInfo.destinations =  [
              {
                  "days": this.gs.currentInsuranceInfo.days_of_travel,
                  "origin": this.gs.currentInsuranceInfo.holder.address,
                  "destination": this.gs.destiny[0].value,
                  "region": this.gs.destiny[0].region,
                  "initDate": this.gs.currentInsuranceInfo.travel_starting_date,
                  "endDate": this.gs.currentInsuranceInfo.travel_end_date,
              }
          ]

          // PaymentInfo
          this.gs.currentInsuranceInfo.payment_info = {
            "payment_responsable": this.gs.currentInsuranceInfo.holder,
            "payment_type": 1,
            "payment_name": this.cardName,
            "expiration_date": expiration_date,
            "payment_token": payment_token,
            "policy_charge":1  
        }

          // console.log('---> OBJETO', this.gs.currentInsuranceInfo)
          // console.log(payment_token)
          
          this.api.enrollAsys.post(this.gs.currentInsuranceInfo)
          .promise().then(resp => {

            if (resp.status == 200 && resp.data.policyNumber !== '') {

              // console.log('---> DATA POLIZA', resp)
              this.gs.policyNumber = resp.data.policyNumber;
              this.gs.transactionNumber = resp.data.transaction;
              this.gs.email = this.gs.currentInsuranceInfo.holder.email;
              this.router.navigate(['/public/thank-you'], { queryParamsHandling: 'preserve' });
            } else {
              if ('message' in resp) {
                this.gs.openDialogMuvit(resp.message);
                this.disBtn = false
                // this.dataConstructor.buyLoader = false
              } else {
                this.gs.openDialogMuvit(this.errorMessage);
                this.disBtn = false
                // this.dataConstructor.buyLoader = false
              }
            }
          }, error => {
            this.disBtn = false
            // this.dataConstructor.buyLoader = false
          })
          .catch(e => {
            this.disBtn = false
            this.gs.openDialogMuvit(this.errorMessage);
            // this.dataConstructor.buyLoader = false

          })
      }
      ).catch(
        err => {        
          let errorLocation = '';
          for (let i = 0; i < err.details.length; i++) {
            const errorDetail = err.details[i];
            errorLocation = errorDetail.location
            this.disBtn = false
            // this.dataConstructor.buyLoader = false
            break
          }
          // this.dataConstructor.buyLoader = false
          this.disBtn = false
          this.gs.openDialogMuvit(getMicroFormError(errorLocation))}
      )

    this.disBtn = false
  }
}

