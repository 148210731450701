import { Component, ElementRef, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ImageLoadingService } from 'src/app/services/image-loading.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit{
  
  constructor(private imageLoadingService: ImageLoadingService,  private el: ElementRef, private activatedRoute: ActivatedRoute, 
    private title: Title){ 
    
    this.title.setTitle(this.activatedRoute.title['destination']['_value']['title'])
  }

  ngOnInit(): void {
    this.imageLoadingService.checkImagesLoaded(this.el.nativeElement);
    
  }
  ngOnDestroy(): void {
  }



}
