<div class="client-container">
    <app-step-header [activeStep]="2"></app-step-header>

    <div class="content">
        <form #holderform="ngForm">
            <div class="title-content">
                <span class="title">Datos requeridos</span>
                <span class="title-message">Completa la información de los viajeros</span>
            </div>

            <div class="form-holder-content">
                <div class="holder-title">
                    <span>Datos del viajero</span>
                </div>
                
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="name">Nombre(s)*</label>
                            <input id="name" name="name" type="text" class="form-control" required [(ngModel)]="gs.currentInsuranceInfo.holder.first_name"/>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="last-name">Apellido(s)*</label>
                            <input id="last-name" name="last-name" type="text" class="form-control" [(ngModel)]="gs.currentInsuranceInfo.holder.last_name"/>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="genre">Género*</label>
                            <select id="genre" name="genre" class="form-control" [(ngModel)]="gs.currentInsuranceInfo.holder.gender">
                                <option [value]="null" disabled selected> Selecciona una opción </option>
                                <option *ngFor="let option of genreOptions" [value]="option.value">{{option.description}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="birthday">Fecha nacimiento*</label>
                            <input class="form-control" matInput [matDatepicker]="picker" placeholder="Selecciona una fecha" (ngModelChange)="onDateChange($event)"
                            required readonly  id="birthday" name="birthday" (click)="openCalendar()" [max]="maxDate" [min]="minDate" [(ngModel)]="gs.currentInsuranceInfo.holder.birthday">
                            <mat-datepicker-toggle matSuffix [for]="picker" style="display: none;"></mat-datepicker-toggle>
                            <mat-datepicker #picker startView="multi-year"></mat-datepicker>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="passport">No. Pasaporte*</label>
                            <input id="passport" name="passport" type="text" class="form-control" [(ngModel)]="gs.currentInsuranceInfo.holder.identification_id"/>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="country">Pais residencia*</label>
                            <select id="country" name="country" class="form-control" (ngModelChange)="onCountryChange($event)" [(ngModel)]="gs.currentInsuranceInfo.holder.address" >
                                <option [value]="null" disabled selected> Selecciona una opción </option>
                                <option *ngFor="let option of countries" [value]="option.value">{{option.description}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group size-margin-bottom">
                            <label for="email">Correo electrónico*</label>
                            <input id="email" name="emial" type="text" class="form-control" [(ngModel)]="gs.currentInsuranceInfo?.holder.email"/>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="row size-margin-bottom">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="prefix">Prefijo* </label>
                                    <select name="prefix" class="form-select" required id="prefix" [(ngModel)]="gs.currentInsuranceInfo.holder.prefix">
                                        <option value="" disabled selected> + --- </option>
                                        <!-- [selected]="option.value === value" -->
                                        <option *ngFor="let option of prefixList" [value]="option.value">+ {{option.value}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-7">
                                <div class="form-group">
                                    <label for="phone"> N&uacute;mero de Tel&eacute;fono* </label>
                                    <input type="phone" name = "phone" class="form-control" required  id="phone" [(ngModel)]="gs.currentInsuranceInfo.holder.phone1">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="cost">Costo Títular</label>
                            <br>
                            <span class="text-accompanist-2">${{gs.priceHolder}}</span>
                        </div>
                    </div>
                </div>
                
                <div class="confirmation-message">
                    <span>Enviaremos la confirmación a este correo</span>
                </div>
                
                <div class="conditions">
                    <input type="checkbox" [checked]="termsAndConditions" (change)="termsAndConditions = !termsAndConditions">
                    <p style="margin: 0;">Acepto los <a class="conditions-underline" [routerLink]="'/public/terms-and-conditions'" target="_blank"> términos y condiciones </a> de Muvit</p>
                </div>
            </div>

            <div class="accepted-cards">
                <img src="../../../assets/muvitBAM/recomendacion.svg" alt="Tip">

                <span class="text-cards">Puedes realiza tus pagos con tarjetas de crédito o débito</span>

                <img src="../../../assets/muvitBAM/visa.svg" alt="Visa" class="visa-img">

                <img src="../../../assets/muvitBAM/mastercard.svg" alt="Mastercard">
            </div>

            <div class="accompanists-title">
                <span>Ingrese acompañantes (Opcional)</span>
            </div>

            <div class="accompanists-message">
                <span>Agregar viajeros adicionales es opcional. Ten en cuenta que cada acompañante tiene un costo que depende de su edad, país de origen y destino, y la fecha del viaje.</span>
            </div>

            <div>
                <div *ngFor="let element of accompanist; let i = index">
                    <div class="accompanist-content">
                        <div class="section">
                            <div class="sub-section">
                                <span class="text-accompanist-1">Costo por viajero {{ i +1 }}</span>
                                <div matTooltip="El costo de tus acompañantes puede variar según su edad." matTooltipPosition="above">
                                    <img src="../../../assets/muvitBAM/circle-question-help.svg" alt="Info">
                                </div>
                            </div>
                            <span class="text-accompanist-2">${{gs.prices[i]}}</span>
                        </div>

                        <div class="section accompanist-name">
                            {{ element.first_name }} {{ element.last_name }}
                        </div>

                        <div class="section accompanist-age">
                            Edad: {{calcYearsOld(element.birthday)}} años
                        </div>

                        <div class="section accompanist-option">
                            <img src="../../../assets/muvitBAM/editar.svg" alt="Editar">
                            <span (click)="openDialog(i)">Editar</span>
                        </div>

                        <div class="section accompanist-option">
                            <img src="../../../assets/muvitBAM/cerrar.svg" alt="Eliminar">
                            <span (click)="deleteAccompanist(i)">Eliminar</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="accompanists-option">
                <img src="../../../assets/muvitBAM/acompañantes.svg" alt="Agregar">

                <span (click)="openDialog(null)">Añadir acompañantes</span>
            </div>

            <div class="btn-options">
                <button class="btn btn-cancel">
                    Cancelar
                </button>
                    
                <button class="btn btn-continue" (click)="goPay()" [disabled]="holderform.invalid || !correctAge || !termsAndConditions">
                    Continuar
                </button>
            </div>
        </form>
    </div>
</div>