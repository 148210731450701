<ng-container *ngIf="showNavBar">
    <app-nav-bar-mobile
      style="width: 100vw; position: fixed; top: 0; z-index: 20;"
      *ngIf="deviceInfo == 'Mobile'">
    </app-nav-bar-mobile>
    <app-nav-bar
      style="width: 100vw; position: fixed; top: 0; z-index: 20;"
      *ngIf="deviceInfo == 'Desktop'">
    </app-nav-bar>
  </ng-container>
  
  <div
    style="width: 100%;"
    [style.padding-top]="showNavBar && deviceInfo == 'Desktop' ? '67px' : '65px'"
    *ngIf="deviceInfo == 'Desktop'">
  </div>
  <div style="width: 100%;" style="padding-top: 50px;" *ngIf="deviceInfo == 'Mobile'"></div>
  
  <router-outlet></router-outlet>
  
  <footer *ngIf="showNavBar && deviceInfo == 'Desktop'"></footer>
  
  <app-loader
    *ngIf="(deviceInfo == 'Desktop' ? (loading || imagesLoading) : loading)"
    style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 100;">
  </app-loader>
  