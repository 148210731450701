<div class="footer">
    <div class="legal">
        <h3>Legal</h3>
        <a [routerLink]="'/public/terms-and-conditions'" [queryParamsHandling]="'preserve'" id="btnTyC">T&eacute;rminos
            y Condiciones</a>
        <a href="">Políticas de Seguridad</a>
        <div class="copyright">
            <div><b>Muvit {{year}} - Bancoagrícola</b></div>
        </div>
    </div>
    <div class="contact">
        <div style="display: flex; flex-direction: column; gap: 5px;">
            <h3>Contacto de emergencia</h3>
            <span><i class="fa-brands fa-whatsapp"></i> <a href="https://wa.me/5491124726164" target="_blank">+54 911
                    2472 6164</a></span>
            <span><i class="fa-solid fa-phone"></i><a href="tel:+502 2268 7757" target="_blank">+502 2268
                    7757</a></span>
        </div>
    </div>
    <div class="contact">
        <div style="display: flex; flex-direction: column; gap: 5px;">
            <h3>Contacto de Información</h3>
            <span style="display: flex; align-items: center;"><i class="fa-solid fa-envelope"></i> <a
                    href="mailto:info@muvit.travel" target="_blank"> soporte&#64;muvit.travel </a></span>
        </div>
    </div>

    <div class="social-media">
        <h3>S&iacute;guenos</h3>
        <div class="buttons-media">
            <button (click)="goTo('facebook')" id="btnFacebook"> <i class="fa-brands fa-facebook"></i> </button>
            <button (click)="goTo('instagram')" id="btnInstagram"> <i class="fa-brands fa-instagram"></i> </button>
            <button (click)="goTo('youtube')" id="btnYoutube"> <i class="fa-brands fa-youtube"></i> </button>
            <button (click)="goTo('tiktok')" id="btnTikTok"> <i class="fa-brands fa-tiktok"></i> </button>
        </div>
    </div>
    <div class="brand">
        <img src="../../../assets/muvitBAM/Muvit-BancoAgricola-footer.svg" alt="Muvit Seguros de Viaje">
    </div>
</div>