import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { CustomerInformationComponent } from './customer-information/customer-information.component';
import { AffiliatesInformationComponent } from './affiliates-information/affiliates-information.component';
import { NavbarServiceService } from '../navbar-service.service';
import Swal from 'sweetalert2';
import { error } from 'console';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { deviceDetectorService } from 'src/app/services/device-detector.service';
import { BeneficiarySvgComponent } from '../home-page/beneficiary-svg/beneficiary-svg.component';
import { Title } from '@angular/platform-browser';
import { InsuranceHolder } from 'src/app/models/insurance-holder';
import { GlobalService } from 'src/app/services/global.service';
import { GenericInsurance } from 'src/app/models/generic-insurance';
import * as moment from 'moment';
import { InsuranceAccompanist } from 'src/app/models/insurance-accompanist';
import { InsuranceBeneficiary } from 'src/app/models/insurance-beneficiary';
import { MatDialog } from '@angular/material/dialog';
import { AddAccompanistComponent } from './add-accompanist/add-accompanist.component';
import { MatDateRangePicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-client-information',
  templateUrl: './client-information.component.html',
  styleUrls: ['./client-information.component.css']
})

export class ClientInformationComponent implements OnInit {
  genreOptions = [
    { description: 'Masculino', value: '1' },
    { description: 'Femenino', value: '0' }
  ]

  countriesList: any = [];
  countries: any = [];
  prefixList: any = [];
  countryCatalog: any = [];

  minDate: any;
  maxDate: any;

  showMessage = false;
  accompanist?: InsuranceAccompanist[] = [];
  termsAndConditions = false;

  correctAge = true;
  @ViewChild('picker') picker: MatDateRangePicker<any>;

  constructor(
    public dialog_: MatDialog,
    private api: ApiService,
    public gs: GlobalService,
    public navBar: NavbarServiceService,
    private router: Router
  ) {
    console.log('dias de viaje ', this.gs.currentInsuranceInfo.days_of_travel)
    this.maxDate = new Date(Date.now());
    const currentDate = new Date();
    this.minDate = new Date();
    this.minDate.setFullYear(currentDate.getFullYear() - 85)

    this.api.countries.getCountryCode().promise().then(resp => {
      this.countryCatalog = resp.map(country => ({ description: country.name, id: country.id }));
    })

    this.api.countries.getCountryAreaCode().promise().then(resp => {
      let country_code = resp.filter(country => country.id != null).map(country => parseInt(country.area_code));
      country_code = this.ordenarYEliminarDuplicados(country_code);
      this.prefixList = country_code.map(prefix => ({ value: prefix }));
      this.countries = resp.map(country => ({ description: country.name, value: country.name, id: country.id }));
      this.countriesList = resp.map(country => ({ id: country.id, description: country.name, value: country.area_code }));
    })
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.gs.currentInsuranceInfo.holder.identification_type = 9;
    if(this.gs.currentInsuranceInfo.accompanisttable && this.gs.currentInsuranceInfo.accompanisttable.length > 0) this.accompanist = this.gs.currentInsuranceInfo.accompanisttable;
    if(this.gs.currentInsuranceInfo.holder.birthday) this.getHolderPrice(this.gs.currentInsuranceInfo.holder.birthday);
    this.getDependentsPrice();

    this.gs.changeFinalPrice.subscribe(value => {
      if(this.gs.currentInsuranceInfo.holder.birthday){
        this.getHolderPrice(this.gs.currentInsuranceInfo.holder.birthday);
      }
      this.getDependentsPrice();
    });
  }

  openDialog(position) {
    this.gs.showMessageAdded = false;
    let dialogRef = this.dialog_.open(AddAccompanistComponent, {
      width: '40%',
      data: position != null ? Object.assign({}, this.accompanist[position]) : null
    });

    dialogRef.afterClosed().subscribe(dependent => {
      if(dependent) {
        let exist = false;
        for (let i = 0; i < this.accompanist.length; i++) {
          if(position != null && i == position) continue
          if(this.accompanist[i].identification_id == dependent.identification_id) exist = true;
        }

        if(exist || this.gs.currentInsuranceInfo.holder.identification_id == dependent.identification_id){
          this.showAlert('Pasaporte repetido', 'Pasaporte repetido con el títular o algún acompañante, por favor verifique.');
          return
        }

        let payload = {
          "insurance_plan_id": this.gs.currentInsuranceInfo.insurance_plan_id,
          "product_code": this.gs.currentInsuranceInfo.product_code,
          "days_of_travel": this.gs.currentInsuranceInfo.days_of_travel,
          "destinations": [
            {
              "region": this.gs.destiny[0].region
            }
          ],
          "accompanisttable": [
            {
              "birthday": dependent.birthday.toISOString()
            }
          ]
        }

        this.api.enrollAsys.getIndividualPrice(payload).promise()
          .then(response => {
            
            if(position != null) {
              this.accompanist[position] = dependent;
              this.gs.prices[position] = response;
            } else {
              this.accompanist.push(dependent);
              this.gs.prices.push(response);
            }

            if(this.gs.currentInsuranceInfo.holder.birthday) this.getGeneralPrice();
            
            this.gs.showMessageAdded = true;
            this.gs.messageAdded = position != null ? 'Tu viajero se modificó correctamente' : 'Tu viajero se agregó correctamente';

            setTimeout(() => {
              this.gs.showMessageAdded = false;
            }, 7500);
          })
          .catch(e => {
            this.showAlert('NO SE PUDO OBTENER EL PRECIO POR ACOMPAÑANTE', 'Intente de nuevo en un momento');
          })
      }
    });
  }

  deleteAccompanist(pos){
    this.accompanist.splice(pos, 1);
    this.gs.prices.splice(pos, 1);
    this.getGeneralPrice();
  }

  getCountryCode(country: any) {
    return this.countriesList.find(element => element.description == country)
  }

  ordenarYEliminarDuplicados(numeros: number[]): number[] {
    numeros.sort((a, b) => a - b);
    return [...new Set(numeros)]
  }

  openCalendar() {
    if (!this.picker.opened) {
      this.picker.open();
    }
  }

  calcYearsOld(birthday){
    const today = new Date();
    let age = today.getFullYear() - birthday.getFullYear();
    const monthDifference = today.getMonth() - birthday.getMonth();
    
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }

    return age;
  }

  onCountryChange(value) {
    const country = this.getCountryCode(value)
    this.gs.currentInsuranceInfo.holder.prefix = country.value;
    this.gs.currentInsuranceInfo.holder.country_id = country.id;
  }

  onDateChange(value){
    const age = this.calcYearsOld(value);

    if (age < 18) {
      this.showAlert('Error en Títular', 'El títular debe ser mayor de edad');

      this.correctAge = false;
      return;
    }

    this.correctAge = true;
    this.getHolderPrice(value);
  }

  getHolderPrice(value){
    let payload = {
      "insurance_plan_id": this.gs.currentInsuranceInfo.insurance_plan_id,
      "product_code": this.gs.currentInsuranceInfo.product_code,
      "days_of_travel": this.gs.currentInsuranceInfo.days_of_travel,
      "destinations": [
        {
          "region": this.gs.destiny[0].region
        }
      ],
      "insured": {
        "birthday": value.toISOString()
      }
    }

    this.api.enrollAsys.getIndividualPrice(payload).promise()
      .then(response => {
        this.gs.priceHolder = response;

        this.getGeneralPrice();
      })
      .catch(e => {
        this.showAlert('NO SE PUDO OBTENER EL PRECIO PARA EL TITULAR', 'Intente de nuevo en un momento');
      })
  }

  getDependentsPrice() {
    for (let i = 0; i < this.accompanist.length; i++) {
      let payload = {
        "insurance_plan_id": this.gs.currentInsuranceInfo.insurance_plan_id,
        "product_code": this.gs.currentInsuranceInfo.product_code,
        "days_of_travel": this.gs.currentInsuranceInfo.days_of_travel,
        "destinations": [
          {
            "region": this.gs.destiny[0].region
          }
        ],
        "accompanisttable": [
          {
            "birthday": this.accompanist[i].birthday.toISOString()
          }
        ]
      }

      this.api.enrollAsys.getIndividualPrice(payload).promise()
        .then(response => {
          this.gs.prices[i] = response
        })
        .catch(e => {
          this.showAlert('NO SE PUDO OBTENER EL PRECIO POR ACOMPAÑANTE', 'Intente de nuevo en un momento');
        })
    }
  }

  goPay(){
    this.gs.currentInsuranceInfo.accompanisttable = this.accompanist && this.accompanist.length > 0 ? this.accompanist : null;
    console.log(this.gs.currentInsuranceInfo)
    this.gs.showMessageAdded = false;
    this.router.navigate(['/public/payment'], { queryParamsHandling: 'preserve' });
  }

  showAlert(title, text) {
    Swal.fire({
      title: title,
      text: text,
      icon: 'error',
      showConfirmButton: true,
      showDenyButton: false,
      denyButtonText: 'Regresar',
      confirmButtonText: 'Aceptar',
      showCloseButton: true,
      timer: 25000
    })
  }

  getGeneralPrice(){
    let data = {
      "insurance_plan_id": this.gs.currentInsuranceInfo.insurance_plan_id,
      "product_code": this.gs.currentInsuranceInfo.product_code,
      "days_of_travel": this.gs.currentInsuranceInfo.days_of_travel,
      "destinations": [
        {
          "region": this.gs.destiny[0].region
        }
      ],
      "insured": {
          "birthday": this.gs.currentInsuranceInfo.holder.birthday.toISOString()
      },
      "accompanisttable": [ ]
    }

    for(let accompanist of this.accompanist) {
      data['accompanisttable'].push({
        "birthday": accompanist.birthday.toISOString()
      })
    }

    this.api.enrollAsys.getPrice(data).promise()
      .then(response =>{
        this.gs.totalPrice = response;
      })
      .catch(e => {
        this.showAlert('NO SE PUDO OBTENER EL PRECIO', 'Intente de nuevo en un momento');
      })
  }
}