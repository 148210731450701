import { Component, ElementRef, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ImageLoadingService } from 'src/app/services/image-loading.service';
import { Questions } from './faq';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';


@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items || !searchText) return items;

    // Normaliza el texto de búsqueda y elimina los acentos
    const normalize = (text: string) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    const normalizedSearchText = normalize(searchText);

    // Filtra las preguntas que contienen el texto de búsqueda en su contenido o respuesta
    return items.filter(question => {
        const questionText = normalize(question.question);
        const answerText = normalize(question.answer);
        return questionText.includes(normalizedSearchText) || answerText.includes(normalizedSearchText);
    });
  }
}
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FAQComponent implements OnInit {


  filter = ''

  Question = [];
  constructor(private imageLoadingService: ImageLoadingService,  private el: ElementRef, private activatedRoute: ActivatedRoute, 
    private title: Title,  private router: Router){ 
    this.title.setTitle(this.activatedRoute.title['destination']['_value']['title'])
    

  }
  ngOnInit(): void {
    this.Question = Questions;
    this.imageLoadingService.checkImagesLoaded(this.el.nativeElement);
    const url = this.router.url; // Obtén la URL actual

    if (url.includes('mobile')) {
      this.updateChubbDataFront({
        platform: 'Mobile',
        stepName: "faq" 
      })
      this.sendGTMData({
        event: "PageView",
        pagePath: "/public/mobile/FAQ", 
        pageTitle: "Muvit Travel: FAQ"
        })
    } else {
      this.updateChubbDataFront({
        stepName: "faq" 
      })
      this.sendGTMData({
        event: "PageView",
        pagePath: "/public/FAQ", 
        pageTitle: "Muvit Travel: FAQ"
        })
    }

  }
  ngOnDestroy(): void {
  }
  sendGTMData(data){
  }
  updateChubbDataFront(updatedData: Partial<any>): void {
    const chubbDataFrontKey = 'chubbDataFront';
  }


}
