import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleService } from './services/google.service';
import { NavbarServiceService } from './components/navbar-service.service';
import { LoaderService } from './services/loader.service';
import { ComponentLoaderService } from './services/component-loader.service';
import { ImageLoadingService } from './services/image-loading.service';
import { ViewportScroller } from '@angular/common';
import { deviceDetectorService } from './services/device-detector.service';
import { Subscription } from 'rxjs';
import { DataConstructorService } from './services/data-constructor.service';
import { MatDialog } from '@angular/material/dialog';
//import { DialogAdGiveawayComponent } from './components/dialog-ad-giveaway/dialog-ad-giveaway.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit{

  showGiveAway = true;

  loading: boolean;
  componentLoading: boolean;
  imagesLoading: boolean;
  deviceInfo: any;
  deviceInfoSubscription: Subscription;

  isLoading: boolean = false;

  startLoading() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 4000); 
  }


  showNavBar: boolean = true; 



  constructor(
    private titleService: Title,
    private router: Router,
    public translate: TranslateService,
    private googleService: GoogleService,
    public navbarService: NavbarServiceService,
    private loaderService: LoaderService,
    private ComponentLoaderService: ComponentLoaderService,
    private imageLoadingService: ImageLoadingService,
    private viewportScroller: ViewportScroller,
    private device: deviceDetectorService,
    public dataConstrucor: DataConstructorService,
    private el: ElementRef,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {
    // The HttpLoaderFactory is required for AOT
    // (ahead of time) compilation in your project.
   

    this.deviceInfoSubscription = this.device.deviceType.subscribe(deviceType => {

      this.deviceInfo = deviceType;
    })

    
  }


  ngAfterViewInit(): void {
 
  }
  ngOnInit(): void {
    // Control de carga general
    this.loaderService.isLoading.subscribe((loading) => {
      this.loading = loading;
      if (loading) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    });
  
    // Control de carga de imágenes
    this.imageLoadingService.areImagesLoaded.subscribe((loaded) => {
      this.imagesLoading = !loaded; // Esto invierte el valor para mantener el loader visible mientras no cargan
      if (loaded) {
        this.imageLoadingService.checkImagesLoaded(this.el.nativeElement);
      }
    });
  
    // Detecta cambios en la navegación
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const isLoadingRoute = event.urlAfterRedirects.includes('/public/loading');
        this.showNavBar = !isLoadingRoute; // Oculta el navbar en la ruta de carga
        if (isLoadingRoute) {
          this.loading = false; // Fuerza a desactivar el loader en la ruta de carga
          this.imagesLoading = false;
        }
      }
    });
  }
  
  

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.device.detectDevice();
  }

  ngOnDestroy() {
    if (this.deviceInfoSubscription) {
      this.deviceInfoSubscription.unsubscribe();
    }
  }

  title = 'app';
  name = '@ngx-share/buttons';

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  getFavIcon(state,parent){
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.fav_icon) {
      data.push(parent.snapshot.data.fav_icon);
    }

    if (state && parent) {
      data.push(... this.getFavIcon(state, state.firstChild(parent)));
    }
    return data;
  }
}
