<div class="form-input-container">
    <div class="select-contain">
    <label for="form-select">{{Title}} <span *ngIf="isRequired">*</span> </label>
    <select name="form-select" class="form-select" [required]="isRequired" (change)="onSelectChange($event)" id="cardSelectPrefix">
      <option value="" disabled selected> + --- </option>
      <option *ngFor="let option of Options" [value]="option.value" [selected]="option.value === value">+ {{option.value}}</option>
    </select>
    </div>
    <div class="input-contain">
    <label for="form-input"> {{InputTitle}} <span *ngIf="isRequired">*</span></label>
    <input type="number" name = "form-input" class="form-input" [required]="isRequired" (change)="onInputChange($event)" id="cardInputPhone">
    </div>
  </div>
  