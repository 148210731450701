
import { Component, Input } from '@angular/core';
import { DataConstructorService } from 'src/app/services/data-constructor.service';

@Component({
  selector: 'app-plan-table-mobile',
  templateUrl: './plan-table.component.html',
  styleUrls: ['./plan-table.component.css']
})
export class PlanTableMobileComponent {
  constructor(private data:DataConstructorService){}
  @Input() dataSource: any = [];
  displayedColumns = ['description', 'price']
}
