import { Component, OnInit, Input } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router'

@Component({
  selector: 'app-step-header',
  templateUrl: './step-header.component.html',
  styleUrls: ['./step-header.component.css']
})
export class StepHeaderComponent implements OnInit {
  @Input() activeStep: number = 1;
  @Input() movil: boolean = false;

  constructor(
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  back() {
    let url = this.movil ? '/public/mobile' : '/public';
    if(this.activeStep == 1) this.router.navigate([url], { queryParamsHandling: 'preserve' });
    else if(this.activeStep == 2) this.router.navigate([url + '/plans'], { queryParamsHandling: 'preserve' });
    else if(this.activeStep == 3) this.router.navigate([url + '/client-info'], { queryParamsHandling: 'preserve' });
    else if(this.activeStep == 4) this.router.navigate([url], { queryParamsHandling: 'preserve' });
  }

}
