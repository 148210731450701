import { NgModule } from '@angular/core';
import { RouterModule, Routes,PreloadAllModules } from '@angular/router';
import { HomePageComponent } from '../components/home-page/home-page.component';
import { PlansPageComponent } from '../components/plans-page/plans-page.component';
import { ClientInformationComponent } from '../components/client-information/client-information.component';
import { CustomerInformationComponent } from '../components/client-information/customer-information/customer-information.component';
import { AffiliatesInformationComponent } from '../components/client-information/affiliates-information/affiliates-information.component';
import { ThankYouComponent } from '../components/thank-you/thank-you.component';
import { PaymentFormComponent } from '../components/payment-form/payment-form.component';
import { HomePageMobileComponent } from '../components/tecniseguros-movil/home-page-mobile/home-page-mobile.component';
import { PlanQuotationMobileComponent } from '../components/tecniseguros-movil/plan-quotation-mobile/plan-quotation-mobile.component';
import { StepFormComponent } from '../components/tecniseguros-movil/step-form/step-form.component';
import { ClientFormMobileComponent } from '../components/tecniseguros-movil/client-form-mobile/client-form-mobile.component';
import { workflowCheckerMobileGuard } from '../guard/workflow-checker-mobile.guard';
import { workflowCheckerGuard } from '../guard/workflow-checker.guard';
import { PaymentMobileComponent } from '../components/tecniseguros-movil/payment-mobile/payment-mobile.component';
import { LoadingComponent } from '../components/loading/loading.component';
import { FAQComponent } from '../components/faq/faq.component';
import { TermsAndConditionsComponent } from '../components/terms-and-conditions/terms-and-conditions.component';
import { MuvitBlogComponent } from '../components/muvit-public/muvit-blog/muvit-blog.component';
const routes: Routes = [
 
  {
    path:"public",
    component: HomePageComponent, 
    data:{ 
          title:'BAM | Muvit - Home',
          fav_icon:'../assets/muvitBAM/icono.png'
        }
  },
  {
    //Loading component
    path: "public/loading",
    component: LoadingComponent,
    data:{
      title: 'BAM | Muvit - Loading',
      fav_icon:'../assets/muvitBAM/icono.png'
    }
  },
  {
    path:"public/plans",
    component: PlansPageComponent,   //PlansPageComponent
    data:{ 
          title:'BAM | Muvit - Planes',
          fav_icon:'../assets/muvitBAM/icono.png'
        },
        canActivate: [workflowCheckerGuard]

  },
  {
    path:"public/client-info",
    component: ClientInformationComponent,
    data:{
      title:'BAM | Muvit - Información del Cliente',
      fav_icon:'../assets/muvitBAM/icono.png'
    },
    canActivate: [workflowCheckerGuard]

  },
  //ThankYou
  {
    path:"public/thank-you",
    component: ThankYouComponent,
    data:{
      title:'BAM | Muvit - Gracias',
      fav_icon:'../assets/muvitBAM/icono.png'
    },
  },
  //Payment
  {
    path:"public/payment",
    component: PaymentFormComponent ,
    data:{
      title:'BAM | Muvit - Formulario de Pago',
      fav_icon:'../assets/muvitBAM/icono.png'
    },
     canActivate: [workflowCheckerGuard]

  },
  {
    path:"public/mobile",
    component: HomePageMobileComponent,
    data:{
      title: 'BAM | Muvit - Home',
      fav_icon:'../assets/muvitBAM/icono.png'
    }
  },
  {
    path:'public/mobile/plans',
    component: PlanQuotationMobileComponent,
    data:{
      title: 'BAM | Muvit - Cotización de Planes',
      fav_icon:'../assets/muvitBAM/icono.png'
    },
    canActivate: [workflowCheckerMobileGuard]
    
  },
  {
    path:"public/mobile/client",
    component: StepFormComponent,
    data:{
      title: 'BAM | Muvit - Cotización de Planes',
      fav_icon:'../assetsmuvitBAMicono.png'
    },
  },
  {
    path:'public/mobile/client-info',
    component: ClientFormMobileComponent,
    data:{
      title: 'BAM | Muvit - Información del Cliente',
      fav_icon:'../assets/muvitBAM/icono.png'
    },
    canActivate: [workflowCheckerMobileGuard]
  },

  {
    path:"public/mobile/payment",
    component: PaymentMobileComponent,
    data:{
      title: 'BAM | Muvit - Formulario de Pago',
      fav_icon:'../assets/muvitBAM/icono.png'
    },
    canActivate: [workflowCheckerMobileGuard]

  },
  {
    path:"public/FAQ",
    component: FAQComponent,
    data:{
      title:'BAM | Muvit: FAQ',
      fav_icon:'../assets/muvitBAM/icono.png'
    }
  },
  {
    path:"public/mobile/FAQ",
    component: FAQComponent,
    data:{
      title:'BAM | Muvit: FAQ',
      fav_icon:'../assets/muvitBAM/icono.png'
    }
  },
  {
    path:"public/terms-and-conditions",
    component: TermsAndConditionsComponent,
    data:{
      title: 'BAM | Muvit - Terminos y Condiciones',
      fav_icon: '../assets/muvitBAM/icono.png'
    }
  },
  {
    path:"public/mobile/muvit-blog",
    component: MuvitBlogComponent,
    data:{ title:'Muvit Travel: Blog',
          fav_icon:'../assets/muvitBAM/icono.png'
        }
  },
  {
    path:"public/muvit-blog",
    component: MuvitBlogComponent,
    data:{ title:'Muvit Travel: Blog',
          fav_icon:'../assets/muvitBAM/icono.png'
        }
  },
  {
    path: '**',
    redirectTo: 'public'
  },

]

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class RoutesModule { }
