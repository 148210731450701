<div style="height: 20px; width: 100%;">
    <div class="nav-bar">
      <div class="navbar-brand">
        <div>
          <img src="../../../assets/muvitBAM/both.png" alt="muvitBAM" style="height: 43px;" id="btnLogo">
        </div>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="loading-page">
      <div class="branding-and-illustration">
        <img class="logo left-logo" src="../../../assets/muvitBAM/BancoAgricola.svg" alt="BancoAgrícola">
        <div class="illustration">
          <img src="../../../assets/muvitBAM/loading.gif" alt="World Travel" class="world-image">
        </div>
        <img class="logo right-logo" src="../../../assets/muvitBAM/Muvit.svg" alt="Muvit">
      </div>
      <div class="message">
        <h2>Te estamos llevando al sitio web de nuestro aliado Muvit</h2>
        <p class="just">Trabajamos juntos para darte la mejor protección en tus viajes</p>
        <p class="redirect">Serás redireccionado en <strong>{{ countdown }}  segundos</strong></p>
        <p class="bottom">De no ser así, haz <a href="/public">click aquí</a>.</p>
      </div>
    </div>
  </div>
  