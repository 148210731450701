<div class="spinner_container" *ngIf="showLoader">
  <mat-progress-spinner class="spinner"  [mode]="'indeterminate'" [value]="50"></mat-progress-spinner>
</div>

<div class="container lato center-box">

  <div class="row" id="stepper">
    <div class="col-sm-12">
      <app-stepper-muvit [tipoPlan]="tipoPlan" [payerIsDifferent]="payerIsDifferent"></app-stepper-muvit>
    </div>
    <div class="col-2 hidden-desktop"></div>
    <div class="col-lg-8">
      <div class="form-container" style="padding: 0px 15px; display: flex; margin-bottom: 50px;" id="divthanks">
        <div style="margin:auto;" class="width-content">
            <!-- <div class="hidden-mobile">
              <img style="height: 55px; position: absolute; left: 0px; right: 0px; margin: auto; bottom: 0px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/muvit.svg" alt="muvit logo" (click)="returnToHome()"> 
            </div> -->
            <div style="display: flex; margin: 50px 0px;">
              <img class="icon-color hight-icon" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/ready.svg" alt="ready">
              <h3 style="text-align: center;" class="title-th">¡Ya todo está <b>listo <br>para tu viaje!</b></h3>
            </div>
            <div style="display: flex; margin: 50px 0px;">
              <h3 style="text-align: center; font-size: 22px;" class="title-th">Tu Número de Poliza es: <b>{{gs.currentInsuranceInfo.policy_number}}</b></h3>
            </div>
            <div class="txt-th">
              <p>Recibirás una confirmación de tu compra a tu correo: <span class="pink">{{gs.currentInsuranceInfo.holder.email}}</span> con detalles de tu plan y coberturas.</p>
              <p style="text-align: center;">¡Vive seguro la experiencia Muvit!</p>
            </div>
            <div style="text-align: center;" class="margin-btn">
              <button (click)="print()" class="btn next-button width-btn" style="line-height: 1.1;" id="btnvoucher">Descargar Comprobante</button>
            </div>
            <div style="text-align: center;" class="margin-btn">
              <button (click)="returnHome()" class="btn next-button width-btn" style="line-height: 1.1;" id="btnreturnhome">Regresar a inicio</button>
            </div>
            <div style="text-align: center;" class="margin-btn">
              <button (click)="openShareDialog()" class="btn next-button width-btn" style="line-height: 1.1;" id="btnShare">Compartir</button>
            </div>
            <!-- SECCION PARA AGREGAR LA APPLE WALLET -->
            <!-- <div>
              <p class="hidden-desktop" style="text-align:center; color:#BABABA; font-size: 18px;">Para añadir tu compra a tu <span class="pink">Wallet</span> abre esta pagina en <br>tu iPhone o iPod touch.</p>
            </div>
            <div style="text-align: center;" class="margin-btn">
              <button style="background-color: black; display: inline-flex; align-items: center; justify-content: center; position: relative; padding-left: 55px;" class="btn continue-button width-btn">
                <img style="height: 30px !important; margin-left: 0; width: auto; position: absolute; left: 15px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/Apple_Wallet_Icon.svg" alt="wallet"> Agregar a Apple Wallet
              </button>
            </div> -->
        </div>
      </div>
    </div>
    <div class="col-2 hidden-desktop"></div>
  </div>
  
</div>