import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InsuranceAccompanist } from 'src/app/models/insurance-accompanist';

@Component({
  selector: 'app-add-accompanist',
  templateUrl: './add-accompanist.component.html',
  styleUrl: './add-accompanist.component.css'
})

export class AddAccompanistComponent {
  genreOptions = [
    { description: 'Masculino', value: '1' },
    { description: 'Femenino', value: '0' }
  ]
  
  dependent: InsuranceAccompanist;
  minDate: any;
  maxDate: any;

  constructor(public dialogRef: MatDialogRef<AddAccompanistComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
    this.dependent = new InsuranceAccompanist();
    this.dependent.identification_type = 9;
    const currentDate = new Date();
    this.maxDate = new Date();
    this.maxDate.setMonth(currentDate.getMonth() - 6);
    this.minDate = new Date();
    this.minDate.setFullYear(currentDate.getFullYear() - 85)
  }

  ngOnInit() {
    if(this.data) this.dependent = this.data;
  }

  closeDialog() {
    this.dialogRef.close(null);
  }

  add() {
    this.dialogRef.close(this.dependent);
  }
}
