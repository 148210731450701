<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 demo-table">
  <!-- Position Column -->
  <ng-container matColumnDef="description" style="padding: 0;">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let element"> {{element.description}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="price">
    <th mat-header-cell *matHeaderCellDef style="padding: 0;"></th>
    <td mat-cell *matCellDef="let element"> {{element.price}} </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns" style="height: 5px; background-color: #333;"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"
    [ngClass]="{'total-row': row.description === 'TOTAL general coberturas'}"></tr>
</table>