import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DeviceDetectorService as NgxDeviceDetectorService } from 'ngx-device-detector';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class deviceDetectorService {
  deviceType = new Subject<string>();
  device: any
  directAccessRoutes = ['contact-us', 'terms-and-conditions', 'FAQ', 'muvit-blog', 'terms-and-conditions-giveaway'];

/**
 * @param {NgxDeviceDetectorService} deviceService - Una instancia del servicio NgxDeviceDetectorService, que se utiliza para detectar el tipo de dispositivo.
 * @param {ActivatedRoute} activatedRoute - Una instancia del servicio ActivatedRoute de Angular, que contiene información sobre la ruta activa actual.
 */
constructor(private router: Router, private deviceService: NgxDeviceDetectorService, private activatedRoute: ActivatedRoute, private titleService: Title) {
  this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
  ).subscribe(() => {
      this.detectDevice();
  });
}

  /**
   * Método detectDevice.
   * 
   * @description
   * Este método detecta el tipo de dispositivo y ajusta el enrutamiento en consecuencia. Si la ruta actual está en la lista de rutas de acceso directo, verifica si el dispositivo es móvil o de escritorio y navega a la ruta correspondiente, preservando los parámetros de consulta. Si la ruta no está en la lista de rutas de acceso directo, hace lo mismo pero solo si la ruta tiene un path. Antes de navegar, establece el título de la página utilizando el método setNewTitle().
   */
  detectDevice() {
    const firstChild = this.activatedRoute.snapshot.firstChild;
    let path = firstChild?.url[0]?.path;
    if (!path && this.router.url === '/public/') {
      path = '/public/';
    }
    let currentRoute = this.router.url;
    if (this.directAccessRoutes.some(route => currentRoute.includes(route))) {
      if (this.deviceService.isMobile() || window.innerWidth <= 1100) {
        this.device = 'Mobile';
        this.deviceType.next('Mobile');
        if (!currentRoute.startsWith('/public/mobile')) {
          this.setNewTitle();
          this.router.navigate([`/public/mobile${currentRoute.replace('/public', '')}`], { queryParamsHandling: 'preserve' });
        }
      } else if (this.deviceService.isDesktop() || window.innerWidth > 1100) {
        this.device = 'Desktop';
        this.deviceType.next('Desktop');
        if (currentRoute.startsWith('/public/mobile')) {
          this.setNewTitle();
          this.router.navigate([currentRoute.replace('/public/mobile', '/public')], { queryParamsHandling: 'preserve' });
        }
      }
      return;
    }
    if (path) {
      if (this.deviceService.isMobile() || window.innerWidth <= 1100) {
        if (!this.router.url.startsWith('/public/mobile')) {
          this.setNewTitle();
          this.router.navigate([`/public/mobile`], { queryParamsHandling: 'preserve' });
        }
        this.device = 'Mobile';
        this.deviceType.next('Mobile');
      } else if (this.deviceService.isDesktop() || window.innerWidth > 1100) {
        if (this.router.url.startsWith('/public/mobile')) {
          this.setNewTitle();
          this.router.navigate([this.router.url.replace('/public/mobile', '/public')], { queryParamsHandling: 'preserve' });
        }
        this.device = 'Desktop';
        this.deviceType.next('Desktop');
      }
    }
  }

/**
 * @description
 * Este método obtiene los datos de la ruta actual y, si existe un título y lo establece como el título de la página.
 */
setNewTitle() {
  const routeData = this.activatedRoute.snapshot.firstChild?.data;
  if (routeData && routeData['title']) {
    const currentTitle = this.titleService.getTitle();
    if (currentTitle !== routeData['title']) {
      this.titleService.setTitle(routeData['title']);
    }
  }
}

}
