
<mat-dialog-content style="min-height: 270px;" >
  <div class="row numeric">
    <div class="col-lg-12">
      <div class="mdl-card__supporting-text center">
        <div class="mdl-stepper-horizontal-alternative" style="height: 120px; display: flex; justify-content: center; align-items: center;">
            <img style="height: 75px;" src="../../../../assets/tecniseguros/iconos/tecnitravelercolor.png" alt="tecniseguros logo">
        </div>
        <div class="mdl-stepper-horizontal-alternative" style="height: 75px; display: flex; justify-content: center; align-items: center;">
          <h2 *ngIf="newMessage == ''" style="line-height: 1.1; margin-bottom: 0px;" mat-dialog-title>DATOS INGRESADOS <br><b style="font-weight: 1000;">INCORRECTOS</b></h2>
          <h2 *ngIf="newMessage != ''" style="line-height: 1.1; margin-bottom: 0px;" mat-dialog-title><b style="font-weight: 1000;">{{newMessage}}</b></h2>
        </div>
      </div>
    </div>
  </div>

  <p style="margin-left: 20px; margin-right: 20px; text-align: center;" *ngIf="desc">{{desc}}</p>
  <p style="margin-left: 20px; margin-right: 20px; text-align: center;" *ngIf="isString(warningMessage)">{{warningMessage}}</p>
  <ul *ngIf="!isString(warningMessage)">
    <li *ngFor="let item of warningMessage">{{ item }}</li>
  </ul>
</mat-dialog-content>
<mat-dialog-actions>
  <button style="font-weight: 600;" *ngIf="cancel == '' || accept == '' " mat-dialog-close class="close-button" id="btnwarningcerrar">Cerrar</button>

</mat-dialog-actions>
<div class="row">
  <div class="col" style="display: flex; justify-content: end;">
    <button class="continue-button" *ngIf="cancel != ''" (click)="continue(0)" id="btnwarningcancelar">{{cancel}}</button>  
  </div>
  <div class="col">
    <button class="continue-button" *ngIf="accept != ''" (click)="continue(1)" id="btnwarningaceptar">{{accept}}</button>
  </div>
</div>
