import { Component, HostListener, OnInit, Renderer2, ElementRef, ViewChild} from '@angular/core';
import { ImageLoadingService } from 'src/app/services/image-loading.service';

import { CotizarButtonComponent } from '../cotizar-button/cotizar-button.component';
import { FooterMobileComponent } from '../footer-mobile/footer-mobile.component';
import { DataConstructorService } from 'src/app/services/data-constructor.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-home-page-mobile',
  templateUrl: './home-page-mobile.component.html',
  styleUrls: ['./home-page-mobile.component.css']
})
export class HomePageMobileComponent implements OnInit{

  @ViewChild(CotizarButtonComponent, {read: ElementRef}) cotizarButton: ElementRef;
  @ViewChild(FooterMobileComponent, {read: ElementRef}) footerMobile: ElementRef;


  constructor(private imageLoadingService: ImageLoadingService, private title: Title , private activatedRoute: ActivatedRoute,private renderer: Renderer2, private el: ElementRef, private dataConstructor: DataConstructorService) {
    
    this.title.setTitle(this.activatedRoute.title['destination']['_value']['title'])
  }
  ngOnInit(): void {
    this.imageLoadingService.checkImagesLoaded(this.el.nativeElement);
    this.dataConstructor.isTotalVisible = false
  }
  


  ngAfterViewInit() {
    this.onWindowScroll();
  }

@HostListener("window:scroll", [])
onWindowScroll() {
  // Obtén el div y el footer
  let div = this.cotizarButton.nativeElement;
  let footer = this.footerMobile.nativeElement;

  // Obtén la posición del footer
  let footerOffset = footer.offsetTop;

  // Comprueba la posición de desplazamiento actual
  if (window.pageYOffset >= footerOffset) {
    this.renderer.setStyle(div, 'position', 'absolute');  // Cambia a posición absoluta
    this.renderer.setStyle(div, 'bottom', '0');  // Asegúrate de que el div esté arriba del footer
  } else {
    this.renderer.setStyle(div, 'position', 'fixed');  // Cambia a posición fija
  }
}


  facebookicon = 'facebook-seguro-de-viaje-01.svg'
  instagramicon = 'instagram-seguro-de-viaje-01.svg'
  whatsappicon = 'whatsapp-seguro-de-viaje-01.svg'

  Maletas = [{
    'description': 'Pérdida de maletas hasta $1,200'
  }, {
    'description': 'Atraso de maletas hasta $100'
  }
  ]
  Hotel = [{
    'description': 'Gastos de hotel por convalecencia hasta $1,000'
  }, {
    'description': 'Gastos de hotel para un familiar $1000'
  }
  ]

  Viaje = [{
    'description': 'Cancelación de viaje hasta  $450 '
  }, {
    'description': 'Interrupción de viaje hasta $450'
  }, {
    'description': 'Demora de viaje hasta $100'
  }]

  Salud = [{
    'description': 'Gastos médicos por enfermedad y accidente hasta  $150,000 '
  }, {
    'description': 'Ambulancia (aérea, marítima o terrestre) hasta $50,000'
  }]

  Comments = [ 
    {
      'name': 'Patty Melz',
      'comment': ' Es la primera vez que compro una asistencia de viaje, la compra en la página web fue fácil y la atención muy personalizada, después de evaluar precios y beneficios Muvit fue la mejor opción.',
      'photo': 'Seguro_de_viaje_Muvit_3.webp',
      'social': this.instagramicon
    },

    {
      'name': 'Melisa Benavides',
      'comment': 'Compré Muvit para mi viaje al Caribe, ¡y aunque no me pasó nada! me fui tranquila sin arriesgar mi viaje de descanso y desconexión por algún percance. Gracias Muvit 😊 ',
      'photo': 'Seguro_de_viaje_Muvit_2.webp',
      'social': this.instagramicon
    },
    {
      'name': 'Ricardo Piral',
      'comment': ' Adquirir Muvit fue tan sencillo y nos dio tanta tranquilidad a mí y mi familia en nuestro viaje a México. Viajar con el respaldo de Muvit realmente hace la diferencia.',
      'photo': 'Seguro_de_viaje_Muvit_1.webp',
      'social': this.instagramicon
    },
    {
      'name': 'Andrea Ramos',
      'comment': 'Tuve una infección en mi viaje, me coordinaron el medico y la entrega de mi medicamento en menos de 1 hora con Muvit. No tuve que hacer uso de mi seguro, todo fue bastante ágil.',
      'photo': '',
      'social': this.whatsappicon
    },
  ]

}
