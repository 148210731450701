import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { RoutesModule } from './routes/routes.module';
import { ApiService } from './api/api.service';
import { RequestserviceService } from './api/requestservice.service';
import { ConfigService } from './config/config.service';
import { GlobalService } from './services/global.service'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ExpDateDirective } from './directives/exp-date.directive';
import { CcNumberDirective } from './directives/cc-number.directive';
import { DatePipe, registerLocaleData } from '@angular/common'
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from './services/loader.interceptor';
import { TagInputModule } from 'ngx-chips';



// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { FlexLayoutModule } from '@angular/flex-layout'; // ---> Pendiente


// Import locale default data for datetimes es-gt
import { LOCALE_ID } from '@angular/core';
import localeEsGt from '@angular/common/locales/es-GT';
registerLocaleData(localeEsGt, 'es-GT');

// NGX Smart popover module import
// import { PopoverModule } from 'ngx-smart-popover'; // ---> Pendiente


// Form validation service
import { ValidationService } from './services/validation.service';



// required for AOT compilation Translate
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}



import { GoogleService } from './services/google.service';
import { UserS2Service } from './services/users2.service';
import { IAmService } from './services/i-am.service';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { HomePageComponent } from './components/home-page/home-page.component';
import { ClickOutsideDirectiveDown, NavBarComponent } from './components/nav-bar/nav-bar.component'
import { BeneficiarySvgComponent } from './components/home-page/beneficiary-svg/beneficiary-svg.component';
import { TagsIlustrastionComponent } from './components/home-page/tags-ilustrastion/tags-ilustrastion.component';
import { CommentsCardComponent } from './components/home-page/comments-card/comments-card.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomePageBrandsSVGComponent } from './components/home-page/home-page-brands-svg/home-page-brands-svg.component';
import { LoaderService } from './services/loader.service';
import { ComponentLoaderService } from './services/component-loader.service';
import { BannerHomeComponent } from './components/home-page/banner-home/banner-home.component';
import { BenefitsComponent } from './components/home-page/benefits/benefits.component';
import { PlansPageComponent } from './components/plans-page/plans-page.component';
import { AseguradoraGeneralComponent } from './components/aseguradora-general/aseguradora-general.component';
import { ClientInformationComponent } from './components/client-information/client-information.component';
import { CardInputComponent } from './components/client-information/customer-information/card-input/card-input.component';
import { CardSelectComponent } from './components/client-information/customer-information/card-select/card-select.component';
import { CardDatepickerComponent } from './components/client-information/customer-information/card-datepicker/card-datepicker.component';
import { CardPhoneSelectComponent } from './components/client-information/customer-information/card-phone-select/card-phone-select.component';
import { CustomerInformationComponent } from './components/client-information/customer-information/customer-information.component';
import { AffiliatesInformationComponent } from './components/client-information/affiliates-information/affiliates-information.component';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { PaymentFormComponent } from './components/payment-form/payment-form.component';
import { HomePageMobileComponent } from './components/tecniseguros-movil/home-page-mobile/home-page-mobile.component';
import { FooterMobileComponent } from './components/tecniseguros-movil/footer-mobile/footer-mobile.component';
import { NavBarMobileComponent } from './components/tecniseguros-movil/nav-bar-mobile/nav-bar-mobile.component';
import { CotizarButtonComponent } from './components/tecniseguros-movil/cotizar-button/cotizar-button.component';
import { BannerHomeMovilComponent } from './components/tecniseguros-movil/banner-home-mobile/banner-home-mobile.component';
import { BenefitsMobileComponent } from './components/tecniseguros-movil/benefits-mobile/benefits-mobile.component';
import { PlanQuotationMobileComponent } from './components/tecniseguros-movil/plan-quotation-mobile/plan-quotation-mobile.component';
import { PlanTableMobileComponent } from './components/tecniseguros-movil/plan-quotation-mobile/plan-table/plan-table.component';
import { StepFormComponent } from './components/tecniseguros-movil/step-form/step-form.component';
import { ClientFormMobileComponent } from './components/tecniseguros-movil/client-form-mobile/client-form-mobile.component';
import { PolicyDialogComponent } from './components/policy-dialog/policy-dialog.component';
import { PaymentMobileComponent } from './components/tecniseguros-movil/payment-mobile/payment-mobile.component';
import {MatChipsModule} from '@angular/material/chips';
import { PolicyDialogDesktopComponent } from './components/policy-dialog-desktop/policy-dialog-desktop.component';
import { WarningCardMuvitComponent } from './components/utils/warning-card-muvit/warning-card-muvit.component';
import { BuyLoaderComponent } from './components/buy-loader/buy-loader.component';
import { LoaderComponent } from './components/loader/loader.component';
import { WarningCardComponent } from './components/utils/warning-card/warning-card.component';
import { HomePlansComponent } from './components/home-page/home-plans/home-plans.component';
import { StepHeaderComponent } from './components/step-header/step-header.component';
import { AddAccompanistComponent } from './components/client-information/add-accompanist/add-accompanist.component';
import { LoadingComponent } from './components/loading/loading.component';
import { TravelInfoComponent } from './components/travel-info/travel-info.component';
import { QuoteBoxComponent } from './components/quote-box/quote-box.component';
import { FAQComponent, FilterPipe } from './components/faq/faq.component';
import { FaqCardComponent } from './components/faq/faq-card/faq-card.component';
import {TermsAndConditionsComponent} from './components/terms-and-conditions/terms-and-conditions.component';
import { MuvitBlogComponent } from './components/muvit-public/muvit-blog/muvit-blog.component';
import { MuvitArticleComponent } from './components/muvit-public/muvit-article/muvit-article.component';
import { MuvitPublicComponent } from './components/muvit-public/muvit-public.component';
import { MuvitInsuranceHolderComponent } from './components/muvit-public/muvit-insurance-holder/muvit-insurance-holder.component';
import { StepperMuvitComponent } from './components/muvit-public/stepper-muvit/stepper-muvit.component';
import { MuvitPaymentFormComponent } from './components/muvit-public/muvit-payment-form/muvit-payment-form.component';
import { MuvitThankYouComponent } from './components/muvit-public/muvit-thank-you/muvit-thank-you.component';
import { MuvitCurLanDialogComponent } from './components/muvit-public/muvit-cur-lan-dialog/muvit-cur-lan-dialog.component';
import { TermsComponent } from './components/muvit-public/terms/terms.component';
import { ShareDialogComponent } from './components/muvit-public/share-dialog/share-dialog.component';
@NgModule({
  declarations: [
    AppComponent,
    ExpDateDirective,
    CcNumberDirective,
    HomePageComponent,
    FooterComponent,
    ClickOutsideDirectiveDown,
    NavBarComponent,

    FooterComponent,
    CommentsCardComponent,
    TagsIlustrastionComponent,
    BeneficiarySvgComponent,
    HomePageBrandsSVGComponent,
    BannerHomeComponent,
    BenefitsComponent,
    PlansPageComponent,
    AseguradoraGeneralComponent,
    ClientInformationComponent,
    CardInputComponent,
    CardSelectComponent,
    CardDatepickerComponent,
    CardPhoneSelectComponent,
    AffiliatesInformationComponent,
    CustomerInformationComponent,
    ThankYouComponent,
    PaymentFormComponent,
    HomePageMobileComponent,
    FooterMobileComponent,
    NavBarMobileComponent,
    CotizarButtonComponent,
    BannerHomeMovilComponent,
    BenefitsMobileComponent,
    PlanQuotationMobileComponent,
    PlanTableMobileComponent,
    StepFormComponent,
    ClientFormMobileComponent,
    PolicyDialogComponent,
    PaymentMobileComponent,
    PolicyDialogDesktopComponent,
    BuyLoaderComponent,
    LoaderComponent,
    WarningCardMuvitComponent,
    WarningCardComponent,
    HomePlansComponent,
    StepHeaderComponent,
    AddAccompanistComponent,
    LoadingComponent,
    QuoteBoxComponent,
    TravelInfoComponent,
    FAQComponent,
    FilterPipe,
    FaqCardComponent,
    TermsAndConditionsComponent,
    MuvitBlogComponent,
    MuvitArticleComponent,
    MuvitPublicComponent,
    MuvitInsuranceHolderComponent,
    StepperMuvitComponent,
    MuvitPaymentFormComponent,
    MuvitThankYouComponent,
    MuvitCurLanDialogComponent,
    TermsComponent,
    ShareDialogComponent,
  ],
  // entryComponents: [
  //   WarningCardComponent,
  //   WarningCardMuvitComponent,
  //   ConfirmCardComponent,
  //   SuccessCardComponent,
  //   MuvitCurLanDialogComponent,
  //   ShareDialogComponent,
  // ],
  imports: [
    BrowserModule,
    TagInputModule,
    MatChipsModule,
    RoutesModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    // FlexLayoutModule, // ---> Pendiente
    ShareButtonsModule,
    ShareIconsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // PopoverModule, // ---> Pendiente
    // NgxMaskModule.forRoot(options) // ---> Pendiente
  ],
  providers: [
    ApiService,
    LoaderService,
    ComponentLoaderService,
    RequestserviceService,
    ConfigService,
    GlobalService,
    BrowserAnimationsModule,
    ValidationService,
    UserS2Service,
    DatePipe,
    GoogleService,
    IAmService,


    { 
      provide: LOCALE_ID,
      useValue: "es-GT"
    },
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi:true},
    provideAnimationsAsync()
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {

}
