<div class="spinner_container" *ngIf="showLoader">
  <mat-progress-spinner class="spinner"  [mode]="'indeterminate'" [value]="50"></mat-progress-spinner>
</div>

<div class="container lato">

  <div class="row" id="stepper">
    <div class="col-sm-12">
      <app-stepper-muvit [tipoPlan]="tipoPlan" [payerIsDifferent]="payerIsDifferent" (returnToHome2)="returnToHome2()"></app-stepper-muvit>
    </div>
    <div class="col-2 hidden-desktop"></div>
    <div class="col-lg-8 bottom-air">
      <div class="form-container">
        <h3 class="form-title hidden-desktop">EMPIEZA <b class="text-bolder"> TU EXPERIENCIA MUVIT</b></h3>
        <h3 class="form-title hidden-mobile">EMPIEZA <b class="text-bolder"> TU <br>EXPERIENCIA MUVIT</b></h3>
        <form #holderformmuvit="ngForm" (ngSubmit)="onSubmit()" class="form-margin-b" >
          <div class="center-content group-spacing">
            <div class="container-right">
              <!-- <p for="email" class="control-label">Correo</p> -->
              <div class="form-control-borderless genre-section" >  
                <!-- <span class="gender">Genero*</span> -->
                <div class="genre-buttons" required >
                  <input type="radio" [(ngModel)]="gender" name="gender_value" required value="0" #Hgender="ngModel">
                  <label for="male"> Hombre </label><br>
                  <input type="radio" [(ngModel)]="gender" name="gender_value" required value="1" #Hgender="ngModel"> 
                  <label for="female" > Mujer </label><br>
                  <input type="radio" [(ngModel)]="gender" name="gender_value" required value="2" #Hgender="ngModel">
                  <label for="other"> Otro </label><br>
                </div>
              </div>
              <div class="form-control-borderless genre-section" >  
                <p *ngIf="Hgender.touched && !Hgender.valid" style="font-size: 0.9rem; color: #FF056F; margin-top: 0px; margin-bottom: 0px; margin-left: 10px; text-align: start;">*Seleccione su género</p>
              </div>
            </div>
            <div class="space-form"></div>
            <div class=" container-left">
              <!--<div style="display: flex;">
                
                <i class="fas fa-exclamation-circle info-btn" data-toggle="tooltip" data-placement="top" title="Fecha en la que se sugiere comenzar a usar el seguro"></i>
              </div>-->
  
              <!-- <p for="dob" class="control-label">Fecha de nacimiento</p> -->
              <div class="form-control-space">
                <input maxlength="10" #Hbirthday="ngModel" 
                class="form-control" id="dob" [max]="currentDate" [ngClass]="{'invalid-form': Hbirthday.touched && !Hbirthday.valid}"
                required [(ngModel)]="insuranceHolder.birthday" name="dob" placeholder="Fecha de nacimiento (DD/MM/YYYY) *" 
                #dob="ngModel" style="margin-left: 0px; text-align: left;" (blur)="validateDate()" mask="d0/M0/0000" >
                <p style="font-size: 0.9rem; color: #9E9E9E; margin-top: 5px; margin-bottom: 0px; margin-left: 10px; text-align: start;">Ejemplo: 01/05/1973</p>
                <!-- <mat-datepicker-toggle class="small-icon" style="display: none;" matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker ></mat-datepicker> -->
              </div>
            </div>
          </div>
          <div class="center-content group-spacing">
            <div class="container-left">
              <mat-select class="form-control border-gray" id="cedulaType" required 
              [(ngModel)]="identification_type" name="cedulaType" #HcedulaType="ngModel" [ngClass]="{'invalid-form': HcedulaType.touched && !HcedulaType.valid}"
              style="font-family: 'rubik';" #bntype="ngModel" placeholder="Tipo de identificación *">
                <mat-option [value]="null">----------Seleccione----------</mat-option>
                <mat-option [value]=9>Pasaporte</mat-option>
                <mat-option [value]=1>Documento oficial de identificación</mat-option>
              </mat-select>
            </div>
            <div class="space-form"></div>
            <div class="container-right">
              <input type="text"
                      class="form-control" 
                      id="cnumber" 
                      placeholder="Identificación *" 
                      autocomplete="off"
                      [(ngModel)]="insuranceHolder.identification_id" 
                      pattern="[0-9a-zA-Z]{4,}$" name="cnumber" 
                      required 
                      #cnumber="ngModel"
                      [ngClass]="{'invalid-form': cnumber.touched && !cnumber.valid}">
              <!-- <p *ngIf="cnumber.touched && !cnumber.valid" style="font-size: 0.9rem; color: #FF056F; margin-top: 0px; margin-bottom: 0px; text-align: center;">*Número de identificación inválido</p> -->

            </div>
          </div>
          <div class="center-content group-spacing">
            <div class="container-left">
              <!-- <p for="firstLastName" class="control-label">Primer Nombre</p> -->
              <input type="text" class="form-control" id="firstName"  placeholder="Nombre *" required 
                [(ngModel)]="insuranceHolder.first_name" name="firstName" #HfirstName="ngModel" 
                [ngClass]="{'invalid-form': HfirstName.touched && !HfirstName.valid}">
            </div>
            <div class="space-form"></div>
            <div class="container-left">
              <!-- <p for="firstLastName" class="control-label">Primer Nombre</p> -->
              <input type="text" class="form-control" id="firstLastName"  placeholder="Apellido *" required 
                [(ngModel)]="insuranceHolder.last_name" name="firstLastName"
                #HlastName="ngModel" [ngClass]="{'invalid-form': HlastName.touched && !HlastName.valid}">
            </div>
            <!-- <div class="container-right">
              <p for="secondName" class="control-label">Segundo Nombre <span class="optional raleway">(opcional)</span></p> 
              <input type="text" class="form-control" id="secondName"  placeholder="Segundo nombre" 
              [(ngModel)]="insuranceHolder.middle_name" name="secondName" >
            </div> -->
          </div>
           <!-- <div class="center-content group-spacing">
           <div class="container-left">
              <p for="firstLastName" class="control-label">Primer Nombre</p>
              <input type="text" class="form-control" id="firstLastName"  placeholder="Primer apellido *" required 
                [(ngModel)]="insuranceHolder.last_name" name="firstLastName"  #name="ngModel">
            </div> -->
            <!-- <div class="space-form"></div> -->
            <!-- <div class="container-right">
              <p for="secondName" class="control-label">Segundo Nombre <span class="optional raleway">(opcional)</span></p> 
              <input type="text" class="form-control" id="secondLastName"  placeholder="Segundo apellido" 
              [(ngModel)]="insuranceHolder.last_name_2" name="secondLastName" >
            </div> 
          </div>-->
          <div class="center-content group-spacing" *ngIf="!this.gs.isYearly">
            <div class="container-left">
              <mat-select class="form-control border-gray" id="areaCodeHolder" required 
              [(ngModel)]="areaCodeHolder" name="areaCodeHolder" #HareaCodeHolder="ngModel" [ngClass]="{'invalid-form': HareaCodeHolder.touched && !HareaCodeHolder.valid}"
              style="font-family: 'rubik';" #bntype="ngModel" placeholder="País de residencia *" (ngModelChange)="changeAreaCode()" required>
                <!-- <mat-option [value]="null">País</mat-option> -->
                <mat-option *ngFor="let country of countries" [value]=country> {{country.name}}</mat-option>
              </mat-select>
            </div>
            <div class="space-form"></div>
            <div class="container-left">
              <div class="d-flex form-control-space" style="margin: auto;">

                <div class="area-code">
                  <!-- <div class="form-control-area-code"> -->

                      <!-- <p style="margin-bottom: 0px; height: 100%; align-items: center; display: flex;"><span *ngIf="areaCodeHolder != null">+{{areaCodeHolder.area_code}}</span> <span *ngIf="areaCodeHolder == null">Código*</span></p>
                    </div> -->
                    <!-- <span *ngIf="!('value' in areaCodeHolder) && areaCodeMod = ''; else areaCodeExtra">{{areaCodeHolder.area_code}} </span>
                    <span #areaCodeExtra>Código*</span> -->
                    
                    <input type="text" id="areacode" class="form-control-area-code"  
                    [(ngModel)]="areaCodeMod" name="areacode" placeholder="Código *" required maxlength="3"
                    #HareaCode="ngModel" [ngClass]="{'invalid-form': HareaCode.touched && !HareaCode.valid}">
                  <!-- </div> -->
                </div>
                <div style="width: 3%;"></div>

                <div class="telephone">
                  <!-- <p for="phone" class="control-label">Teléfono</p> -->
                  <input type="phone" class="form-control-phone" 
                    id="phone"  placeholder="Número de teléfono *" required 
                    [(ngModel)]="contactHolder" name="phone"  #phone="ngModel" [ngClass]="{'invalid-form': phone.touched && !phone.valid}">
                    <!-- pattern="([2|4|5|6|7|8])([0-9]){7}$" -->
                </div>
              </div>
              <div style="padding-top: 2px; position: absolute;" class="form-control-borderless bottom-text-mob">
                <p style="margin-bottom: 0px; line-height: 1.3;" class="small-font">Sólo nos pondremos en contacto contigo si es necesario</p>
              </div>
            </div>
          </div>
          <div class="center-content group-spacing" *ngIf="!this.gs.isYearly">
            <div class="container-left">
              <input type="email" class="form-control" id="email"  placeholder="Correo electrónico *" required [ngClass]="{'invalid-email': email.touched && !email.valid}" 
                [(ngModel)]="insuranceHolder.email" name="email"  #email="ngModel" style="display: grid; margin: auto;" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
              <div style="padding-top: 2px; position: absolute; height: auto;" class="form-control-borderless bottom-text-mob">
                <p style="margin-bottom: 0px; margin-top: 2px; line-height: 1.3;" class="small-font">Enviaremos la confirmación a esta dirección</p>
              </div>
              
            </div>
            <div class="space-form"></div>
            <div class="container-right">
              <div class="form-control-borderless" style="padding-left: 0px !important; text-align: initial;">
                <div >
                  <!-- <input type="checkbox" class="form-check-input checkbox-btn" [ngModel]="offersCB">
                  <p class="check-box smaller-font p-form">Me gustaría recibir ofertas exclusivas por email</p> -->
                  <!-- <span class="checkmark" style="top: 6px"></span> -->

                  <mat-checkbox [(ngModel)]="offersCB" [ngModelOptions]="{standalone: true}" class="smaller-font">Me gustaría recibir ofertas exclusivas por email y SMS</mat-checkbox>
                </div>
                <div >
                  <!-- <input type="checkbox" class="form-check-input checkbox-btn"  [ngModel]="termsCB">
                  <p class="check-box smaller-font p-form">Acepto los <span>términos y condiciones</span> de Muvit</p> -->
                  <!-- <span class="checkmark" style="top: 36px"></span> -->

                  <mat-checkbox 
                  [(ngModel)]="termsCB" required [ngModelOptions]="{standalone: true}" class="smaller-font">
                  Acepto los 
                  <a (click) = "showTosPage()" routerLinkActive="active" target="_blank" rel="bookmark">
                    <span 
                  style="color: #067acc; cursor: pointer;" 
                  (mousedown)="$event.stopPropagation()" > términos y condiciones </span></a>de Muvit
                </mat-checkbox>
                </div>

                <!-- <mat-checkbox color="primary">Check me!</mat-checkbox> -->

                <!-- <div class="myTest custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="customCheck1" />
                  <label class="custom-control-label smaller-font" for="customCheck1">Check this custom checkbox</label>
                </div> -->
                
                <!-- <mat-radio-button  >Me gustaría recibir ofertas exclusivas por email</mat-radio-button>
                <mat-radio-button [ngModel]="termsCB" >Acepto los <span>términos y condiciones</span> de Muvit</mat-radio-button> -->
                <!-- <mat-checkbox class="smaller-font" [ngModel]="offersCB">Me gustaría recibir ofertas exclusivas por email</mat-checkbox>
                <mat-checkbox class="smaller-font" [ngModel]="termsCB">Acepto los <span>términos y condiciones</span> de Muvit</mat-checkbox> -->
              </div> 

            </div>
          </div>

          <div class="center-content group-spacing" *ngIf="this.gs.isYearly">
            <div class="container-left">
              <mat-select class="form-control border-gray" id="areaCodeHolder" required 
              [(ngModel)]="areaCodeHolder" name="areaCodeHolder" #HareaCodeHolder="ngModel" [ngClass]="{'invalid-form': HareaCodeHolder.touched && !HareaCodeHolder.valid}"
              style="font-family: 'rubik';" #bntype="ngModel" placeholder="País de residencia *" (ngModelChange)="changeAreaCode()" required>
                <!-- <mat-option [value]="null">País</mat-option> -->
                <mat-option *ngFor="let country of countries" [value]=country> {{country.name}}</mat-option>
              </mat-select>
            </div>
            <div class="space-form"></div>
            <div class="container-left">
              <mat-select class="form-control border-gray" id="areaCodeHolder" required 
              [(ngModel)]="selectedRegion" name="areaCodeHolder" #HareaCodeHolder="ngModel" [ngClass]="{'invalid-form': HareaCodeHolder.touched && !HareaCodeHolder.valid}"
              style="font-family: 'rubik';" #bntype="ngModel" placeholder="Region de Viaje*" (ngModelChange)="changeRegion()" required>
                <!-- <mat-option [value]="null">País</mat-option> -->
                <mat-option *ngFor="let region of regions" [value]=region> {{region.name}}</mat-option>
              </mat-select>
            </div>
          </div>
          <div class="center-content group-spacing" *ngIf="this.gs.isYearly">
            <div class="container-left">
              <input type="email" class="form-control" id="email"  placeholder="Correo electrónico *" required [ngClass]="{'invalid-email': email.touched && !email.valid}" 
                [(ngModel)]="insuranceHolder.email" name="email"  #email="ngModel" style="display: grid; margin: auto;" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
              <div style="padding-top: 2px; position: absolute; height: auto;" class="form-control-borderless bottom-text-mob">
                <p style="margin-bottom: 0px; margin-top: 2px; line-height: 1.3;" class="small-font">Enviaremos la confirmación a esta dirección</p>
              </div>
              
            </div>
            <div class="space-form"></div>
            <div class="container-left">
              <div class="d-flex form-control-space" style="margin: auto;">

                <div class="area-code">
                  <!-- <div class="form-control-area-code"> -->

                      <!-- <p style="margin-bottom: 0px; height: 100%; align-items: center; display: flex;"><span *ngIf="areaCodeHolder != null">+{{areaCodeHolder.area_code}}</span> <span *ngIf="areaCodeHolder == null">Código*</span></p>
                    </div> -->
                    <!-- <span *ngIf="!('value' in areaCodeHolder) && areaCodeMod = ''; else areaCodeExtra">{{areaCodeHolder.area_code}} </span>
                    <span #areaCodeExtra>Código*</span> -->
                    
                    <input type="text" id="areacode" class="form-control-area-code"  
                    [(ngModel)]="areaCodeMod" name="areacode" placeholder="Código *" required maxlength="3"
                    #HareaCode="ngModel" [ngClass]="{'invalid-form': HareaCode.touched && !HareaCode.valid}">
                  <!-- </div> -->
                </div>
                <div style="width: 3%;"></div>

                <div class="telephone">
                  <!-- <p for="phone" class="control-label">Teléfono</p> -->
                  <input type="phone" class="form-control-phone" 
                    id="phone"  placeholder="Número de teléfono *" required 
                    [(ngModel)]="contactHolder" name="phone"  #phone="ngModel" [ngClass]="{'invalid-form': phone.touched && !phone.valid}">
                    <!-- pattern="([2|4|5|6|7|8])([0-9]){7}$" -->
                </div>
              </div>
              <div style="padding-top: 2px; position: absolute;" class="form-control-borderless bottom-text-mob">
                <p style="margin-bottom: 0px; line-height: 1.3;" class="small-font">Sólo nos pondremos en contacto contigo si es necesario</p>
              </div>
            </div>
            
          </div>

          <div class="center-content group-spacing" *ngIf="this.gs.isYearly">
            <div class="container-right">
              <div class="form-control-borderless" style="padding-left: 0px !important; text-align: initial;">
                <div >
                  <!-- <input type="checkbox" class="form-check-input checkbox-btn" [ngModel]="offersCB">
                  <p class="check-box smaller-font p-form">Me gustaría recibir ofertas exclusivas por email</p> -->
                  <!-- <span class="checkmark" style="top: 6px"></span> -->

                  <mat-checkbox [(ngModel)]="offersCB" [ngModelOptions]="{standalone: true}" class="smaller-font">Me gustaría recibir ofertas exclusivas por email y SMS</mat-checkbox>
                </div>
                <div >
                  <!-- <input type="checkbox" class="form-check-input checkbox-btn"  [ngModel]="termsCB">
                  <p class="check-box smaller-font p-form">Acepto los <span>términos y condiciones</span> de Muvit</p> -->
                  <!-- <span class="checkmark" style="top: 36px"></span> -->

                  <mat-checkbox 
                  [(ngModel)]="termsCB" required [ngModelOptions]="{standalone: true}" class="smaller-font">
                  Acepto los 
                  <a (click) = "showTosPage()" routerLinkActive="active" target="_blank" rel="bookmark">
                    <span 
                  style="color: #067acc; cursor: pointer;" 
                  (mousedown)="$event.stopPropagation()" > términos y condiciones </span></a>de Muvit
                </mat-checkbox>
                </div>

                <!-- <mat-checkbox color="primary">Check me!</mat-checkbox> -->

                <!-- <div class="myTest custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="customCheck1" />
                  <label class="custom-control-label smaller-font" for="customCheck1">Check this custom checkbox</label>
                </div> -->
                
                <!-- <mat-radio-button  >Me gustaría recibir ofertas exclusivas por email</mat-radio-button>
                <mat-radio-button [ngModel]="termsCB" >Acepto los <span>términos y condiciones</span> de Muvit</mat-radio-button> -->
                <!-- <mat-checkbox class="smaller-font" [ngModel]="offersCB">Me gustaría recibir ofertas exclusivas por email</mat-checkbox>
                <mat-checkbox class="smaller-font" [ngModel]="termsCB">Acepto los <span>términos y condiciones</span> de Muvit</mat-checkbox> -->
              </div> 

            </div>
            
           
          </div>
          <!-- *ngIf="totalPersons > 1" -->
        </form>
        <div  class="companion-space">
          <form [formGroup]="form">
            
            <ng-container formArrayName="companions">
                
              <ng-container *ngFor="let companionForm of companions.controls; let i = index">
              
                <div style="place-items: center;" [formGroupName]="i" >
                  <div >
                    <div class="center-content ">
                      <mat-divider class="divider mat-divider"></mat-divider>
                    </div>
                    <div class="center-content group-spacing">
                      <div class="container-left">
                        <div class="form-control-space">
                          <p style="font-size: 20px" class="subtitle-mob">ACOMPAÑANTE {{ i+1 }}</p> 
                          <div style="position:absolute; top: 5px; right: 5px; cursor: pointer" (click)="deleteAccompanist(i)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-lg pink" viewBox="0 0 20 20">
                              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                            </svg>
                          </div>  
                        </div>
                      </div>
                      <div class="space-form"></div>
                      <div class="container-left hidden-desktop">
                        <div class="form-control-space"></div>
                      </div>
                    </div>
                    <div class="center-content group-spacing">
                      <div class="container-right">
                        <!-- <p for="email" class="control-label">Correo</p> -->
                        <div class="form-control-borderless genre-section" >  
                          <!-- <span class="gender">Genero*</span> -->
                          <div class="genre-buttons" >
                            <!-- type="radio"  name="gender" -->
                            <input type="radio" formControlName="gender" required [value]="0" (ngModelChange)="changeGender(0,i)">
                            <label for="male"> Hombre </label><br>
                            <input type="radio" formControlName="gender" required [value]="1" (ngModelChange)="changeGender(1,i)">
                            <label for="female"> Mujer </label><br>
                            <input type="radio" formControlName="gender" required [value]="2" (ngModelChange)="changeGender(2,i)">
                            <label for="other"> Otro </label><br>
                          </div>
                        </div>
                        <div class="form-control-borderless genre-section">  
                          <p *ngIf="form.controls['companions']['controls'][i].controls['gender'].touched && !form.controls['companions']['controls'][i].controls['gender'].valid" 
                            style="font-size: 0.9rem; color: #FF056F; margin-top: 0px; margin-bottom: 0px; margin-left: 10px; text-align: start;">*Seleccione el género</p>
                        </div>
                      </div>
                      <div class="space-form"></div>
                      <div class=" container-left">
                        <!--<div style="display: flex;">
                          
                          <i class="fas fa-exclamation-circle info-btn" data-toggle="tooltip" data-placement="top" title="Fecha en la que se sugiere comenzar a usar el seguro"></i>
                        </div>-->
            
                        <!-- <p for="dob" class="control-label">Fecha de nacimiento</p> -->
                        <div class="form-control-space">
                          <!-- <input maxlength="10"
                          class="form-control" id="bday" 
                          required formControlName="birthday" name="bday" placeholder="Fecha de nacimiento *" [max]="currentDateCompanion"
                          style="margin-left: 0px; text-align: left;" (click)="birthdayi.open()" readonly (ngModelChange)="onChangeDateOfBirth(companions.value[i].birthday, i)"> -->
                          
                          <input maxlength="10" class="form-control" id="bday" [max]="currentDateCompanion"
                          required name="bday" placeholder="Fecha de nacimiento (DD/MM/YYYY)*" formControlName="birthday"
                          style="margin-left: 0px; text-align: left;" (blur)="validateDate2(i)" mask="d0/M0/0000" 
                          [ngClass]="{'invalid-form': form.controls['companions']['controls'][i].controls['birthday'].touched && !form.controls['companions']['controls'][i].controls['birthday'].valid}">
                          <p style="font-size: 0.9rem; color: #9E9E9E; margin-top: 5px; margin-bottom: 0px; margin-left: 10px; text-align: start;">Ejemplo: 01/05/1973</p>
                          <!-- <mat-datepicker-toggle class="small-icon" style="display: none;" matSuffix [for]="birthdayi"></mat-datepicker-toggle>
                          <mat-datepicker #birthdayi ></mat-datepicker> -->
                        </div>
                      </div>
                    </div>
                    <!-- Identificacion y tipo de identificacion del acompañante -->
                    <div class="center-content group-spacing">
                      
                      <div class="container-left">
                          <mat-select class="form-control border-gray" id="cedulaTypeA" formControlName="identification_type" 
                          name="cedulaTypeA" style="font-family: 'rubik';" placeholder="Tipo de identificación *"
                          [ngClass]="{'invalid-form': form.controls['companions']['controls'][i].controls['identification_type'].touched && !form.controls['companions']['controls'][i].controls['identification_type'].valid}">
                            <mat-option [value]="null">----------Seleccione----------</mat-option>
                            <mat-option [value]=9> Pasaporte</mat-option>
                            <mat-option [value]=1>Documento oficial de identificación</mat-option>
                          </mat-select>
                      </div>
                      <div class="space-form"></div>
                      <div class="container-right">
                          <input type="text"
                                  class="form-control" 
                                  id="cnumberA" 
                                  placeholder="Identificación *" 
                                  autocomplete="off"
                                  formControlName="identification_id" 
                                  pattern="[0-9a-zA-Z]{4,}$" name="cnumberA" 
                                  [ngClass]="{'invalid-form': form.controls['companions']['controls'][i].controls['identification_id'].touched && !form.controls['companions']['controls'][i].controls['identification_id'].valid}">
                      </div>
                    </div>
                    <div class="center-content group-spacing">
                      <div class="container-left">
                        <!-- <p for="firstLastName" class="control-label">Primer Nombre</p> -->
                        <input type="text" class="form-control" id="first_name"  placeholder="Nombre *" required 
                        formControlName="first_name" name="first_name" 
                        [ngClass]="{'invalid-form': form.controls['companions']['controls'][i].controls['first_name'].touched && !form.controls['companions']['controls'][i].controls['first_name'].valid}">
                      </div>
                      <div class="space-form"></div>
                      <div class="container-left">
                        <!-- <p for="firstLastName" class="control-label">Primer Nombre</p> -->
                        <input type="text" class="form-control" id="last_name"  placeholder="Apellido *" required 
                        formControlName="last_name" name="last_name" 
                        [ngClass]="{'invalid-form': form.controls['companions']['controls'][i].controls['last_name'].touched && !form.controls['companions']['controls'][i].controls['last_name'].valid}">
                      </div>
                      <!-- <div class="container-right">
                         <p for="secondName" class="control-label">Segundo Nombre <span class="optional raleway">(opcional)</span></p> 
                        <input type="text" class="form-control" id="middle_name"  placeholder="Segundo nombre" 
                        formControlName="middle_name" name="middle_name" >
                      </div> -->
                    </div>
                    <div class="center-content group-spacing">
                      <!-- <div class="container-left">
                        <p for="firstLastName" class="control-label">Primer Nombre</p> 
                        <input type="text" class="form-control" id="last_name"  placeholder="Primer apellido *" required 
                        formControlName="last_name" name="last_name" >
                      </div> -->
                      <!-- <div class="space-form"></div> -->
                      <!-- <div class="container-right">
                        <p for="secondName" class="control-label">Segundo Nombre <span class="optional raleway">(opcional)</span></p>
                        <input type="text" class="form-control" id="last_name_2"  placeholder="Segundo apellido" 
                        formControlName="last_name_2" name="last_name_2" >
                      </div> -->
                    </div>
                  </div>
                  
              </div>
              </ng-container>
              
            </ng-container>


          </form>
        </div>
        <div class="btn-group next-process-btn">
          <div class="center-txt">
            <button *ngIf="!this.gs.isYearly" class="btn add-companion-button" [disabled]="!form.valid" (click)="addCompanion()" id="btnaddaccompanist">Añadir acompañante</button>
          </div>
          <div class="center-txt">
            <!-- <button class="btn continue-button" type="submit" [disabled]="!holderformmuvit.valid || !termsCB || !form.valid || !email.valid" (click)="onSubmit()" id="btnpersonaldata">Siguiente</button> -->
            <button class="btn continue-button" type="submit" [disabled]="!termsCB" (click)="onSubmit()" id="btnpersonaldata">Siguiente</button>
          </div>
        </div>
        <div class="hidden-desktop">

        </div>
      </div>
    </div>
    <div class="col hidden-mobile" style="margin-bottom: 30px">
      <div class="row" style="max-width: 400px; width: 100%; margin: auto;">
        <div class="col-3" style="display: flex;">
          <a href="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/muvit_documents/FAQ_muvit.pdf" target="_blank" >
            <img class="icon-color" style="height: 32px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/faqs1.svg" alt="faqs">
          </a> 
        </div>
        <div class="col-9"> 
          <p style="margin-bottom: 4px !important;"><b>¿Necesitas ayuda?</b></p>
          <p style="margin-bottom: 2xp !important; color: #5B5B5B;">Puedes encontrar las <a class="hyperlink" href="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/muvit_documents/FAQ_muvit.pdf" target="_blank">respuestas a las preguntas más frecuentes </a>aquí</p>
        </div>
      </div>
    </div>
  </div>
</div>