<div class="spinner_container" *ngIf="showLoader">
  <mat-progress-spinner class="spinner"  [mode]="'indeterminate'" [value]="50"></mat-progress-spinner>
</div>

<div class="container lato">

  <div class="row" id="stepper">
    <div class="col-sm-12">
      <app-stepper-muvit (returnToHome2)="returnToHome2()"></app-stepper-muvit>
    </div>
    <div class="col-2 hidden-desktop"></div>
    <div class="col-lg-8 bottom-air">
      <div class="form-container" style="padding: 0px 15px;">
        <h3 class="form-title">COMPLETA <b class="text-bolder"> TU PAGO</b></h3>
        <div class="payment-card hidden-desktop">
          <div style="display: grid; place-items: initial; width: 369px;" >
            <div class="plan-background plan-background-basic-no-desc">
              <div class="row top-row" style="height: 55%;">
                <div class="col-5">
                  <div style="height: 100%; display: flex; align-items: center;">
                    <p class="white" style="margin-bottom: 0px!important;">PLAN<b style="font-weight: 800;"><br>VIAJERO<br>{{title}}</b>
                      <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow"> -->
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg> -->
                    </p>
                  </div>
                </div>
                <div class="col-7">
                  <div style="display: grid; width: 100%; height: 100%; place-items: center;"> 
                    <div class="plan-selected-amount">
                      <p class="center-plan-title pink"><span class="total-responsive">Total</span> <b style="font-weight: 800; font-size: 18px;">USD {{prima | number : '1.2-2'}}</b></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row " style="height: 45%; margin-right: 0px !important; margin-left: 0px !important;">
                <div class="col">
                  <div style="height: 100%; display: flex; align-items: center;">
                    <p *ngIf="!this.gs.isYearly" class="white" style="margin-bottom: 0px!important;">Máximo global <br> <b style="font-weight: 800;">USD {{coverage[plan - 1] | number:'1.0':'en-US' }}</b></p>    
                    <p *ngIf="this.gs.isYearly" class="white" style="margin-bottom: 0px!important;">Máximo global <br> <b style="font-weight: 800;">USD {{coverageYearly[plan - 1] | number:'1.0':'en-US' }}</b></p>    

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="vertical-center" >
            <p><b>Personas: </b>{{adultsHolder + oldsHolder + olds + adults + kids}} Persona(s) ( <span *ngIf="totalAdults >1">{{totalAdults}} adultos</span><span *ngIf="totalAdults == 1">{{totalAdults}} adulto</span> <span *ngIf="kids > 1">y {{kids}} niños</span><span *ngIf="kids ==1">y {{kids}} niño</span>)</p>
            <p><b>Desde: </b>{{gs.currentInsuranceInfo.travel_starting_date | date:'dd' }} de {{gs.currentInsuranceInfo.travel_starting_date | date:'MMMM' }}</p>
            <p><b>Hasta: </b>{{gs.currentInsuranceInfo.travel_end_date | date:'dd' }} de {{gs.currentInsuranceInfo.travel_end_date | date:'MMMM' }}</p>
          </div>
        </div>
        <app-add-captcha [action] = "ventaMuvit"></app-add-captcha>
        <form #paymentform="ngForm" (ngSubmit)="onSubmit()" class="top-air">
          <div class="center-content group-spacing">
            <div class="container-left specific-mob" style="position: relative;">
              <p class="control-label form-control-space" for="cNumber" >Ingresa el número de la tarjeta de crédito</p>
              
              <div id="number-container" class="form-control flex-microform card-container" [hidden]="!cardFlexComponentIsLoaded" style="display: flex;"></div>
              <input type="text" class="form-control" placeholder="Número de tarjeta de crédito *" required 
                style="padding-right: 45px;" *ngIf="!cardFlexComponentIsLoaded">
                <img class="card-icon hidden-desktop" style="height: 20px;" *ngIf="visa" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/visa.webp" alt="visa">
                <img class="card-icon hidden-desktop" style="bottom: -2px !important; height: 29px;" *ngIf="master" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/mastercard.webp" alt="mastercard">
                <img class="card-icon hidden-desktop" style="bottom: -9px !important; height: 34px;" *ngIf="amex" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/amex.webp" alt="amex">
                <div class="form-control-space hidden-mobile">
                  <img *ngIf="visa" class="card-icon-mob-v" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/visa.webp" alt="visa">
                  <img *ngIf="master" class="card-icon-mob-m" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/mastercard.webp" alt="mastercard">
                  <img *ngIf="amex" class="card-icon-mob-m" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/amex.webp" alt="amex">

                </div>
                <!-- <div class="card-logo" [ngClass]="{'visa': visa , 'master' : master }"></div> -->
            </div>
            <div class="space-form"></div>
            <div class="container-right">
              <p class="control-label form-control-space" for="cardName">Ingresa el nombre como aparece en la tarjeta de crédito</p>
              <input type="text" class="form-control" id="cardName"  placeholder="Nombre *" required
              [(ngModel)]="cardName" name="cardName" #name="ngModel">
            </div>
          </div>
          <div class="center-content group-spacing">

            <div class="container-left">
              <div class="d-flex form-control-space" style="margin: auto;">

                <div class="area-code">
                  <p class="control-label" for="expdate">Vencimiento</p>
                  <!-- <div class="form-control-area-code"> -->
                    <input class="form-control-area-code border-gray" type="text" appExpDate id="cardExpDate" required [(ngModel)]="cardExpDate" name="cardExpDate" #expdate="ngModel" placeholder="MM/AA" (ngModelChange)="verifyExpiration()" [ngClass]="{'invalid-cvv': invalidDate && expdate.touched}">
                  <!-- </div> -->
                  <!-- <span *ngIf="!('value' in areaCodeHolder)"></span> -->
                  
                  <!-- <input type="text" id="areacode" class="form-control-area-code"  placeholder="Código*" 
                  [(ngModel)]="areaCodeHolder['value']" name="areacode" disabled> -->
                </div>
                <div style="width: 3%;"></div>

                <div class="telephone">
                  <p class="control-label" for="cvv">Ingrese CVV</p>
                  <div id="securityCode-container" class="form-control flex-microform" style="display: flex;" [hidden]="!cvvFlexComponentIsLoaded"></div>
                  <input type="text" class="form-control-phone border-gray" 
                    placeholder="Código de seguridad *" required 
                    *ngIf="!cvvFlexComponentIsLoaded">
                    <div style="padding-top: 2px; position: absolute; height: auto; display: flex;" class="telephone bottom-text-mob ">
                      <img class="icon-color" style="height: 18px; margin-right: 5px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/cvv.svg" alt="cvv">
                      <p style="margin-bottom: 0px; margin-top: 2px; line-height: 1.3;" class="small-font">Encuéntralo en el reverso de tu tarjeta</p>
                    </div>
                </div>
              </div>
            </div>
            <div class="space-form"></div>
            <div class="container-left">
              <p class="control-label form-control-space" for="promoCode">Código promocional</p>
              <input type="text" class="form-control" 
                    id="promoCode" [(ngModel)]="promoCode" name="promoCode"  #promo="ngModel" >
              <!-- <input type="text" class="form-control"
              [(ngModel)]="promoCode" name="promoCode" #promoCode="ngModel"> -->
            </div>

          </div>
        </form>
        <div class="nxt-process-btn">
          <div style="text-align: center;">
            <button class="btn continue-button hidden-desktop" [disabled]="disBtn || !paymentform.valid || invalidDate || !isPaymentValid()" id="btnpay" (click)="buyMuvit()">Pagar</button>
            <button class="btn continue-button hidden-mobile" [disabled]="disBtn || !paymentform.valid || invalidDate || !isPaymentValid()" id="btnpay" (click)="buyMuvit()">Pagar USD {{prima | number : '1.2-2'}}</button>
          </div>
        </div>
        <div class="left-bottom hidden-desktop" style="display: inline; text-align: left;">
          <div style="display: inline-flex; margin-left: 21px; margin-bottom: 15px; margin-top: 15px;">
            <div style="margin: auto;">
              <img class="icon-color" style="height: 27px; margin-right: 15px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/ssl.svg" alt="ssl">
            </div>
            <div>
              <p style="margin-bottom: 4px !important;"><b>Transmisión de Datos Seguros</b></p>
              <p style="color: #5B5B5B; margin-bottom: 5px !important;" class="change-width">Tu información personal y de tarjeta de crédito están seguras</p>
            </div>
          </div>
        </div>
        <div class="col hidden-mobile" style="padding-bottom: 30px;">
          <div class="row" style="max-width: 400px; width: 100%; margin: auto;">
            <div class="col-3" style="display: flex;">
              <img class="icon-color" style="height: 27px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/ssl.svg" alt="ssl">
            </div>
            <div class="col-9"> 
              <p style="margin-bottom: 4px !important;"><b>Transmisión de Datos Seguros</b></p>
              <p style="margin-bottom: 2xp !important; color: #5B5B5B;">Tu información personal y de tarjeta de crédito están seguras</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col hidden-mobile">
      <div class="row" style="max-width: 400px; width: 100%; margin: auto;">
        <div class="col-3">
          <img class="icon-color" style="height: 27px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/personas.svg" alt="personas">
        </div>
        <div class="col-9"> 
          <p style="margin-bottom: 4px !important;">¿Necesitas ayuda?</p>
          <p style="margin-bottom: 2xp !important; color: #5B5B5B;">Puedes encontrar las <span class="hyperlink">respuestas a las preguntas más frecuentes </span>aquí</p>
        </div>
      </div>
    </div> -->
  </div>
</div>