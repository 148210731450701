import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class ConfigService {
  s2Url = environment.s2Url;
  countryGT = environment.countryGT

  constructor() {
    // Empty. This is intentional
  }

}
