import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-home-page-brands-svg',
  templateUrl: './home-page-brands-svg.component.html',
  styleUrls: ['./home-page-brands-svg.component.css']
})
export class HomePageBrandsSVGComponent {
@Input() windowTitle:any
@Input() windowIcon:any
@Input() colorbg:any
@Input() closedWindow:boolean
@Input() ilustration:boolean
@Input() openWindow:boolean
}
