import { Component, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDateRangePicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-card-datepicker',
  templateUrl: './card-datepicker.component.html',
  styleUrls: ['./card-datepicker.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CardDatepickerComponent),
      multi: true
    }
  ]
})
export class CardDatepickerComponent {

  @Input() inputTitle: string = '';
  @Input() isRequired: boolean = false;
  @Input() isPrincipal: boolean = false;
  @Output() valueChanged = new EventEmitter<any>();
  @Output() valueChange = new EventEmitter<any>();

  lastAge: any
  dateChange = false
  value: any = '';
  today: any;
  maxAge: any



  constructor() { 
    this.today = new Date().toISOString().split('T')[0];
 
  }

/**
 * Calcular el Min para el date picker para que la edad que seleccione no sea menor a 0 meses.
 * @return {string} La fecha mínima que puede seleccionar el usuario en formato 'YYYY-MM-DD'.
 */
get minAgePick() {
  let today = new Date();
  today.setMonth(today.getMonth());
  let year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate() + 1;
  return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
}


/**
 * Calcular el Min para el date picker para que la edad que seleccione no sea mayor a 75 años.
 * @return {string} La fecha mínima que puede seleccionar el usuario en formato 'YYYY-MM-DD'.
 */
get maxAgePick() {
  let today = new Date();
  let year = today.getFullYear() - 75;
  let month = today.getMonth();
  let day = today.getDate() + 2;
  
  if (day < 1) {
    month -= 1;
    if (month < 0) {
      month = 11;
      year -= 1;
    }
    day = new Date(year, month + 1, 0).getDate();
  }
  
  return `${year}-${month < 10 ? '0' + (month + 1) : month + 1}-${day < 10 ? '0' + day : day}`;
}





  @ViewChild('picker') picker: MatDateRangePicker<any>;

  openCalendar() {
    if (!this.picker.opened) {
      this.picker.open();
    }
  }

  onChange: (value: string) => void = () => { };
  onTouch: () => void = () => { };

  writeValue(value: string): void {
    this.value = value;
    this.onChange(this.value);
    this.onTouch();
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Implement if needed
  }

  getAge(birthdate: any) {
    let today = new Date();
    let birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  
  /**
 * Este método se llama cuando el valor del datepicker cambia.
 * Verifica si la edad del titular es mayor de 18 años y emite los cambios de valor.
 * @param {MatDatepickerInputEvent<Date>} event - El evento del datepicker.
 */
onInputChange(event: MatDatepickerInputEvent<Date>): void {
  const inputValue = event.value;
  const age = this.getAge(inputValue);

  if (age < 18 && this.isPrincipal) {
    Swal.fire({
      title: 'Error En Titular',
      icon: 'error',
      text: 'El titular debe ser mayor de edad',
    });

    this.value = null;
    this.writeValue(null);
    this.picker.select(undefined);
    return;
  }

  this.value = inputValue;
  this.onChange(this.value);
  this.onTouch();
  this.valueChanged.emit(inputValue);

  const emitterObject = {
    dateChange: this.dateChange || false,
    lastAge: this.dateChange ? this.lastAge : undefined
  };
  this.valueChange.emit(emitterObject);

  this.dateChange = true;
  this.lastAge = age;
}




}
