import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ApiService } from 'src/app/api/api.service'
import { DataConstructorService } from 'src/app/services/data-constructor.service'

import Swal from 'sweetalert2'
import { NavbarServiceService } from '../../navbar-service.service'
import * as moment from "moment";


@Component({
  selector: 'app-customer-information',
  templateUrl: './customer-information.component.html',
  styleUrls: ['./customer-information.component.css']
})
export class CustomerInformationComponent {
  clientForm: FormGroup
  @Output() FormValue = new EventEmitter<any>()
  // Este evento emitirá el estado de validez del formulario
  @Output() formValidity = new EventEmitter<boolean>();
  countriesList: any = []
  countries: any = []

  prefixList: any = []
  genreOptions = [
    { description: 'Masculino', value: '1' },
    { description: 'Femenino', value: '0' }
  ]
  TermsAndConditions = false
  msgSub: any = true
  isChanged: any
  extraPrice: any
  countryCatalog: any = []
  totalPerPerson: any = 0
  individualPrice: number
  typePerson: string
  @Input() isLastForm: boolean = false
  @Output() addFormEvent = new EventEmitter<void>();

  currentDate: Date;
  birthdayMinDate: Date;

  onAddFormButtonClick(): void {
    this.addFormEvent.emit();
  }

  /**
   * Ordena un array de números en orden ascendente y elimina los duplicados.
   * @param {number[]} numeros - El array de números a ordenar y eliminar duplicados.
   * @returns {number[]} El array de números ordenados y sin duplicados.
   */
  ordenarYEliminarDuplicados(numeros: number[]): number[] {
    numeros.sort((a, b) => a - b)
    return [...new Set(numeros)]
  }



  /**
   * @constructor
   * @param {FormBuilder} formBuilder - El constructor de formularios.
   * @param {ApiService} api - El servicio de la API.
   * @param {DataConstructorService} dataConstructor - El servicio del constructor de datos.
   * 
   * Al instanciar la clase, se realizan llamadas a la API para obtener los códigos de país y de área.
   * Los resultados se procesan y se almacenan en las propiedades correspondientes.
   */
  constructor(private formBuilder: FormBuilder, private api: ApiService,
    public dataConstructor: DataConstructorService, private navbar: NavbarServiceService) {

    this.api.countries.getCountryCode().promise().then(resp => {
      this.countryCatalog = resp.map(country => ({ description: country.name, id: country.id }))
    })

    this.api.countries.getCountryAreaCode().promise().then(resp => {
      let country_code = resp.filter(country => country.id != null).map(country => parseInt(country.area_code))
      country_code = this.ordenarYEliminarDuplicados(country_code)
      this.prefixList = country_code.map(prefix => ({ value: prefix }))

      this.countries = resp.map(country => ({ description: country.name, value: country.name }))
      this.countriesList = resp.map(country => ({ id: country.id, description: country.name, value: country.area_code }))
    })
  }


  /**
  * Obtiene el código de país correspondiente a un país.
  * @param {any} country - El país del que se quiere obtener el código.
  * @returns {any} El código del país.
  */
  getCountryCode(country: any) {
    return this.countriesList.find(element => element.description == country).value
  }

  /**
  * Construye la residencia de la persona.
  */
  constructResidency() {
    let residencyCountry = this.getFormFieldValue('residency')
    if (!residencyCountry.description && residencyCountry != '' && residencyCountry != null) {
      let residency = this.countriesList.find(element => element.description == residencyCountry)
      let residencyID = this.countryCatalog.find(element => element.description == residencyCountry)
      let residencyT = {
        description: residency.description,
        code: residency.value,
        id: residencyID.id
      }
      this.setFormFieldValue('residency', residencyT)
    }
  }


  /**
   * Calcula la edad de la persona basándose en su fecha de nacimiento.
   * @returns {number} La edad de la persona.
   */
  getNewAge() {
    if (this.clientForm.get('birthday').value) {
      let date = new Date(this.clientForm.get('birthday').value)
      let today = new Date()
      let age = today.getFullYear() - date.getFullYear()
      let month = today.getMonth() - date.getMonth()
      if (month < 0 || (month === 0 && today.getDate() < date.getDate())) {
        age--
      }
      return age
    }
    return ''
  }

  /**
  * Actualiza el estado de la fecha y llama a la función de actualización.
  * @param {any} event El evento que indica si la fecha ha cambiado.
  */
  isChangeDate(event: any) {
    this.isChanged = event
    this.update()
  }

  /**
   * Obtiene el precio extra basado en la edad de la persona y actualiza el total final.
   * @param {any} age - La edad de la persona.
   */
  getExtraPrice(age: any) {
    this.extraPrice = this.dataConstructor.getExtraPrice(age, true);
    this.dataConstructor.FinalPrice()
    this.dataConstructor.updateFinalTotal();
    this.totalPerPerson = this.extraPrice
  }




  /**
   * Actualiza el precio extra y el total final basado en la nueva edad.
   * Si la fecha ha cambiado, elimina la persona del tipo anterior, obtiene el precio extra para el nuevo tipo,
   * resta el precio extra del total final, establece el precio individual a 0 y actualiza el total final.
   * Independientemente de si la fecha ha cambiado o no, obtiene el precio extra para la nueva edad.
   */
  update() {
    if (this.clientForm.get('birthday')) {
      this.dataConstructor.setHolderName(this.dataConstructor.getPersonType(this.getAge(this.clientForm.get('birthday').value)))
      this.typePerson = this.dataConstructor.getPersonType(this.getAge(this.clientForm.get('birthday').value));
      this.getExtraPrice(this.getAge(this.clientForm.get('birthday').value));
      this.navbar.isPriceShowerVisible = true
    }
  }



  /**
   * Actualiza el valor del campo 'units' del formulario con el total por persona.
   */
  updateUnitsForm() {
    this.clientForm.get('units').setValue(this.totalPerPerson)
  }


  /**
   * Método que se ejecuta al inicializar el componente.
   * Inicializa el formulario con los campos requeridos.
   */
  ngOnInit(): void {
    this.clientForm = this.formBuilder.group({
      first_name: this.formBuilder.control('', Validators.required),
      middle_name: this.formBuilder.control(''),
      last_name: this.formBuilder.control('', Validators.required),
      last_name_2: this.formBuilder.control(''),
      genre: this.formBuilder.control('', Validators.required),
      birthday: this.formBuilder.control('', Validators.required),
      birthday_: this.formBuilder.control(''),
      passport: this.formBuilder.control('', Validators.required),
      residency: this.formBuilder.control('', Validators.required),
      email: this.formBuilder.control(this.dataConstructor.email, [Validators.required, Validators.email]),
      numberPrefix: this.formBuilder.control('', Validators.required),
      phoneNumber: this.formBuilder.control('', Validators.required),
      nit: this.formBuilder.control('', Validators.required),
      name_billing: this.formBuilder.control('', Validators.required),
      billingAddress: this.formBuilder.control('', Validators.required),
      age: this.formBuilder.control(''),
      units: this.totalPerPerson

    })

    // Emitir valor de validez cada vez que el formulario cambia
    this.clientForm.statusChanges.subscribe(() => {
      this.checkFormValidity();
    });
    this.clientForm.get("residency").valueChanges.subscribe(selectedCountry => {
      if (typeof selectedCountry === "string") {
        let areaCode = this.getCountryCode(selectedCountry);
        this.clientForm.get('numberPrefix').setValue(Number(areaCode));
      }
    });
    let newDate = moment(new Date());
    this.currentDate = newDate.subtract("6", "months").toDate();
    newDate = moment(new Date());
    this.birthdayMinDate = newDate.subtract("85", "years").toDate();
  }


  checkFormValidity() {
    this.formValidity.emit(this.clientForm.valid);
  }

  /**
  * Obtiene el valor de un campo del formulario.
  * @param {string} fieldName - El nombre del campo del formulario.
  * @returns {any} El valor del campo del formulario.
  */
  getFormFieldValue(fieldName: string): any {
    return this.clientForm.get(fieldName).value
  }

  /**
  * Establece el valor de un campo del formulario.
  * @param {string} fieldName - El nombre del campo del formulario.
  * @param {any} value - El valor que se va a establecer para el campo del formulario.
  */
  setFormFieldValue(fieldName: string, value: any): void {
    //validar que value traiga un valor TypeError: Cannot read properties of null (reading 'setValue')

    if (value == null) {
      value = ''
    }
    this.clientForm.get(fieldName).setValue(value);


    if (fieldName == 'email') {
      this.navbar.email = value
    }

    if (fieldName == 'passport') {
      //Verificar que el pasaporte no se repita entre titular y otros beneficiarios
      let passport = value
      let beneficiaries = this.dataConstructor.beneficiaries
      let principal = this.dataConstructor.principalClient
      let repeated = false
      if (principal.passport == passport) {
        repeated = true
      }
      beneficiaries.forEach(beneficiary => {
        if (beneficiary.passport == passport) {
          repeated = true
        }
      });
      if (repeated) {
        Swal.fire({
          title: 'Error',
          text: 'El pasaporte no puede ser el mismo para el titular y los beneficiarios',
          icon: 'error',
          confirmButtonText: 'Aceptar'
        })
        this.clientForm.get(fieldName).setValue('')
      }
    }
  }

  /**
  * Calcula la edad de la persona basándose en su fecha de nacimiento.
  * @param {any} birthday - La fecha de nacimiento de la persona.
  * @returns {number} La edad de la persona.
  */
  getAge(birthday: any) {
    let date = new Date(birthday)
    let today = new Date()
    let age = today.getFullYear() - date.getFullYear()
    let month = today.getMonth() - date.getMonth()
    if (month < 0 || (month === 0 && today.getDate() < date.getDate())) {
      age--
    }
    return age
  }


  /**
   * Transforma la fecha de nacimiento a formato dd/mm/yyyy en español.
   */
  transformBirthday() {
    let birthday = this.getFormFieldValue('birthday')
    let fechaConvertida = new Date(birthday)
    let dia = fechaConvertida.getDate()
    let mes = fechaConvertida.getMonth() + 1
    let ano = fechaConvertida.getFullYear()
    this.setFormFieldValue('birthday_', `${dia}/${mes}/${ano}`)
  }

  /**
  * Calcula la edad basada en la fecha de nacimiento y la establece en el formulario.
  */
  constructAge() {
    var birthday = this.getFormFieldValue('birthday')
    var age = this.getAge(birthday)
    this.setFormFieldValue('age', age)
  }


  /**
   * Muestra una alerta con el título y el texto proporcionados.
   * @param {string} title - El título de la alerta.
   * @param {string} text - El texto de la alerta.
   */
  showAlert(title, text) {
    Swal.fire({
      title: title,
      text: text,
      icon: 'error',
      showConfirmButton: true,
      showDenyButton: false,
      denyButtonText: 'Regresar',
      confirmButtonText: 'Aceptar',
      showCloseButton: true,
      timer: 25000
    }).then(result => {
      this.dataConstructor.deleteAllBeneficiaries();
      this.dataConstructor.deletePrincipalClient();
    })
  }



  /**
   * Valida el correo electrónico proporcionado.
   * @param {string} email - El correo electrónico a validar.
   * @returns {boolean} Retorna verdadero si el correo electrónico es válido, de lo contrario retorna falso.
   */
  validateEmail(email) {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email);
  }

  /**
   * Valida el pasaporte proporcionado.
   * @param {string} passport - El pasaporte a validar.
   * @returns {boolean} Retorna verdadero si el pasaporte es válido (solo contiene números y letras), de lo contrario retorna falso.
   */
  validatePassport(passport) {
    const passportRegex = /^[a-zA-Z0-9]*$/;
    return passportRegex.test(passport);
  }


  /**
   * Revisa los campos del formulario.
   * @returns {boolean} Retorna verdadero si los campos son válidos, de lo contrario retorna falso y muestra una alerta.
   */
  fieldReview() {
    if (!this.validateEmail(this.clientForm.value.email)) {
      this.showAlert('EMAIL INVALIDO', 'El correo ingresado es invalido')
      return false
    }
    if (!this.validatePassport(this.clientForm.value.passport)) {
      this.showAlert('PASAPORTE INVALIDO', 'El pasaporte ingresado es invalido')
      return false
    }
    if (!this.getFormFieldValue('birthday')) {
      this.showAlert('FECHA DE NACIMIENTO', 'La fecha de nacimiento es requerida')
      return false
    }


    return true
  }


  onSubmit(): void {
    if (this.TermsAndConditions) {
      if (this.fieldReview()) {
        this.constructResidency();
        this.transformBirthday();
        this.constructAge();
        this.updateUnitsForm();
        if (this.clientForm.valid) {
          this.FormValue.emit(this.clientForm.value);
        } else {
          this.FormValue.emit('Error');
          Swal.fire({
            title: 'Error',
            icon: 'error',
            html: `<h4>Faltaron los siguientes Datos del Titular</h4>${this.buildErrorAlertMessage()}`,
            confirmButtonText: 'OK'
          });
        }
      }
    } else {
      this.FormValue.emit('Error');
      Swal.fire({
        title: 'Acepta los Terminos y Condiciones para continuar',
        icon: 'info',
        confirmButtonText: 'OK'
      });
    }
  }

  /**
  * Obtiene los errores del formulario.
  * @returns {any} Un objeto que contiene los errores del formulario.
  */
  getFormErrors(): any {
    const errors: any = {}
    Object.keys(this.clientForm.controls).forEach(key => {
      const controlErrors = this.clientForm.get(key).errors
      if (controlErrors != null) {
        errors[key] = controlErrors
      }
    })
    return errors
  }

  /**
   * Construye un mensaje de alerta de error basado en los errores del formulario.
   * @returns {string} El mensaje de alerta de error.
   */
  buildErrorAlertMessage(): string {
    const errors = this.getFormErrors();
    let errorMessage = '';

    for (const key of Object.keys(errors)) {
      const fieldTitle = this.getFieldTitle(key);
      if (fieldTitle) {
        errorMessage += `${fieldTitle}, `;
      }
    }

    // Elimina la última coma y espacio si existe
    if (errorMessage.endsWith(', ')) {
      errorMessage = errorMessage.slice(0, -2);
    }

    return errorMessage;
  }

  /**
  * Obtiene el título del campo basado en el nombre del campo.
  * @param {string} fieldName - El nombre del campo.
  * @returns {string} El título del campo.
  */
  getFieldTitle(fieldName: string): string {
    switch (fieldName) {
      case 'name_1':
        return 'Nombre 1';
      case 'name_2':
        return 'Nombre 2';
      case 'last_name_1':
        return 'Apellido 1';
      case 'last_name_2':
        return 'Apellido 2';
      case 'genre':
        return 'Género';
      case 'birthday':
        return 'Fecha de Nacimiento';
      case 'passport':
        return 'Pasaporte';
      case 'residency':
        return 'Residencia';
      case 'email':
        return 'Correo Electrónico';
      case 'numberPrefix':
        return 'Prefijo del Número';
      case 'phoneNumber':
        return 'Número de Teléfono';
      case 'nit':
        return 'NIT';
      case 'name_billing':
        return 'Nombre de Facturación';
      case 'billingAddress':
        return 'Dirección de Facturación';
      default:
        return '';
    }
  }


}
