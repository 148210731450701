import { Component } from '@angular/core';

@Component({
  selector: 'app-aseguradora-general',
  templateUrl: './aseguradora-general.component.html',
  styleUrl: './aseguradora-general.component.css'
})
export class AseguradoraGeneralComponent {

}
