import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-warning-card',
  templateUrl: './warning-card.component.html',
  styleUrls: ['./warning-card.component.css']
})
export class WarningCardComponent implements OnInit {

  warningMessage = ""
  constructor(public dialogRef: MatDialogRef<WarningCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    
    this.warningMessage = this.data.warning;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isString(val): boolean { return typeof val === 'string'; }

}
