<div class="tag">
    <img src="../../../../assets/tag-ilustration/maleta-tag-seguro-de-viaje-cobertura-maletas-01.svg" alt="" class="tag-ilustration">
    <div class="tag-content">
        <div class="tag-header">
            <img src="../../../../assets/tag-ilustration/{{this.tagFile}}.svg" alt="" style="height: 45px;">
            <h4 style="color: {{this.tagTitleColor}};"> {{this.tagTitle}} </h4>
        </div>
        <div class="tag-info">
            <div *ngFor="let offer of Offers" class="offer-model">
            <i class="fa-solid fa-check"></i>
            <p>{{offer.description}}</p>
            </div>
        </div>
        <div class="tag-footer">
        </div>
    </div>
</div>