<div class="footer-mobile">
    <div class="footer-logo">
        <img src="../../../../assets/tecniseguros/tecnitraveler.png" alt="Logo Tecniseguros">
    </div>
    <div class="social-links">
        <a href="https://www.facebook.com/TecniSegurosGT/" target="_blank" aria-label="Facebook">
            <i class="fab fa-facebook-square"></i>
        </a>
        <a href="https://www.instagram.com/tecnisegurosguatemala/" target="_blank" aria-label="Instagram">
            <i class="fab fa-instagram"></i>
        </a>
        <a href="https://www.linkedin.com/company/tecniseguros/" target="_blank" aria-label="LinkedIn">
            <i class="fab fa-linkedin"></i>
        </a>
        <a href="https://www.youtube.com/channel/UCYcFXad0n2WOsWgbi2mkL2w" target="_blank" aria-label="YouTube">
            <i class="fab fa-youtube-square"></i>
        </a>
    </div>
    <div class="footer-menu">
        <a href="https://tecniseguros.com/quienes-somos/">¿Quiénes somos?</a>
        <a href="https://tecniseguros.com/reclamo-digital/">Reclamo Digital</a>
        <a href="https://tecniseguros.com/formularios-reclamos/">Formularios de reclamos</a>
        <a href="https://tecniseguros.com/politica-de-privacidad/">Política de Privacidad</a>
        <a href="https://tecniseguros.com/individual-familias/">Individual – Familias</a>
        <a href="https://tecniseguros.com/empresas/">Empresas</a>
        <a href="https://tecniseguros.com/por-que-tecniseguros/">¿Por qué Tecniseguros?</a>
        <a href="https://tecniseguros.com/contacto/">Contacto</a>
        <a href="https://portal.office.com">WEBMAIL</a>
        <a href="https://bpm.procesalo.com/AP/login.aspx?ReturnUrl=%2FAP%2FHome.aspx%3FidFrm%3D3050&amp;idFrm=3050">INTERMEDIARIOS</a>
    </div>
    <div class="footer-bottom">
        <a href="https://rpn.mediprocesos.com/" target="_blank">
            <img src="../../../../assets/tecniseguros/iconos/rpn.png" height="150" alt="rpn">
        </a>
        <a href="https://psminternacional.com/" target="_blank">
            <img src="../../../../assets/tecniseguros/iconos/psm.png"  height="150" alt="psm">
        </a>
        <a href="https://saludtotal.com.gt/" target="_blank">
            <img src="../../../../assets/tecniseguros/iconos/salud-total.png"  height="150" alt="Salud total">
        </a>
        <div class="copyright">
            <hr style="color: white;">
            © 2024 TECNISEGUROS, CORREDORES DE SEGUROS S.A.
        </div>
        <br>
        <br>
    </div>
</div>
