<div class="plans-container">
  <div class="plan-banner">
    <div class="banner-title">
      <h1>Nuestros <br> Planes</h1>
    </div>
    <div class="banner-detail">
      <p>
        Las tarifas y coberturas pueden variar según el país de destino y la duración de tu viaje.
      </p>
    </div>
    <div class="banner-img">
      <img class="girl-img" src="../../../../assets/muvitBAM/nuestros-planes.png"/>
    </div>
  </div>
  <div class="plan-detail">
    <div class="detail-title">
      <h1>Plan Diario</h1>
    </div>
    <div class="detail-description">
      <p>
        Plan que abarca únicamente los días que estarás de viaje a nivel mundial
      </p>
    </div>
    <div class="plan-box">
      <div class="plan-header">
        <div class="plan-name">
          <h3>
            {{planConfig?.plan_name}}
          </h3>
        </div>
        <div class="plan-price">
          <p class="text">Desde</p>
          <p class="price"><strong style="font-size: 29px; font-weight: 700; color: #2C2A29;">$5.25</strong>/por día</p>
          <p class="disclaimer">*Precio para mayores de 65 años Desde $15.28</p>
        </div>
      </div>
      <div class="plan-coverages">
        <div class="coverage ">
          <div class="coverage-name">{{planConfig?.plan_detail_full[1].benefits[0].benefit}}</div>
          <div class="coverage-amount">{{regionBenefit(planConfig?.plan_detail_full[1].benefits[0])}}</div>
        </div>
        <div class="coverage ">
          <div class="coverage-name">{{planConfig?.plan_detail_full[1].benefits[1].benefit}}</div>
          <div class="coverage-amount">{{regionBenefit(planConfig?.plan_detail_full[1].benefits[1])}}</div>
        </div>
        <div class="coverage ">
          <div class="coverage-name">{{planConfig?.plan_detail_full[2].benefits[0].benefit}}</div>
          <div class="coverage-amount">{{regionBenefit(planConfig?.plan_detail_full[2].benefits[0])}}</div>
        </div>
        <div class="coverage ">
          <div class="coverage-name">{{planConfig?.plan_detail_full[2].benefits[1].benefit}}</div>
          <div class="coverage-amount">{{regionBenefit(planConfig?.plan_detail_full[2].benefits[1])}}</div>
        </div>
        <div class="coverage ">
          <div class="coverage-name">{{planConfig?.plan_detail_full[3].benefits[0].benefit}}</div>
          <div class="coverage-amount">{{regionBenefit(planConfig?.plan_detail_full[3].benefits[0])}}</div>
        </div>
        <div class="coverage ">
          <div class="coverage-name">{{planConfig?.plan_detail_full[3].benefits[1].benefit}}</div>
          <div class="coverage-amount">{{regionBenefit(planConfig?.plan_detail_full[3].benefits[1])}}</div>
        </div>
        <div class="coverage ">
          <div class="coverage-name">{{planConfig?.plan_detail_full[1].benefits[6].benefit}}</div>
          <div class="coverage-amount">{{regionBenefit(planConfig?.plan_detail_full[1].benefits[6])}}</div>
        </div>
      </div>
    </div>
    <div class="quote-box">
      <button class="quote-button" (click) ="openQuoteBox()">Cotizar</button>
    </div>
  </div>
</div>