<div style="background-color: #faf8f6;">
  <div style="width: 100%; height: 570px; display: flex; justify-content: center; align-items: center;" *ngIf="!isScreenWidthLessThan1100">
    <div class="cover"  style="width: 80%; height: 90%; border-radius: 25px; background-image: url('{{selectedArticle.cover2}}'); background-size: cover; background-position: top center; box-shadow: 2px 2px 5px #0000004d;">
    </div>
  </div>
  <div class="cover" *ngIf="isScreenWidthLessThan1100">
    <img class="cover-img" [src]="selectedArticle.coverR" [alt]="selectedArticle.altPhoto" />
  </div>
  <div class="content-article" *ngIf="!isScreenWidthLessThan1100">
  <div class="full-article">
    <div class="article-title">
      <div class="article-title-text">
      <h1 [innerHTML]="formatTitle(selectedArticle.title)"></h1>
      </div>
    </div>
    <div class="article-intro">
        <div class="article-intro-text">
          <p [innerHTML]="formatContent(selectedArticle.intro)"></p>
        </div>
    </div>
      
      

  <div class="article-body">
    <div [ngClass]="{'first': section.box === 'white', 'even': section.box === 'pink', 'odd': section.box === 'purple'}" *ngFor="let section of selectedArticle.sections; let i = index">
      <h3 [innerHTML]="formatContent(section.subtitle)"></h3>
      <p [innerHTML]="formatContent(section.resume)"></p>
      
      <div *ngFor="let header of section.headers; let j = index">
        <h4 [innerHTML]="formatContent(header)"></h4>
        <p [innerHTML]="formatContent(section.contents[j])"></p>
        
        <img class="article-image" *ngIf="section.photos[j] != null " [src]="section.photos[j]" [alt]="section.altPhotos[j]" />
        <div class="article-image-legend">
          <p *ngIf="section.photos[j] != null "> {{ section.altPhotos[j] }}</p>
          <p *ngIf="section.photos[j] != null ">Autor: {{ section.authorPhotos[j] }}</p>
          
        </div>
      </div>
    </div>
    </div>





    <div class="article-conclusion">
      <div class="article-conclusion-text">
        <p [innerHTML]="formatContent(selectedArticle.conclusion)"></p>
      </div>
    </div>


    <div class="page-div">
      <button  (click)="prevArticle()" class="page-btn" [disabled]="currentPageIndex === 0"> 
        <img *ngIf="currentPageIndex === 0" class="arrow-page-left"  src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-left.svg" />
        <img *ngIf="!(currentPageIndex === 0)" class="arrow-page-left"  src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-left-active.svg" />
        Anterior </button>
      
      <!-- Números de página -->
    <div class="page-numbers">
      <ng-container *ngFor="let pageNumber of getPageNumbersToShow()">
        <div
          class="page-number"
          [ngClass]="{ 'current-page': pageNumber === currentPageIndex }"
          (click)="selectArticle(pageNumber )"
        >
          {{ pageNumber + 1}}
      </div> 
      </ng-container>
    </div>
    <button (click)="nextArticle()" class="page-btn" [disabled]="currentPageIndex === listOfArticles.length - 1">
      Siguiente
      <img *ngIf="currentPageIndex === listOfArticles.length - 1" class="arrow-page-right"  src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-right.svg" />
      <img *ngIf="!(currentPageIndex === listOfArticles.length - 1)" class="arrow-page-right"  src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-right-active.svg" />
    </button>
    </div>
  </div>


  </div>

  <div class="content-article-mobile" *ngIf="isScreenWidthLessThan1100">
    <div class="full-article">
      <div class="article-title">
        <div class="article-title-text">
        <h1 [innerHTML]="formatTitle(selectedArticle.title)"></h1>
        </div>
      </div>
      <div class="article-intro">
          <div class="article-intro-text">
            <p [innerHTML]="formatContent(selectedArticle.intro)"></p>
          </div>
      </div>
        
        
  
    <div class="article-body">
      <div [ngClass]="{'first': i === 0, 'even': (i!== 0 && i % 2 === 0), 'odd': i % 2 !== 0}" *ngFor="let section of selectedArticle.sections; let i = index">
        <h3 [innerHTML]="formatContent(section.subtitle)"></h3>
        <p [innerHTML]="formatContent(section.resume)"></p>
        
        <div  *ngFor="let header of section.headers; let j = index">
          <h4 *ngIf="section.headers[j] != null" [innerHTML]="formatContent(header)"></h4>
          <p *ngIf="section.contents[j] != null" [innerHTML]="formatContent(section.contents[j])"></p>
          
          <img class="article-image" *ngIf="section.photos[j] != null " [src]="section.photos[j]" [alt]="section.altPhotos[j]" />
          <div class="article-image-legend">
            <p *ngIf="section.photos[j] != null "> {{ section.altPhotos[j] }}</p>
            <p *ngIf="section.photos[j] != null ">Autor: {{ section.authorPhotos[j] }}</p>
            
          </div>
        </div>
      </div>
      </div>
  
  
  
  
  
      <div class="article-conclusion">
        <div class="article-conclusion-text">
          <p [innerHTML]="formatContent(selectedArticle.conclusion)"></p>
        </div>
      </div>
  
  
      <div class="page-div">
        <button  (click)="prevArticle()" class="page-btn" [disabled]="currentPageIndex === 0"> 
          <img *ngIf="currentPageIndex === 0" class="arrow-page-left"  src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-left.svg" />
          <img *ngIf="!(currentPageIndex === 0)" class="arrow-page-left"  src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-left-active.svg" />
          Anterior </button>
        
        <!-- Números de página -->
      <div class="page-numbers">
        <ng-container *ngFor="let pageNumber of getPageNumbersToShow()">
          <div
            class="page-number"
            [ngClass]="{ 'current-page': pageNumber === currentPageIndex }"
            (click)="selectArticle(pageNumber )"
          >
            {{ pageNumber + 1}}
        </div> 
        </ng-container>
      </div>
      <button (click)="nextArticle()" class="page-btn" [disabled]="currentPageIndex === listOfArticles.length - 1">
        Siguiente
        <img *ngIf="currentPageIndex === listOfArticles.length - 1" class="arrow-page-right"  src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-right.svg" />
        <img *ngIf="!(currentPageIndex === listOfArticles.length - 1)" class="arrow-page-right"  src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-right-active.svg" />
      </button>
      </div>
    </div>
    
  
    </div>
</div>