import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';

@Injectable()
export class IAmService {
  roles: any[] = [];
  constructor(private globalService:GlobalService) {     
  }

  isRole = (roles) => {    
    if (this.roles.indexOf('policy_integrator_admin') !== -1) {
      return true;
    }
    else {
      if(roles){
        return roles.some((role) => {
          return this.roles.indexOf(role) !== -1;
        });
      } else {
        return false;
      }
    }    
};

isTTAdmin = (roles)=>{
  //policy_integrator_crm_temporal_admin
  if (this.roles.indexOf('policy_integrator_crm_temporal_admin') !== -1) {
    return true;
  }
  else {
    if(roles){
      return roles.some((role) => {
        return this.roles.indexOf(role) !== -1;
      });
    } else {
      return false;
    }
  } 
}

  updateRoles(roles){
    this.roles = roles;
  }

}
