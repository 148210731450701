<div class="cardFAQ">
    <div class="title">{{question.question}}</div>
    <div *ngIf="question.answer" class="answer">
        <div class="text">{{question.answer}} </div>
        <div class="information">
        <div class="contact" *ngIf="question.contact">
            <div class="cardNumber"> <i class="fas fa-phone"></i> +502 2268 7757</div>
            <div class="cardNumber"> <i class="fab fa-whatsapp"></i> +54 911 2472 6164</div>
        </div>
        <div class="questionary">
            <div class="thumbs">
                ¿Te fue de ayuda? 
                <i class="far fa-thumbs-up" [ngClass]="{'selected': selectedThumb == 'up'}" (click)="selectThumb('up')"></i> 
                <i class="far fa-thumbs-down" [ngClass]="{'selected': selectedThumb == 'down'}" (click)="selectThumb('down')"></i>
            </div>
        </div>
    </div>
    </div>
</div>