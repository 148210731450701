import { AfterViewInit, Component, Input, input, OnInit, SimpleChanges } from '@angular/core';
import { Route, Router } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';
import { GlobalService } from 'src/app/services/global.service';
import { QuoteBoxComponent } from '../../quote-box/quote-box.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-home-plans',
  templateUrl: './home-plans.component.html',
  styleUrl: './home-plans.component.css'
})
export class HomePlansComponent implements OnInit, AfterViewInit{
  constructor(
    public gs: GlobalService,
    private router: Router,
    public api: ApiService,
    public dialog: MatDialog,

  ){
    this.screenWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      this.screenWidth = window.innerWidth;
    });
  }
  @Input() plans: any
  planConfig: any;
  screenWidth: number;



  ngAfterViewInit(): void {
  
  }

  ngOnInit(): void {

    console.log()
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['plans'] && this.plans) {
      this.planConfig = JSON.parse(this.plans[0].config); // Parsear los datos cuando estén disponibles
      console.log('Plan config:', this.planConfig);
    }
  }


  regionBenefit(benefit){
    if(this.gs.destiny && Array.isArray(this.gs.destiny))
      {
      if(this.gs.destiny[0]){
        if(this.gs.destiny[0].kind == 4){
          return benefit?.details.ROW
        }
        if(this.gs.destiny[0].kind == 3){
          return benefit?.details.LATAM
        }
        if(this.gs.destiny[0].kind == 2){
          return benefit?.details.US
        }
      }
      else{
        return benefit?.details.LATAM
      }
      
    }
    else{
      return benefit?.details.LATAM
    }
    
    
      
  }

  // getHolderPrice(){
  //   const currentDate = new Date(); // Fecha actual
  //   const eighteenYearsAgo = new Date(currentDate.setFullYear(currentDate.getFullYear() - 18)); // Restar 18 años
  //   let region = ""
  //   // Convertir a formato ISO 8601
  //   const isoDate = eighteenYearsAgo.toISOString();

    
  //   let payload = {
  //     "insurance_plan_id": "1",
  //     "product_code": "BAM_MUVIT_DIARIO",
  //     "days_of_travel": 1,
  //     "destinations": [
  //       {
  //         "region": (this.gs.destiny && this.gs.destiny[0]) ? this.gs.destiny[0].region : "LATAM" 
  //       }
  //     ],
  //     "insured": {
  //       "birthday": isoDate
  //     }
  //   }
  //   this.api.enrollAsys.getIndividualPrice(payload).promise()
  //   .then(response => {
  //     return response
  //   })
  //   .catch(e => {
  //     return "00.00"
  //   })
  // }
  openQuoteBox(){
    if(this.screenWidth >= 11){
      this.dialog.open(QuoteBoxComponent, {
        width: '550px',
        data: this.gs.countries,
        panelClass: ['dialog_']
      });
    }
  }
    
}
