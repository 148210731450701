import { Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDateRangePicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataConstructorService } from 'src/app/services/data-constructor.service';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { ApiService } from 'src/app/api/api.service';
import { InsuranceDependent } from 'src/app/models/insurance-dependent';
import { AddAccompanistComponent } from '../../client-information/add-accompanist/add-accompanist.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-client-form-mobile',
  templateUrl: './client-form-mobile.component.html',
  styleUrls: ['./client-form-mobile.component.css']
})
export class ClientFormMobileComponent {
  genreOptions = [
    { description: 'Masculino', value: '1' },
    { description: 'Femenino', value: '0' }
  ]

  countriesList: any = [];
  countries: any = [];
  prefixList: any = [];
  countryCatalog: any = [];

  minDate: any;
  maxDate: any;

  showMessage = false;
  dependents?: InsuranceDependent[] = [];
  termsAndConditions = false;

  correctAge = true;
  @ViewChild('picker') picker: MatDateRangePicker<any>;

  travelInfo: any;
  Total: any = 0
  blockedDates: any[];
  Date: any = {
    start: '',
    end: ''
  }
  today = new Date();
  convertirFecha(fecha:any) {
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const fechaConvertida = new Date(fecha);
    const dia = fechaConvertida.getDate();
    const mes = meses[fechaConvertida.getMonth()];
    const ano = fechaConvertida.getFullYear();
    return `${dia} ${mes} ${ano}`;
  }

  convertDate(date: any) {
    const fecha = new Date(date);
    const dia = fecha.getDate() + 1;
    const mes = fecha.getMonth() + 1;
    const ano = fecha.getFullYear();
    return `${ano}-${mes < 10 ? '0' + mes : mes}-${dia < 10 ? '0' + dia : dia}`;
  }

  constructor(public dataConstructor: DataConstructorService, private builder: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute, private title: Title,
    public dialog_: MatDialog,
    private api: ApiService,
    public gs: GlobalService,
  ){ 
    
    this.title.setTitle(this.activatedRoute.title['destination']['_value']['title'])
    
    var initialDate = this.convertirFecha(this.dataConstructor.date.start)
    var finalDate = this.convertirFecha(this.dataConstructor.date.end)
    this.travelInfo = this.builder.group({
      destination: this.builder.control(this.dataConstructor.countries[0].description),
      persons: this.builder.control([String(this.dataConstructor.totalPeople) + ' personas']),
      startDate: this.builder.control([initialDate]),
      endDate: this.builder.control([finalDate]),
    })
    this.Total = this.dataConstructor.setTotalMobile()
    this.dataConstructor.isTotalVisible = true

    this.travelInfo.disable()

    this.Date = {start: this.convertDate(this.dataConstructor.date.start), end: this.convertDate(this.dataConstructor.date.end)}

    this.maxDate = new Date(Date.now());
    const currentDate = new Date();
    this.minDate = new Date();
    this.minDate.setFullYear(currentDate.getFullYear() - 85)

    this.api.countries.getCountryCode().promise().then(resp => {
      this.countryCatalog = resp.map(country => ({ description: country.name, id: country.id }));
    })

    this.api.countries.getCountryAreaCode().promise().then(resp => {
      let country_code = resp.filter(country => country.id != null).map(country => parseInt(country.area_code));
      country_code = this.ordenarYEliminarDuplicados(country_code);
      this.prefixList = country_code.map(prefix => ({ value: prefix }));

      this.countries = resp.map(country => ({ description: country.name, value: country.name }));
      this.countriesList = resp.map(country => ({ id: country.id, description: country.name, value: country.area_code }));
    })
   }



   range:  any = {
    start: '',
    end: ''
  }
  oldDates: { start: Date, end: Date } = { start: null , end: null };
  secondDate: any
  hasInitialDate() {
    if (this.initialDate != null) {
      this.secondDate= true
    }
    this.secondDate=  false
  }

  @ViewChild('initialPicker') initialPicker: MatDateRangePicker<any>
  @ViewChild('lastPicker') lastPicker: MatDateRangePicker<any>


  initialCalendar() {
    if (!this.initialPicker.opened) {
      
      this.initialPicker.open()
    }
  }

  initialDate(event: MatDatepickerInputEvent<Date>) {
    this.oldDates = { start: this.Date.start , end: this.Date.end };
    this.blockedDates = []
    this.hasInitialDate()
    let newDate = new Date(event.value);
    this.Date.start = newDate;
    this.range.start = newDate;
    this.range.end = null
    this.blockedDates = [];
    this.Date.end = ''
    // Bloquear todas las fechas desde la fecha seleccionada hasta el día de hoy
    let dateBefore = new Date();
    while (dateBefore >= newDate) {
      this.blockedDates.push(new Date(dateBefore));
      dateBefore.setDate(dateBefore.getDate() - 1);
    }
    this.blockedDates.push(this.Date.start)

    for (let i = 0; i <= 1; i++) {
      // Clona la fecha para evitar modificar la misma referencia
      let clonedDate = new Date(newDate);
      clonedDate.setDate(clonedDate.getDate() + 1 + i);
      this.blockedDates.push(clonedDate);
    }
    this.dateFilter = (date: Date): boolean => {
      try {
        const formattedDate = date.toISOString().split('T')[0]; // Convierte la fecha a formato 'YYYY-MM-DD'
        const formattedFirstDate = this.Date.start.toISOString().split('T')[0];
        return !this.blockedDates.some(blockedDate => blockedDate.toISOString().split('T')[0] === formattedDate) && formattedDate >= formattedFirstDate;
      }
      catch (error) {
        return false

      }
    }
    this.lastPicker.open()
  }
  
  lastDateSave(event: MatDatepickerInputEvent<Date>) {
    this.Date.end = event.value
    this.range.end = event.value
    var date = {
      start: this.Date.start,
      end: this.Date.end
    }

    this.dataConstructor.date = date
    this.dataConstructor.calculateDailyPrices()
  }
  get addTwoDays() {
    let date = new Date(this.Date.start);
    date.setDate(date.getDate() + 3)
    return date;
  }

  dateFilter = (date: Date): boolean => {
    try {
      const formattedDate = date.toISOString().split('T')[0] // Convierte la fecha a formato 'YYYY-MM-DD'
      return !this.blockedDates.some(blockedDate => blockedDate.toISOString().split('T')[0] === formattedDate)
    }
    catch (error) {
      return false
    }
  }

  lastCalendar() {
    if (!this.lastPicker.opened) {
      this.lastPicker.open()

    }
  }

  onPickerClosed() {
    if (!this.range.start || !this.range.end) {
      this.Date.start = this.oldDates.start
      this.Date.end = this.oldDates.end
      this.dataConstructor.FinalPrice()
    } 
  }

  

  ngOnInit(): void {
    window.scroll(0, 0);
    if(this.gs.currentInsuranceInfo.dependents) this.dependents = this.gs.currentInsuranceInfo.dependents;
    if(this.gs.currentInsuranceInfo.holder.birthday) this.getHolderPrice(this.gs.currentInsuranceInfo.holder.birthday);
    this.getDependentsPrice();

    this.gs.changeFinalPrice.subscribe(value => {
      if(this.gs.currentInsuranceInfo.holder.birthday){
        this.getHolderPrice(this.gs.currentInsuranceInfo.holder.birthday);
      }
      this.getDependentsPrice();
    });
  }

  openDialog(position) {
    this.gs.showMessageAdded = false;
    const width = window.innerWidth > 950 ? '50%' : '80%';
    let dialogRef = this.dialog_.open(AddAccompanistComponent, {
      width: width,
      data: position != null ? Object.assign({}, this.dependents[position]) : null
    });

    dialogRef.afterClosed().subscribe(dependent => {
      if(dependent) {
        let exist = false;
        for (let i = 0; i < this.dependents.length; i++) {
          if(position != null && i == position) continue
          if(this.dependents[i].identification_id == dependent.identification_id) exist = true;
        }

        if(exist || this.gs.currentInsuranceInfo.holder.identification_id == dependent.identification_id){
          this.showAlert('Pasaporte repetido', 'Pasaporte repetido con el títular o algún acompañante, por favor verifique.');
          return
        }

        let payload = {
          "insurance_plan_id": "1",
          "product_code": "BAM_MUVIT_DIARIO",
          "days_of_travel": 4,//this.gs.currentInsuranceInfo.days_of_travel,
          "destinations": [
            {
              "region": "LATAM" //this.gs.destiny[0].region
            }
          ],
          "accompanisttable": [
            {
              "birthday": dependent.birthday.toISOString()
            }
          ]
        }

        this.api.enrollAsys.getIndividualPrice(payload).promise()
          .then(response => {
            
            if(position != null) {
              this.dependents[position] = dependent;
              this.gs.prices[position] = response;
            } else {
              this.dependents.push(dependent);
              this.gs.prices.push(response);
            }

            if(this.gs.currentInsuranceInfo.holder.birthday) this.getGeneralPrice();
            
            this.gs.showMessageAdded = true;
            this.gs.messageAdded = position != null ? 'Tu viajero se modificó correctamente' : 'Tu viajero se agregó correctamente';

            setTimeout(() => {
              this.gs.showMessageAdded = false;
            }, 7500);
          })
          .catch(e => {
            this.showAlert('NO SE PUDO OBTENER EL PRECIO POR ACOMPAÑANTE', 'Intente de nuevo en un momento');
          })
      }
    });
  }

  deleteAccompanist(pos){
    this.dependents.splice(pos, 1);
    this.gs.prices.splice(pos, 1);
    this.getGeneralPrice();
  }

  getCountryCode(country: any) {
    return this.countriesList.find(element => element.description == country).value
  }

  ordenarYEliminarDuplicados(numeros: number[]): number[] {
    numeros.sort((a, b) => a - b);
    return [...new Set(numeros)]
  }

  openCalendar() {
    if (!this.picker.opened) {
      this.picker.open();
    }
  }

  calcYearsOld(birthday){
    const today = new Date();
    let age = today.getFullYear() - birthday.getFullYear();
    const monthDifference = today.getMonth() - birthday.getMonth();
    
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }

    return age;
  }

  onCountryChange(value) {
    this.gs.currentInsuranceInfo.holder.prefix = this.getCountryCode(value);
  }

  onDateChange(value){
    const age = this.calcYearsOld(value);

    if (age < 18) {
      this.showAlert('Error en Títular', 'El títular debe ser mayor de edad');

      this.correctAge = false;
      return;
    }

    this.correctAge = true;
    this.getHolderPrice(value);
  }

  getHolderPrice(value){
    let payload = {
      "insurance_plan_id": "1",
      "product_code": "BAM_MUVIT_DIARIO",
      "days_of_travel": 4,//this.gs.currentInsuranceInfo.days_of_travel,
      "destinations": [
        {
          "region": "LATAM" //this.gs.destiny[0].region
        }
      ],
      "insured": {
        "birthday": value.toISOString()
      }
    }

    this.api.enrollAsys.getIndividualPrice(payload).promise()
      .then(response => {
        this.gs.priceHolder = response;

        this.getGeneralPrice();
      })
      .catch(e => {
        this.showAlert('NO SE PUDO OBTENER EL PRECIO PARA EL TITULAR', 'Intente de nuevo en un momento');
      })
  }

  getDependentsPrice() {
    for (let i = 0; i < this.dependents.length; i++) {
      let payload = {
        "insurance_plan_id": "1",
        "product_code": "BAM_MUVIT_DIARIO",
        "days_of_travel": 4,//this.gs.currentInsuranceInfo.days_of_travel,
        "destinations": [
          {
            "region": "LATAM" //this.gs.destiny[0].region
          }
        ],
        "accompanisttable": [
          {
            "birthday": this.dependents[i].birthday.toISOString()
          }
        ]
      }

      this.api.enrollAsys.getIndividualPrice(payload).promise()
        .then(response => {
          this.gs.prices[i] = response
        })
        .catch(e => {
          this.showAlert('NO SE PUDO OBTENER EL PRECIO POR ACOMPAÑANTE', 'Intente de nuevo en un momento');
        })
    }
  }

  goPay(){
    this.gs.currentInsuranceInfo.dependents = this.dependents ? this.dependents : null;
    console.log(this.gs.currentInsuranceInfo)
    this.gs.showMessageAdded = false;
    this.router.navigate(['/public/mobile/payment'], { queryParamsHandling: 'preserve' });
  }

  showAlert(title, text) {
    Swal.fire({
      title: title,
      text: text,
      icon: 'error',
      showConfirmButton: true,
      showDenyButton: false,
      denyButtonText: 'Regresar',
      confirmButtonText: 'Aceptar',
      showCloseButton: true,
      timer: 25000
    })
  }

  getGeneralPrice(){
    let data = {
      "insurance_plan_id": "1",
      "product_code": "BAM_MUVIT_DIARIO",
      "days_of_travel": 4,//this.gs.currentInsuranceInfo.days_of_travel,
      "destinations": [
        {
          "region": "LATAM" //this.gs.destiny[0].region
        }
      ],
      "insured": {
          "birthday": this.gs.currentInsuranceInfo.holder.birthday.toISOString()
      },
      "accompanisttable": [ ]
    }

    for(let accompanist of this.dependents) {
      data['accompanisttable'].push({
        "birthday": accompanist.birthday.toISOString()
      })
    }

    this.api.enrollAsys.getPrice(data).promise()
      .then(response =>{
        this.gs.currentInsuranceInfo.final_price = response;
      })
      .catch(e => {
        this.showAlert('NO SE PUDO OBTENER EL PRECIO', 'Intente de nuevo en un momento');
      })
  }
}
