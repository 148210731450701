import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-faq-card',
  templateUrl: './faq-card.component.html',
  styleUrls: ['./faq-card.component.css']
})
export class FaqCardComponent {
@Input() question:any
selectedThumb: 'up' | 'down' | null = null;

selectThumb(thumb: 'up' | 'down') {
    this.selectedThumb = thumb;
}
}
