import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ImageLoadingService } from 'src/app/services/image-loading.service';
import { NavbarServiceService } from '../navbar-service.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { deviceDetectorService } from '../../services/device-detector.service';
import { Router } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { ApiService } from 'src/app/api/api.service';



export interface TableInfo {
  Description: string
  Plan1: string
  Plan2: string
  subdescription?: string
  info?: string
}

const Plan1: TableInfo[] = [
  {
    Description: 'Seguro de Accidentes Personales',
    Plan1: '$1,000',
    Plan2: '$1,000',
    info: 'Si durante el viaje el asegurado sufriera un accidente y perdiera la vida, se pagará a los beneficiarios el monto que establece en la póliza.',
    subdescription: '',
  },
  {
    Description: 'Asistencia Médica por accidente o enfermedad',
    Plan1: '$30,000',
    Plan2: '$10,000',
    subdescription: 'Incluye COVID 19.'
  },

  {
    Description: 'Asistencia Médica por accidente o enfermedad',
    Plan1: '30,000.00 €',
    Plan2: 'x',
    subdescription: 'Zona Schengen',
    info: 'Se requiere esta cobertura en caso de viaje a los siguientes países: Alemania, Austria, Bélgica, Croacia, Dinamarca, Eslovaquia, Eslovenia, Estonia, España, Finlandia, Francia, Grecia, Hungría, Italia, Letonia, Lituania, Luxemburgo, Malta, Países Bajos, Polonia, Portugal, República Checa, Suecia.'
  },
  {
    Description: 'Traslado y/o Repatriación Médica',
    Plan1: '$30,000', Plan2: '$10,000'
  },
  {
    Description: 'Repatriación Funeraria',
    Plan1: '$30,000',
    Plan2: '$10,000',
    info: 'Si durante el viaje el asegurado sufriera un accidente y perdiera la vida, el seguro cubre los gastos incurridos como ataúd o embalsamiento, transportación y gastos legales.'
  },
  {
    Description: 'Medicamentos', Plan1: '✔︎', Plan2: '✔︎'
  },
  {
    Description: 'Emergencia Dental', Plan1: '$300', Plan2: '$150'
  },
  {
    Description: 'Traslado de Familiar por Hospitalización',
    Plan1: '✔︎',
    Plan2: '✔︎',
    info: 'En caso el asegurado durante su viaje necesite ser hospitalizado y supere los 8 días, se cubre un boleto aéreo de ida y vuelta para el traslado de un familiar que se encuentre en Guatemala y pueda acompañarlo.'
  },
  {
    Description: 'Gastos de Estadia de un Familiar',
    Plan1: '✔︎',
    Plan2: '✔︎',
    info: 'En caso el asegurado durante su viaje necesite ser hospitalizado y supere los 8 días, se cubren los gastos de hotel del familiar que le acompañe.'
  },
  {
    Description: 'Gastos de Hotel por convalencia',
    Plan1: '$800',
    Plan2: '$300',
    subdescription: 'incluye Covid',
    info: 'Si por orden médica, el asegurado durante su viaje tiene una hospitalización y luego necesita quedarse un tiempo en el país para monitorear su recuperación, ya que su estado de salud no le permite continuar con su viaje, se cubren los gastos de hotel incurridos. Incluye COVID 19.'
  },
  {
    Description: 'Orientación en caso de Extravío de Documentos, Equipajes', Plan1: '✔︎', Plan2: '✔︎', subdescription: 'Boleto Económico.'
  },
  {
    Description: 'Viaje de Regreso por Fallecimiento de Familiar en 1er grado', Plan1: '✔︎', Plan2: '✔︎', subdescription: 'Boleto Económico.'
  },
  {
    Description: 'Regreso Anticipado por Siniestro Grave en Domicilio', Plan1: '✔︎', Plan2: '✔︎', subdescription: 'Boleto Económico.'
  },
  {
    Description: 'Acompañamiento e Menores', Plan1: '✔︎', Plan2: '✔︎', subdescription: 'Boleto Económico.'
  },
  {
    Description: 'Cancelación de Viaje contratado',
    Plan1: '$2,000',
    Plan2: '$1,000',
    info: 'Se reintegran los gastos por la cancelación del viaje, siempre y cuando la cancelación sea por lo menos 8 días antes de iniciarlo, debido a una enfermedad o accidente que imposibilite su movilidad o la muerte de un familiar directo, indicados en la póliza.'
  },
  {
    Description: 'Sustitución de ejecutivo',
    Plan1: '✔︎',
    Plan2: 'x',
    subdescription: 'Boleto Económico y Hotel',
    info: 'En viaje de negocios, se cubre el traslado y hospedaje de un sustituto que su empresa designe para completar su trabajo.'
  },
  {
    Description: 'Linea de Consultas 24 horas',
    Plan1: '✔︎',
    Plan2: '✔︎',
    info: 'Mientras el asegurado se encuentre en el viaje, podrá recibir orientación telefónica sobre obligaciones consulares, sanitarias, turísticas y otras consultas relacionadas al país de destino.'
  },
  {
    Description: 'Transmisión de Mensajes Urgentes', Plan1: '✔︎', Plan2: '✔︎'
  },
  {
    Description: 'Cargo por Transferencia de Fondos', Plan1: '$20,000', Plan2: '$10,000', info: 'Si durante el viaje, el asegurado por una necesidad imprevista requiere de fondos, es posible que, con un previo depósito de los mismos en las oficinas de la aseguradora, se transferirán para su disponibilidad en donde se encuentre, asumiendo los costos de dicha gestión.'
  },
  {
    Description: 'Cargo por Transferencia de Fondos para Fianza Legal', Plan1: '$20,000', Plan2: '$10,000', info: 'Si a causa de un accidente de tránsito el asegurado fuese encarcelado, se trasladarán los fondos para el pago de la fianza penal, con un previo depósito de la cantidad, en las oficinas de la aseguradora.'
  },
  {
    Description: 'Asistencia Legal por Accidente de Transito', Plan1: '$2,000', Plan2: '$1,000', info: 'Gastos de honorarios que ocasione la defensa del asegurado, ya sea civil, criminal o penal tras un accidente de tránsito.'
  },
  {
    Description: 'Compensación por Demora en Devolución del Equipaje', Plan1: '$800', Plan2: '$600', info: 'Se pagará el monto correspondiente si se produce una demora en la devolución del equipaje.'
  },
  {
    Description: 'Compensación por Pérdida del Equipaje', Plan1: '$1,200', Plan2: '$600', info: 'Se pagará el monto correspondiente si se produce la pérdida del equipaje.'
  },
  {
    Description: 'Robo o pérdida de documentos', Plan1: '$70', Plan2: '$70', subdescription: 'Exclusivamente en viaje.'
  },
  {
    Description: 'Pérdida de Vuelo', Plan1: '$300', Plan2: '$100', info: 'A consecuencia de cualquier motivo diferente a cancelación o demora por parte de la aerolínea, que ocasione la pérdida de conexión con destino internacional y/o vuelos directos, se cubrirá el pago de penalizaciones, nuevos boletos, alimentación, llamadas y hotel.'
  },



]

@Component({
  selector: 'app-plans-page',
  templateUrl: './plans-page.component.html',
  styleUrl: './plans-page.component.css'
})


export class PlansPageComponent implements OnInit, OnDestroy, AfterViewInit {
  deviceInfoSubscription: Subscription;

  deviceInfo: any;
  checkBoxes = [true, true]
  TotalCost = []
  TotalPrices = [{
    planName: 'Plan B',
    Adulto: 6.9972,
    AdultoM: 9.44328
  },
  {
    planName: 'Plan A',
    Adulto: 3.822,
    AdultoM: 6.1152
  }
  ]

  TotalPricesUSA =[
    {
      planName: 'Plan B',
      Adulto: 7.9968,
      AdultoM: 11.76
    },
    {
      planName: 'Plan A',
      Adulto: 4.33944,
      AdultoM: 8.175552
    }
  ]
  PlanNameV = ['Plan B', 'Plan A']
  PlanNameA = ['34.75', '18.45']
  PlanName = ['Plan Diario ', 'Plan A']
  displayedColumns: string[] = ['descriptionCharge', 'plan-view-1']
  displayedColumns2: string[] = ['descriptionCharge']

  dataSource = Plan1
  selectedPlan: string = 'ViajeCompleto'
  clientQuantity: any
  dayTime: any


  constructor(
    private imageLoadingService: ImageLoadingService,
    private navBar: NavbarServiceService, 
    private el: ElementRef, private device: deviceDetectorService,
    public navbarService: NavbarServiceService,
    private cdr: ChangeDetectorRef, 
    private router: Router,
    private activatedRoute: ActivatedRoute, private title: Title, public navService: NavbarServiceService,
    public gs: GlobalService,
    private api: ApiService,) {
    this.title.setTitle(this.activatedRoute.title['destination']['_value']['title'])

    this.deviceInfoSubscription = this.device.deviceType.subscribe(deviceType => {

      this.deviceInfo = deviceType;
    })

    this.title.setTitle(this.activatedRoute.title['destination']['_value']['title'])
    this.navService.isPlanSelectorVisible = false
    this.navService.isEmailVisible = false
    this.navService.isButtonVisible = false
    this.navService.isPersonsEditable = true
    this.navService.isDatePickerEditable = true
    this.navService.isPriceShowerVisible = false
  }
  planConfig: any
  totalPrice = 0
  ngOnInit(): void {
    this.totalPrice = 0
    this.imageLoadingService.checkImagesLoaded(this.el.nativeElement);
    if(this.gs.people) this.getTotalPrice()
    this.planConfig = JSON.parse(this.gs.plans[0].config); // Parsear los datos cuando estén disponibles
    const transformed = this.planConfig.plan_detail_full.flatMap(category =>
      category.benefits.map(benefit => ({
        Description: benefit.benefit,
        Plan1: this.regionBenefit(benefit)
      }))
    );
    this.dataSource = transformed
    
    
  }
  ngOnDestroy() {
    if (this.deviceInfoSubscription) {
      this.deviceInfoSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
  }
  regionBenefit(benefit){
    if(this.gs.destiny && Array.isArray(this.gs.destiny))
      {
      if(this.gs.destiny[0]){
        if(this.gs.destiny[0].kind == 4){
          return benefit?.details.ROW
        }
        if(this.gs.destiny[0].kind == 3){
          return benefit?.details.LATAM
        }
        if(this.gs.destiny[0].kind == 2){
          return benefit?.details.US
        }
      }
      else{
        return benefit?.details.LATAM
      }
      
    }
    else{
      return benefit?.details.LATAM
    }
    
    
      
  }

  generateDate(yearsAgo: number): string {
    const date = new Date();
    date.setFullYear(date.getFullYear() - yearsAgo);
    return date.toISOString(); 
  }

  generateBirthdays(){
    // Recorrer el array y generar fechas
    let personBirthdays = []
    this.gs.people.forEach(item => {
      
      const yearsAgo = item.type === "0 a 64 años" ? 18 : 70;

      // Generar fechas según el count
      for (let i = 0; i < item.count; i++) {
        personBirthdays.push(this.generateDate(yearsAgo));
      }
    });
    return personBirthdays
  }
  getTotalPrice(){
    let birthdays = this.generateBirthdays()
    birthdays.forEach((date, index) =>{
      this.getIndividualPrice(date)
    })
  }
  getIndividualPrice(date){
    let payload = {
      "insurance_plan_id": this.gs.currentInsuranceInfo.insurance_plan_id,
      "product_code": this.gs.currentInsuranceInfo.product_code,
      "days_of_travel": this.gs.currentInsuranceInfo.days_of_travel,
      "destinations": [
        {
          "region": this.gs.destiny[0].region
        }
      ],
      "accompanisttable": [
        {
          "birthday": date
        }
      ]
    }
    
    this.api.enrollAsys.getIndividualPrice(payload).promise()
      .then(response => {
        this.totalPrice += response
        console.log(response)
        console.log(this.totalPrice)
      })
    
  }
  selectPlan() {
    this.navbarService.isPriceShowerVisible = true;
    this.router.navigate(['/public/client-info'], { queryParamsHandling: 'preserve' });
  }

  originalOrder: string[] = ['descriptionCharge', 'plan-view-1'];

  toggleColumnVisibility(index: number): void {
    const columnId = `plan-view-${index + 1}`
    if (columnId === 'descriptionCharge') {
      return;
    }
    if (this.displayedColumns.includes(columnId)) {
      this.displayedColumns = this.displayedColumns.filter(id => id !== columnId)
      this.checkBoxes[index] = false
    } else {
      this.checkBoxes[index] = true
    }
    this.displayedColumns = this.originalOrder.filter(id => this.checkBoxes[this.originalOrder.indexOf(id) - 1] || id === 'descriptionCharge');
    this.cdr.detectChanges()
  }
}
