import { Injectable, EventEmitter } from '@angular/core'
import { Plan1, Plan2 } from './plan-info'
import { ApiService } from '../api/api.service';
import { GlobalService } from './global.service';
import { discardPeriodicTasks } from '@angular/core/testing'
import { ParseFlags } from '@angular/compiler'
import { NavbarServiceService } from '../components/navbar-service.service'
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';

export interface Beneficiary {
  first_name: any
  middle_name: any
  last_name: string
  last_name_2: any
  name: string
  genre: string
  birthdate: string
  passport: string
  units: any
  relationship: any
}

export interface PrincipalClient {
  age: any
  first_name: any
  middle_name: any
  last_name: any
  last_name_2: any
  name: any
  genre: any
  birthday: any
  birthday_: any
  passport: any
  residency: any
  email: any
  numberPrefix: any
  phoneNumber: any
  nit: any
  name_billing: any
  billingAddress: any
  units: any
}

@Injectable({
  providedIn: 'root'
})
export class DataConstructorService {
  resetRequested: EventEmitter<void> = new EventEmitter<void>();
  email: any = null
  countries: any[] = []
  originCountry: any
  persons: any[] = [
    { type: '0 a 64 años', count: 0 },
    { type: '65 a 85 años', count: 0 },
    
  ]

  totalPlanPrices = [
    { name: 'Plan A', total: 0, total_: '', price: 0, price_: '', prices: { Adulto: 3.822, AdultoM: 6.1152 }, discount: 0.30, information: Plan1, id: 1 },
    { name: 'Plan B', total: 0, total_: '', price: 0, price_: '', prices: { Adulto: 6.9972, AdultoM: 9.44328 }, discount: 0.30, information: Plan2, id: 2 },
  ]

  totalPlanPricesUSA = [
    { name: 'Plan A', total: 0, total_: '', price: 0, price_: '', prices: { Adulto: 4.33944, AdultoM: 8.175552 }, discount: 0.30, information: Plan1, id: 1 },
    { name: 'Plan B', total: 0, total_: '', price: 0, price_: '', prices: { Adulto: 7.9968, AdultoM: 11.76 }, discount: 0.30, information: Plan2, id: 2 },
  ]


  buyLoader: boolean = false
  date: any = {
    start: '',
    end: ''
  }

  formsQuantity: number = 0;
  temporaryHolder: boolean = true
  finalTotal: number = 0.00
  facebookLeadId: any
  private quantity = new BehaviorSubject<any[]>(this.persons)

  formsQuantity$ = this.quantity.asObservable();

  ip: any
  finalTotal_: string = this.finalTotal.toFixed(2)
  planSelected: any
  totalPeople: any
  daysQuantity: any
  totalCost: any
  typePlan: any
  principalClient: PrincipalClient
  payment_name: any
  expiration_date: any
  payment_token: any
  policy_number: any
  transaction: any
  lastStep = 1
  beneficiaries: Beneficiary[] = []
  Prices: Array<any> = ['', '']
  isTotalVisible: boolean = false
  ExtraPrices: any[] = []
  cardNumber: any;
  startDate: any;
  endDate: any;


  constructor(private api: ApiService,
    private globalService: GlobalService) {
    this.globalService.getIpClient()
      .subscribe(data => this.ip = data.ip);
  }

  updateFinalTotal() {
    this.finalTotal_ = this.finalTotal.toFixed(2)
  }

  requestReset() {
    this.resetRequested.emit();
  }

  finalPeople: any[] = [
    { type: 'Adulto', count: 0, isHolder: false },
    { type: 'Niño', count: 0, isHolder: false },
    { type: 'Adulto Mayor', count: 0, isHolder: false }]

  reCalculateFinalPersons() {
    var total = 0
    for (let person of this.finalPersons) {
      total += person.count
    }
    this.totalPeople = total
  }


  addFinalPerson(type: string, isHolder: boolean) {
    var person = this.finalPeople.find(person => person.type == type)
    person.count++
    person.isHolder = isHolder
    this.reCalculateFinalPersons()
  }


  /**
   * Actualiza los precios de los planes en el array Prices.
   * Si el tipo de plan es 'ViajeCompleto', los precios se actualizan en el orden [PRO,ESTANDAR,PLUS].
   * Si el tipo de plan es 'Anual', los precios se actualizan en el orden [150K, 50K, 100K].
   */
  updatePlanPrice(planNames, plans) {
    planNames.forEach((name, index) => {
      const plan = plans.find(plan => plan.name == name)
      if (plan) {
        this.Prices[index] = plan.total_
      }
    })
  }

  finalPersons = [
    { type: 'Holder', name: '', count: 0 },
    { type: 'Afiliado', name: 'Adulto', count: 0 },
    { type: 'Afiliado', name: 'Niño', count: 0 },
    { type: 'Afiliado', name: 'Adulto Mayor', count: 0 },

  ]

  // metodo para ponerle nombre al Holder en FinalPersons
  setHolderName(name: string) {
    this.finalPersons[0].name = name
    this.finalPersons[0].count = 1
  }

  // Metodo para aumentar el count de las personas en FinalPersons 
  addFinalPersonNew(type: string) {
    var person = this.finalPersons.find(person => person.name == type && person.type == 'Afiliado')
    person.count++
  }

  // Metodo para disminuir el count de las personas en FinalPersons
  deleteFinalPerson(type: string) {
    var person = this.finalPersons.find(person => person.name == type && person.type == 'Afiliado')
    if (person.count > 0) {
      person.count--
    }
  }

  // obtener el type de las personas dependiendo la edad
  getPersonType(age: any) {
    return age >= 64 ? 'Adulto Mayor' : age < 64 && age >= 17 ? 'Adulto' : 'Niño'
  }



  /**
   * Calculates the final price based on the selected plan, number of persons, and duration.
   * If the plan is 'Anual', the final price is retrieved from the AnualPrices array.
   * Otherwise, the final price is calculated based on the totalPlanPrices array and the number of persons.
   * The final price is stored in the finalTotal property.
   * @returns {Array} The totalPlanPrices array.
   */
  FinalPrice() {
    this.calculateDays()
    this.reCalculateFinalPersons()
    var Total = 0
    var Plan


    if (this.countries.length > 0 && this.countries[0].kind === 2) {
      Plan = this.totalPlanPricesUSA.find(Plan => Plan.name == this.planSelected)
    } else {
      Plan = this.totalPlanPrices.find(Plan => Plan.name == this.planSelected)
     }
    var originalPersons = JSON.parse(JSON.stringify(this.finalPersons))
    var holder = originalPersons.find(person => person.type == 'Holder')
    let price = holder.name == 'Adulto Mayor' ? Plan.prices.AdultoM : Plan.prices.Adulto
    Total += price * holder.count * this.daysQuantity
    for (let person of originalPersons) {
      if (person.count > 0 && person.type != 'Holder') {
        let discount = person.name == 'Niño' ? 0.7 : 0.9
        let price = person.name == 'Adulto Mayor' ? Plan.prices.AdultoM : Plan.prices.Adulto
        Total += (price * discount) * person.count * this.daysQuantity
      }
    }

    this.finalTotal = Total
    this.finalTotal_ = Total.toFixed(2)
    return this.countries.length > 0 && this.countries[0].kind === 2 ? this.totalPlanPricesUSA : this.totalPlanPrices
  }



  /**
   * Calcula los precios diarios para cada plan.
   * Si hay más de una persona, se aplica un descuento a todas excepto a una,
   * seleccionada por jerarquía (primero un adulto, luego un adulto mayor).
   * @returns {Array} Retorna la lista de planes con los precios diarios calculados.
   */
  calculateDailyPrices() {

    this.calculateDays()
    this.reCalculatePersons()
    const planNames = this.typePlan = ['Plan B', 'Plan A']
    let plans = this.countries.length > 0 && this.countries[0].kind === 2 ? this.totalPlanPricesUSA : this.totalPlanPrices

    if (plans.length == 0) {
      plans = this.totalPlanPrices
    }
    for (let plan of plans) {
      plan.total = 0
      var Plan = plans.find(Plan => Plan.name == plan.name)
      var originalPersons = JSON.parse(JSON.stringify(this.persons))

      if (this.totalPeople > 1) {

        if (originalPersons[0].count > 0) {
          plan.total += Plan.prices.Adulto * this.daysQuantity
          originalPersons[0].count--

        } else if (originalPersons[2].count > 0) {
          plan.total += Plan.prices.AdultoM * this.daysQuantity
          originalPersons[2].count--
        }

        for (let person of originalPersons) {
          if (person.count > 0) {
            let discount = person.type == 'Niño' ? 0.7 : 0.9
            let price = person.type == 'Adulto Mayor' ? Plan.prices.AdultoM : Plan.prices.Adulto
            plan.total += (price * discount) * person.count * this.daysQuantity
          }
        }
      } else {
        for (let person of originalPersons) {
          if (person.count > 0) {
            const prices = {
              'Adulto': Plan.prices.Adulto,
              'Adulto Mayor': Plan.prices.AdultoM,
              'Niño': Plan.prices.Adulto
            }
            plan.total += prices[person.type] * person.count * this.daysQuantity
          }
        }
      }
      plan.total = parseFloat(plan.total.toFixed(2))
      plan.total_ = plan.total.toFixed(2)
      plan.price = parseFloat(((plan.total) / (1 - plan.discount)).toFixed(2))
      plan.price_ = ((plan.total) / (1 - plan.discount)).toFixed(2)

    }

    this.updatePlanPrice(planNames, plans)
    return plans
  }




  /**
   * Formatea una fecha en formato yyyy-mm-dd.
   * @param {Date} date - La fecha a formatear.
   * @returns {string} La fecha formateada.
   */
  formatDate(date: Date) {
    return date.toISOString().split('T')[0]
  }

  /**
   * Transforma la fecha de inicio a formato yyyy-mm-dd.
   * @returns {string} La fecha de inicio transformada.
   */
  get initialDateTransform() {
    return this.formatDate(new Date(this.date.start))
  }

  /**
   * Transforma una fecha GMT-600 a UTC en formato ISO 8601.
   * @param {any} dateI - La fecha a transformar.
   * @returns {string} La fecha transformada a UTC.
   */
  DateUTC(dateI: any) {
    return new Date(dateI).toISOString()
  }


  /**
   * Obtiene la fecha de hoy en formato yyyy-mm-dd.
   * @returns {string} La fecha de hoy.
   */
  get todayDate() {
    return this.formatDate(new Date())
  }

  /**
   * Transforma una fecha GMT-600 a dd/mm/yyyy.
   * @param {any} birthDate - La fecha de nacimiento a transformar.
   * @returns {string} La fecha de nacimiento transformada.
   */
  transformBirthday(birthDate: any) {
    const date = new Date(birthDate)
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
  }

  /**
   * Transforma una fecha a formato yyyy-mm-dd.
   * @param {any} date - La fecha a transformar.
   * @returns {string} La fecha transformada.
   */
  transformDateToYMD(date: any) {
    return this.formatDate(new Date(date))
  }

  /**
   * Obtiene el precio por persona basado en la fecha de nacimiento.
   * @param {any} birthday - La fecha de nacimiento de la persona.
   * @returns {string} El precio por persona.
   */
  getPricePerPerson(birthday: any) {
    const age = this.getAge(birthday)
    const plan = this.typePlan = this.totalPlanPrices.find(plan => plan.name == this.planSelected)
    const total = age >= 64 ? plan.prices.AdultoM : plan.prices.Adulto
    return (total * this.daysQuantity).toFixed(2)
  }

  /**
   * Obtiene el id del plan seleccionado.
   * @returns {number} El id del plan seleccionado.
   */
  get planId() {

    const plan = this.typePlan == (this.countries.length > 0 && this.countries[0].kind === 2) ? this.totalPlanPricesUSA : this.totalPlanPrices
    return plan.find(plan => plan.name == this.planSelected).id

  }

  /**
   * Convierte una fecha a edad.
   * @param {any} date - La fecha a convertir.
   * @returns {number} La edad.
   */
  getAge(date: any) {
    const today = new Date()
    const birthDate = new Date(date)
    let age = today.getFullYear() - birthDate.getFullYear()
    const month = today.getMonth() - birthDate.getMonth()
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  }


  /**
   * Obtiene los datos de los beneficiarios.
   * @returns {Array} Retorna un array con los datos de los beneficiarios.
   */
  get beneficiaryData() {
    return this.beneficiaries.map(beneficiary => ({
      first_name: beneficiary.first_name,
      middle_name: beneficiary.middle_name,
      last_name: beneficiary.last_name,
      last_name_2: beneficiary.last_name_2,
      birthday: this.DateUTC(beneficiary.birthdate),
      birthday_: this.transformBirthday(beneficiary.birthdate),
      gender: beneficiary.genre,
      identification_id: beneficiary.passport,
      identification_type: 9,
      age: this.getAge(beneficiary.birthdate),
      name: `${beneficiary.first_name} ${beneficiary.middle_name} ${beneficiary.last_name} ${beneficiary.last_name_2}`,
      units: beneficiary.units,
      address: this.principalClient.residency.description,
      relationship: beneficiary.relationship
    }))
  }

  /**
   * Obtains the plan ID based on the selected plan type.
   * @returns {number} - The plan ID.
   */
  get planById() {
    var plan
    if (this.countries.length > 0 && this.countries[0].kind === 2) {
      plan = this.totalPlanPricesUSA.find(plan => plan.name == this.planSelected)
    }
    else {
      plan = this.totalPlanPrices.find(plan => plan.name == this.planSelected)
    }
    return plan.id

  }

  /**
   * Obtiene los datos del titular principal.
   * @returns {Object} Retorna un objeto con los datos del titular principal.
   */
  get principalHolder() {
    const commonData = {
      birthday_: this.principalClient.birthday_,
      identification_id: this.principalClient.passport,
      first_name: this.principalClient.first_name,
      middle_name: this.principalClient.middle_name,
      last_name: this.principalClient.last_name,
      last_name_2: this.principalClient.last_name_2,
      email: this.principalClient.email,
      birthday: this.principalClient.birthday,
      age: this.principalClient.age,
      identification_type: 9,
      mobile: `${this.principalClient.numberPrefix}${this.principalClient.phoneNumber}`,
      phone1: `${this.principalClient.phoneNumber}`,
      country_id: 1,
      origin: this.principalClient.residency.description,
      gender: this.principalClient.genre,
      name: `${this.principalClient.first_name} ${this.principalClient.last_name}`,
      address: this.principalClient.residency.description,
      nit: this.principalClient.nit,
      name_billing: this.principalClient.name_billing,
      billingAddress: this.principalClient.billingAddress,
      units: ''
    }
    commonData.units = this.typePlan == 'ViajeCompleto' || this.typePlan == 'Diario' ? this.principalClient.units : this.finalTotal_
    return commonData
  }

  /**
   * Obtiene los datos del titular principal sin las unidades.
   * @returns {Object} Retorna un objeto con los datos del titular principal sin las unidades.
   */
  get principalHolderNoUnits() {
    const { units, ...holder } = this.principalHolder
    return holder
  }

  createLead(platform: string) {
    let sendInssuranceLeadData = {
      email: this.email,
      source: `Tecniseguros public ${platform}`,
      product: "TECTRA"
    }
    this.api.landingLeads.newLead_v3(sendInssuranceLeadData).promise().then(
      (data) => {
        this.facebookLeadId = data.id;
      }
    );
  }

  updateInsuranceLead(platform: string) {
    let sendInssuranceLeadData = {
      name: this.principalHolder.name,
      email: this.email,
      phone: this.principalHolder.mobile,
      source: `Tecniseguros public ${platform}`,
      product: "TECTRA",
      plan: this.planId,
      ip: this.ip,
      id: this.facebookLeadId,
      step: 2
    }
    this.api.landingLeads.updateStatus_v3(sendInssuranceLeadData).promise().then(
      () => {
      }
    );
  }

  updatePaymentInfoLead() {
    let datasend = {
      id: this.facebookLeadId,
      policy_id: this.policy_number,
      step: 3
    }
    this.api.landingLeads.updateStatus_v3(datasend).promise().then(
      () => {
      });
  }

  GTMData() {
    let labelJson: any;
    let policyNumber = this.policy_number;
    labelJson = {
      items: [{
        'item_id': policyNumber + '_1',
        'item_name': this.principalHolder.name,
        'price': this.principalHolder.units,
        'quantity': 1

      }]
    }
    if (this.beneficiaryData) {
      this.beneficiaryData.forEach(function (beneficiary, i) {
        let item = {
          'item_id': policyNumber + '_' + (i + 2).toString(),
          'item_name': beneficiary.name,
          'price': beneficiary.units,
          'quantity': 1
        }
        labelJson.items.push(item);
      });
    }

    const pushData = {
      "event": "purchase",
      "currency": "USD",
      "value": this.finalTotal_,
      "transaction_id": this.policy_number,
      "plan_name": `Tecniseguros ${this.planSelected}`,
      "plan_id": this.planId,
      "items": []
    };
    pushData.items.push(labelJson);
    return pushData;
  }


  /**
   * Genera la información del producto basándose en el tipo de plan y el plan seleccionado.
   * @returns {Object} Retorna un objeto con la información del producto.
   */
  GenerateInformation() {

    var product_code = 'MUVIT_TEC'
    var commonData = {
      product_code: product_code,
      date_issued: this.todayDate,
      policy_number: '',
      holder: this.principalHolder,
      insured: this.principalHolderNoUnits,
      insurance_plan_id: this.planId,
      date_effective: this.transformDateToYMD(this.date.start),
      creation_address: 'online-page-tecniseguros',
      observation: '',
      insured_firm: '',
      holder_firm: '',
      travel_starting_date: this.DateUTC(this.date.start),
      travel_end_date: this.DateUTC(this.date.end),
      accompanisttable: this.beneficiaryData,
      self_insurance: true,
      payment_info: {

      },
      destinations: [],
      days_of_travel: this.daysQuantity,
      extra_data:[
        {
          key: "nit",
          value: this.principalClient.nit
        },
        {
          key: "full_name",
          value: this.principalClient.name_billing
          
        },
        {
          key: "billing_address",
          value: this.principalClient.billingAddress
        }
      ]
    }


    commonData.destinations = this.countries.map(country => ({
      days: this.daysQuantity,
      origin: country.description,
      destination: country.description,
      region: country.kind === 2 ? 'US' : 'REST OF THE WORLD', // Se evalúa directamente el 'kind' de cada país
      initDate: this.DateUTC(this.date.start),
      endDate: this.DateUTC(this.date.end)
    }));

    return commonData
  }



  /**
   * Sets the principal client.
   * @param {PrincipalClient} principalClient - The principal client object.
   */
  setPrincipalClient(principalClient: PrincipalClient) {
    this.principalClient = principalClient;
    //setear los datos del principalClient al insuranceHolder

  }

  /**
  * Deletes the principal client.
  */
  deletePrincipalClient() {
    this.principalClient = null;
  }

  /**
  * Deletes all beneficiaries.
  */
  deleteAllBeneficiaries() {
    this.beneficiaries = [];
  }

  /**
  * Adds a beneficiary.
  * @param {Beneficiary} beneficiary - The beneficiary object to add.
  */
  addBeneficiary(beneficiary: Beneficiary) {
    this.beneficiaries.push(beneficiary);
  }

  /**
  * Adds a person.
  * @param {string} type - The type of person to add.
  */
  addPerson(type: string) {
    var person = this.persons.find(person => person.type === type);
    person.count++;
    this.calculateDailyPrices();
    this.reCalculatePersons();
  }




  /**
   * Obtiene el tipo de personas y cuenta cuántas hay de cada tipo.
   * @returns {Object} Retorna un objeto con la cantidad de cada tipo de persona.
   */
  getPeopleType() {
    var TypeA = this.persons.filter(person => (person.type == 'Adulto' || person.type == 'Niño') && person.count > 0).length
    var TypeB = this.persons.filter(person => person.type == 'Adulto Mayor' && person.count > 0).length
    return { TipoA: TypeA, TipoB: TypeB }
  }

  /**
   * Disminuye en uno la cantidad de una persona de un tipo específico.
   * @param {string} type - El tipo de persona.
   */
  deletePerson(type: string) {
    var person = this.persons.find(person => person.type == type)
    if (person.count > 0) {
      person.count--
      this.calculateDailyPrices()
      this.reCalculatePersons()
    }
  }

  /**
   * Establece la cantidad de una persona de un tipo específico.
   * @param {string} type - El tipo de persona.
   * @param {number} count - La cantidad de personas.
   */
  setPersonCount(type: string, count: number) {
    var person = this.persons.find(person => person.type == type)
    person.count = count
  }

  /**
   * Recalcula la cantidad total de personas.
   */
  reCalculatePersons() {
    this.totalPeople = this.persons.reduce((total, person) => total + person.count, 0)
  }


  /**
   * Recalcula el precio total basado en el plan seleccionado, el tipo de persona y la cantidad de días.
   * Actualiza el costo total con el nuevo cálculo.
   */
  reCalculatePrice() {
    var plan
    if (this.countries.length > 0 && this.countries[0].kind === 2) {
      plan = this.totalPlanPricesUSA.find(plan => plan.name == this.planSelected)
    } else {
      plan = this.totalPlanPrices.find(plan => plan.name == this.planSelected)
    }
    var total = 0
    for (let person of this.persons) {
      if (person.type == 'Adulto' || person.type == 'Niño') {
        total += plan.prices.Adulto * person.count * this.daysQuantity
      } else if (person.type == 'Adulto Mayor') {
        total += plan.prices.AdultoM * person.count * this.daysQuantity
      }
    }


  }


  /**
   * Añade un precio extra al costo total.
   * @param {any} price - El precio extra a añadir.
   */
  addExtraPrice(price: any) {
    this.totalCost = (Number(this.totalCost) + Number(price)).toFixed(2)
  }

  /**
   * Elimina un precio extra del costo total.
   * @param {any} price - El precio extra a eliminar.
   */
  removeExtraPrice(price: any) {
    this.totalCost = (Number(this.totalCost) - Number(price)).toFixed(2)
  }


  /**
   * Calcula el precio extra basado en la edad y si la persona es titular o no.
   * @param {any} age - La edad de la persona.
   * @param {boolean} holder - Indica si la persona es titular o no. Por defecto es falso.
   * @returns {string} Retorna el precio extra calculado como un string con dos decimales. Si no se cumple ninguna condición, retorna '0.00'.
   */
  getExtraPrice(age: any, holder: boolean = false) {
    var plan
    if (this.countries.length > 0 && this.countries[0].kind === 2) {
      plan = this.totalPlanPricesUSA.find(plan => plan.name == this.planSelected)
    } else {
      plan = this.totalPlanPrices.find(plan => plan.name == this.planSelected)
    }

    var basePrice, discountFactor
    if (age >= 64) {
      basePrice = plan.prices.AdultoM
      discountFactor = holder ? 1 : 0.9
    } else if (age < 64 && age >= 17) {
      basePrice = plan.prices.Adulto
      discountFactor = holder ? 1 : 0.9
    } else if (age < 17) {
      basePrice = plan.prices.Adulto
      discountFactor = holder ? 1 : 0.7
    }

    if (basePrice) {
      var total = (basePrice * discountFactor) * this.daysQuantity
      return total
    }

    return '0.00'
  }



  /**
   * Restablece las personas a sus valores por defecto.
   */
  restablishPersons() {
    this.persons = [
      { type: '0 a 64 años', count: 0 },
      { type: '65 a 85 años', count: 0 },
    ]
  }

  /**
   * Establece el total para la versión móvil basándose en el plan seleccionado.
   */
  setTotalMobile() {
    var plan

    if (this.countries.length > 0 && this.countries[0].kind === 2) {
      plan = this.totalPlanPricesUSA.find(plan => plan.name == this.planSelected)
    } else {
      plan = this.totalPlanPrices.find(plan => plan.name == this.planSelected)
    }
    this.totalCost = plan.total
  }

  /**
   * Establece el conteo para la versión móvil basándose en el tipo y el conteo proporcionados.
   * @param {string} type - El tipo de persona.
   * @param {number} count - El conteo de personas.
   */
  setCountMobile(type: string, count: number) {
    var person = this.persons.find(person => person.type == type)
    person.count = count
    this.reCalculatePersons()
  }

  /**
   * Calcula la cantidad de días entre las fechas de inicio y fin.
   */
  calculateDays() {
    var date1 = new Date(this.date.start)
    var date2 = new Date(this.date.end)
    var Difference_In_Time = date2.getTime() - date1.getTime()
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)
    this.daysQuantity = Difference_In_Days + 1
  }




  /**
   * Establece todos los valores por defecto.
   * Resetea todas las variables y llama a las funciones para eliminar todos los beneficiarios y el cliente principal.
   */
  setAllDefault() {
    this.email = null
    this.countries = []
    this.persons = [
      { type: '0 a 64 años', count: 0 },
      { type: '65 a 85 años', count: 0 },
    ]
    this.requestReset();


    this.totalPlanPrices = [
      { name: 'Plan A', total: 0, total_: '', price: 0, price_: '', prices: { Adulto: 3.822, AdultoM: 6.1152 }, discount: 0.30, information: Plan1, id: 1 },
      { name: 'Plan B', total: 0, total_: '', price: 0, price_: '', prices: { Adulto: 6.9972, AdultoM: 9.44328 }, discount: 0.30, information: Plan2, id: 2 },
    ]

    this.totalPlanPricesUSA = [
      { name: 'Plan A', total: 0, total_: '', price: 0, price_: '', prices: { Adulto: 4.33944, AdultoM: 8.175552 }, discount: 0.30, information: Plan1, id: 1 },
      { name: 'Plan B', total: 0, total_: '', price: 0, price_: '', prices: { Adulto: 7.9968, AdultoM: 11.76 }, discount: 0.30, information: Plan2, id: 2 },
    ]
    this.buyLoader = false
    this.date = {
      start: '',
      end: ''
    }

    this.planSelected = ''
    this.facebookLeadId = ''
    this.ip = ''
    this.totalPeople = ''
    this.daysQuantity = ''
    this.totalCost = ''
    this.typePlan = ''
    this.deleteAllBeneficiaries()
    this.deletePrincipalClient()
    this.payment_name = ''
    this.expiration_date = ''
    this.payment_token = ''
    this.policy_number = ''
    this.transaction = ''
    this.lastStep = 1
    this.Prices = ['', '', '']
    this.planSelected = ''
    this.ExtraPrices = []
    this.finalPersons = [
      { type: 'Holder', name: '', count: 0 },
      { type: 'Afiliado', name: 'Adulto', count: 0 },
      { type: 'Afiliado', name: 'Niño', count: 0 },
      { type: 'Afiliado', name: 'Adulto Mayor', count: 0 },

    ]
  }



}



