import { Component, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-comments-card',
  templateUrl: './comments-card.component.html',
  styleUrls: ['./comments-card.component.css']
})
export class CommentsCardComponent {
  @Input() commentInfo:any
  @Input() photoUrl:any
  @Input() socialMediaUrl:any
  @Input() name:any
  stars: any
  screenWidth: number;

  constructor(){
    this.stars = Array(5).fill(0).map((x,i)=>i);
    this.screenWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
  }
}
