import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-mobile',
  templateUrl: './footer-mobile.component.html',
  styleUrls: ['./footer-mobile.component.css']
})
export class FooterMobileComponent {

  // Obtener el año Actual
  year = new Date().getFullYear();
  goTo(media:any){
    if(media == 'youtube'){
      window.open('https://www.youtube.com/@MuvitAsistenciadeViaje', '_blank')
    }else if (media == 'facebook'){
      window.open('https://www.facebook.com/muvit.travel', '_blank')
    }else if(media == 'tiktok'){
      window.open('https://www.tiktok.com/@muvit.travel?_t=8atH4Jl8OFq&_r=1', '_blank')
    }else if(media == 'instagram'){
      window.open('https://www.instagram.com/muvit.travel/?utm_source=google&utm_medium=banner&utm_campaign=visitas_trafico_icono_pagina_web', '_blank')
    }
  }
}
