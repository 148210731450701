<div class="faq-container">
    <div class="Header"> <b> Preguntas Frecuentes</b></div>

    <div class="searchBar">
        <div class="search-container">
          <input [(ngModel)]="filter" class="search" placeholder="Buscar..." />
          <div class="iconSearch">
            <i class="fas fa-search"></i>
          </div>
        </div>
      </div>
    <!-- Filtra las preguntas basándote en el texto de búsqueda -->
    <div class="Quest">
        <div *ngFor="let question of Question | filter:filter" class="Questions">
            <app-faq-card [question]="question" style="width: 100%; display: flex;
        justify-content: center;"></app-faq-card>
        </div>
    </div>
</div>