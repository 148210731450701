import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DataConstructorService } from 'src/app/services/data-constructor.service';

@Component({
  selector: 'app-policy-dialog',
  templateUrl: './policy-dialog.component.html',
  styleUrls: ['./policy-dialog.component.css']
})
export class PolicyDialogComponent {
  dates={
    start: '',
    final: ''
  }
  convertirFecha(fecha:any) {
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const fechaConvertida = new Date(fecha);
    const dia = fechaConvertida.getDate();
    const mes = meses[fechaConvertida.getMonth()];
    const ano = fechaConvertida.getFullYear();
    return `${dia} ${mes} ${ano}`;
  }

  downloadPolicy(){
    window.open(`api/documentTecniseguros/${this.dataConstructor.policy_number}/${this.dataConstructor.transaction}`,'_blank', 'noopener, noreferrer')
}

constructor(public dataConstructor:DataConstructorService, private activatedRoute: ActivatedRoute, private title: Title){ 
  this.title.setTitle('Muvit - Agradecimiento')

   this.dates = {
     start: this.convertirFecha(this.dataConstructor.date.start),
     final: this.convertirFecha(this.dataConstructor.date.end)
   }

}
}
