import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api/api.service';
import { GlobalService } from './global.service';
import { AbstractControl } from '@angular/forms';

@Injectable()
export class UserS2Service {  

  url:string="";
  private _perPage = 10;
  private _perPage5 = 5;
  private _users:any[];
  private _total:number;
  private _status = {0:"Inactivo", 1:"Activo"};
  private _statusList = [{id: 0, name: "Inactivo"}, {id: 1, name: "Activo"}];
  private _filter;
  private _page;
  private _user:any;
  private _roles:any[];
  private _totalRoles:number;
  private _locations:any[];
  public _password_change_status: number;
  public _email_password_recovery: string;
  private _token_password_status: number;
  public _token: string;
  public _id_user_change_password: number;

  get users():any[] {return this._users;}
  set users(value:any[]) {this._users = value;}
  get total():number { return this._total; }
  set total(value:number) { this._total = value; }
  get perPage():number { return this._perPage; }
  set perPage(value:number) { this._perPage = value; }
  get perPage5():number { return this._perPage5; }
  set perPage5(value:number) { this._perPage5 = value; }
  get user():any { return this._user; }
  set user(value:any) { this._user = value; }
  get statusList():any[] { return this._statusList }  
  set roles(value:any[]) { this._roles = value; }
  get roles() { return this._roles; }
  set totalRoles(value:number) { this._totalRoles = value; }
  get totalRoles():number { return this._totalRoles; } 
  get locations():any[] { return this._locations; }
  set locations(value:any[]) { this._locations = value; }

  constructor(private api: ApiService,
    private globalService: GlobalService,
    private router:Router) {
     }
    
    requestError(){
      this.globalService.showLoader=false;    
      // this.globalService.openDialog('Ocurrión un error, Genere un nuevo token');
      this.router.navigate(['passwordrecovery']); 
    }

    logout(){
      localStorage.clear();
      this.globalService.showError = false;
      this.globalService.showLoader=false;
      this.router.navigate(['/private']);
    }

    isAuthenticated():boolean{
      return (localStorage.getItem('accessToken')?true:false);    
    }     

    reset(){
      this._users= []
    }

    requestThen(users){
      console.log(users)       
      this._users = users.data;
      console.log(this._users)
      this._total = users.total;
    }   

    getStatus(id:number){
      return this._status[id];
    }    
    
    validateSymbolPassword(control: AbstractControl): { [key: string]: boolean } | null {
      let symbols ="@_$*/!=(){}#,.:;";
      if (control.value) {
        for(let i = 0; i < symbols.length; i++){
          if (control.value.includes(symbols.charAt(i))) {
            return null
          }
        }
        return { 'symbol': true };
      }
      return null;
    }

    getLocationProspect(creation_address) {
      let loc = this._locations.find(loc_ => loc_.value == creation_address);
      if (loc) {
        return creation_address;
      }
      return undefined;
    }

    createRandomPassword(){
      const TOKEN_SYMBOLS = 'abcdefghjkmnpqrstuvwxyzABCDEFGHJKMNPQRSTUVWXYZ123456789'
      const PASSWORD_LENGTH = 8
      const PASSWORD_SYMBOLS_ALLOWED = '@_$*/!=(){}#,.:;'
      let valid = false;
      let password = ''
      let random = 0;
      let selector = 0;
      for (let i = 0; i <= PASSWORD_LENGTH; i++) {
        random = Math.floor(Math.random()*100)
        if((i==7 && !valid) || random<15){
          selector = Math.floor(Math.random()*PASSWORD_SYMBOLS_ALLOWED.length)
          password += PASSWORD_SYMBOLS_ALLOWED.charAt(selector)
          console.log(`${selector} Entre ${password}`)
          valid = true
        }else{
          selector = Math.floor(Math.random()*TOKEN_SYMBOLS.length + 1)
          password += TOKEN_SYMBOLS.charAt(selector)
        }
      }
      return password
    }

}