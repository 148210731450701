<div class="payment-container">
    <app-step-header [activeStep]="3"></app-step-header>

    <div class="content">
        <div class="title">
            <span class="title-text-1">Pago</span>
            <br>
            <span class="title-text-2">Ingresa el método de pago</span>
        </div>
        <div class="payment-content">
            <div class="travel-info">
                <span class="plan-name">Plan 1</span>
                <br>
                <span class="destiny">{{gs.destiny[0].value}}</span>
                <div class="dates-detail">
                    <div class="start">
                        <img src="../../../assets/muvitBAM/despegar.png" alt="Despegue" style="width: 20px;">
                        <span>{{initialDate}}</span>
                    </div>
                    <div class="end">
                        <img src="../../../assets/muvitBAM/aterrizar.png" alt="Aterrizaje" style="width: 20px;">
                        <span>{{finalDate}}</span>
                    </div>
                </div>
            </div>
            <div>
                <div class="age-detail">
                    <span>{{range0_64['quantity']}} viajero(s) de 0 a 64 años </span>
                    <span class="age-price">${{range0_64['price']}}</span>
                </div>
                <div class="age-detail">
                    <span>{{range65_85['quantity']}} viajero(s) de 65 a 85 años   </span>
                    <span class="age-price">${{range65_85['price']}}</span>
                </div>
                <div class="total-price">
                    <span>Total ${{gs.totalPrice}}</span>
                </div>
            </div>
            <hr>

            <div>
                <div class="logo">
                    <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/brands-logo/logo_cybersource.png" alt="Cybersource" width="140">
                </div>
                <form #paymentform="ngForm"> 
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="name">No. de Tarjeta*</label>
                                <div></div>
                                   
                                <div id="creditCardNumber" class="CyberInput"></div>
    
                                <div class="card-type">
                                    <div class="card-img">
                                    <img class="card-icon hidden-desktop" style="height: 20px;" *ngIf="visa" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/visa.webp" alt="visa">
                                    <img class="card-icon hidden-desktop" style="bottom: -2px !important; height: 29px;" *ngIf="master" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/mastercard.webp" alt="mastercard">
                                    <img class="card-icon hidden-desktop" style="bottom: -9px !important; height: 34px;" *ngIf="amex" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/amex.webp" alt="amex">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="name">Nombre titular tarjeta*</label>
                                <input type="text" class="form-control" [(ngModel)]="cardName" name="cardName" #name="ngModel" style="width: 100%;" id="cardName" placeholder="Ingrese el nombre" required>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="name">Válida hasta*</label>
                                <input type="text" class="form-control" id="cardExpDate" id="cardExpDate" required [(ngModel)]="cardExpDate" name="cardExpDate" #expdate="ngModel" placeholder="MM/AA" (ngModelChange)="verifyExpiration()" [ngClass]="{'invalid-cvv': invalidDate && expdate.touched}" (keyup)="onKey($event)">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="name">CVV*</label>
                                <div  id="securityCode" class="CyberInput"></div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div style="position: relative;">
                                <button class="btn btn-pay" [disabled]="disBtn || !paymentform.valid || invalidDate || !isPaymentValid()" (click)="onSubmit()">Pagar</button>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="ssl-content">
                    <div>
                        <img src="../../../assets/muvitBAM/SSL.svg" alt="SSL">
                    </div>
                    <div class="ssl-message">
                        <span class="ssl-text-1">Transmisión de datos seguros</span>
                        <span class="ssl-text-2">No guardaremos tu información personal ni de tarjeta de crédito, están seguros</span>
                    </div>
                </div>

                <div class="tip-message">
                    <img src="../../../assets/muvitBAM/recomendacion.svg" alt="Tip">
    
                    <span class="text-message">El cargo de tu tarjeta se hará de inmediato al finalizar la contratación</span>
                </div>
               
            </div>
        </div>
    </div>
</div>