<div class="thank-you-container">
  <app-step-header [activeStep]="4"></app-step-header>

  <div class="content">
      <div class="thank-you-content">
          <div class="policy-content">
            <div class="policy-img">
              <img src="../../../assets/muvitBAM/check-shield.svg" alt="Completed">
            </div>
            <div class="policy-data">
              <div>
                <span class="message">Tu viaje está protegido </span>
              </div>
              <div>
                <span class="policy">Póliza No. {{gs.policyNumber}}</span>
              </div>
            </div>
          </div>
          <div class="travel-info">
              <div>
                <span class="plan-name">Plan 1</span>
                <br>
                <span class="destiny">{{destiny}}</span>
              </div>
              <div>
                  <div class="dates-detail">
                      <div class="start">
                        <img src="../../../assets/muvitBAM/despegar.png" alt="Despegue" style="width: 20px;">
                        <span>{{initialDate}}</span>
                    </div>
                    <div class="end">
                        <img src="../../../assets/muvitBAM/aterrizar.png" alt="Aterrizaje" style="width: 20px;">
                        <span>{{finalDate}}</span>
                    </div>
                  </div>
                  
                  <div>
                    <div class="age-detail">
                        <span>{{range0_64['quantity']}} viajero(s) de 0 a 64 años </span>
                        <span class="age-price">${{range0_64['price']}}</span>
                    </div>
                    <div class="age-detail">
                        <span>{{range65_85['quantity']}} viajero(s) de 65 a 85 años   </span>
                        <span class="age-price">${{range65_85['price']}}</span>
                    </div>
                    <div class="total-price">
                        <span>Total ${{totalPrice}}</span>
                    </div>
                </div>
              </div>
          </div>

          <div class="message-info">
            <span>
              Recibirás la confirmación de tu compra al correo: {{gs.email}}
            </span>
          </div>

          <div class="btn-options">
            <button class="btn btn-cancel" (click)="returnHome()">
                Regresar al inicio
            </button>
                
            <button class="btn btn-continue" (click)="print()">
                Descargar
            </button>
          </div>
      </div>
  </div>
</div>