import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

interface Optn {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-muvit-cur-lan-dialog',
  templateUrl: './muvit-cur-lan-dialog.component.html',
  styleUrls: ['./muvit-cur-lan-dialog.component.css', 
  '../muvit-insurance-holder/muvit-insurance-holder.component.css',
  '../muvit-public.component.css'
]
})

export class MuvitCurLanDialogComponent implements OnInit{
  lan:Optn;
  cur:Optn;
  languages:Array<Optn>;
  currencies:Array<Optn>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MuvitCurLanDialogComponent>,
    public dialog:MatDialog,
    
    ) { }

    ngOnInit(): void {
      
      this.languages = this.data['languages']
      this.currencies = this.data['currencies']
      this.lan = this.data['language']
      this.cur = this.data['currency']
      
      
    }


    onNoClick(): void {
      this.dialogRef.close();
    }

    close(): void {
      
      this.dialogRef.close();
    }

    onSubmit(){
      this.dialogRef.close();
    }
  
}
