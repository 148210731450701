import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalService} from '../../../services/global.service';
import { WarningCardMuvitComponent } from '../../utils/warning-card-muvit/warning-card-muvit.component';

@Component({
  selector: 'app-stepper-muvit',
  templateUrl: './stepper-muvit.component.html',
  styleUrls: ['./stepper-muvit.component.css']
})
export class StepperMuvitComponent implements OnInit {

  @Input() tipoPlan: string;
  @Input() payerIsDifferent:boolean;
  @Output("returnToHome2") returnToHome2: EventEmitter<any> = new EventEmitter();

  step : number = null;

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    public dialog_: MatDialog,
    public gs: GlobalService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.step = params["step"]
    })
  }

  goBack(){
    //
    let jumps = 1;

    if(this.step == 2){
      this.router.navigate([this.gs.currentSiteUrl], { queryParams: { step: null }, queryParamsHandling: 'merge' });
      return;
    
    }else if (this.step == 4 && !this.payerIsDifferent){
      jumps = 1;

    }else if(this.step==5){
      if (this.tipoPlan == 'Titular'){

        jumps = 1;

      }
    }
    
    if(this.step !=3){
      this.router.navigate(
        [this.gs.currentSiteUrl], { queryParams: { step: (this.step - jumps)} , queryParamsHandling: 'merge'}
      );
    }
    else{
      let dialogRef = this.dialog_.open(WarningCardMuvitComponent, {
        width: '400px',
        data: { warning: "Si regresa de página los datos ingresados se borrarán. ¿Desea continuar?","cancel": "Cancelar","accept": "Aceptar", "newMessage": "ATENCIÓN"},
        position:{top:'20%'}
      })
      dialogRef.afterClosed().subscribe(result => {
        //
        //
        if(result){
          this.gs.currentInsuranceInfo.holder = null
          this.router.navigate(
            [this.gs.currentSiteUrl], { queryParams: { step: (this.step - jumps)} , queryParamsHandling: 'merge'}
          );
        }
      });
    }

  }
  returnHome(){
    this.returnToHome2.emit();
  }
}