import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../../api/api.service';
import { GlobalService } from '../../../services/global.service';
import { ConfigService } from '../../../config/config.service';
import { Meta, Title } from '@angular/platform-browser';
import { ShareDialogComponent } from '../share-dialog/share-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { GenericInsurance } from '../../../models/generic-insurance';
declare var gtag;
declare const fbq: any;
@Component({
  selector: 'app-muvit-thank-you',
  templateUrl: './muvit-thank-you.component.html',
  styleUrls: ['./muvit-thank-you.component.css', 
  '../muvit-insurance-holder/muvit-insurance-holder.component.css',
  '../muvit-public.component.css'
]
})
export class MuvitThankYouComponent implements OnInit {
  @Input() PRODUCT_FOR_LOCATION:string;
  @Input() titulos:Array<string>;
  @Output("returnToHome") returnToHome: EventEmitter<any> = new EventEmitter();
  private router :Router
  showLoader = false;
  tipoPlan;
  payerIsDifferent;
  transactionId = ""

  constructor(
    public gs: GlobalService,
    private injector: Injector,
    private api: ApiService,
    private config: ConfigService,
    private titleService: Title,
    public shareDialog:MatDialog,
    private meta:Meta
  ) {
    this.router = this.injector.get<Router>(Router);
    
    this.meta.addTags([
      {name: 'description', content: '¡Gracias por confiar en nosotros! Aquí puedes revisar el resumen de tu pago y los detalles de tu póliza de seguro de viaje. Viaja seguro con nuestra protección completa.'},
      {name: 'author', content: 'muvit'},
    ]);
   }

  ngOnInit() {
    if (!("travel_starting_date" in this.gs.currentInsuranceInfo)) {
      this.router.navigate([this.gs.currentSiteUrl], { queryParams: { step: null }, queryParamsHandling: 'merge'});
    }
    this.titleService.setTitle("Muvit - Thanks");
    this.gotoTop()
    //
    this.transactionId = this.gs.currentInsuranceInfo.transaction
   

    fbq('track', 'Purchase', {value: localStorage.getItem("final_price"), currency: 'USD'}, {eventID: this.gs.currentInsuranceInfo.policy_number});
  }
  print() {
    let country = "GT"
    if (this.gs.currentInsuranceInfo.product_code == "ASYSTRAVEL_ISELL_ANUAL_150" || this.gs.currentInsuranceInfo.product_code == "ASYSTRAVEL_ISELL_ANUAL_100" ||
    this.gs.currentInsuranceInfo.product_code == "ASYSTRAVEL_ISELL_ANUAL_50" || this.gs.currentInsuranceInfo.product_code == "ASYSTRAVEL_ISELL") {
      country = "HO"
    }
    // 
    window.open(`api/documentMuvit/${this.gs.currentInsuranceInfo.policy_number}/${this.gs.currentInsuranceInfo.transaction}/${country}`,'_blank')
  }
  returnHome(){
    this.returnToHome.emit();
    this.router.navigate(
      [this.gs.currentSiteUrl], { queryParams: { step: 1 } , queryParamsHandling: 'merge'}
    );
  }

  gotoTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  openShareDialog() {
    this.shareDialog.open(ShareDialogComponent, {
      data: {}
    });
  }


  onSubmit() {}
}
