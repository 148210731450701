<div class="nav-bar">
    <div class="mobile-nav">
      <!-- Ícono de hamburguesa -->
      <div class="menu-toggle" (click)="toggleMenu()">
        <img src="../../../../assets/muvitBAM/movil/hamburger.png" alt="Menu Icon" />
      </div>
  
      <!-- Línea divisoria -->
      <div class="divider">
        <img src="../../../../assets/muvitBAM/movil/line.png" alt="Divider" />
      </div>
  
      <!-- Logo -->
      <div class="logo">
        <img src="../../../../assets/muvitBAM/movil/BancoAgricola-Muvit-header.svg" alt="Logo" />
      </div>
    </div>
  
    <!-- Menú lateral -->
    <div class="side-menu" [ngClass]="{ 'open': showMenu }">
      <!-- Logo con botón de cerrar (X) -->
      <div class="menu-header">
        <div class="logo">
          <img src="../../../../assets/muvitBAM/movil/BancoAgricola-Muvit-header.svg" alt="Logo" />
        </div>
        <button class="close-btn" (click)="toggleMenu()">×</button>
      </div>
  
      <!-- Opciones del menú -->
      <nav>
        <ul>
          <li>
            <a routerLink="/public/mobile" (click)="closeMenu()" class="menu-option">
              <b>Inicio</b>
            </a>
          </li>
          <li>
            <a routerLink="/public/mobile/FAQ" (click)="closeMenu()" class="menu-option">Preguntas frecuentes</a>
          </li>
          <li>
            <a routerLink="/public/mobile/muvit-blog" (click)="closeMenu()" class="menu-option">Blog</a>
          </li>
        </ul>
      </nav>
  
    </div>
  </div>
  