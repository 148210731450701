import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loaderElement: HTMLElement;
  public isLoading = new BehaviorSubject(false);

  constructor() {
    this.createLoaderElement();
  }

  private createLoaderElement() {
  }
}







