
<p mat-dialog-title>Compartir</p>
<mat-dialog-content>
  <share-buttons theme="circles-dark"
    [include]="['facebook', 'instagram', 'twitter', 'telegram', 'copy']"
    [showIcon]="true"
    [showText]="false"
    url="https://muvit.travel"
    description="Muvit Travel"
    class="pt-5">
  </share-buttons>
</mat-dialog-content>