import { Component, OnInit } from '@angular/core';
import { QuoteBoxComponent } from '../../quote-box/quote-box.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-banner-home',
  templateUrl: './banner-home.component.html',
  styleUrls: ['./banner-home.component.css']
})
export class BannerHomeComponent implements OnInit {
  constructor(
    public gs: GlobalService,
    private router: Router,
    public api: ApiService,
    public dialog: MatDialog,

  ){
    this.screenWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      this.screenWidth = window.innerWidth;
    });
  }
  planConfig: any;
  screenWidth: number;
  currentSlide = 0; // Índice actual
  intervalId: any;
  price = 5.25;

  slides = [0, 1, 2]; // Solo para los indicadores

  ngOnInit(): void {
    this.startAutoSlide();
  }

  startAutoSlide(): void {
    this.intervalId = setInterval(() => {
      this.nextSlide();
    }, 5000);
  }

  stopAutoSlide(): void {
    clearInterval(this.intervalId);
  }

  goToSlide(index: number): void {
    this.currentSlide = index;
  }

  nextSlide(): void {
    this.currentSlide = (this.currentSlide + 1) % 3;
  }

  prevSlide(): void {
    this.currentSlide = (this.currentSlide - 1 + 3) % 3;
  }
  openQuoteBox(){
    if(this.screenWidth >= 11){
      this.dialog.open(QuoteBoxComponent, {
        width: '550px',
        data: this.gs.countries,
        panelClass: ['dialog_']
      });
    }
  }
}
