import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NavbarServiceService } from '../../../navbar-service.service';
import { DataConstructorService } from '../../../../services/data-constructor.service';

@Component({
  selector: 'app-card-input',
  templateUrl: './card-input.component.html',
  styleUrls: ['./card-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CardInputComponent),
      multi: true
    }
  ]
})
export class CardInputComponent implements ControlValueAccessor {
  @Input() inputTitle: string = '';
  @Input() isRequired: boolean = false;
  @Output() valueChanged = new EventEmitter<any>();
  @Input()  subTitle : any
  @Input() isEmail: boolean = false
  email: string = ''
  value: string = '';

  onChange: (value: string) => void = () => {};
  onTouch: () => void = () => {};

  constructor(public dataConstructor: DataConstructorService, private navBar: NavbarServiceService){

  }


  writeValue(value: string): void {
    this.value = value;
    this.onChange(this.value);
    this.onTouch();
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Implement if needed
  }

  onInputChange(event: Event): void {
    const inputValue = (event.target as HTMLInputElement)?.value;
    this.value = inputValue;
    this.onChange(this.value);
    this.onTouch();
    if(this.isEmail){
      this.navBar.email = inputValue
    }
    // Emitir el valor directamente en el evento
    this.valueChanged.emit(inputValue);
  }
  
  
  
  
  
}
