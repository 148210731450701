import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tags-ilustrastion',
  templateUrl: './tags-ilustrastion.component.html',
  styleUrls: ['./tags-ilustrastion.component.css']
})
export class TagsIlustrastionComponent {

  @Input() tagTitle:any
  @Input() tagFile:any
  @Input() tagTitleColor:any
  @Input() Offers:any 

  constructor(){
  }
}
