<div class="banner">
  <div class="banner-shadow">
    <div class="top-slogan">
      <div class="row">
        <div class="col-sm">
          <img src="'../../../../../assets/tecniseguros/tecnitraveler.png" height="180px" width="180px" alt="">
        </div>
        <div class="col-sm">
          <div class="circles-container">
            <div class="circle">
              <img src="../../../assets/tecniseguros/circe-deducible.png" alt="CERO deducible" class="circle-image">
              <div class="circle-text">CERO <br> deducible</div>
            </div>
            <div class="circle">
              <img src="../../../assets/tecniseguros/circle-medical.png" alt="Emergencias médicas" class="circle-image">
              <div class="circle-text">Emergencias médicas</div>
            </div>
            <div class="circle">
              <img src="../../../assets/tecniseguros/circle-luggage.png" alt="Perdida de equipaje" class="circle-image">
              <div class="circle-text">Perdida de equipaje</div>
            </div>
          </div>
        </div>
      </div>

      
    </div>
    <div class="bottom-slogan">
      <div class="footer-home-page">
        <span style="font-size: 18px; font-weight: 500;">A donde vayas <span
            style="color: white;  font-weight: 800; font-size: 18px;">cuidamos tu tranquilidad,</span> con el seguro de
          <span style="color: white;  font-weight: 800; font-size: 18px;">viaje internacional de Tecniseguros.</span>
        </span>       
      </div>
    </div>
    <div class="overlay">
      <div class="discount-tag">
        <img src="../../../../assets/tecniseguros/movil/discount-tag.svg" alt="Promotion Tag">
      </div>
      
    </div>
   
  </div>
</div>