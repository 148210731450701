import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { DataConstructorService } from 'src/app/services/data-constructor.service';
import { ApiService } from 'src/app/api/api.service';
import * as forge from 'node-forge';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PolicyDialogComponent } from '../../policy-dialog/policy-dialog.component';
import { Title } from '@angular/platform-browser';
import { InsurancePayment } from 'src/app/models/insurance-payment';

@Component({
  selector: 'app-payment-mobile',
  templateUrl: './payment-mobile.component.html',
  styleUrls: ['./payment-mobile.component.css']
})
export class PaymentMobileComponent implements OnInit {

  initialDate: any;
  finalDate: any;

  range0_64 = {'quantity': 0, 'price': 0.00}
  range65_85 = {'quantity': 0, 'price': 0.00}

  constructor(
    public gs: GlobalService
  ) {
    let age = this.calcYearsOld(this.gs.currentInsuranceInfo.holder.birthday);
    if(age < 65){
      this.range0_64['quantity'] += 1;
      this.range0_64['price'] += this.gs.priceHolder;
    } else {
      this.range65_85['quantity'] += 1;
      this.range65_85['price'] += this.gs.priceHolder;
    }

    if(this.gs.currentInsuranceInfo.dependents.length) {
      for (let i = 0; i < this.gs.currentInsuranceInfo.dependents.length; i++) {
        age = this.calcYearsOld(this.gs.currentInsuranceInfo.dependents[i].birthday);
        if(age < 65){
          this.range0_64['quantity'] += 1;
          this.range0_64['price'] += this.gs.prices[i];
        } else {
          this.range65_85['quantity'] += 1;
          this.range65_85['price'] += this.gs.prices[i];
        }
      }
    }
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.initialDate = this.convertirFecha(this.gs.currentInsuranceInfo.travel_starting_date);
    this.finalDate = this.convertirFecha(this.gs.currentInsuranceInfo.travel_end_date);
  }

  calcYearsOld(birthday){
    const today = new Date();
    let age = today.getFullYear() - birthday.getFullYear();
    const monthDifference = today.getMonth() - birthday.getMonth();
    
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }

    return age;
  }

  convertirFecha(fecha: any) {
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const fechaConvertida = new Date(fecha);
    const dia = fechaConvertida.getDate();
    const mes = meses[fechaConvertida.getMonth()];
    const ano = fechaConvertida.getFullYear();
    return `${dia} ${mes} ${ano}`;
  }
}
