import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, last } from 'rxjs';
import { DataConstructorService } from 'src/app/services/data-constructor.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-affiliates-information',
  templateUrl: './affiliates-information.component.html',
  styleUrls: ['./affiliates-information.component.css']
})
export class AffiliatesInformationComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, public dataConstructor: DataConstructorService) { 
    
  }
  clientForm: FormGroup
  @Input() numeroAc = 1

  @Input() isLast: boolean = false
  @Input() BuildForm: any
  @Output() FormValue = new EventEmitter<any>()
  @Input() isAdded = false
  @Input() isLastForm: boolean = false
  @Output() addFormEvent = new EventEmitter<void>();
  @Output() removeFormEvent = new EventEmitter<any>();
  typePerson: any
  individualPrice: any = 0
  typePersonAdded = []
  extraPrice: any = 0
  isChanged = {
    dateChange: false,
    lastAge: 0
  }
  noBirthdate = true

  genreOptions = [
    { description: 'Masculino', value: '1' },
    { description: 'Femenino', value: '0' }
  ]

  kinshipOptions = [
    { description: 'Hijo(a)', value: '4' },
    { description: 'Esposo(a)', value: '3' },

  ]

  filteredKinshipOptions = [...this.kinshipOptions]; // Nueva propiedad


 
/**
 * Se ejecuta cuando se hace clic en el botón de eliminar formulario.
 * Emite un evento de eliminación de formulario y elimina una persona adulta de los datos del constructor.
 */
onRemoveFormButtonClick(): void {
  let eventConstructor = {
    typePerson: this.typePerson,
    pricePerPerson: this.individualPrice,
    numberIndex: this.numeroAc -1,
    isAdded: this.isAdded
  }

  if(this.individualPrice > 0){
  this.dataConstructor.ExtraPrices.splice(this.numeroAc - 1, 1);
  this.dataConstructor.deleteFinalPerson(this.dataConstructor.getPersonType(this.dataConstructor.getAge(this.clientForm.get('birthday').value)));
  this.dataConstructor.FinalPrice()
  }
  
  this.removeFormEvent.emit(eventConstructor);
}

/**
 * Se ejecuta cuando se hace clic en el botón de agregar formulario.
 * Emite un evento de adición de formulario.
 */
onAddFormButtonClick(): void {
  this.addFormEvent.emit();
}

/**
 * Se ejecuta cuando se inicializa el componente.
 * Crea un formulario de cliente con campos requeridos.
 */
ngOnInit(): void {
  this.clientForm = this.formBuilder.group({
    first_name: this.formBuilder.control('', Validators.required),
    middle_name: this.formBuilder.control(''),
    last_name: this.formBuilder.control('', Validators.required),
    last_name_2: this.formBuilder.control(''),
    genre: this.formBuilder.control('', Validators.required),
    birthday: this.formBuilder.control('', Validators.required),
    relationship: this.formBuilder.control(''),
    passport: this.formBuilder.control('', Validators.required),
    units: this.individualPrice,
  });
  this.dataConstructor.ExtraPrices.push(0.00)
  this.dataConstructor.ExtraPrices[this.numeroAc - 1] = 0.00;
}

isChangeDate(event: any) {
  this.isChanged = event;
  this.update();

  const age = this.getAge();

  if (age >= 25 && this.clientForm.get('relationship').value === '4') {
    this.showAlert2("El hijo/a debe ser menor de 25 años");
    this.clientForm.get('relationship').setValue(null); 
  }

  const hasSpouse = this.dataConstructor.beneficiaries.some(
    (beneficiary) => beneficiary.relationship === '3'
  );

  if (hasSpouse && this.clientForm.get('relationship').value === '3') {
    this.showAlert2("No puedes agregar más de un acompañante con parentesco 'Esposo(a)'.");
    this.clientForm.get('relationship').setValue(null); 
  }

  this.filteredKinshipOptions = this.kinshipOptions.filter((option) => {
    if (hasSpouse && option.value === '3') {
      return false; 
    }
    if (age >= 25 && option.value === '4') {
      return false; 
    }
    return true;
  });
}


/**
 * Muestra una alerta con el mensaje proporcionado.
 * @param {string} message - El mensaje de alerta.
 */
showAlert2(message: string) {
  Swal.fire({
    title: 'Advertencia',
    text: message,
    icon: 'warning',
    confirmButtonText: 'Aceptar'
  });
}


  /**
   * Calcula la edad de la persona basándose en su fecha de nacimiento.
   * @returns {number} La edad de la persona.
   */
  getAge() {
    const date = new Date(this.clientForm.get('birthday').value);
    const today = new Date();
    let age = today.getFullYear() - date.getFullYear();
    const month = today.getMonth() - date.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < date.getDate())) {
      age--;
    }
    return age;
  }


  /**
   * Obtiene el precio extra basado en la edad de la persona y actualiza el total final.
   * @param {any} age - La edad de la persona.
   */
  getExtraPrice(age: any) {
    let personType = age >= 65 ? 'Adulto Mayor' :
      age < 65 && age >= 18 ? 'Adulto' : 'Niño';
    this.dataConstructor.addFinalPersonNew(this.dataConstructor.getPersonType(this.dataConstructor.getAge(this.clientForm.get('birthday').value)))
    this.individualPrice = this.dataConstructor.getExtraPrice(age);
    this.typePerson = this.dataConstructor.getPersonType(this.dataConstructor.getAge(this.clientForm.get('birthday').value));
    if (this.isAdded) {
      this.dataConstructor.addPerson(personType);
    }
    this.dataConstructor.ExtraPrices[this.numeroAc - 1] = this.individualPrice;
    this.dataConstructor.FinalPrice();

  }




  /**
   * Actualiza el precio extra y el total final basado en la nueva edad.
   * Si la fecha ha cambiado, elimina la persona del tipo anterior, obtiene el precio extra para el nuevo tipo,
   * resta el precio extra del total final, establece el precio individual a 0 y actualiza el total final.
   * Independientemente de si la fecha ha cambiado o no, obtiene el precio extra para la nueva edad.
   */
  update() {
    this.noBirthdate = false;
    if (this.clientForm.get('birthday')) {
      let personType = this.isChanged.lastAge >= 65 ? 'Adulto Mayor' :
        this.isChanged.lastAge < 65 && this.isChanged.lastAge >= 18 ? 'Adulto' : 'Niño';

      if(this.isChanged.dateChange){
        this.dataConstructor.deleteFinalPerson(this.dataConstructor.getPersonType(this.isChanged.lastAge));
        this.dataConstructor.ExtraPrices[this.numeroAc - 1] = 0;
      }
      if (this.isChanged.dateChange && this.isAdded) {
        this.dataConstructor.deletePerson(personType)
        this.individualPrice = 0;
      }
      this.typePerson = personType;
      this.getExtraPrice(this.getAge());

    }
  }



  /**
   * Establece el valor de un campo del formulario.
   * @param {string} fieldName - El nombre del campo del formulario.
   * @param {any} value - El valor que se va a establecer para el campo del formulario.
   */
  setFormFieldValue(fieldName: string, value: any): void {
    this.clientForm.get(fieldName).setValue(value);
  
    
    if (fieldName === 'relationship') {
      console.log('Beneficiarios',this.dataConstructor.beneficiaries)
      const hasSpouse = this.dataConstructor.beneficiaries.some(
        (beneficiary) => beneficiary.relationship === '3' // '3' representa "Esposo(a)"
      );
  
      if (hasSpouse && value === '3') {
        // Mostrar alerta y resetear el campo
        this.showAlert2("No puedes agregar más de un acompañante con parentesco 'Esposo(a)'.");
        this.clientForm.get(fieldName).setValue(null); // Resetea el valor del campo
      }
    }
  
    if (fieldName === 'passport') {
      // Verificar que el pasaporte no se repita entre titular y otros beneficiarios
      let passport = value;
      let beneficiaries = this.dataConstructor.beneficiaries;
      let principal = this.dataConstructor.principalClient;
      let repeated = false;
      if (principal.passport === passport) {
        repeated = true;
      }
      beneficiaries.forEach((beneficiary) => {
        if (beneficiary.passport === passport) {
          repeated = true;
        }
      });
      if (repeated) {
        Swal.fire({
          title: 'Error',
          text: 'El pasaporte no puede ser el mismo para el titular y los beneficiarios',
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
        this.clientForm.get(fieldName).setValue('');
      }
    }
  }
  

  /**
   * Obtiene los errores del formulario.
   * @returns {any} Un objeto que contiene los errores del formulario.
   */
  getFormErrors(): any {
    const errors: any = {}
    Object.keys(this.clientForm.controls).forEach(key => {
      const controlErrors = this.clientForm.get(key).errors;
      if (controlErrors != null) {
        errors[key] = controlErrors;
      }
    });
    return errors;
  }



  /**
   * Construye un mensaje de alerta de error basado en los errores del formulario.
   * @returns {string} El mensaje de alerta de error.
   */
  buildErrorAlertMessage(): string {
    const errors = this.getFormErrors();
    let errorMessage = '';

    for (const key of Object.keys(errors)) {
      const fieldTitle = this.getFieldTitle(key);
      errorMessage += `${fieldTitle}, `;
    }

    return errorMessage;
  }


  /**
   * Obtiene el título del campo basado en el nombre del campo.
   * @param {string} fieldName - El nombre del campo.
   * @returns {string} El título del campo.
   */
  getFieldTitle(fieldName: string): string {
    switch (fieldName) {
      case 'first_name':
        return 'Nombre 1';
      case 'middle_name':
        return 'Nombre 2';
      case 'last_name':
        return 'Apellido 1';
      case 'last_name_2':
        return 'Apellido 2';
      case 'genre':
        return 'Género';
      case 'birthday':
        return 'Fecha de Nacimiento';
      case 'relationship':
        return 'Parentesco';
      case 'passport':
        return 'Pasaporte';
      default:
        return fieldName;
    }

  }

/**
 * Actualiza las unidades en el formulario del cliente al precio individual.
 */
updateUnits(){
  this.clientForm.get('units').setValue(this.individualPrice);
}


/**
 * Muestra una alerta con el título y el texto proporcionados.
 * @param {string} title - El título de la alerta.
 * @param {string} text - El texto de la alerta.
 */
showAlert(title, text) {
  Swal.fire({
    title: title,
    text: text,
    icon: 'error',
    showConfirmButton: true,
    showDenyButton: false,
    denyButtonText: 'Regresar',
    confirmButtonText: 'Aceptar',
    showCloseButton: true,
    timer: 25000
  }).then(result => {
    this.dataConstructor.deleteAllBeneficiaries();
    this.dataConstructor.deletePrincipalClient();
  })
}

/**
 * Valida el pasaporte proporcionado.
 * @param {string} passport - El pasaporte a validar.
 * @returns {boolean} Retorna verdadero si el pasaporte es válido (solo contiene números y letras), de lo contrario retorna falso.
 */
validatePassport(passport) {
  const passportRegex = /^[a-zA-Z0-9]*$/;
  return passportRegex.test(passport);
}

/**
 * Revisa los campos del formulario.
 * @returns {boolean} Retorna verdadero si los campos son válidos, de lo contrario retorna falso y muestra una alerta.
 */
fieldReview() {

  if (!this.validatePassport(this.clientForm.value.passport)) {
    this.showAlert('PASAPORTE INVALIDO', 'El pasaporte ingresado es invalido')
    return false
  }
  return true
}

/**
 * Se ejecuta cuando se envía el formulario.
 * Actualiza las unidades y emite los valores del formulario si es válido.
 * Si el formulario no es válido, emite un error y muestra una alerta.
 */
onSubmit(): void {
  this.updateUnits();
  if (this.clientForm.valid && this.fieldReview()) {
    this.FormValue.emit(this.clientForm.value);
  } else {
    this.FormValue.emit('Error');
    Swal.fire({
      title: 'Error',
      icon: 'error',
      html: `<h4>Faltaron los siguientes Datos en el Acompañante ${this.numeroAc}</h4>${this.buildErrorAlertMessage()}`,
      confirmButtonText: 'OK'
    }).then(result => {
      this.dataConstructor.deleteAllBeneficiaries();
      this.dataConstructor.deletePrincipalClient();
    });
  }
}



}
