import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NavbarServiceService } from '../components/navbar-service.service';

@Injectable({
  providedIn: 'root'
})

export class workflowCheckerGuard implements CanActivate {

  constructor(private router: Router, public navbarService: NavbarServiceService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const travelInfo = this.navbarService.TravelInfo
    if (travelInfo) {
      return true;
    } else {
      this.router.navigate(['/public/'], { queryParamsHandling: 'preserve' });; // Redirige al usuario a la página inicial si no ha llenado la información
      return false;
    }
  }
  
}