<div class="HomePage">

    <app-banner-home></app-banner-home>

    <app-benefits></app-benefits>
    <app-home-plans [plans]="gs.plans"></app-home-plans>
    <app-beneficiary-svg></app-beneficiary-svg>

    <app-footer style="position: relative; z-index: 1;"></app-footer>

</div>