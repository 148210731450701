import { Injectable } from '@angular/core';
import { RequestserviceService } from './requestservice.service';
import { ConfigService } from '../config/config.service';


@Injectable()
export class ApiService {

  constructor(private request: RequestserviceService,
    private configSvc: ConfigService) { }



  countries = {
    //getAllCountries:()=> this.request
    //.get()
    //.url(this.configSvc.s2Url + 'GET/Countries')

    getAllCountries: () => this.request
      .get()
      .url(this.configSvc.s2Url + '/api/enrollasys/getCountryCode'),
    getCountryAreaCode: () => this.request
      .get()
      .url(this.configSvc.s2Url + '/api/enrollasys/getCountryAreaCode'),
    getCountryCode: () => this.request
      .get()
      .url(this.configSvc.s2Url + '/api/enrollasys/getCountryCode'),
  }



  enrollAsys = {
    post: (insuranceInfo) => this.request
      .post()
      .url(this.configSvc.s2Url + '/api/insurance/sales/public')
      .auth()
      .payload(insuranceInfo),
    getCountryCode: () => this.request
      .get()
      .url(this.configSvc.s2Url + '/api/enrollasys/getCountryCode'),
    getDestinationCountryCode: (origin) => this.request
      .get()
      .url(this.configSvc.s2Url + '/api/enrollasys/getCountryCode/{}', origin),
    getCountryAreaCode: () => this.request
      .get()
      .url(this.configSvc.s2Url + '/api/enrollasys/getCountryAreaCode'),
    getPriceTravelDays: (product_code, days, age_range) => this.request
      .get()
      .url(this.configSvc.s2Url + '/api/enrollasys/getPrice/{}/{}/{}', product_code, days, age_range),
    getPriceIsell: (days) => this.request
      .get()
      .url(this.configSvc.s2Url + '/api/enrollasys/getPrice/{}', days),
    getDocument: (policy_number, transaction) => this.request
      .get()
      .url(this.configSvc.s2Url + '/api/documentMuvit/{}/{}', policy_number, transaction),
    getIndividualPrice: (data) => this.request
      .post()
      .url(this.configSvc.s2Url + '/api/insurance/get_individual_price')
      .payload(data),
    getPrice: (data) => this.request
      .post()
      .url(this.configSvc.s2Url + '/api/insurance/get_price')
      .payload(data),
    getPlans: (data) => this.request
      .post()
      .url(this.configSvc.s2Url +'/api/insurance/plans')
      .payload(data),
  }

  catalog = {
    getEquivalenceByValue: (grouper, value) => this.request
      .get()
      .url(this.configSvc.s2Url + '/api/catalogEquivalence/{}/{}', grouper, value)
  }

  cybersource = {
    getFlex: () => this.request
      .get()
      .url(this.configSvc.s2Url + '/api/cybersource/generateFlex')
  }

  facebookEvents = {
    pageView: (data) => this.request
      .post()
      .url(this.configSvc.s2Url + '/api/site/fbevents/pageview')
      .payload(data)
      .auth()
  }

  getMuvitInformation = {
    getQuote: (data) => this.request
      .post()
      .url(this.configSvc.s2Url + '/api/asys/send_muvit_quote')
      .payload(data),
  }

  checkUID = {
    post: (data) => this.request
      .post()
      .url('/api/call/check/uid')
      .payload(data)
  }

  landingLeads = {
    newLead: (data) => this.request
      .post()
      .url(this.configSvc.s2Url + '/api/generateLeads')
      .payload(data),

    updateStatus: (data) => this.request
      .post()
      .url(this.configSvc.s2Url + '/api/update_lead_status')
      .payload(data),

    newLead_v3: (data) => this.request
      .post()
      .url(this.configSvc.s2Url + '/api/generateLeads_v3')
      .payload(data),

    updateStatus_v3: (data) => this.request
      .post()
      .url(this.configSvc.s2Url + '/api/update_lead_v3')
      .payload(data)
  }



}