export interface Questions {
    question: string
    answer: string
    contact: boolean
  }
  
  export const Questions: Questions[] = [
    {
      question: '¿Me aseguro con Muvit, me aseguro con Agrícola?',
      answer: '¡Claro! Muvit y Banco Agrícola han creado una sociedad para poder cuidarte y acompañarte en todo momento mientras viajas, por cualquier parte del mundo.',
      contact: false
    },
    {
      question: '¿A dónde me comunico si quiero hacer uso de una de las coberturas de MUVIT?',
      answer: 'Si te encuentras de viaje, tienes una emergencia y requieres hacer uso de una de las coberturas, puedes comunicarte con nuestro centro de atención.',
      contact: true
    },
    {
      question: '¿Qué es una asistencia en viaje?',
      answer: 'Tu asistencia de viaje Muvit fue creada para que puedas viajar al extranjero con tranquilidad. Tiene las mejores coberturas para emergencias médicas, ambulancias y repatriación, así como coberturas para tu equipaje y vuelo, entre otras.',
      contact: false
    },
    {
      question: '¿Si vivo en el extranjero me cubre cuando visito mi país de origen?',
      answer: 'MUVIT te brinda cobertura en todos los países fuera de tu país de residencia, así que puedes visitar tu país de origen y gozar de los beneficios.',
      contact: false
    },
    {
      question: '¿Si me contagio de COVID-19 durante mi viaje, estoy cubierto?',
      answer: 'COVID-19 es una de las enfermedades cubiertas por MUVIT y te brinda tu cobertura de gastos médicos y transferencias sin ninguna penalidad.',
      contact: true
    },
    {
      question: '¿Desde cuándo estoy cubierto después de comprar mi asistencia MUVIT?',
      answer: 'Tu cobertura MUVIT empieza desde el momento que adquieres tu asistencia y viajas fuera de tu país de residencia.',
      contact: false
    },
    {
      question: '¿Puedo contratar MUVIT si ya me encuentro en mi país de destino?',
      answer: 'Si ya te encuentras en tu país destino, puedes adquirir MUVIT, sin embargo hay ciertas coberturas que pudieran ser limitadas. Si quieres gozar de todos los beneficios, te recomendamos comprarlo antes de iniciar tu viaje.',
      contact: false
    },
    {
      question: '¿Tengo que pagar algún deducible al usar MUVIT?',
      answer: 'No, tus coberturas MUVIT no tienen deducible ni copago.',
      contact: false
    },
    {
      question: '¿Qué edades están cubiertas en MUVIT?',
      answer: 'MUVIT cubre pasajeros desde los 6 meses hasta los 85 años.',
      contact: false
    },
    {
      question: '¿Mi asistencia MUVIT cubre enfermedades preexistentes?',
      answer: 'No, MUVIT no cubre enfermedades preexistentes. En tu condicionado puedes encontrar el listado de enfermedades excluidas.',
      contact: false
    }
  ];
  
  