<div class="payment-container">
  <app-step-header [activeStep]="3" [movil]="true"></app-step-header>

  <div class="title-header">
    <span class="header-1">Pago</span>
    <br>
    <span class="header-2">Ingresa el método de pago</span>
  </div>

  <div class="content">
      <div class="payment-content">
          <div class="travel-info">
              <span class="plan-name">Plan Viajero PRO</span>
              <br>
              <span class="destiny">Madrid ESP</span>
              <div class="dates-detail">
                  <div class="start">
                      <img src="../../../assets/muvitBAM/despegar.png" alt="Despegue" style="width: 20px;">
                      <span>{{initialDate}}</span>
                  </div>
                  <div class="end">
                      <img src="../../../assets/muvitBAM/aterrizar.png" alt="Aterrizaje" style="width: 20px;">
                      <span>{{finalDate}}</span>
                  </div>
              </div>
          </div>
          <div>
              <div class="age-detail">
                  <span>{{range0_64['quantity']}} viajero(s) de 0 a 64 años </span>
                  <span class="age-price">${{range0_64['price']}}</span>
              </div>
              <div class="age-detail">
                  <span>{{range65_85['quantity']}} viajero(s) de 65 a 85 años   </span>
                  <span class="age-price">${{range65_85['price']}}</span>
              </div>
              <div class="total-price">
                  <span>Total ${{gs.currentInsuranceInfo.final_price}}</span>
              </div>
          </div>
          <hr>

          <div>
              <div class="logo">
                  <img src="../../../assets/muvitBAM/Wompi.svg" alt="Wompi">
              </div>
              <form #paymentform="ngForm"> 
                  <div class="row">
                      <div class="col-md-12">
                          <div class="form-group">
                              <label for="name">No. de Tarjeta*</label>
                              <input id="name" name="name" type="text" class="form-control" required />
                          </div>
                      </div>
                      <div class="col-md-12">
                          <div class="form-group">
                              <label for="name">Nombre titular tarjeta*</label>
                              <input id="name" name="name" type="text" class="form-control" required />
                          </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-6">
                          <div class="form-group">
                              <label for="name">Válida hasta*</label>
                              <input id="name" name="name" type="text" class="form-control" required />
                          </div>
                      </div>
                      <div class="col-6">
                          <div class="form-group">
                              <label for="name">CVV*</label>
                              <input id="name" name="name" type="text" class="form-control" required />
                          </div>
                      </div>
                      <div class="col-12">
                          <div style="position: relative;">
                              <button class="btn btn-pay" [disabled]="paymentform.invalid">Pagar</button>
                          </div>
                      </div>
                  </div>
              </form>

              <div class="ssl-content">
                  <div>
                      <img src="../../../assets/muvitBAM/SSL.svg" alt="SSL" width="40px">
                  </div>
                  <div class="ssl-message">
                      <span class="ssl-text-1">Transmisión de datos seguros</span>
                      <span class="ssl-text-2">No guardaremos tu información personal ni de tarjeta de crédito, están seguros</span>
                  </div>
              </div>

              <div class="tip-message">
                  <img src="../../../assets/muvitBAM/recomendacion.svg" alt="Tip" width="30px">
  
                  <span class="text-message">El cargo de tu tarjeta se hará de inmediato al finalizar la contratación</span>
              </div>
             
          </div>
      </div>
  </div>
</div>