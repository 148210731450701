import { Component } from '@angular/core';

@Component({
  selector: 'app-beneficiary-svg',
  templateUrl: './beneficiary-svg.component.html',
  styleUrls: ['./beneficiary-svg.component.css']
})
export class BeneficiarySvgComponent {
  cards = [
    {
      color: '#FBD9A3',
      icon: '../../../../assets/muvitBAM/Tarjetas/ambulancia.svg', 
      title: 'Traslado y hospedaje en caso de accidente',
      description: 'Cubrimos el traslado a un hospital ya sea vía terrestre, marítima o aérea. En caso requieras que un familiar te cuide cubrimos su boleto de avión y hotel.'
    },
    {
      color: '#F5A8C6',
      icon: '../../../../assets/muvitBAM/Tarjetas/dinero.svg',
      title: 'Utiliza tus coberturas sin copago o deducibles',
      description: 'Disfruta de tus coberturas sin realizar pagos de copagos, coaseguros o deducibles extra.'
    },
    {
      color: '#D6B4D4',
      icon: '../../../../assets/muvitBAM/Tarjetas/corazon.svg',
      title: 'Teledoctor ilimitado, en todo el mundo las 24 horas',
      description: 'Descarga el app de Teledoctor y podrás llamar, tener videollamada o chatear con un doctor en cualquier parte del mundo.'
    }
  ];
}
