import { Injectable, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import { GenericInsurance } from "../models/generic-insurance";
import { InsuranceHolder } from "../models/insurance-holder";
import { PayerResponsable } from '../models/payer-responsable';
import { InsuranceInsured } from "../models/insurance-insured";
import { InsurancePayment } from '../models/insurance-payment';
import { InsuranceExtraInfo } from '../models/insurance-extrainfo';
import { InsuranceBeneficiary } from "../models/insurance-beneficiary";
import { InsuranceAccompanist } from "../models/insurance-accompanist";
import { InsuranceDependent } from '../models/insurance-dependent';
import { Spouse } from '../models/spouse';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { InsuranceDestination } from '../models/insurance-destinations';
import { WarningCardMuvitComponent } from '../components/utils/warning-card-muvit/warning-card-muvit.component';
import { WarningCardComponent } from '../components/utils/warning-card/warning-card.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class GlobalService {

  product:string;
  email_login:string;
  password_login:string;
  plans: any;
  countries = []

  private rangeSubject = new BehaviorSubject<FormGroup>(this.fb.group({
    start: [''],
    end: ['']
  }));
  range$ = this.rangeSubject.asObservable();

  setRange(formGroup: FormGroup) {
    this.rangeSubject.next(formGroup);
  }

  get range() {
    return this.rangeSubject.value;
  }

  searchInsProspectParams = {
    country: 2,
    sponsor: 54,
    product: null
  };

  payment_info:any = {
    payment_number: "",
    expiration_date: "",
    payment_name: "",
    card_type: ""
  };
  discount: any;
  isYearly: any;

  searchPriceTravelDay = {
    domestic: "DOMESTIC",
    us: "US",
    latam: "LATAM",
    row: "REST OF THE WORLD",
  }

  policyNumber: string = "";
  transactionNumber: string = "";

  id:number;
  user: string;
  roles: any;
  locations: any;
  userName: string;
  logged: boolean;
  role: number;
  private: boolean =false;
  token: string;
  showError: boolean=false;
  showErrorTwoTokenFactor = false;
  showLoader: boolean=false;
  userPin:string;
  canDoInsertRaffle:boolean = false;
  intermediaryCode: string;
  intermediaryLogo: string;
  paramsProspects: any;
  prospectConfig: any;
  sponsor: string;
  isProductFlag: boolean = false;
  internal_product_code: string;
  flag_insurance_plan_id: number;
  activeProduct: number;
  isLoading: boolean;

  premium_to_pay = 0;
  planId : string;
  planType : string;
  SEGUROS_CLOUD_URL:string="https://seguros-cloud.appspot.com"
  POLICY_INTEGRATOR_URL: string = "https://policy-integrator-prod.appspot.com";
  SC_public_PEM_key: string="-----BEGIN PUBLIC KEY-----\nMIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC8zxixWN236eXKN66HK9x1T8W6\nGx7s5tkJ0HXwr0aMoLHRkolcmgwu3myyLT5ASYFnv/Bxxoq1Vgt8OFmp0tiMV5P1\niaFyrzLYtUFsKY2GU6owFELTosbbOc0J+DyN9NIf/atuNApBeDfXZXm97LXYZPi7\nEta7y4IlrKD0aWesuwIDAQAB\n-----END PUBLIC KEY-----"
  currentInsuranceInfo: GenericInsurance;
  // currentSiteUrl: string = '/private/segurosalud';
  currentSiteUrl: string;

  isSelectedPlanIndividual: boolean;

  cration_address:string;
  seller_id: string;
  pin2:string;
  pin:string;

  emailRegex:string = "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$";

  showMessageAdded = false;
  messageAdded = '';
  prices = [];
  priceHolder = 0.00;
  totalPrice = 0.00;
  destiny:any;
  origin:any;
  email = '';
  selectedCountryCode: any[] = []
  selectedCountryCodeOrigin: any[] = []
  isEditable: boolean = true
  personDropDown: boolean = false
  Countries = []
  
  firstDate: any
  lastDate: any
  Days: any
  blockedDates = []
  oldDates: { start: Date, end: Date } = { start: null , end: null };
  people = [
    { type: '0 a 64 años', count: 0 },
    { type: '65 a 85 años', count: 0 },
  ]
  changeFinalPrice: EventEmitter<any> = new EventEmitter();

  constructor(public dialog_: MatDialog,
              private snackBar: MatSnackBar,
              public http: HttpClient,
              private fb: FormBuilder,
              private observableRoute: ActivatedRoute) { }

  

  setPremiumToPay(value: number) {
    this.premium_to_pay = value;
  }

  getPremiumToPay(): number {
    return this.premium_to_pay;
  }

  setCurrentInsuranceHolder(holder: InsuranceHolder){
    this.currentInsuranceInfo.holder = Object.assign(InsuranceHolder.prototype, holder);
  }

  getCurrentInsuranceHolder(): InsuranceHolder {
    return Object.assign(InsuranceHolder.prototype, this.currentInsuranceInfo.holder)
  }

  setCurrentInsuranceInsured(insured: InsuranceInsured){
    this.currentInsuranceInfo.insured = Object.assign(InsuranceInsured.prototype, insured);
  }

  getCurrentInsuranceInsured(): InsuranceInsured {
    return Object.assign(InsuranceInsured.prototype, this.currentInsuranceInfo.insured)
  }

  setCurrentInsurancePayment(payment: InsurancePayment) {
    this.currentInsuranceInfo.payment_info = Object.assign(
      InsurancePayment.prototype, payment
    );
  }

  getCurrentInsurancePayment(): InsurancePayment {
    return Object.assign(
      InsurancePayment.prototype, this.currentInsuranceInfo.payment_info
    );
  }

  setCurrentInsuranceExtraInfo(extraInfo: InsuranceExtraInfo[]){
    this.currentInsuranceInfo.extra_info = [
      ...extraInfo
    ];
  }

  getCurrentInsuranceExtraInfo(): InsuranceExtraInfo[] {
    return [ ...this.currentInsuranceInfo.extra_info ];
  }

  setCurrentInsuranceBeneficiary(beneficiary: InsuranceBeneficiary[]) {
    this.currentInsuranceInfo.beneficiarytable = [ ...beneficiary ];
  }

  setCurrentInsuranceAccompanist(accompanist: InsuranceAccompanist[]) {
    this.currentInsuranceInfo.accompanisttable = [ ...accompanist ];
  }

  setCurrentInsuranceDestination(destinations: InsuranceDestination[]) {
    this.currentInsuranceInfo.destinations = [ ...destinations ];
  }

  getCurrentInsuranceBeneficiary(): InsuranceBeneficiary[] {
    return [ ...this.currentInsuranceInfo.beneficiarytable  ];
  }

  setCurrentInsuranceDependent(dependents: InsuranceDependent[]) {
    this.currentInsuranceInfo.dependents = [ ...dependents ];
  }

  getCurrentInsuranceDependent(): InsuranceDependent[] {
    return [ ...this.currentInsuranceInfo.dependents  ];
  }

  setCurrentInsuranceSpouse(spouse: any): void {
    this.currentInsuranceInfo.spouse = { ...spouse };
  }

  getCurrentPaymentResponsable(): PayerResponsable {
    return Object.assign(PayerResponsable.prototype, this.currentInsuranceInfo.payment_info.payment_responsable);
  }

  setCurrentPaymentResponsable(payer: PayerResponsable){
    this.currentInsuranceInfo.payment_info.payment_responsable = Object.assign(PayerResponsable.prototype, payer);
  }

  

  setCallId(callId:string) {
    this.currentInsuranceInfo.call_id = callId;
  }

  getCallId():string {
    return this.currentInsuranceInfo.call_id;
  }

  showMessage(message: string, action: string, type:string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: [type + '-message'],
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }

  getIpClient(): Observable<any> {
    return this.http.get('https://api.ipify.org/', { params: { "format": "json" } });
  }

  isBancoPopularPrivate(route: ActivatedRoute): boolean {    
    let url = this.segmentUrl(route);

    return url.includes('private/bancopopular');
  }

  copyToClipboard(val: string){
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  isGeneralSell(route: ActivatedRoute): boolean {    
    let url = this.segmentUrl(route);

    return url.includes('private/general');
  }

  segmentUrl(route: ActivatedRoute){
    let url = ''
    route.url.forEach(urlSegment => {
      urlSegment.forEach(segment => {        
        url += '/' + segment.path;        
      })
    });
    return url;
  }

  openDialogMuvit(message:string|string[],title="Datos incorrectos",desc=""): void {
    var w = window.innerWidth;
    let width = w - (w*0.20);
    if(width> 400) width = 400;

    let dialogRef = this.dialog_.open(WarningCardMuvitComponent, {
      width: width.toString()+"px",
      height:'370px',
      data: { warning: message, newMessage: title, description: desc},
      position:{top:'20%'}
    });

    dialogRef.afterClosed().subscribe(result => {
      //
    });
  }

  openDialog(message:string|string[]): void {
    let dialogRef = this.dialog_.open(WarningCardComponent, {
      width: '400px',
      data: { warning: message},
      position:{top:'20%'}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
