import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ShareDialogComponent } from '../share-dialog/share-dialog.component';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  public secciones: Array<string> = ['primera', 'segunda', 'tercera', 'cuarta', 'quinta'];
  private router: Router;

  constructor(public dialog:MatDialog,
            private injector: Injector,  public title : Title) {
            this.router = this.injector.get<Router>(Router);
           }

  ngOnInit() {
    this.title.setTitle("Muvit - Terminos y Condiciones")
  }

  Navigate(id){
    //
    let targetSection = document.getElementById(id);

      if (targetSection) {
        window.scrollTo({
          top: targetSection.offsetTop,
          behavior: 'smooth'
        });
      }
      else{
        //
      }
  }

  openShareDialog() {
    this.dialog.open(ShareDialogComponent, {
      data: {}
    });
  }

  showTosPage(){
    this.router.navigate(['/public/muvit/es/terminos-y-condiciones'], { queryParams: { step: null }, queryParamsHandling: 'merge' });
  }

}