import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ShareDialogComponent } from '../share-dialog/share-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../../services/global.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MuvitCurLanDialogComponent } from '../muvit-cur-lan-dialog/muvit-cur-lan-dialog.component';
import { GenericInsurance } from '../../../models/generic-insurance';
import * as moment from 'moment';
interface Optn {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'muvit-article',
  templateUrl: './muvit-article.component.html',
  styleUrls: ['./muvit-article.component.css']
})
export class MuvitArticleComponent implements OnInit {
  languages: Optn[] = [{"value":'ES', "viewValue": "Español (ES)"}];
  currencies: Optn[] = [{"value":'USD ', "viewValue": "USD  - Dólar estadounidense ($)"}];
    language: Optn
    currency: Optn
  @Input() selectedArticle;
  @Input() listOfArticles;
  constructor(public dialog:MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    public gs: GlobalService,
    private sanitizer: DomSanitizer
   ) { }
  travelStartMinDate = null;
  travelMinEndDate = null;
  travelMaxEndDate = null;
  emailHolder= null;
  persons = false
  adults=0
  olds=0
  kids=0
  totalPersons = 0
  totalAdults = 0
  personsJson = {}
  disableApply = true
  showSide = false   
  showPerson = false;         
  currentPageIndex: number = 0; // Índice de la página actual
  
  startDatePick = false
  ngOnInit() {
    
    this.gs.currentSiteUrl = this.router.url.split('?')[0];
    this.language = this.languages[0]
    this.currency = this.currencies[0]
    this.travelStartMinDate = moment().toDate();
    this.emailHolder = ""
    this.initGenericInsurance();
  }

  initGenericInsurance() {
    if(this.gs.currentInsuranceInfo && this.gs.currentInsuranceInfo.email){
      this.emailHolder = this.gs.currentInsuranceInfo.email;
    }
    this.gs.currentInsuranceInfo = new GenericInsurance();
    this.gs.currentInsuranceInfo.insurance_plan_id = 2
    
    const dateNow = moment().format('YYYY-MM-DD');

    this.gs.currentInsuranceInfo.date_effective = dateNow;
    this.gs.currentInsuranceInfo.date_issued = dateNow;
    this.gs.currentInsuranceInfo.self_insurance = true;
  }
  onChangeTravelStartDate() {
    let travelStartDate = moment(this.gs.currentInsuranceInfo.travel_starting_date);
    this.travelMinEndDate = travelStartDate.add("3", "days").toDate();
    this.travelMaxEndDate = travelStartDate.add("90", "days").toDate();
    this.gs.currentInsuranceInfo.travel_end_date = null
    this.startDatePick = false
  }

  formatContent(content: string) : SafeHtml {
    // Reemplazar texto entre ** por <strong>texto</strong>
    if(content != null){
      content = content.replace(/\*\*(.*?)\*\*/g, '<strong style="font-weight: bold; color: black;">$1</strong>');
  
      // Reemplazar [texto](url) por <a href="url">texto</a>
      content = content.replace(/\[(.*?)\]\((.*?)\)/g, '<a style="color: #ff056f;"  class="link-txt" href="$2" target= "_blank" >$1</a>');
        // Reemplazar \n por <br> para los saltos de línea
      content = content.replace(/\n/g, '<br>');
      let contentF = this.sanitizer.bypassSecurityTrustUrl(content);
    }
   

    return (content);
  }
  formatTitle(title: string): SafeHtml {
    // Verificar si el título contiene ":" o "."
    if (title.includes(':') || title.includes('.')) {
      // Dividir el título en dos partes
      const parts = title.split(/[:.]/);
  
      // Verificar si se dividió en dos partes válidas
      if (parts.length === 2) {
        // Aplicar formato a la parte derecha con un salto de línea y un tamaño de fuente menor
        const formattedTitle = `${parts[0]}:<br><span style="font-size: 70%; font-weight:light; color:#353535">${parts[1]}</span>`;
  
        // Devolver el título formateado como HTML seguro
        return this.sanitizer.bypassSecurityTrustHtml(formattedTitle);
      }
    }
  
    // Si no se cumple la condición, devolver el título sin cambios
    return this.sanitizer.bypassSecurityTrustHtml(title);
  }
  

  formatArray(contents: any){
    //
    // for(let i = 0 ; i < contents.length; i++){
    //   //
    //   let formatted = this.formatContent(contents[i])
    //   contents[i] = formatted
    // }
    // return contents
  }
  openLanCur(){
    let dialogRef = this.dialog.open(MuvitCurLanDialogComponent, {
      width: '440px',
      height: '440px',
      data: { languages: this.languages, currencies: this.currencies, language: this.language, currency: this.currency }
    });

    dialogRef.afterClosed().subscribe(result => {
      //
    });
  }

  // Función para mostrar el artículo anterior
  prevArticle() {
    this.gotoTop()
    if (this.currentPageIndex > 0) {
      this.currentPageIndex--;
    }
    this.selectedArticle = this.listOfArticles[this.currentPageIndex]
  }

  // Función para mostrar el artículo siguiente
  nextArticle() {
    this.gotoTop()
    if (this.currentPageIndex < this.listOfArticles.length - 1) {
      this.currentPageIndex++;
    }
    this.selectedArticle = this.listOfArticles[this.currentPageIndex]
  } 

  getPageNumbersToShow(): number[] {
    const pageCount = this.listOfArticles.length;
    const currentPage = this.currentPageIndex; // Sin sumar 1, ya que los índices comienzan en 0
    const pageNumbersToShow = [];
    const pageNumbersPerPage = 5;
  
    let startPage = Math.floor(currentPage / pageNumbersPerPage) * pageNumbersPerPage;
    let endPage = Math.min(startPage + pageNumbersPerPage - 1, pageCount - 1);
  
    // Asegurarse de que el número de la primera página no sea negativo
    if (startPage < 0) {
      startPage = 0;
    }
  
    for (let i = startPage; i <= endPage; i++) {
      pageNumbersToShow.push(i);
    }
  
    return pageNumbersToShow;
  }

  showPersons(){
    this.persons = !this.persons
  }
  addPerson(val, person){
    if(val == 1){
      if(person == 1) this.adults += 1;
      else if(person == 2) this.olds += 1;
      else this.kids += 1;
    }
    else{
      if(person == 1) this.adults -= 1;
      else if(person == 2) this.olds -= 1;
      else this.kids -= 1;
      if(this.adults ==0 && this.olds == 0) this.kids = 0
    }
    if(this.adults == 0 && this.olds == 0 && this.kids == 0) this.disableApply = true 
    else this.disableApply = false
    this.confirmPersons()
  }
  confirmPersons(){
    this.totalPersons = this.adults + this.kids + this.olds
    this.totalAdults = this.adults + this.olds
    this.personsJson ={"adults": this.adults, "olds": this.olds, "kids": this.kids, "totalPersons": this.totalPersons, "totalAdults": this.totalAdults}
    //this.persons = false
    //this.showPerson = false
    this.showSide = false
    //this.goBack(2)
  }
  sPersons(){
    //this.gotoTop()
    if(!this.showPerson)
    this.showPerson = true

    else
    this.showPerson = false
    //this.showSide = true
    // this.personsSel.setAttribute('class', this.showDestiny ? 'slide-out' : 'slide-in');
  }
  onSubmit() {
    
  }

  quoteSubmit(){
    this.persons = false
    

    if(this.totalPersons == 0){
      this.addPerson(1,1);
      this.confirmPersons();
    }

    
    // validar fecha dentro de if 'name' in this.destiny &&
    if(this.gs.currentInsuranceInfo.travel_starting_date && this.gs.currentInsuranceInfo.travel_end_date && this.totalPersons >0 && this.persons == false && this.emailHolder)  {


      this.gs.currentInsuranceInfo.email = this.emailHolder;
      this.router.navigate(
        ['public/muvit/'], { queryParams: { step: 1 }, queryParamsHandling: 'merge' }
      );

      
    }
    else{
      this.gs.openDialogMuvit("Ingrese todos los datos");
    }


  }
  

  
  selectArticle(pageNumber: number) {
    this.gotoTop()
    this.currentPageIndex = pageNumber;
    this.selectedArticle = this.listOfArticles[this.currentPageIndex]

  }


  isScreenWidthLessThan1100: boolean = window.innerWidth < 1099;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isScreenWidthLessThan1100 = window.innerWidth < 1099;
  }

  gotoTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
    //
  }
  
  // Método para cerrar el dropdown cuando se hace clic fuera de él
  closeDropdown(event: any) {
    const dropdownElement = document.getElementById('persons');
  
    
    if (dropdownElement && !dropdownElement.contains(event.target)) {
      const personForm =  document.getElementById('person-form')
     
      if(personForm && !personForm.contains(event.target))
      this.showPerson = false;
    }
  }
  preventDropdownClosure(event: Event) {
    event.stopPropagation();
  }


  // Manejar clics en el documento para cerrar el dropdown
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: any): void {
    this.closeDropdown(event);
  }

  openShareDialog() {
    this.dialog.open(ShareDialogComponent, {
      data: {}
    });
  }
  



  
}
