<div class="plan-page">
    <div class="plan-container">
        <div class="stepper-container">
            <app-step-header [activeStep]="1"></app-step-header>
        </div>
        <div class="container-title">
            <h2>Elige tu plan</h2>
            <p>Cotización con base a la información ingresada</p>
        </div>
            
        <div class="plan-quotation">
            <table mat-table [dataSource]="dataSource" class="plan-table">
                <ng-container matColumnDef="descriptionCharge">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="img-container">
                            <img src="" height="370" width="auto"alt="" style="z-index: 15;">
                        </div>
                    
                    </th>
                    <td mat-cell style="height: 70px;" *matCellDef="let element" [ngClass]="{'black-cell': element.Description === 'TOTAL general coberturas'}"> 
                        <div class="description-container">
                            <div>
                                <h5 style="font-size: 15px; font-weight: 500; line-height: 21.09px; padding: auto;">{{element.Description}} </h5> 
                            </div>
                        </div>
                    </td>
                    <td mat-header-cell *matHeaderCellDef>
                        <div class="PlanDesign2">
                            <span></span>
                        </div>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="plan-view-1">
                    <th mat-header-cell *matHeaderCellDef>
                        
                        <div class="select-plan"
                            [ngStyle]="{'background': 'rgba(254, 244, 187, 1)', 'height': '275px', 'width': '250px', 'top': '0', 'margin-top': '95px'}">
                            
                            <div class="header-text">
                                <h5>{{ this.PlanName[0] }} <br> <span style="font-size: 18px;">
                                        <span> Total: USD {{totalPrice}}</span><br>
                                        <span style="font-weight: 400; font-size: 14px;">Por día: USD {{totalPrice/gs.currentInsuranceInfo.days_of_travel}}</span>   
                                    </span>
                                </h5>
                            </div>
                            <button class="buy-button" (click)="selectPlan()"
                                id="buyButton">Comprar</button>
                        </div>
        
                    </th>
                    <td mat-cell *matCellDef="let element" [ngClass]="{'black-cell': element.Description === 'TOTAL general coberturas'}">
                        <div class="PlanDesign"
                            [ngClass]="{'black-cell-price': element.Description === 'TOTAL general coberturas'}"
                            [ngStyle]="{'background': 'rgba(254, 244, 187, 0.1)', 'width': '250px', 'border-radius': '0', 'font-weight': '700'}">
                            {{element.Plan1}}
                        </div>
                    </td>
                    <td mat-header-cell *matHeaderCellDef>
                        <div class="PlanDesign2">
                            <span></span>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    ></tr>
                    <tr mat-footer-row></tr>
            </table>
        
        </div>
        <div class="accepted-cards">
            <img src="../../../assets/muvitBAM/recomendacion.svg" alt="Tip">
    
            <span class="text-cards">Puedes realiza tus pagos con tarjetas de crédito o débito</span>
    
            <img src="../../../assets/muvitBAM/visa.svg" alt="Visa" class="visa-img">
    
            <img src="../../../assets/muvitBAM/mastercard.svg" alt="Mastercard">
        </div>
    </div>
    
</div>
<!-- <app-aseguradora-general></app-aseguradora-general> -->
<app-footer></app-footer>