import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { ApiService } from '../../../api/api.service';
import { InsuranceHolder } from '../../../models/insurance-holder';
import { GlobalService } from '../../../services/global.service';
import { IAmService } from '../../../services/i-am.service';
import { UserS2Service } from '../../../services/users2.service';
import * as moment from "moment";
import { WarningCardMuvitComponent } from '../../utils/warning-card-muvit/warning-card-muvit.component';
import { Meta, Title } from '@angular/platform-browser';
import { ConfigService } from '../../../config/config.service';

interface Country {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-muvit-insurance-holder',
  templateUrl: './muvit-insurance-holder.component.html',
  styleUrls: ['./muvit-insurance-holder.component.css']
})

export class MuvitInsuranceHolderComponent implements OnInit, AfterViewInit {
  @Input() stepProduct: string;
  @Input() countries = [];
  @Input() PRODUCT_FOR_LOCATION: string;
  @Input() personsJson: any;
  // @Output() newPersonsJson: object;
  insuranceHolder?: InsuranceHolder;
  genderEquivalence = [];
  showLoader = false;
  iAm;
  gender:any="";
  birthday: Date;
  areaCodeHolder:{}
  areaCodeMod:any = ''
  contactHolder :string;
  offersCB = true;
  termsCB = false;
  verOlds:number = 0;
  verAdults:number = 0;
  verKids:number = 0;
  selectedRegion:any;
  screenHeight: number;
  screenWidth: number;
  alternate = false;
  foundUser = true;
  call_id: string;
  step: string;
  valueToStepper: boolean;
  uid_valid = false;
  uid_message = "";
  // genderEquivalence = [];
  age = null;
  age2 = null;
  dateAccompanist:any;
  days_of_travel = null;
  travelStartMinDate = null;
  travelMinEndDate = null;
  travelMaxEndDate = null;
  premium_to_pay = null;
  companions_: string;
  currentDate: Date;
  currentDateCompanion: Date;
  startDate = new Date(2000, 0, 2);
  events: string[] = [];
  tipoPlan:any;
  payerIsDifferent:any;
  form: FormGroup
  ip = "";
  genderArray=[]
  identification_type = 0
  regions = [{name:'Doméstica',id:1}, {name:'USA y Canadá' ,id:2},{name: 'Latinoamérica', id: 3}, {name:'Resto del Mundo', id:4} ]
  regionMod: number = 0;
  //UID
  isCallCenter = false;
  roltype = 0;
  regionSelected = {};
  private router: Router;
  private iam: IAmService;

  // companionFrom:InsuranceAccompanist 



  @ViewChild('holderformmuvit') holderform: NgForm;
  @Output("returnToHome") returnToHome: EventEmitter<any> = new EventEmitter();

  constructor(
    private httpClient: HttpClient,
    public gs: GlobalService,
    private api: ApiService,
    // public adisaLocations: AdisaLocationsService,
    public userSvc: UserS2Service,
    private dateAdapter: DateAdapter<Date>,
    private injector: Injector,
    private fb:FormBuilder,
    public dialog_: MatDialog,
    public global_svc: GlobalService,
    private titleService: Title,
    private conf : ConfigService,
    private changeDetector: ChangeDetectorRef,
    private meta: Meta
  ) { 
    this.router = this.injector.get<Router>(Router);
    this.iam = this.injector.get<IAmService>(IAmService);
    this.iAm = this.iam.isRole;
    this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    this.global_svc.getIpClient()
    .subscribe(data => {
      this.ip = data.ip;
    }, e => {
      this.ip = null;
    });
    this.meta.addTags([
      {name: 'description', content: 'Proporcione sus datos para finalizar su cotización de seguro de viaje. Nuestro proceso es seguro y confidencial, protegemos tus datos como si fueran los nuestros.'},
      {name: 'author', content: 'muvit'},
    ]);
  }

  ngOnInit() {
    if (!("travel_starting_date" in this.gs.currentInsuranceInfo)) {
      this.router.navigate([this.gs.currentSiteUrl], { queryParams: { step: null }, queryParamsHandling: 'merge'});
    }
    this.titleService.setTitle("Muvit - Datos Personales");
    this.gotoTop()
    this.verOlds = 0;
    this.verAdults = 0;
    this.verKids = 0;

    window.scrollTo({top: 0, behavior: 'smooth'});
    this.form = this.fb.group({
      companions: this.fb.array([])
    }) 

    
    let newDate = moment(new Date());
    this.currentDate = newDate.subtract("6", "months").toDate();
    this.currentDateCompanion = new Date()

    if (!("insurance_plan_id" in this.gs.currentInsuranceInfo)) {
      this.router.navigate([this.gs.currentSiteUrl], {queryParams: { step: null }, queryParamsHandling: 'merge' });
    }

    const stepperView = document.getElementById('stepper');
    stepperView.scrollIntoView();
    window.scroll(0, 0);

    if ('holder' in this.gs.currentInsuranceInfo && this.gs.currentInsuranceInfo.holder != null) {      

      this.insuranceHolder = this.gs.getCurrentInsuranceHolder();
      this.gender = this.insuranceHolder.gender
      let country = this.insuranceHolder.origin
      let index = this.countries.findIndex(object =>{
        return object['name'] === country
      })
      
      this.identification_type = this.insuranceHolder.identification_type
      this.areaCodeHolder = this.countries[index]
      this.areaCodeMod =this.countries[index]['area_code']   
      this.contactHolder = this.insuranceHolder.mobile.substring(this.areaCodeMod.length)
      this.onChangeDateOfBirth(this.insuranceHolder.birthday, 99)

      // If exists data from current insurance info
      // init form with correct validations and load district data
      setTimeout(() => {
        this.initForm();
      }, 100);

      this.call_id = this.gs.currentInsuranceInfo.call_id;
      this.foundUser = true;
    } else {
      console.error("Else en holder!")
      this.reset();
      setTimeout(() => {
        this.holderform.resetForm();
        this.initForm();
      }, 100);
    }
    if("accompanisttable" in this.gs.currentInsuranceInfo){
      
      
      this.form.setControl('companions', this.setExistingAccompanists(this.gs.currentInsuranceInfo.accompanisttable))
    }

    if (this.gs.currentInsuranceInfo.payment_info === undefined) {
      this.emptyPaymentInfo();
    }

    if(this.gs.currentInsuranceInfo.email){
      // 
      setTimeout(() => {
        this.insuranceHolder.email = this.gs.currentInsuranceInfo.email;
      }, 1200);
    }
    
  }

  initForm() {
    // Set cedula type 9 dropdown to Passport
    if (this.holderform) {

    }
  }

  onChangeTravelDates() {
    let travel_starting_date = this.gs.currentInsuranceInfo.travel_starting_date;
    let travel_end_date = this.gs.currentInsuranceInfo.travel_end_date;
    let birthday = this.insuranceHolder.birthday;

    if (travel_starting_date && travel_end_date && birthday) {
      this.days_of_travel = moment(travel_end_date).diff(moment(travel_starting_date), "days") + 1;
      this.gs.currentInsuranceInfo.days_of_travel = this.days_of_travel;

    } else {
      this.days_of_travel = null;
      this.gs.setPremiumToPay(null);
    }
  }

  onChangeTravelStartDate() {
    let travelStartDate = moment(this.gs.currentInsuranceInfo.travel_starting_date);
    this.travelMinEndDate = travelStartDate.add("3", "days").toDate();
    if (this.gs.currentInsuranceInfo.product_code == "ASYSTRAVEL_MAYA") {
      this.travelMaxEndDate = travelStartDate.add("11", "days").toDate();
    }
    else {
      this.travelMaxEndDate = travelStartDate.add("86", "days").toDate();
    }
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.holderform.resetForm();
    this.initForm();
  }

  holderCondition() {
    return this.insuranceHolder.calcYearsOld() < 18 || this.insuranceHolder.calcYearsOld() > 200;
  }

  reset() {
    this.insuranceHolder = new InsuranceHolder();
    if(this.gs.currentInsuranceInfo.email){
      
    }
    return this.insuranceHolder;
  }

  addEvent(type: string, event) {
    this.events.push(`${type}: ${event.value}`);
  }

  isValidDate() {
    if (this.age == 0 || this.age == null) {
      return false;
    }
    this.insuranceHolder.age = this.age
    return true;
  }

  onChangeDateOfBirth(value, index) {
    if(index == 99){
      if (value) {
        this.age = moment().diff(moment(value), "years");
        if (this.age <= 18) {
          // if (moment().diff(moment(value), "months") < 6) {
            this.age = null;
            // alert("Edad mínima: 18 años");
            this.gs.openDialogMuvit("Edad mínima: 18 años");
            this.insuranceHolder.birthday = '';
          // }
          // else {
          //   this.age = 1;
          // }
        }
        if (this.gs.currentInsuranceInfo.product_code == "MUVIT_DTC" && this.age > 85) {
          this.age = null;
          this.gs.openDialogMuvit("Edad máxima: 85 años");
          this.insuranceHolder.birthday = '';
        }
      } else {
        this.age = null;
      }
    }
    else {
      setTimeout(() => {
        // if (value) {
          this.age2 = moment().diff(moment(this.companions.value[index].birthday), "years");
          if (this.age2 <= 18) {
            if (moment().diff(moment(this.companions.value[index].birthday), "months") < 6) {
              this.age2 = null;
              this.companions.value[index].birthday = null;
              this.companions.value[index].birthday_ = null;
              this.form.controls['companions']['controls'][index].controls['birthday_'].setValue('');
              this.form.controls['companions']['controls'][index].controls['birthday'].setValue('');
              this.gs.openDialogMuvit("Edad mínima: 6 meses");
            }
            else {
              this.age2 = 1;
              this.companions.value[index].age = this.age2;
            }
          }
          if (this.gs.currentInsuranceInfo.product_code == "MUVIT_DTC" && this.age2 > 85) {
            this.age2 = null;
            this.companions.value[index].birthday = null;
            this.companions.value[index].birthday_ = null;
            this.form.controls['companions']['controls'][index].controls['birthday'].setValue('');
            this.form.controls['companions']['controls'][index].controls['birthday_'].setValue('');
            this.gs.openDialogMuvit("Edad máxima: 85 años");
          }

      }, 200);
    }

    this.onChangeDateOfBirth2(moment(value, 'DD/MM/YYYY').format('DD/MM/YYYY'));
    this.isValidDate();
  }
  changeAreaCode(){
    if(this.areaCodeHolder && 'area_code' in this.areaCodeHolder){
      this.areaCodeMod = this.areaCodeHolder['area_code']
    }

  
    
  }

  changeRegion(){
    if(this.selectedRegion && 'id' in this.selectedRegion){
      this.regionMod = this.selectedRegion['id'];
    }
    //
  }
  gotoTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
  onChangeDateOfBirth2(value) {
    this.birthday = value;
  }
  onChangeDateOfBirth3(value) {
    let age_ =0
    if (value) {
      age_ = moment().diff(moment(value), "years");
      if (age_ == 0) {
        if (moment().diff(moment(value), "months") < 6) {
          age_ = null;
          this.gs.openDialogMuvit("Edad mínima: 6 meses");
        }
        else {
          age_ = 1;
        }
      }
      if (this.gs.currentInsuranceInfo.product_code == "ASYSTRAVEL_MAYA" && this.age > 85) {
        age_ = null;
        this.gs.openDialogMuvit("Edad máxima: 85 años");
      }
    } else {
      age_ = null;
    }
    return age_
  }

  emptyPaymentInfo() {
    this.gs.setCurrentInsurancePayment({ payment_name: "", payment_number: "", expiration_date: "" });
    this.gs.payment_info = {
      payment_number: "",
      expiration_date: "",
      payment_name: ""
    };
  }

  setEffectiveDate(event) {
    this.gs.currentInsuranceInfo.date_effective = event.target.value;
  }

  // loadInsCantons(value) {
  //   this.adisaLocations.loadAdisaCantons(value + '-');
  // }

  // loadAdisaDistricts(value) {
  //   this.adisaLocations.loadAdisaDistricts(this.insuranceHolder.province_code + "-" + value + "-");
  // }

  searchProspect() {
    this.showLoader = true;
    this.httpClient.post(this.getAPIURL(), this.getAPIPayload()).subscribe(resp => {
      const response = this.parseResponse(resp);
      if (response.length > 0) {
        this.foundUser = true;
        this.showLoader = false;
        this.setFieldsFromAPI(response[0]);
      } else {
        this.foundUser = false;
        this.showLoader = false;
        if (this.userSvc.locations.length > 0) this.gs.currentInsuranceInfo.creation_address = this.userSvc.locations[0].value;
      }

    }, error => {
      console.error(error);
      this.showLoader = false;
    });
  }

  getAPIURL() {
    return `/api/prospects/search`
  }

  getAPIPayload() {
    return {
      ...this.gs.searchInsProspectParams,
      "national_id": this.insuranceHolder.identification_id,
      "campaign": "",//no se utiliza,
      "product":this.gs.activeProduct
    }
  }

  parseResponse(response) {
    return <Array<Object>>response;
  }

  setFieldsFromAPI(prospect: any) {

    if (prospect.account_number != null && prospect.account_number.length !== 0) this.setPaymentInfo(prospect);

    this.insuranceHolder.first_name = prospect.first_name;
    this.insuranceHolder.middle_name = prospect.middle_name;
    this.insuranceHolder.last_name = prospect.last_name;
    this.insuranceHolder.last_name_2 = prospect.last_name_2;

    if (prospect.birthday != null) {
      let d1 = prospect.birthday.split('-')
      let d2 = new Date(parseInt(d1[0]), (parseInt(d1[1]) - 1), parseInt(d1[2])); // year, month, day
      this.insuranceHolder.birthday = d2;
    }
    this.insuranceHolder.phone1 = prospect.phone1;
    this.insuranceHolder.phone2 = prospect.phone2;
    this.insuranceHolder.email = prospect.email;
    this.insuranceHolder.address = prospect.address;
    this.gs.currentInsuranceInfo.intermediary_code = prospect.intermediary_code;
    if (prospect.sex === 'M') this.insuranceHolder.gender = 0;
    if (prospect.sex === 'F') this.insuranceHolder.gender = 1;
    // set location
    if ('reference_6' in prospect && prospect.reference_6 != null) {
      this.gs.currentInsuranceInfo.creation_address = this.userSvc.getLocationProspect(prospect.reference_6);
      this.insuranceHolder.temporal_creation_address = prospect.reference_6;
    } else {
      this.insuranceHolder.temporal_creation_address = undefined;
      if (this.userSvc.locations.length > 0) {
        this.gs.currentInsuranceInfo.creation_address = this.userSvc.locations[0].value;
      }
    }
  }

  setPaymentInfo(prospect) {
    this.gs.payment_info.payment_number = prospect.account_number;
    this.gs.payment_info.expiration_date = prospect.expiration_date.substring(0, 2) + '/' + prospect.expiration_date.substring(2, 4);
    this.gs.payment_info.payment_name = prospect.name;
    this.gs.payment_info.card_type = prospect.card_type;
  }

  onSubmit() {
    if(!this.form.valid || !this.holderform.valid){
      Object.keys(this.holderform.form.controls).forEach(key => {
        const control = this.holderform.form.controls[key];
        control.markAsTouched();
      });

      Object.keys(this.form.controls['companions']['controls']).forEach(key2 => {
        const control = this.form.controls['companions']['controls'][key2];
        Object.keys(control['controls']).forEach(key => {
          const control2 = control.controls[key];
          control2.markAsTouched();
        });
        control.markAsTouched();
      });

      this.gs.openDialogMuvit("Hubo un error al procesar sus datos, uno o más datos están incompletos o son inválidos","Error");
      return;
    }

    try {
     
      this.verAdults = 0
      this.verOlds = 0
      this.verKids = 0
      this.insuranceHolder.identification_type = 0;

      this.insuranceHolder.mobile = this.areaCodeHolder['area_code'] + this.contactHolder
      this.insuranceHolder.country_id = this.conf.countryGT;
      this.insuranceHolder.origin = this.areaCodeHolder["name"]
      this.insuranceHolder.age = this.age;
      this.insuranceHolder.gender = this.gender;
      this.insuranceHolder.identification_type = this.identification_type;
      this.insuranceHolder.setFullNameByParts();
      this.companions.value.forEach((a, index) => {
        a.name = a.first_name + " " + a.last_name
        a.age = this.onChangeDateOfBirth3(a.birthday)
        a.gender = this.genderArray[index]

      });

      this.gs.setCurrentInsuranceHolder(this.insuranceHolder);
      this.gs.setCurrentInsuranceInsured(this.insuranceHolder);
      if(this.companions.value && this.companions.value.length > 0 ){
        this.gs.setCurrentInsuranceAccompanist(this.companions.value);
      }

      this.gs.setCallId(this.call_id);

      let step = 4;


      if(this.insuranceHolder.age >= 65){
        
        this.verOlds +=1
        this.personsJson.adults = this.verAdults
        this.personsJson.olds = this.verOlds
        this.personsJson.kids = this.verKids
      }
      else if(this.insuranceHolder.age >= 18 && this.insuranceHolder.age <65){
        this.verAdults+=1
        this.personsJson.adults = this.verAdults
        this.personsJson.olds = this.verOlds
        this.personsJson.kids = this.verKids
      }
      else {
        this.verKids+=1
        this.personsJson.adults = this.verAdults
        this.personsJson.olds = this.verOlds
        this.personsJson.kids = this.verKids
      };
      this.companions.value.forEach(com => {
        if(com.age >= 65)this.verOlds +=1
        else if(com.age >= 18 && com.age < 65)this.verAdults+=1;
        else this.verKids+=1;
      });

      if(this.personsJson.adults == this.verAdults && this.personsJson.olds == this.verOlds && this.personsJson.kids == this.verKids)
      {
        //this.generateLead();
        this.updateLead();
        this.router.navigate( 
          [this.gs.currentSiteUrl],
          { queryParams: { step: 4 } , queryParamsHandling: 'merge'}
        );
      }
      else{
        let dialogRef = this.dialog_.open(WarningCardMuvitComponent, {
          width: '400px',
          height: '420px',
          data: { newMessage: "ACABAS DE AÑADIR UN ACOMPAÑATE" ,warning: "Este se incluirá en tu cotización original. ¿deseas continuar?","cancel": "Cancelar","accept": "Aceptar"},
          position:{top:'20%'}
        });
        dialogRef.afterClosed().subscribe(result => {
          this.updateLead();

          if(result){
            this.router.navigate( 
              [this.gs.currentSiteUrl],
              { queryParams: { step: 4 }, queryParamsHandling: 'merge' }
            );
          }
        });
      }

      
    } catch (error) {
     console.error("ERROR: ",error);
     this.gs.openDialogMuvit("Hubo un error al procesar sus datos, uno o más datos están incompletos o son inválidos","Error");
    }
  }
  generateLead() {
    let sendInssuranceLeadData = {
      // name : this.insuranceHolder.first_name+" "+(this.insuranceHolder.middle_name)+" "+this.insuranceHolder.last_name+" "+this.insuranceHolder.last_name_2,
      name : this.insuranceHolder.first_name+" "+this.insuranceHolder.last_name,
      email : this.insuranceHolder.email,
      phone : this.areaCodeMod + ""+ this.contactHolder,
      source : "Muvit public",
      product : "DTMU",
      plan : this.gs.currentInsuranceInfo.insurance_plan_id,
      ip: this.ip,
      id : localStorage.getItem('lead')
    }
    this.api.landingLeads.newLead_v3(sendInssuranceLeadData).promise().then(
      (data)=>{
        localStorage.setItem('lead',data.id)
      }
    );
  }

  updateLead(){
    let sendInssuranceLeadData = {
      // name : this.insuranceHolder.first_name+" "+(this.insuranceHolder.middle_name)+" "+this.insuranceHolder.last_name+" "+this.insuranceHolder.last_name_2,
      name : this.insuranceHolder.first_name+" "+this.insuranceHolder.last_name,
      email : this.insuranceHolder.email,
      phone : this.areaCodeMod + ""+ this.contactHolder,
      source : "Muvit public",
      product : "DTMU",
      plan : this.gs.currentInsuranceInfo.insurance_plan_id,
      ip: this.ip,
      step: 2,
      id : localStorage.getItem('lead'),
    }
    this.api.landingLeads.updateStatus_v3(sendInssuranceLeadData).promise().then(
      (data)=>{
        localStorage.setItem('lead',data.id)
      }
    );
  }
  async checkUID() {
    let data = {
      "callUID": this.call_id,
      "rol": this.roltype
    }
    await this.api.checkUID.post(data).promise().then(
      req => {
        this.uid_message = req.Message;
        if (req.status == 200) {
          this.uid_valid = true;
        } else {
          this.uid_valid = false;
        }
      }
    )
  }

  async rolesCheck() {
    let roles = this.iam.roles
    for (let element of roles) {
      if (element == 'inbound_call_center_15') {
        this.isCallCenter = true;
        this.roltype = 15;
      } else if (element == 'inbound_call_center_1') {
        this.isCallCenter = true;
        this.roltype = 1;
      }
    }
  }

  
  // ---------------------------------------------------------------------acompañantes-------------------------------------------------------
  get companions(){
    return this.form.get('companions') as FormArray;
    }
  addCompanion(){
    const companionForm = this.fb.group({
      first_name:['', Validators.required],
      middle_name:[''],
      last_name:['', Validators.required],
      last_name_2:[''],
      birthday_: ['', Validators.required],
      birthday: [''],
      gender:[null, Validators.required],
      identification_id:['',Validators.required],
      identification_type:['',Validators.required],
      age:[''],
    })

    this.companions.push(companionForm)
    this.gs.currentInsuranceInfo.accompanisttable = this.companions.value
    
    
    

  }
  deleteAccompanist(index){
    this.genderArray.splice(index, 1)
    this.companions.removeAt(index);
    this.gs.currentInsuranceInfo.accompanisttable = this.companions.value
  }
  returnToHome2(){
    this.returnToHome.emit();
    this.router.navigate(
      [this.gs.currentSiteUrl], { queryParams: { step: 1 }, queryParamsHandling: 'merge' }
    );
  }
  setExistingAccompanists(accompanists){
    
    
    const formArray = new FormArray([]);
    accompanists.forEach( a =>{
      formArray.push(this.fb.group({
        first_name: a.first_name,
        middle_name: a.middle_name,
        last_name: a.last_name,
        last_name_2: a.last_name_2,
        birthday: a.birthday,
        birthday_: a.birthday_,
        gender: a.gender,
        identification_id: a.identification_id,
        identification_type: a.identification_type,
        age: a.age,
      }))
    })
    
    return formArray
  }
  changeGender(gender,index){
    if(this.genderArray[index] == 'undefined' || this.genderArray[index] == null){
      this.genderArray.splice(index, 0 , gender)
    }
    else {this.genderArray[index] = gender
    }
  }

  validateDate() {
    if(this.insuranceHolder.birthday){
      let parts = this.insuranceHolder.birthday.split("/");
      if(parts.length <3){
        this.gs.openDialogMuvit("Fecha de nacimiento inválida", "Error");
      }
      this.insuranceHolder.birthday = new Date(Date.parse(parts[2]+"-"+parts[1]+"-"+parts[0]));
      this.onChangeDateOfBirth(this.insuranceHolder.birthday, 99);
    }
  }

  validateDate2(i) {
    if(this.companions.value[i].birthday_){
      let parts = this.companions.value[i].birthday_.split("/");
      if(parts.length <3){
        this.gs.openDialogMuvit("Fecha de nacimiento inválida", "Error");
      }
      let timestamp = new Date(Date.parse(parts[2]+"-"+parts[1]+"-"+parts[0]));
      //1978-06-14T06:00:00.000Z

      this.companions.value[i].birthday = timestamp;

      this.onChangeDateOfBirth(this.companions.value[i].birthday, i);
    }
  }

  setValue(value: string) {
    const parsedValue = value || "";
    this.changeDetector.detectChanges();
    this.insuranceHolder.email = parsedValue;
  }

  showTosPage(){
    this.router.navigate(['/public/muvit/es/terminos-y-condiciones'], { queryParams: { step: null }, queryParamsHandling: 'merge' });
  }
  
}

