import { InsuranceInsured } from './insurance-insured'
import { InsuranceHolder } from './insurance-holder'
import { InsurancePayment } from './insurance-payment'
import { InsuranceExtraInfo } from "./insurance-extrainfo";
import { InsuranceBeneficiary } from './insurance-beneficiary';
import { InsuranceAccompanist } from './insurance-accompanist';
import { InsuranceDependent } from "./insurance-dependent";
import { Spouse } from './spouse';
import { InsuranceDestination } from './insurance-destinations';

export class GenericInsurance {
    date_issued?: string;
    date_effective?: any;
    policy_number?: string;
    coin: any;
    data: any;
    transaction?: string;
    transaction_id?: string;
    final_price?:number;
    creation_address?: string;
    insured?: InsuranceInsured;
    holder?: InsuranceHolder;
    holder_firm?: string;
    insured_firm?: string;
    payment_info?: InsurancePayment;
    insurance_plan_id?: number;
    flag_insurance_plan_id?: number;
    product_code?: string; //INS_HEALTH
    self_insurance?: boolean;
    observation?: string;
    seller_id?: string;
    beneficiarytable?: InsuranceBeneficiary[];
    accompanisttable?: InsuranceAccompanist[];
    destinations?: InsuranceDestination[];
    extra_info?: InsuranceExtraInfo[];
    dependents?: InsuranceDependent[];
    spouse?: Spouse;
    call_id?: string;
    pin2?: string;
    assistance_plan_id: number;
    intermediary_code: string;
    travel_starting_date?: any;
    travel_end_date?: any;
    days_of_travel?: any;
    travel_price?: number;
    ezprice?:any;
    original_policy?:string;
    contract_number: string;
    product_number: string;
    cycle: string;
    language?:string;
    sponsor?:string;
    captcha_token?:string;
    ip?:string;
    email?:string;
}
