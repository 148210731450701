<div class="benefits-container">
  <h2 class="text-center">Conoce nuestros beneficios</h2>

  <div class="benefits-tabs">
    <button class="tab" [class.active]="activeTab === 'health'" (click)="setActiveTab('health')">Salud</button>
    <button class="tab" [class.active]="activeTab === 'luggage'" (click)="setActiveTab('luggage')">Equipaje</button>
    <button class="tab" [class.active]="activeTab === 'travel'" (click)="setActiveTab('travel')">Viajes</button>
  </div>

  <!-- Contenido dinámico -->
  <div class="tab-content">
    <div *ngIf="activeTab === 'health'" class="tab-pane">
      <button class="carousel-control-prev" (click)="prevCard('health')"></button>
      <div class="card-wrapper">
        <div class="card" *ngFor="let card of healthCards; let i = index"
          [hidden]="i < currentHealthCard || i >= currentHealthCard + 2">
          <table class="card-table">
            <tr>
              <td rowspan="2" class="icon-cell">
                <img [src]="card.icon" alt="{{ card.title }}">
              </td>
              <td class="title-cell">
                <h5>{{ card.title }}</h5>
              </td>
            </tr>
            <tr>
              <td class="description-cell">
                <p>{{ card.description }}</p>
              </td>
            </tr>
          </table>
        </div>
      </div>


      <button class="carousel-control-next" (click)="nextCard('health')"></button>
      <!-- Indicadores de paginación -->
      <div class="pagination-indicators">
        <span *ngFor="let i = index of getPaginationArray('health')" [class.active]="i === getCurrentPage('health')"
          (click)="goToPage(i, 'health')"></span>
      </div>
    </div>




    <div *ngIf="activeTab === 'luggage'" class="tab-pane luggage-grid">

      <div class="card-wrapper equipaje">
        <div class="luggage-card" *ngFor="let card of luggageCards; let i = index"
          [hidden]="i < currentLuggageCard || i >= currentLuggageCard + 2">
          <img [src]="card.icon" alt="{{ card.title }}">
          <div class="luggage-card-content">
            <h5>{{ card.title }}</h5>
            <p>{{ card.description }}</p>
          </div>
        </div>
      </div>



    </div>

    <div *ngIf="activeTab === 'travel'" class="tab-pane">
      <div class="card-wrapper equipaje">
        <div class="travel-card" *ngFor="let card of travelCards; let i = index"
          [hidden]="i < currentTravelCard || i >= currentTravelCard + 3">
          <!-- Imagen superior -->
          <img [src]="card.icon" alt="{{ card.title }}">
          <!-- Contenido textual -->
          <div class="travel-card-content">
            <h5>{{ card.title }}</h5>
            <p>{{ card.description }}</p>
          </div>
        </div>
      </div>

    </div>
  </div>


</div>
