<div class="accompanist-form">
    <div class="header-dialog">
        <div class="header-text">
            <div>
                <img src="../../../../assets/muvitBAM/viajero.svg" alt="Viajero">
            </div>
            <div>
                <div class="text1">
                    <span *ngIf="!data">Añadir viajero</span>
                    <span *ngIf="data">Editar viajero</span>
                </div>
                <div class="text2">Ingrese los datos del viajero</div>
            </div>
        </div>
        <div (click)="closeDialog()" style="cursor: pointer;">
            <img src="../../../../assets/muvitBAM/cerrar.svg" alt="Viajero">
        </div>
    </div>

    <form #accompanistform="ngForm" class="form">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="name">Nombre(s)*</label>
                    <input id="name" name="name" type="text" class="form-control" [(ngModel)]="dependent.first_name" required/>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label for="last-name">Apellido(s)*</label>
                    <input id="last-name" name="last-name" type="text" class="form-control" [(ngModel)]="dependent.last_name" required/>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="birthday">Fecha nacimiento*</label>
                    <input class="form-control" matInput [matDatepicker]="picker" placeholder="Selecciona una fecha"
                    required readonly [(ngModel)]="dependent.birthday" id="birthday" name="birthday" (click)="picker.open()" [max]="maxDate" [min]="minDate">
                    <mat-datepicker-toggle matSuffix [for]="picker" style="display: none;"></mat-datepicker-toggle>
                    <mat-datepicker #picker startView="multi-year"></mat-datepicker>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label for="genre">Género*</label>
                    <select id="genre" name="genre" class="form-control" [(ngModel)]="dependent.gender" required>
                        <option [value]="null" disabled selected> Selecciona una opción </option>
                        <option *ngFor="let option of genreOptions" [value]="option.value">{{option.description}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="passport">No. Pasaporte*</label>
                    <input id="passport" name="passport" type="text" class="form-control" [(ngModel)]="dependent.identification_id" required/>
                </div>
            </div>

            <div class="col-md-6"></div>
        </div>

        <div class="btn-options">
            <button class="btn btn-cancel" (click)="closeDialog()">
                Cancelar
            </button>
                
            <button class="btn btn-continue" (click)="add()" [disabled]="accompanistform.invalid">
                <span *ngIf="!data">Añadir</span>
                <span *ngIf="data">Guardar</span>
            </button>
        </div>
    </form>
</div>