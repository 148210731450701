import { Component } from '@angular/core';

@Component({
  selector: 'app-cotizar-button',
  templateUrl: './cotizar-button.component.html',
  styleUrls: ['./cotizar-button.component.css']
})
export class CotizarButtonComponent {

}
