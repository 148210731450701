import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-banner-home-mobile',
  templateUrl: './banner-home-mobile.component.html',
  styleUrl: './banner-home-mobile.component.css'
})
export class BannerHomeMovilComponent {
  @Input() backgroundImageUrl: string;

}
