import { Injectable } from '@angular/core';

@Injectable()
export class ValidationService {

    constructor() {
        // Empty. This is intentional
    }

    static getValidationErrorMessage(validatorName: string, validatorValue?: any) {

        let configuration = {
            'vendedorValid': 'Nombre de vendedor no reconocido',
            'required': 'Campo requerido',
            'minimumAge': 'Tiene que ser mayor a 18 años',
            'maxlength': `La longitud máxima son ${validatorValue.requiredLength} caracteres.`,
            'strUpper': `Por favor, escribe todo en mayúsculas.`,
            'invalidEmailAddress': 'Formato de email incorrecto. Ej: example@gmail.com',
            'invalidNumber': 'Introduce solo números, sin espacios.',
            'wordNoSpacesValidator': 'Introduce solo una palabra, sin espacios.',
            'minDate': `Fecha incorrecta.`,
            'maxDate': `Fecha incorrecta.`,
            'invalidDocumentLetter': `La última letra del documento es incorrecta.`,
            'invalidDocumentFormat': `El número del documento introducido es erróneo.`,
            'invalidAge': `Este asegurado es mayor de 13 años. Por favor, completa el tipo de documento.`,
            'passwordInvalid': 'La contraseña debe tener como mínimo 7 caracteres y al menos una letra.',
            'invalidNumberPositive': 'Solo puedes introducir números enteros.',
            'invalidNumberPhone': 'Número de teléfono incorrecto. Ej: +34657789784',
            'invalirLowerCase': 'No se permite ningun carecter en minusculas.'
        };

        return configuration[validatorName];
    }

    validateUpperLetters(numeroDocumento) {
        if (numeroDocumento && numeroDocumento != numeroDocumento.toUpperCase()) return { 'strUpper': true };
        return null;
    }

    static emailValidator(control) {

        // RFC 2822 compliant regex
        const valor = control.value || "";
        if (!valor) {
            // Si no tiene valores no se valida el email
            // Debido a que este mismo al no comprobar el valor
            // forza a que sea requerido el field cuando puede que no lo sea
            return null
        }

        let regex_email = /^\w+([\.-]?\w+)*@[a-zA-Z]*(\.(arpa|root|aero|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|um|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw)|(\.[a-zA-Z]{2,3}))+$/;

        if (valor.match(regex_email)) {
            return null;
        } else {
            return { 'invalidEmailAddress': true };
        }
    }

    static numberValidator(control) {
        const valor = control.value || "";
        if (!valor) {
            return null
        }

        if (!valor.match(/(\D+)/g) || valor == "") {
            return null;
        } else {
            return { 'invalidNumber': true };
        }
    }

    // Solo una palabra sin espacios al prinicio o al final
    static wordNoSpacesValidator(control) {
        const valor = control.value || "";
        if (valor.match(/^\b\w+\b$/) || valor == "") {
            return null;
        } else {
            return { 'wordNoSpacesValidator': true };
        }
    }

    static numberphoneValidator(control) {
        const valor = control.value || "";
        if (!valor) {
            return null
        }

        var regExpPhone = /^[+]?\d*\d\d*$/g;
        if (valor.match(regExpPhone)) {
            return null;
        } else {
            return { 'invalidNumberPhone': true };
        }
    }

    // Validación que representa mínimo 7 caracteres y una letra
    static validatePassword(control) {

        let passwordRexp = /^(?=.*[A-Z+])([~@#$%&*'+=<>`|{}:;!.·?\"()\[\]_Çç¿§«»ω⊙¤°℃℉€¥£¢¡®©-]){7,}$/i;

        if (control.value && control.value.match(passwordRexp)) {
            return null;
        } else {
            return { 'passwordInvalid': true };
        }
    }

    static onlyNumber(control) {
        let onlyNumberPositive = /^\d*\d\d*$/;

        const valor = (control.value || "").toString();
        if (valor.match(onlyNumberPositive)) {
            return null;
        } else {
            return { 'invalidNumberPositive': true };
        }
    }

    static onlyUpperCase(control) {
        const onlyUpperCase = '.*[a-z].*';
        const valor = (control.value || "").toString();

        if (valor.match(onlyUpperCase)) {
            return { 'invalirLowerCase': true };
        } else {
            return null;
        }
    }

    isBancoPopularCard(card) {
        let bines = ['405705' ,'409731', '476399', '493743', '493744', '493745', '491261', '402932'];
        for(let value of bines){
            if (card.includes(value)) {
                return true;
            }
        }
        return false;
    }

}
