import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Directive, ElementRef, EventEmitter, HostListener, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDateRangePicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataConstructorService } from 'src/app/services/data-constructor.service';
import Swal from 'sweetalert2';

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<void>();
  constructor(private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      this.clickOutside.emit();
    }
  }
}

@Component({
  selector: 'app-plan-quotation-mobile',
  templateUrl: './plan-quotation-mobile.component.html',
  styleUrls: ['./plan-quotation-mobile.component.css'],
  animations: [
    trigger('dropdownAnimation', [
      state('void', style({
        height: '0',
        width: '100%'
      })),
      state('*', style({
        height: '770px',
        width: '100%'
      })),
      transition('void => *', animate('0.5s ease-out')),
      transition('* => void', animate('0.4s ease-out'))
    ]),
    trigger('flipHorizontal', [
      state('normal', style({
        transform: 'scaleY(1)'
      })),
      state('flipped', style({
        transform: 'scaleY(-1)'
      })),
      transition('normal <=> flipped', animate('0.5s ease-out'))
    ]),
    trigger('dropdownAnimation2', [
      state('void', style({
        height: '0px',
        padding: '0px'

      })),
      state('*', style({
        height: '130px',
        padding: '0.5rem'

      })),
      transition('void => *', animate('0.5s ease-out')),
      transition('* => void', animate('0.4s ease-out'))
    ]),
  ]
})
export class PlanQuotationMobileComponent implements OnInit {
  today = new Date();
  showTable: boolean[] = [];
  planSelect: any = 'Diario';
  AnualPlan: any = []
  backgroundColors = ['rgba(231, 235, 248, 1)', 'rgba(248, 234, 235, 1)'];
  personDropDown: boolean = false;
  Date: any = {
    start: '',
    end: ''
  }
  persons = [
    { type: 'Adultos', count: 0 },
    { type: 'Niños', count: 0 },
    { type: 'Adultos Mayores', count: 0 }
  ]
  travelInfo: FormGroup
  totalPrices: any = []
  blockedDates: any;

  firstDate: any
  lastDate: any

  planes: any[] = []


  // Convertir fecha tipo "Mon Mar 04 2024 00:00:00 GMT-0600 a YYYY-MM-DD"
  convertDate(date: any) {
    const fecha = new Date(date);
    const dia = fecha.getDate() + 1;
    const mes = fecha.getMonth() + 1;
    const ano = fecha.getFullYear();
    return `${ano}-${mes < 10 ? '0' + mes : mes}-${dia < 10 ? '0' + dia : dia}`;
  }


  // Transformar la fecha a formato en español (dd/mm/yyyy) Original Sat Feb 17 2024 00:00:00 GMT-0600 (hora estándar central)
  convertirFecha(fecha: any) {
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const fechaConvertida = new Date(fecha);
    const dia = fechaConvertida.getDate();
    const mes = meses[fechaConvertida.getMonth()];
    const ano = fechaConvertida.getFullYear();
    return `${dia} ${mes} ${ano}`;
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: Event) {
    const clickedInside2 = this.el.nativeElement.contains(event.target);

    if (!clickedInside2) {
      this.personDropDown = false;
    }
  }


  hasAdult() {
    return this.persons.some(persons => (persons.type === 'Adulto' || persons.type === 'Adulto Mayor') && persons.count > 0)
  }

  personsDropDown() {

    if (this.personDropDown) {
      this.personDropDown = false

    } else {
      this.personDropDown = true

    }
  }


  get getTotalPeople() {
    var total = 0
    for (let person of this.persons) {
      total = total + person.count
    }

    return total
  }

  constructor(public dataConstructor: DataConstructorService, private builder: FormBuilder,
    private router: Router, private renderer: Renderer2, private el: ElementRef, private activatedRoute: ActivatedRoute, private title: Title) {
    this.title.setTitle(this.activatedRoute.title['destination']['_value']['title'])
    this.dataConstructor.isTotalVisible = false
    this.persons = this.dataConstructor.persons
    this.planes = this.dataConstructor.calculateDailyPrices()
    this.travelInfo = this.builder.group({
      destination: this.builder.control(this.dataConstructor.countries[0].description),
      persons: this.builder.control([String(this.getTotalPeople) + ' personas']),
    })


    this.travelInfo.disable()

    this.Date = { start: this.convertDate(this.dataConstructor.date.start), end: this.convertDate(this.dataConstructor.date.end) }
  }

  onCheckboxChange(event) {
    if (event.target.checked) {
      this.planSelect = 'Anual';

    } else {
      this.planSelect = 'Diario';

    }
  }

  ngOnInit(): void {
    this.dataConstructor.calculateDailyPrices()

    this.dataConstructor.totalPlanPrices.forEach(() => this.showTable.push(false));


  }

  toggleTable(index: number) {
    this.showTable[index] = !this.showTable[index];
  }


  buyPlan(plan: any, costo: any) {
    if (this.dataConstructor.totalPeople >= 1) {
      this.dataConstructor.totalCost = costo
      this.dataConstructor.planSelected = plan
      this.dataConstructor.typePlan = plan
      this.router.navigate(['/public/mobile/client-info'], { queryParamsHandling: 'preserve' });
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Selecciona al menos una persona',
        showConfirmButton: true,
        timer: 6000,
      })
    }

  }


  increaseCount(person) {
    if (this.getTotalPeople <= 15) {
      person.count++;
      this.dataConstructor.setPersonCount(person.type, person.count)
      this.dataConstructor.calculateDailyPrices()
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Maximo 16 personas',
        showConfirmButton: false,
        timer: 2000,
      })
    }
  }

  setCount(person, value) {
    person.count = value;
  }

  decreaseCount(person) {
    if (person.count > 0) {
      person.count--;
      this.dataConstructor.setPersonCount(person.type, person.count)
      this.dataConstructor.calculateDailyPrices()
    }
  }


  range: any = {
    start: this.dataConstructor.date.start,
    end: this.dataConstructor.date.end
  }
  secondDate: any


  hasInitialDate() {
    if (this.initialDate != null) {
      this.secondDate = true
    }
    this.secondDate = false
  }

  @ViewChild('initialPicker') initialPicker: MatDateRangePicker<any>
  @ViewChild('lastPicker') lastPicker: MatDateRangePicker<any>

  oldDates: { start: Date, end: Date } = { start: null, end: null };

  initialCalendar() {
    if (!this.initialPicker.opened) {

      this.initialPicker.open()
    }
  }

  initialDate(event: MatDatepickerInputEvent<Date>) {
    this.oldDates = { start: this.Date.start, end: this.Date.end };
    this.blockedDates = []
    this.hasInitialDate()
    let newDate = new Date(event.value);
    this.Date.start = newDate;
    this.range.start = newDate;
    this.range.end = null
    this.blockedDates = [];
    this.Date.end = ''
    // Bloquear todas las fechas desde la fecha seleccionada hasta el día de hoy
    let dateBefore = new Date();
    while (dateBefore >= newDate) {
      this.blockedDates.push(new Date(dateBefore));
      dateBefore.setDate(dateBefore.getDate() - 1);
    }
    this.blockedDates.push(this.Date.start)

    for (let i = 0; i <= 1; i++) {
      // Clona la fecha para evitar modificar la misma referencia
      let clonedDate = new Date(newDate);
      clonedDate.setDate(clonedDate.getDate() + 1 + i);
      this.blockedDates.push(clonedDate);
    }
    this.dateFilter = (date: Date): boolean => {
      try {
        const formattedDate = date.toISOString().split('T')[0]; // Convierte la fecha a formato 'YYYY-MM-DD'
        const formattedFirstDate = this.Date.start.toISOString().split('T')[0];
        return !this.blockedDates.some(blockedDate => blockedDate.toISOString().split('T')[0] === formattedDate) && formattedDate >= formattedFirstDate;
      }
      catch (error) {
        return false

      }
    }
    this.lastPicker.open()
  }

  lastDateSave(event: MatDatepickerInputEvent<Date>) {
    this.Date.end = event.value
    this.range.end = event.value
    var date = {
      start: this.Date.start,
      end: this.Date.end
    }

    this.dataConstructor.date = date
    this.dataConstructor.calculateDailyPrices()
  }
  get addTwoDays() {
    let date = new Date(this.Date.start);
    date.setDate(date.getDate() + 4)
    return date;
  }

  dateFilter = (date: Date): boolean => {
    try {
      const formattedDate = date.toISOString().split('T')[0]; // Convierte la fecha a formato 'YYYY-MM-DD'
      const formattedFirstDate = this.Date.start.toISOString().split('T')[0];
      return !this.blockedDates.some(blockedDate => blockedDate.toISOString().split('T')[0] === formattedDate) && formattedDate >= formattedFirstDate;
    }
    catch (error) {

      return null
    }
  }

  lastCalendar() {
    if (!this.lastPicker.opened) {
      this.lastPicker.open()

    }
  }

  onPickerClosed() {
    if (!this.range.start || !this.range.end) {
      this.Date.start = this.oldDates.start
      this.Date.end = this.oldDates.end
    }
  }
}
