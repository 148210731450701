import { Component, ViewChild, ElementRef, HostListener, OnInit, Directive, Output, EventEmitter } from '@angular/core'
import { trigger, state, style, animate, transition } from '@angular/animations'
import { MatDateRangePicker, MatDatepickerInputEvent } from '@angular/material/datepicker'
import { Subscription, first } from 'rxjs'
import { ApiService } from 'src/app/api/api.service'
import { Router } from '@angular/router'
import { NavbarServiceService } from '../navbar-service.service'
import Swal from 'sweetalert2'
import { GlobalService } from 'src/app/services/global.service'
import { InsuranceHolder } from 'src/app/models/insurance-holder'
import { GenericInsurance } from 'src/app/models/generic-insurance'
import { FormGroup } from '@angular/forms'

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirectiveDown {

  @Output() clickOutside = new EventEmitter<void>()
  constructor(private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const clickedInside = this.elementRef.nativeElement.contains(target)
    if (!clickedInside) {
      this.clickOutside.emit()
    }
  }
}

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
  animations: [
    trigger('dropdownAnimation', [
      state('void', style({
        height: '0',
        padding: '0',
        overflow: 'hidden',
        'box-shadow': '0 0 0 black',

      })),
      state('*', style({
        height: '110px',
        padding: '1rem',
        overflow: 'hidden',
        'box-shadow': '0 0.4rem black'
      })),
      transition('void => *', animate('0.5s ease-out')),
      transition('* => void', animate('0.5s ease-out'))
    ]),
    trigger('flipHorizontal', [
      state('normal', style({
        transform: 'scaleY(1)'
      })),
      state('flipped', style({
        transform: 'scaleY(-1)'
      })),
      transition('normal <=> flipped', animate('0.5s ease-out'))
    ])
  ]
})

export class NavBarComponent implements OnInit {
  isLoading: boolean = false;
  personDropDown: boolean = false
  initialDate = new Date()
  range!: FormGroup;

  private subscription: Subscription
  lastDateChanged: boolean

  constructor(
    public gs: GlobalService,
    private el: ElementRef,
    private api: ApiService,
    public router: Router,
    public navbarService: NavbarServiceService) {
    this.getCountries()
  }
  
  ngOnInit():void {
    this.navbarService.TravelInfo = false;
    if(!this.gs.currentInsuranceInfo) {
      this.gs.currentInsuranceInfo = new GenericInsurance();
      this.gs.currentInsuranceInfo.holder = new InsuranceHolder();

      this.gs.currentInsuranceInfo.insurance_plan_id = 1;
      this.gs.currentInsuranceInfo.product_code = "BAM_MUVIT_DIARIO";
      this.gs.currentInsuranceInfo.creation_address = "online-page"
      
      this.gs.prices = [];
      this.gs.priceHolder = 0.00;
    }

    this.isLoading = this.gs.isLoading
    this.subscription = this.navbarService.personsChange.subscribe(change => {
      this.editPeople(change.method, change.type, change.value)
    })

    this.gs.range$.subscribe(range => {
      this.range = range;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  Cotizar() {
    this.navbarService.TravelInfo = false;
    if (!this.validateEmail(this.gs.currentInsuranceInfo.holder.email)) {
      this.showAlert('EMAIL INVÁLIDO', 'El correo ingresado es inválido')
      return
    }
    if (this.gs.selectedCountryCode.length === 0) {
      this.showAlert('NO HAY PAISES SELECCIONADOS', 'Selecciona al menos un país de destino')
      return
    }

    if (this.gs.selectedCountryCodeOrigin.length === 0) {
      this.showAlert('NO HAY PAISES SELECCIONADOS', 'Selecciona al menos un país de origen')
      return
    }

    if (this.totalPeople === 0) {
      this.showAlert('NO HAY PERSONAS SELECCIONADAS', 'Selecciona la cantidad de personas a las que quieres asegurar')
      return
    }
    if (!this.datesValidate) {
      this.showAlert('FECHAS INVÁLIDAS', 'La fecha de vuelo ingresada es inválida')
      return
    }

    this.gs.prices = [];
    this.gs.priceHolder = 0.00;

    this.navbarService.isButtonVisible = false;
    this.navbarService.TravelInfo = true;
    this.router.navigate(['/public/plans'], { queryParamsHandling: 'preserve' });
  }

  calculateDayTime() {
    const oneDay = 24 * 60 * 60 * 1000 // horas*minutos*segundos*milliseconds
    const diffDays = Math.round(Math.abs((this.range.value.start.getTime() - this.range.value.end.getTime()) / oneDay))
    return diffDays
  }

  showAlert(title, text) {
    Swal.fire({
      title: title,
      text: text,
      icon: 'error',
      showConfirmButton: true,
      showDenyButton: false,
      denyButtonText: 'Regresar',
      confirmButtonText: 'Aceptar',
      showCloseButton: true,
      timer: 25000
    })
  }

  hasAdult() {
    return this.gs.people.some(people => (people.type === '0 a 64 años' || people.type === '65 a 85 años') && people.count > 0)
  }

  validateEmail(email) {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
    console.log('reges', emailRegex.test(email))
    return emailRegex.test(email)
  }

  @ViewChild('picker') picker: MatDateRangePicker<any>

  openCalendar() {
    if (!this.picker.opened && this.navbarService.isDatePickerEditable) {
      // Guardar las fechas antiguas
      this.gs.oldDates = { start: this.range.value.start, end: this.range.value.end };
      this.gs.blockedDates = []
      this.range.reset()
      this.picker.open();
    }
  }

  onPickerClosed() {
    if (!this.range.value.start || !this.range.value.end && this.gs.oldDates) {
      // Restaurar las fechas antiguas si no se seleccionaron ambas fechas
      this.range.setValue(this.gs.oldDates);
    } else if (this.range.value.start && !this.range.value.end) {
      // Si solo se seleccionó la fecha de inicio, restablecer ambas fechas
      this.range.reset();
    }
  }

  startDateChanged(event: MatDatepickerInputEvent<Date>) {
    const newDate = new Date(event.value);
    this.gs.firstDate = newDate;
    this.range.value.start = newDate;
    this.range.value.end = null;
    this.gs.blockedDates = [];

    const minDate = new Date(newDate);
    minDate.setDate(minDate.getDate() + 3);

    const today = new Date();
    while (today < minDate) {
      this.gs.blockedDates.push(new Date(today));
      today.setDate(today.getDate() + 1);
    }

    for (let i = 0; i <= 1; i++) {
      const clonedDate = new Date(newDate);
      clonedDate.setDate(clonedDate.getDate() + 1 + i);
      this.gs.blockedDates.push(clonedDate);
    }

    this.dateFilter = (date: Date): boolean => {
      try {
        const formattedDate = date.toISOString().split('T')[0];
        const formattedFirstDate = this.gs.firstDate.toISOString().split('T')[0];
        return !this.gs.blockedDates.some(blockedDate => blockedDate.toISOString().split('T')[0] === formattedDate) && formattedDate >= formattedFirstDate;
      } catch (error) {
        return false;
      }
    };

    this.gs.currentInsuranceInfo.travel_starting_date = this.range.value.start
    this.gs.currentInsuranceInfo.date_effective = (this.range.value.start).toISOString().split('T')[0]
  }

  lastDateChange(event: MatDatepickerInputEvent<Date>) {
    if (event.value != null) {
      this.gs.blockedDates = []
      this.dateFilter = null
      this.gs.lastDate = event.value
      this.gs.Days = this.calculateDayTime() + 1
    }

    this.gs.currentInsuranceInfo.days_of_travel = this.gs.Days;
    this.gs.currentInsuranceInfo.travel_end_date = this.range.value.end;
    
    this.gs.setRange(this.range);
    this.gs.changeFinalPrice.emit();
  }

  dateFilter = (date: Date): boolean => {
    try {
      const formattedDate = date.toISOString().split('T')[0]
      return !this.gs.blockedDates.some(blockedDate => blockedDate.toISOString().split('T')[0] === formattedDate)
    }
    catch (error) {
      return false
    }
  }

  onTagAdded(event: any) {
    this.gs.selectedCountryCode = []
    this.gs.selectedCountryCode.push({ code: event.code, kind: event.kind, description: event.description, region: event.region });
    if (this.gs.selectedCountryCode.length >= 1) {
      this.gs.isEditable = false
    }

    this.gs.changeFinalPrice.emit();
  }

  onTagRemoved(event: any) {
    // Elimina el código del país de selectedCountryCode
    const codeIndex = this.gs.selectedCountryCode.indexOf(event.code);
    if (codeIndex > -1) {
      this.gs.selectedCountryCode.splice(codeIndex, 1);
    }

    // Elimina el objeto del país de selectedCountry
    const countryIndex = this.gs.destiny.findIndex(country => country.code === event.code);
    if (countryIndex > -1) {
      this.gs.destiny.splice(countryIndex, 1);
    }

    this.gs.selectedCountryCode = []
  }

  onTagAddedOrigin(event: any) {
    this.gs.selectedCountryCodeOrigin = []
    this.gs.selectedCountryCodeOrigin.push({ code: event.code, kind: event.kind, description: event.description })
    if (this.gs.selectedCountryCodeOrigin.length >= 1) {
      this.gs.isEditable = false
    }
  }

  onTagRemovedOrigin(event: any) {
    const codeIndex = this.gs.selectedCountryCodeOrigin.indexOf(event.code);
    if (codeIndex > -1) {
      this.gs.selectedCountryCodeOrigin.splice(codeIndex, 1);
    }

    const countryIndex = this.gs.origin.findIndex(country => country.code === event.code);
    if (countryIndex > -1) {
      this.gs.origin.splice(countryIndex, 1);
    }

    this.gs.selectedCountryCodeOrigin = []
  }

  personsDropDown() {
    if (this.navbarService.isPersonsEditable == false) {
      this.personDropDown = false
      return
    }
    if (this.personDropDown) {
      this.personDropDown = false

    } else {
      this.personDropDown = true

    }
  }

  get totalPeople() {
    return this.gs.people.reduce((total, person) => total + person.count, 0)
  }

  editPeople(method: string, type: string, value: any) {
    if (method == 'add') {
      for (let i = 0; i < value; i++) {
        this.increaseCount(this.gs.people.find(person => person.type === type))
      }
    } else if (method == 'remove') {
      for (let i = 0; i < value; i++) {
        this.decreaseCount(this.gs.people.find(person => person.type === type))
      }
    } else if (method == 'set') {
      this.setCount(this.gs.people.find(person => person.type === type), value)
    }
  }

  increaseCount(person) {
    if (this.totalPeople <= 15) {
      person.count++
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Maximo 16 personas',
        showConfirmButton: false,
        timer: 20000,
      })
    }
  }

  setCount(person, value) {
    person.count = value
  }

  decreaseCount(person) {
    if (person.count > 0) {
      person.count--
    }
  }

  get getTodayDate() {
    const today = new Date()
    const day = String(today.getDate()).padStart(2, '0')
    const month = String(today.getMonth() + 1).padStart(2, '0') // Los meses en JavaScript empiezan en 0
    const year = today.getFullYear()

    return `${year}-${month}-${day}`
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: Event) {
    const clickedInside = this.el.nativeElement.contains(event.target)
    if (!clickedInside) {
      this.personDropDown = false
    }
  }

  get datesValidate() {
    if (this.range.value.start && this.range.value.end) {
      return true
    } else {
      return false
    }
  }

  normalizeText(text) {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  }

  getCountries() {
    this.api.countries.getAllCountries().promise().then(resp => {
      for (let country of resp) {
        let Country = {
          value: this.normalizeText(country.name), // Normaliza el nombre del país
          code: country.code,
          description: country.name,
          kind: country.kind_city_id,
          region: country.kind_city_name
        }
        this.gs.Countries.push(Country)
      }
    })
  }

  goHome() {
    this.navbarService.isButtonVisible = true;
    this.navbarService.isPriceShowerVisible = false;
    this.router.navigate(['/public/'], { queryParamsHandling: 'preserve' });
  }
}
