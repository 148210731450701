<div class="client-container">
    <app-step-header [activeStep]="2" [movil]="true"></app-step-header>

    <div class="title-header">
        <span class="header-1">Datos requeridos</span>
        <br>
        <span class="header-2">Completa la información de los viajeros</span>
    </div>

    <div class="travelInfo">
        <form action="" [formGroup]="travelInfo">

            <div class="information">
                <label></label>
                <input type="text" formControlName="destination">
            </div>

            <div class="information">
                <label></label>
                <input type="text" formControlName="persons">
            </div>

        </form>
        <div class="information" (click)="initialCalendar()">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none" style="position: relative; top: 28px; right: -10px">
                <path
                    d="M0.401067 8.82916C0.150756 8.54756 0 8.17778 0 7.77102C0 7.67147 0.00853334 7.57476 0.0256 7.48089C0.116622 6.99164 0.426667 6.5792 0.853333 6.35449L1.39093 6.07004C1.62418 5.94773 1.88018 5.88516 2.13902 5.88516C2.4064 5.88516 2.65956 5.95058 2.88142 6.0672L4.70187 7.02293L4.71609 7.03147L4.73031 7.02293L6.95751 5.84818L6.96889 5.84249L6.96036 5.8368L3.49582 3.57262C3.05778 3.28818 2.77049 2.79609 2.77049 2.23289C2.77049 1.61849 3.11751 1.08373 3.62382 0.816356L4.82133 0.184889C5.0432 0.0682667 5.2992 0 5.56942 0C5.77422 0 5.97049 0.0398222 6.14969 0.108089L12.8484 2.72213L12.8626 2.72782L12.874 2.72213L15.8293 1.16053C16.731 0.685511 17.6412 0.443733 18.5344 0.443733C18.6425 0.443733 18.7506 0.446578 18.8558 0.455111C20.0306 0.534756 20.8014 0.944356 21.1484 1.66969C21.4983 2.40071 21.3476 3.3024 20.699 4.352C20.1614 5.21956 19.3479 5.95911 18.3467 6.48818L9.00551 11.4204C8.64142 11.611 8.23467 11.7134 7.8336 11.7134L3.69209 11.7305H3.6864C3.20853 11.7305 2.77902 11.5228 2.48604 11.19L0.401067 8.82916ZM21.3333 14.9362V15.4709C21.3333 15.7668 21.0944 16.0057 20.7986 16.0057H0.534756C0.238933 16.0028 0 15.7639 0 15.4681V14.9333C0 14.6375 0.238933 14.3986 0.534756 14.3986H20.8014C21.0972 14.3986 21.3362 14.6375 21.3362 14.9333L21.3333 14.9362ZM1.60142 7.77102L3.68924 10.1348L7.83076 10.1177C7.97867 10.1177 8.12658 10.0807 8.26311 10.0096L17.6043 5.07733C18.3552 4.68196 18.9554 4.14151 19.3422 3.51289C19.7234 2.89564 19.7774 2.5088 19.7092 2.36373C19.638 2.21298 19.254 2.09067 18.7506 2.05653C18.6795 2.05084 18.6084 2.05084 18.5372 2.05084C17.9058 2.05084 17.2487 2.23004 16.5803 2.58276L12.965 4.49138L5.56658 1.60142L4.36907 2.23289L10.1177 5.98756L4.71893 8.83769L2.13902 7.48373L1.60142 7.76818V7.77102Z"
                    fill="rgba(0, 28, 108, 1)" />
            </svg>
                <input class="formInput" matInput [min]="today" [matDatepicker]="initialPicker"
                placeholder="Selecciona una fecha" readonly 
                (dateChange)="initialDate($event)" [value]="this.Date.start" placeholder="DD/MM/YYYY">
            <mat-datepicker-toggle matSuffix [for]="initialPicker" style="display: none;"></mat-datepicker-toggle>
            <mat-datepicker #initialPicker></mat-datepicker>
        </div>

        <div class="information" (click)="lastCalendar()">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none" style="position: relative; top: 27px; right: -10px">
                <g clip-path="url(#clip0_611_16693)">
                    <path
                        d="M0.530667 7.20802C0.194667 6.92269 0 6.50402 0 6.06402V3.42135C0 2.90935 0.394667 2.01602 1.49867 2.01602C1.74133 2.01602 1.87467 2.06402 2.31467 2.17869C2.77067 2.29869 3.144 2.53069 3.32267 2.96535L4.05867 4.75202L6.456 5.37869L5.408 1.93869C5.36533 1.80002 5.344 1.65335 5.344 1.50135C5.344 1.09869 5.504 0.733352 5.76267 0.464019C6.048 0.165352 6.44267 0.00268555 6.84533 0.00268555C7.096 0.00268555 7.144 0.0293522 8.27467 0.325352C8.68 0.432019 9.016 0.698686 9.21333 1.05869L12.4213 6.93602L15.48 7.73602C16.5013 8.00269 17.384 8.48269 18.0373 9.12802C18.8293 9.90935 19.1333 10.6614 18.9413 11.368C18.752 12.056 18.112 12.552 17.0453 12.8374C15.912 13.1387 14.8373 12.9787 14.1307 12.7947L4.55733 10.2934C4.18133 10.1947 3.82667 10.008 3.54133 9.76535L0.530667 7.20802ZM20 15V15.5014C20 15.7787 19.776 16.0027 19.4987 16.0027H0.501333C0.224 16.0027 0 15.7787 0 15.5014V15C0 14.7227 0.224 14.4987 0.501333 14.4987H19.5013C19.7787 14.4987 20.0027 14.7227 20.0027 15H20ZM1.49867 6.06135L4.512 8.61335C4.632 8.71735 4.77867 8.79469 4.936 8.83469L14.5093 11.336C15.6693 11.6374 16.4453 11.4374 16.6587 11.3814C17.3067 11.208 17.4853 11.008 17.496 10.9627C17.5093 10.9174 17.4587 10.656 16.984 10.1894C16.5173 9.73069 15.8667 9.38135 15.0987 9.18135L11.4133 8.21869L7.89867 1.77602L6.848 1.50135L8.67733 7.50669L2.96 6.01335L1.93867 3.53602L1.50133 3.42135V6.06402L1.49867 6.06135Z"
                        fill="rgba(0, 28, 108, 1)" />
                </g>
                <defs>
                    <clippath id="clip0_611_16693">
                        <rect width="20" height="16" fill="white" />
                    </clippath>
                </defs>
            </svg>
            <input class="formInput" matInput  [min]="this.Date.start + 1" [disabled]="this.Date.start == ''" [matDatepickerFilter]="dateFilter"  [matDatepicker]="lastPicker"
            placeholder="Selecciona una fecha" readonly 
             (dateChange)="lastDateSave($event)" [value]="this.Date.end" 
              [value]="this.Date.end" placeholder="DD/MM/YYYY">
           <mat-datepicker-toggle matSuffix [for]="lastPicker" style="display: none;" ></mat-datepicker-toggle>
           <mat-datepicker #lastPicker [startAt]="addTwoDays" (closed)="onPickerClosed()"></mat-datepicker>
        </div>
    </div>

    <app-travel-info></app-travel-info>

    <div class="content">
        <form #holderform="ngForm">
            <div class="form-holder-content">
                <div class="holder-title">
                    <span>Datos del viajero</span>
                </div>
                
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="name">Nombre(s)*</label>
                            <input id="name" name="name" type="text" class="form-control" required [(ngModel)]="gs.currentInsuranceInfo.holder.first_name"/>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <label for="last-name">Apellido(s)*</label>
                            <input id="last-name" name="last-name" type="text" class="form-control" [(ngModel)]="gs.currentInsuranceInfo.holder.last_name"/>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <label for="genre">Género*</label>
                            <select id="genre" name="genre" class="form-control" [(ngModel)]="gs.currentInsuranceInfo.holder.gender">
                                <option [value]="null" disabled selected> Selecciona una opción </option>
                                <option *ngFor="let option of genreOptions" [value]="option.value">{{option.description}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="birthday">Fecha nacimiento*</label>
                            <input class="form-control" matInput [matDatepicker]="picker" placeholder="Selecciona una fecha" (ngModelChange)="onDateChange($event)"
                            required readonly  id="birthday" name="birthday" (click)="openCalendar()" [max]="maxDate" [min]="minDate" [(ngModel)]="gs.currentInsuranceInfo.holder.birthday">
                            <mat-datepicker-toggle matSuffix [for]="picker" style="display: none;"></mat-datepicker-toggle>
                            <mat-datepicker #picker startView="multi-year"></mat-datepicker>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <label for="passport">No. Pasaporte*</label>
                            <input id="passport" name="passport" type="text" class="form-control" [(ngModel)]="gs.currentInsuranceInfo.holder.identification_id"/>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <label for="country">Pais residencia*</label>
                            <select id="country" name="country" class="form-control" (ngModelChange)="onCountryChange($event)" [(ngModel)]="gs.currentInsuranceInfo.holder.country_id" >
                                <option [value]="null" disabled selected> Selecciona una opción </option>
                                <option *ngFor="let option of countries" [value]="option.value">{{option.description}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group size-margin-bottom">
                            <label for="email">Correo electrónico*</label>
                            <input id="email" name="emial" type="text" class="form-control" [(ngModel)]="gs.currentInsuranceInfo?.holder.email"/>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="row size-margin-bottom">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="prefix">Prefijo* </label>
                                    <select name="prefix" class="form-select" required id="prefix" [(ngModel)]="gs.currentInsuranceInfo.holder.prefix">
                                        <option value="" disabled selected> + --- </option>
                                        <!-- [selected]="option.value === value" -->
                                        <option *ngFor="let option of prefixList" [value]="option.value">+ {{option.value}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-7">
                                <div class="form-group">
                                    <label for="phone"> N&uacute;mero de Tel&eacute;fono* </label>
                                    <input type="phone" name = "phone" class="form-control" required  id="phone" [(ngModel)]="gs.currentInsuranceInfo.holder.phone1">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <label for="cost">Costo Títular</label>
                            <br>
                            <span class="text-accompanist-2">${{gs.priceHolder}}</span>
                        </div>
                    </div>
                </div>
                
                <div class="conditions">
                    <input type="checkbox" [checked]="termsAndConditions" (change)="termsAndConditions = !termsAndConditions">
                    <p style="margin: 0;">Acepto los <span class="conditions-underline"> términos y condiciones </span> de Muvit</p>
                </div>
            </div>

            <div class="accepted-cards">
                <img src="../../../assets/muvitBAM/recomendacion.svg" alt="Tip">

                <span class="text-cards">Puedes realiza tus pagos con tarjetas de crédito o débito</span>
            </div>
            
            <div class="accepted-cards-img">
                <img src="../../../assets/muvitBAM/visa.svg" alt="Visa" class="visa-img">

                <img src="../../../assets/muvitBAM/mastercard.svg" alt="Mastercard">
            </div>

            <div class="accompanists-title">
                <span>Ingrese acompañantes (Opcional)</span>
            </div>

            <div class="accompanists-message">
                <span>Agregar viajeros adicionales es opcional. Ten en cuenta que cada acompañante tiene un costo que depende de su edad, país de origen y destino, y la fecha del viaje.</span>
            </div>

            <div>
                <div *ngFor="let element of dependents; let i = index">
                    <div class="accompanist-content">
                        <div class="section">
                            <div class="sub-section">
                                <span class="text-accompanist-1">Costo por viajero {{ i +1 }}</span>
                                <div matTooltip="El costo de tus acompañantes puede variar según su edad." matTooltipPosition="above">
                                    <img src="../../../assets/muvitBAM/circle-question-help.svg" alt="Info">
                                </div>
                            </div>
                            <span class="text-accompanist-2">${{gs.prices[i]}}</span>
                        </div>

                        <div class="section accompanist-name">
                            {{ element.first_name }} {{ element.last_name }}
                        </div>

                        <div class="section accompanist-age">
                            Edad: {{calcYearsOld(element.birthday)}} años
                        </div>

                        <div class="section accompanist-option group-1">
                            <img src="../../../assets/muvitBAM/editar.svg" alt="Editar">
                            <span (click)="openDialog(i)">Editar</span>
                        </div>

                        <div class="section accompanist-option group-1">
                            <img src="../../../assets/muvitBAM/cerrar.svg" alt="Eliminar">
                            <span (click)="deleteAccompanist(i)">Eliminar</span>
                        </div>

                        <div class="group-2">
                            <div class="sub-section accompanist-option">
                                <img src="../../../assets/muvitBAM/editar.svg" alt="Editar">
                                <span (click)="openDialog(i)">Editar</span>
                            </div>
    
                            <div class="sub-section accompanist-option">
                                <img src="../../../assets/muvitBAM/cerrar.svg" alt="Eliminar">
                                <span (click)="deleteAccompanist(i)">Eliminar</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="accompanists-option">
                <img src="../../../assets/muvitBAM/acompañantes.svg" alt="Agregar">

                <span (click)="openDialog(null)">Añadir acompañantes</span>
            </div>

            <div class="btn-options">
                <button class="btn btn-cancel">
                    Cancelar
                </button>
                
                <button class="btn btn-continue" (click)="goPay()" [disabled]="holderform.invalid || !correctAge || !termsAndConditions">
                    Continuar
                </button>
            </div>
        </form>
    </div>
</div>