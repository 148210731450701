<div>
    <div class="back-content" [ngClass]="{'back-content-mobile': movil}">
        <div class="back-img" (click)="back()">
            <img src="../../../assets/muvitBAM/back.svg" alt="Regresar">
        </div>
        <span class="back-text" (click)="back()">Atrás</span>
    </div>

    <div *ngIf="activeStep != 4">
        <div class="steps-container" *ngIf="!movil">
            <div class="circle" [ngClass]="{'active-step': activeStep == 1, 'completed-step': activeStep > 1}">
                <div class="step">
                    <span *ngIf="activeStep == 1; else completed">1</span>
                    <ng-template #completed>
                        <img src="../../../assets/muvitBAM/ico-check.svg" alt="1">
                    </ng-template>
                </div>

                <div class="text">
                    <span>Elige plan</span>
                </div>
            </div>

            <div class="line" [ngClass]="{'completed-line': activeStep > 1}"></div>

            <div class="circle" [ngClass]="{'active-step': activeStep == 2, 'completed-step': activeStep > 2}">
                <div class="step">
                    <span *ngIf="activeStep <= 2; else completed">2</span>
                    <ng-template #completed>
                        <img src="../../../assets/muvitBAM/ico-check.svg" alt="2">
                    </ng-template>
                </div>

                <div class="text">
                    <span>Información</span>
                </div>
            </div>

            <div class="line"  [ngClass]="{'completed-line': activeStep > 2}"></div>

            <div class="circle" [ngClass]="{'active-step': activeStep == 3}">
                <div class="step">
                    <span *ngIf="activeStep <= 3">3</span>
                </div>

                <div class="text">
                    Pago
                </div>
            </div>
        </div>

        <div class="step-container" *ngIf="movil">
            <div class="step-bar">
                <div class="step-color" [ngClass]="{'complete-2': activeStep == 2, 'complete-3': activeStep == 3}"> </div>
            </div>
            <div class="step-number">
                {{activeStep}} de 3
            </div>
        </div>
    </div>
</div>