<div class="carousel">
  <!-- Indicadores -->
  <div class="carousel-indicators">
    <span 
      *ngFor="let _ of slides; let i = index" 
      [class.active]="i === currentSlide" 
      (click)="goToSlide(i)">
    </span>
  </div>

  <!-- Contenido del carrusel -->
  <div class="carousel-inner">
    <!-- Diapositiva 1 -->
    <div class="carousel-item" [class.active]="currentSlide === 1">
      <div class="carousel-content">
        <h1>Viaja a donde quieras</h1>
        <h1>sin preocupaciones</h1>
        <p>Planes desde 
          <span class="highlighted-text">{{ price  | currency:'$ ':'symbol':'1.2-2' }}
            <span class="highlighted-normal">diarios</span> 
          </span>
        </p>
        <button (click)="openQuoteBox()" class="btn btn-primary">Cotizar</button>
      </div>
      <img src="../../../../assets/muvitBAM/slider/slide1.png" alt="Slide 2">
    </div>

    <!-- Diapositiva 2 -->
    <div class="carousel-item" [class.active]="currentSlide === 2">
      <div class="carousel-content">
        <h1>En Muvit siempre</h1>
        <h1>viajamos contigo</h1>
        <p>Planes desde
          <span class="highlighted-text">{{ price  | currency:'$ ':'symbol':'1.2-2' }}
            <span class="highlighted-normal">diarios</span> 
          </span>
        </p>
      </div>
      <img src="../../../../assets/muvitBAM/slider/slide2.png" alt="Slide 3">
    </div>

    <!-- Diapositiva 3 -->
    <div class="carousel-item" [class.active]="currentSlide === 0">
      <div class="carousel-content">
        <h1>Te protegemos</h1>
        <h1>en todo momento</h1>
        <p>Planes desde
          <span class="highlighted-text">{{ price  | currency:'$ ':'symbol':'1.2-2' }}
            <span class="highlighted-normal">diarios</span> 
          </span>
        </p>
      </div>
      <img src="../../../../assets/muvitBAM/slider/slide3.png" alt="Slide 1">
    </div>
  </div>

  <!-- Controles -->
  <button class="carousel-control-prev" (click)="prevSlide()"></button>
  <button class="carousel-control-next" (click)="nextSlide()"></button>
</div>
