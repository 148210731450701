// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  recaptcha: {
    siteKey: '6Lcfu1MeAAAAAE3o1IYH5G7H2ckirji0pMm2tR2w',
  },
  s2Url: "https://policy-integrator-dev.appspot.com",

  //Google tag manager
  tagsManagers: {
    
  },

  //Googel analytics id
  analyticsIds: {
    
  },

  //Google ads id
  adsIds: {
  },

  //Facebook
  facebookIds: {
  },
  countryGT: 1
};