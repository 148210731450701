export class InsuranceAccompanist {
    identification_id?: string;
    identification_type?: number;
    name?:string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    last_name_2?: string;
    birthday?: any;
    gender?: any;
    relationship?:any;
    age?: any;
    units?:number;
    address?:string;
}
