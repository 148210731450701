import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { Meta, Title } from '@angular/platform-browser';
import { GenericInsurance } from 'src/app/models/generic-insurance';
import { InsuranceHolder } from 'src/app/models/insurance-holder';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css'
]
})
export class ThankYouComponent implements OnInit {
  private router :Router
  initialDate: any;
  finalDate: any;

  range0_64 = {'quantity': 0, 'price': 0.00}
  range65_85 = {'quantity': 0, 'price': 0.00}
  totalPrice = 0.00;
  destiny = '';

  constructor(
    public gs: GlobalService,
    private injector: Injector,
    private titleService: Title,
    private fb: FormBuilder,
  ) {
    this.router = this.injector.get<Router>(Router);
    this.destiny = this.gs.destiny[0].value;
    this.totalPrice = this.gs.totalPrice;

    let age = this.calcYearsOld(this.gs.currentInsuranceInfo.holder.birthday);
    if(age < 65){
      this.range0_64['quantity'] += 1;
      this.range0_64['price'] += this.gs.priceHolder;
    } else {
      this.range65_85['quantity'] += 1;
      this.range65_85['price'] += this.gs.priceHolder;
    }

    if(this.gs.currentInsuranceInfo.accompanisttable?.length) {
      for (let i = 0; i < this.gs.currentInsuranceInfo.accompanisttable.length; i++) {
        this.gs.currentInsuranceInfo.accompanisttable[i].address = this.gs.currentInsuranceInfo.holder.address;
        age = this.calcYearsOld(this.gs.currentInsuranceInfo.accompanisttable[i].birthday);
        if(age < 65){
          this.range0_64['quantity'] += 1;
          this.range0_64['price'] += this.gs.prices[i];
        } else {
          this.range65_85['quantity'] += 1;
          this.range65_85['price'] += this.gs.prices[i];
        }
      }
    }
   }

  ngOnInit():void {
    if (!("travel_starting_date" in this.gs.currentInsuranceInfo)) {
      this.router.navigate([this.gs.currentSiteUrl], { queryParams: { step: null }, queryParamsHandling: 'merge'});
    }
    this.titleService.setTitle("Muvit - Thanks");

    this.initialDate = this.convertirFecha(this.gs.currentInsuranceInfo.travel_starting_date);
    this.finalDate = this.convertirFecha(this.gs.currentInsuranceInfo.travel_end_date);

    this.gotoTop();

    this.gs.currentInsuranceInfo = new GenericInsurance();
    this.gs.currentInsuranceInfo.holder = new InsuranceHolder();

    this.gs.currentInsuranceInfo.insurance_plan_id = 1;
    this.gs.currentInsuranceInfo.product_code = "BAM_MUVIT_DIARIO";
    this.gs.currentInsuranceInfo.creation_address = "online-page";

    this.gs.prices = [];
    this.gs.priceHolder = 0.00;
    this.gs.totalPrice = 0.00;
    this.gs.destiny = [];
    this.gs.origin = [];
    this.gs.email = '';
    this.gs.selectedCountryCode = []
    this.gs.selectedCountryCodeOrigin = []

    const newRange = this.fb.group({
      start: [''], // Valor inicial vacío
      end: ['']    // Valor inicial vacío
    });
    this.gs.setRange(newRange);

    this.gs.people = [
      { type: '0 a 64 años', count: 0 },
      { type: '65 a 85 años', count: 0 },
    ]
  }
  
  print() {
    let country = "ES"
    window.open(`api/documentMuvit/${this.gs.policyNumber}/${this.gs.transactionNumber}/${country}`,'_blank')
  }

  returnHome(){
    this.router.navigate(['/public'], { queryParamsHandling: 'preserve' });
  }

  gotoTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  convertirFecha(fecha: any) {
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const fechaConvertida = new Date(fecha);
    const dia = fechaConvertida.getDate();
    const mes = meses[fechaConvertida.getMonth()];
    const ano = fechaConvertida.getFullYear();
    return `${dia} ${mes} ${ano}`;
  }

  calcYearsOld(birthday){
    const today = new Date();
    let age = today.getFullYear() - birthday.getFullYear();
    const monthDifference = today.getMonth() - birthday.getMonth();
    
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }

    return age;
  }
}
