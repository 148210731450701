<div>
  <div style="position:absolute; top: 5px; left: 5px; " style="cursor: pointer; width: 3%;" (click)="close()">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-lg pink" viewBox="0 0 20 20">
      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
    </svg>
  </div>  
  <div style="margin-top: 50px; place-items: center;">
    <form #changeCurLan="ngForm" (ngSubmit)="onSubmit()">
      <div class="center-content group-spacing">
        <div class="container-left">
          <p class="control-label form-control-space" for="cardNumber" >Idioma</p>
          <mat-select class="form-control" id="lan" required [(ngModel)]="lan" name="lang" style="font-family: 'rubik';" #lang="ngModel" >
            <!-- <mat-option [value]="null">País</mat-option> -->
            <mat-option *ngFor="let language of languages" [value]=language> {{language.viewValue}}</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="center-content group-spacing">
        <div class="container-left">
          <p class="control-label form-control-space" for="cardNumber" >Moneda</p>
          <mat-select class="form-control" id="cur" required [(ngModel)]="cur" name="curr" style="font-family: 'rubik';" #curr="ngModel" >
            <!-- <mat-option [value]="null">País</mat-option> -->
            <mat-option *ngFor="let currency of currencies" [value]=currency> {{currency.viewValue}}</mat-option>
          </mat-select>
        </div>
      </div>
      <div style="text-align: end; padding-top: 11px;">
        <button class="btn continue-button" type="submit" [disabled]="changeCurLan.form.invalid">Cerrar</button>
      </div>
    </form>
  </div>
</div>
