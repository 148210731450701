<h2 mat-dialog-title>Atención</h2>
<mat-dialog-content>
  <img class="warningx" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/warning.svg" alt="...">
  <p *ngIf="isString(warningMessage)">{{warningMessage}}</p>
  <ul *ngIf="!isString(warningMessage)">
    <li *ngFor="let item of warningMessage">{{ item }}</li>
  </ul>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-dialog-close class="close-button">Cerrar</button>
</mat-dialog-actions>
