<div class="container-fluid">
  {{title.setTitle("Muvit - Terminos y Condiciones")}}
  <div class="row">
    <div class="row" class="tosHeader">
      <p class="headerTitle">Términos y condiciones</p>
    </div>
    <div class="row" style="padding: 5% 10% 0% 10%; justify-content: center;">
      <p class="tosTitle"><b>Términos y condiciones Muvit</b></p>
      <p class="tosContent">Por favor, lea estos términos y condiciones cuidadosamente antes de suscribirse a nuestro newsletter. Al hacer clic en "Suscribirse" o aceptar nuestra solicitud de suscripción, usted acepta y reconoce que ha leído, entendido y aceptado estos términos y condiciones en su totalidad.</p>
    </div>
  </div>
  <hr>
  <div class="row">
    <!-- <div class="col-12 col-sm-3 sidebar">
      <ul id="menu">
        <li><a (click)="Navigate('section-1')">1. Suscripción</a></li>
        <li><a (click)="Navigate('section-2')">2. Consentimiento y privacidad</a></li>
        <li><a (click)="Navigate('section-3')">3. Derechos de propiedad intelectual</a></li>
        <li><a (click)="Navigate('section-4')">4. Cancelación de la suscripción</a></li>
        <li><a (click)="Navigate('section-5')">5. Modificaciones</a></li>
        <li><a (click)="Navigate('section-6')">6. Protección de datos personales</a></li>
        <li><a (click)="Navigate('section-7')">7. Derechos de los interesados</a></li>
        <li><a (click)="Navigate('section-8')">8. Limitación de responsabilidad</a></li>
      </ul>
    </div> -->
    <!-- <div class="col-12 col-sm-9"> -->
    <div class="col-12" style="padding: 3% 10% 3% 10%; ">
      <section id="section-1">
        <p class="titles"><b>1. Suscripción</b></p>
        <p class="tosContent">Al suscribirse a nuestro newsletter, usted acepta recibir nuestras comunicaciones periódicas que incluyen contenido promocional, noticias, actualizaciones, ofertas y otra información relevante relacionada con nuestro negocio.</p>
      </section>
      <section id="section-2">
        <p class="titles"><b>2. Consentimiento y privacidad</b></p>
        <p class="tosContent">Al proporcionarnos su información personal, usted nos otorga su consentimiento para recopilar, almacenar y utilizar sus datos para los fines descritos en estos términos y condiciones. Nos comprometemos a utilizar sus datos de manera responsable y de conformidad con las leyes y regulaciones de privacidad de la Unión Europea, incluyendo el Reglamento General de Protección de Datos (RGPD).</p>
      </section>
      <section id="section-3">
        <p class="titles"><b>3. Derechos de propiedad intelectual</b></p>
        <p class="tosContent">Todo el contenido del newsletter, incluyendo, pero no limitado a texto, imágenes, gráficos, logotipos y diseños, está protegido por derechos de propiedad intelectual y es propiedad exclusiva de nuestro negocio. Usted acepta no utilizar, reproducir, modificar, distribuir o transmitir el contenido del newsletter sin nuestro consentimiento expreso por escrito.</p>
      </section>
      <section id="section-4">
        <p class="titles"><b>4. Cancelación de la suscripción</b></p>
        <p class="tosContent">Usted puede cancelar su suscripción a nuestro newsletter en cualquier momento haciendo clic en el enlace de cancelación de la suscripción que se encuentra en la parte inferior de cada correo electrónico que le enviamos. También puede contactarnos directamente para solicitar la cancelación de su suscripción.</p>
      </section>
      <section id="section-5">
        <p class="titles"><b>5. Modificaciones</b></p>
        <p class="tosContent">Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento sin previo aviso. Siempre publicaremos la versión actualizada de los términos y condiciones en nuestro sitio web, por lo que le recomendamos que los revise regularmente.</p>
      </section>
      <section id="section-6">
        <p class="titles"><b>6. Protección de datos personales</b></p>
        <p class="tosContent">Nos comprometemos a proteger su privacidad y los datos personales que nos proporciona en virtud de los términos y condiciones de esta suscripción. Su información será tratada de forma confidencial y solo será utilizada para los fines descritos en estos términos y condiciones. Nos comprometemos a tomar medidas técnicas y organizativas adecuadas para proteger sus datos personales contra el acceso no autorizado o ilegal, la pérdida, la destrucción o el daño accidental.</p>
      </section>
      <section id="section-7">
        <p class="titles"><b>7. Derechos de los interesados</b></p>
        <p class="tosContent">De acuerdo con el RGPD, usted tiene ciertos derechos con respecto a sus datos personales. Usted tiene derecho a acceder, rectificar, cancelar y oponerse al procesamiento de sus datos personales, así como a la portabilidad de los mismos. Si desea ejercer alguno de estos derechos, por favor contáctenos.</p>
      </section>
      <section id="section-8">
        <p class="titles"><b>8. Limitación de responsabilidad</b></p>
        <p class="tosContent">No nos hacemos responsables por ningún daño, pérdida o perjuicio que pueda sufrir como resultado de su suscripción a nuestro newsletter o de cualquier interrupción en el servicio de newsletter.
          Al hacer clic en "Suscribirse" o aceptar nuestra solicitud de suscripción.</p>
      </section>
    </div>
  </div>
</div>
<div class="row"  style="margin: 0;">
  <div class="footer_v2">
    <div class="row">
      <div class="col-12 col-sm-4 footer-column-center" style="margin-top: 25px;">
        <img class="footer-logo_v2" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit_logo.svg" alt="muvit logo" style="cursor: pointer;" routerLink="./public/muvit">
      </div>
      <div class="col-12 col-sm-8 footer-column-end" style="margin-top: 25px;">
        <button class="btn footer-button" id="btnfacebook1">
          <a href="https://www.facebook.com/muvit.travel" target='_blank' id="btnfacebook2">
            <img class="icon-color_v2" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/facebook (1).svg" alt="facebook" id="btnfacebook3">
          </a>
        </button>
        <button class="btn footer-button" id="btninstagram1">
          <a href="https://www.instagram.com/muvit.travel/?utm_source=google&utm_medium=banner&utm_campaign=visitas_trafico_icono_pagina_web" target='_blank' id="btninstagram2">
            <img class="icon-color_v2" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/instagram.svg" alt="instagram" id="btninstagram3">
          </a>
        </button>
        <button class="btn footer-button" id="btnyoutube1">
          <a href="https://bit.ly/44Kmw4T" target='_blank' id="btnyoutube2">
            <img class="icon-color_v2" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/youtube.svg" alt="youtube" id="btnyoutube3">
          </a>
        </button>
        <button class="btn footer-button" id="btntiktok1">
          <a href="https://www.tiktok.com/@muvit.travel?_t=8atH4Jl8OFq&_r=1" target='_blank' id="btntiktok2">
            <img class="icon-color_v2" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/tiktok.svg" alt="tiktok" id="btntiktok3">
          </a>
        </button>
        <button class="btn footer-button" id="shareButton" (click)="openShareDialog()">
          <img class="icon-color_v2" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/share_.svg" alt="share link" id="shareButtonImg">
        </button>
      </div>
    </div>
    <div class="row" style="margin-top: 15px; justify-content: end;">
      <div class="col-12 social-media_v2">
        <p class="p-footer">9a. Avenida 19-61 zona 10, nivel 11. </p>
        <p class="p-footer p-terms">
          <a class="a-footer" (click) = "showTosPage()" routerLinkActive="active" rel="bookmark" target="_blank">
            <span>Términos y condiciones</span></a>
        </p>
        <p class="p-footer">Muvit 2023. Todos los derechos reservados</p>
      </div>
    </div>
  </div>
</div>