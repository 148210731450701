import { Component } from '@angular/core';
import { DataConstructorService } from '../../services/data-constructor.service';
import { ApiService } from 'src/app/api/api.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-policy-dialog-desktop',
  templateUrl: './policy-dialog-desktop.component.html',
  styleUrls: ['./policy-dialog-desktop.component.css']
})
export class PolicyDialogDesktopComponent { 
  planType: string = 'Anual'
  dates={
  start: '',
  final: ''
}
convertirFecha(fecha:any) {
  const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  const fechaConvertida = new Date(fecha);
  const dia = fechaConvertida.getDate();
  const mes = meses[fechaConvertida.getMonth()];
  const ano = fechaConvertida.getFullYear();
  return `${dia} ${mes} ${ano}`;
}

downloadPolicy(){
    window.open(`api/documentTecniseguros/${this.dataConstructor.policy_number}/${this.dataConstructor.transaction}`,'_blank')
}


constructor(public dataConstructor:DataConstructorService, private api: ApiService, private activatedRoute: ActivatedRoute, private title: Title){ 
  this.title.setTitle('Tecniseguros - Agradecimiento')
  if(this.dataConstructor.typePlan == 'ViajeCompleto'){
    this.planType = 'Viajero'
  }else if(this.dataConstructor.typePlan == 'Anual'){
    this.planType = 'Anual'
  }

 this.dates = {
   start: this.convertirFecha(this.dataConstructor.date.start),
   final: this.convertirFecha(this.dataConstructor.date.end)
 }

}
}
