import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { Subject } from 'rxjs';
import { DataConstructorService } from '../services/data-constructor.service';

@Injectable({
  providedIn: 'root'
})
export class NavbarServiceService {
  isNavbarVisible = true
  isEmailVisible = true
  isCountriesVisible = true
  isPersonsVisible = true
  isPersonsEditable = true
  isDatePickerVisible = true
  isDatePickerEditable = true
  TravelInfo:any = false;
  isPlanSelectorVisible = false
  isButtonVisible = true
  isPriceShowerVisible = false
  selectedPlan:any
  personsChange = new Subject<{ method: string, type: string, value: any }>();
  email: any
  emitPersonsChange(method: string, type: string, value: any) {
    this.personsChange.next({ method, type, value });
  }
  constructor(private router: Router, private dataConstructor: DataConstructorService) { 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateNavbarVisibility();
      }
    });
  }

  private updateNavbarVisibility(){
    const hiddenRoutes = ['/public/terms-and-conditions','/public/contact-us', '/public/mobile/terms-and-conditions', '/public/mobile/contact-us', '/public/FAQ', '/public/muvit-blog', '/public/payment', '/public/thank-you'];
    const currentRoute = this.router.url;
    this.isNavbarVisible = !hiddenRoutes.includes(currentRoute)
  }
}
