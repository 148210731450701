import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-card-phone-select',
  templateUrl: './card-phone-select.component.html',
  styleUrls: ['./card-phone-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CardPhoneSelectComponent),
      multi: true
    }
  ]
})
export class CardPhoneSelectComponent {
  @Input() Title: any;
  @Input() InputTitle: any;
  @Output() inputChange = new EventEmitter<any>()
  @Input() Options: any;
  @Input() isRequired: boolean;
  @Output() valueChanged = new EventEmitter<any>();


  value: any;

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any): void {
    this.value = value;
    this.onChange(this.value);
    this.onTouch();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Implement if needed
  }

  onSelectChange(event: any): void {
    const selectedValue = event.target.value;
    this.value = selectedValue;
    this.onChange(this.value);
    this.onTouch();
    // Emitir el valor directamente en el evento
    this.valueChanged.emit(selectedValue);
  }

  onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.value.length >= 4) {
      this.inputChange.emit(input.value);
    }
  }
}



