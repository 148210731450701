import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { MatDateRangePicker, MatDatepickerInputEvent } from '@angular/material/datepicker'
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from 'src/app/api/api.service'
import Swal from 'sweetalert2'
import { LiveAnnouncer } from '@angular/cdk/a11y'
import { DataConstructorService } from 'src/app/services/data-constructor.service'
import { first } from 'rxjs'
import { Title } from '@angular/platform-browser'

@Component({
  selector: 'app-step-form',
  templateUrl: './step-form.component.html',
  styleUrls: ['./step-form.component.css']
})
export class StepFormComponent {
  Step: number
  stepName = 'Correo'
  today: Date = new Date(); 
  userEmail = ''
  selectedCountry: any

  firstDate: any
  lastDate: any

  selectedCountryCode: any[] = []
  isEditable: boolean = true
  Countries: Country[] = []
  countries: string[] = ['Lemon']
  blockedDates = []
  Days: any

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  })

  @ViewChild('countryInput') countryInput: ElementRef<HTMLInputElement>
  announcer = inject(LiveAnnouncer)

  handleInput(event: Event) {
    this.userEmail = (event.target as HTMLInputElement).value.toLowerCase()
  }
  minReturnDate: Date;

  initialDate(event: MatDatepickerInputEvent<Date>) {
    this.hasInitialDate();
    let newDate = new Date(event.value);
    this.firstDate = newDate;
    this.blockedDates = [];
    this.lastDate = '';
    

    this.minReturnDate = new Date(this.firstDate);
    this.minReturnDate.setDate(this.firstDate.getDate() + 3);
  
    let today = new Date();
    today.setHours(0, 0, 0, 0); 
    let dateBefore = new Date(today);
    while (dateBefore < today) {
      this.blockedDates.push(new Date(dateBefore));
      dateBefore.setDate(dateBefore.getDate() + 1);
    }
  
    this.blockedDates.push(this.firstDate); 
  }
  calculateDayTime() {
    const oneDay = 24 * 60 * 60 * 1000 // horas*minutos*segundos*milliseconds
    const diffDays = Math.round(Math.abs((this.lastDate.getTime() - this.firstDate.getTime()) / oneDay))
    return diffDays
  }



  lastDateSave(event: MatDatepickerInputEvent<Date>) {
    if(event.value != null){
      this.lastDate = event.value
      this.Days = this.calculateDayTime() + 1
    }

    var date = {
      start: this.firstDate,
      end: this.lastDate
    }

    this.dataConstructor.date = date

  }


  get addTwoDays() {
    let date = new Date(this.firstDate);
    date.setDate(date.getDate() + 3)
    return date;
  }

  dateFilter = (date: Date): boolean => {
    try {
      const formattedDate = date.toISOString().split('T')[0]
      return !this.blockedDates.some(blockedDate => blockedDate.toISOString().split('T')[0] === formattedDate)
    }
    catch (error) {
      return false
    }
  }


  hasAdult() {
    return this.persons.some(person => (person.type === 'Adulto' || person.type === 'Adulto Mayor') && person.count > 0)
  }

  datesStep() {
    if (this.firstDate && this.lastDate) {
      this.nextStep()
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Selecciona una fecha',
        showConfirmButton: true,
        timer: 6000,
      })
    }
  }


  peopleStep() {

    if (this.getTotalPeople > 0) {
      for (let person of this.persons) {
        this.dataConstructor.setCountMobile(person.type, person.count)
      }

      this.nextStep()
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Selecciona al menos una persona',
        showConfirmButton: true,
        timer: 6000,
      })
    }
  }

  persons = [
    { type: 'Adulto', count: 0, description: '18 a 64 años' },
    { type: 'Niño', count: 0, description: '0 a 17 años' },
    { type: 'Adulto Mayor', count: 0, description: '65 a 85 años' }
  ]

  constructor(private api: ApiService, private router: Router, private builder: FormBuilder, private dataConstructor: DataConstructorService, private activatedRoute: ActivatedRoute, private title: Title) {

    this.title.setTitle(this.activatedRoute.title['destination']['_value']['title'])
    this.getCountries()
    this.dataConstructor.isTotalVisible = false
    for (let person of this.persons) {
      var personType = this.dataConstructor.persons.find(personType => personType.type == person.type)
      person.count = personType.count
    }

    this.Step = this.dataConstructor.lastStep
    this.firstDate = this.dataConstructor.date.start
    this.lastDate = this.dataConstructor.date.end
    this.userEmail = this.dataConstructor.email
    this.selectedCountryCode = this.dataConstructor.countries
  }




  onTagAdded(event: any) {
    this.selectedCountryCode = []
    this.selectedCountryCode.push({ code: event.code, kind: event.kind, description: event.description })
    if (this.selectedCountryCode.length >= 1) {
      this.isEditable = false
    }
    this.dataConstructor.countries = this.selectedCountryCode
  }



  getCountries() {
    this.api.countries.getAllCountries().promise().then(resp => {
      for (let country of resp) {
        let Country = {
          value: this.normalizeText(country.name), // Normaliza el nombre del país
          code: country.code,
          description: country.name,
          kind: country.kind_city_id         
        }
        this.Countries.push(Country)
      }
    })
  }



  normalizeText(text) {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
  }

  @ViewChild('initialPicker') initialPicker: MatDateRangePicker<any>
  @ViewChild('lastPicker') lastPicker: MatDateRangePicker<any>

  initialCalendar() {
    if (!this.initialPicker.opened) {
      //bloquear fechas anteriores a hoy
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      this.blockedDates = []
      let dateBefore = new Date()
      while (dateBefore < today) {
        this.blockedDates.push(new Date(dateBefore))
        dateBefore.setDate(dateBefore.getDate() + 1)
      }
      this.initialPicker.open()
    }
  }

  lastCalendar() {
    if (!this.lastPicker.opened) {
      let minDate = new Date(this.firstDate);
      minDate.setDate(minDate.getDate() + 3);
      this.lastPicker.startAt = minDate;  
      this.lastPicker.open();
    }
  }


  saveCountries() {
    if (this.selectedCountryCode.length > 0) {
      this.dataConstructor.countries = this.selectedCountryCode
      this.nextStep()
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Selecciona un destino',
        showConfirmButton: true,
        timer: 6000,
      })

    }

  }


  updateTitle() {
    const stepNames = ['Correo', 'Destino', 'Personas', 'Fechas']
    if (this.Step >= 1 && this.Step <= 4) {
      this.stepName = stepNames[this.Step - 1]
    }
  }

  stepBack() {

    if (this.Step == 1) {
      this.router.navigate(['/public/mobile'], { queryParamsHandling: 'preserve' });
    }
    this.Step--
    this.updateTitle()
  }

  nextStep() {
    if (this.Step == 4) {
      this.dataConstructor.countries = this.selectedCountryCode
      this.dataConstructor.persons = this.persons
      this.dataConstructor.daysQuantity = this.Days
      this.dataConstructor.calculateDailyPrices()
      this.router.navigate(['/public/mobile/plans'], { queryParamsHandling: 'preserve' });
    }
    else {
      this.Step++
      this.dataConstructor.lastStep = this.Step
      this.updateTitle()
    }


  }

  get getTotalPeople() {
    var total = 0
    for (let person of this.persons) {
      total = total + person.count
    }

    return total
  }
  secondDate: any
  hasInitialDate() {
    if (this.initialDate != null) {
      this.secondDate = true
    }
    this.secondDate = false
  }

  increaseCount(person) {
    if (this.getTotalPeople <= 15) {
      person.count++
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Maximo 16 personas',
        showConfirmButton: true,
        timer: 6000,
      })
    }
  }
  decreaseCount(person) {
    if (person.count > 0) {
      person.count--
    }
    var adult = this.persons.find(persons => persons.type == 'Adulto')
    var child = this.persons.find(persons => persons.type == 'Niño')
    var adultM = this.persons.find(persons => persons.type == 'Adulto Mayor')
    if (adult.count == 0 && adultM.count == 0) {
      this.setCount(child, 0)
    }
  }

  setCount(person, value) {
    person.count = value
  }

  registerEmail() {
    const emailPattern = /[\w-\.]+@([\w-]+\.)+[\w-]{2,4}/
    if (emailPattern.test(this.userEmail.toLowerCase())) {
      this.dataConstructor.email = this.userEmail
      this.dataConstructor.isTotalVisible = false
      this.dataConstructor.createLead('Mobile');
      this.nextStep()
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Email Invalido',
        text: 'El email ingresado no es correcto',
        showConfirmButton: true,
        confirmButtonText: 'Entendido'
      })
    }
  }


}



export interface Country {
  value: string
  code: string
  description: string
}