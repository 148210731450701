import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, merge } from 'rxjs';
import { map, filter, debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImageLoadingService {
  public areImagesLoaded = new BehaviorSubject(false);

  constructor() { }

  checkImagesLoaded(element: HTMLElement) {
    const images = Array.from(element.getElementsByTagName('img'));
    if (images.length === 0) {
      this.areImagesLoaded.next(true);
      return;
    }

    const loadEvents = images.map(image => fromEvent(image, 'load'));
    merge(...loadEvents)
      .pipe(
        debounceTime(500),
        map(() => images.every(image => image.complete)),
        filter(isComplete => isComplete)
      )
      .subscribe(() => this.areImagesLoaded.next(true));
  }
}




