
<div class="benefits-container">
    <div class="row" style="align-items: center; text-align: center;">
        <div class="col">
            <img src="../../../../assets/tecniseguros/movil/maletas.png" style="gap: 20px; padding: 5px" width="252px" height="211px" alt="Maletas">
        </div>
        <div class="col">
            <img src="../../../../assets/tecniseguros/movil/hotel.png" style="gap: 20px; padding: 5px" width="252px" height="211px" alt="Hotel">
        </div>
        <div class="col">
            <img src="../../../../assets/tecniseguros/movil/viaje.png" style="gap: 20px; padding: 5px" width="252px" height="211px" alt="Viaje">
        </div>
        <div class="col">
            <img src="../../../../assets/tecniseguros/movil/salud.png" style="gap: 20px; padding: 5px" width="252px" height="211px" alt="Salud">
        </div>
    </div>
</div>
<div class="benefits-container-text">
    <h3>En tu seguro de viaje, puedes agregar como acompañantes únicamente cónyugue o hijos*</h3>
</div>
