import * as moment from 'moment';

export class InsuranceHolder{
    name?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    last_name_2?: string;
    married_name?: string;
    gender?: number;
    identification_type?: number;
    identification_id?: string;
    relationship_status?: number;
    occupation?: string;
    profession?: string;
    education_center?: string;
    pupilage?: number;
    birthday?: any;
    nationality?: number;
    address?: string;
    country_id?: number;
    state_id?: number;
    state_district_id?: number;
    mobile?: string;
    area_code?: number;
    phone1?: string;
    phone2?: string;
    email?:string;
    age?: any;
    nit?: string;
    reference?: any;
    reference_code?: string;
    province_code?:string;
    canton_code?:string;
    district_code?:string;
    working_years? : number;
    working_months? : number;
    working_place? : string;
    temporal_creation_address? : { name : string, value : string };
    departamentCode? : number;
    municipalityCode? : number;
    cityCode? : number;

    weight_kg? : string;
    weight_g? : string;
    height_mts? : string;
    height_cms? : string;

    travel_starting_date?: any;
    travel_end_date?: any;
    email_confirm?:string;
    airline?:string;
    isProspect?:boolean;
    destiny?:string;
    origin?:string;
    destiny_?:any;
    origin_?:any;

    seller_mail?: string;
    emergencyContactName?: string;
    emailCNC?: string;
    prefix?: string;

    customer_sponsor_code?:any;

    setFullNameByParts(){
        let full_name = this.first_name.trim()

        const names = [this.middle_name, this.last_name, this.last_name_2]

        names.forEach(name => {
            if (name != null && name.length > 0) full_name = full_name + " " + name.trim()
        });

        this.name = full_name;
    }

    calcYearsOld(){
        if(!this.birthday){
            return 0;
        }

        const insuredBirthday = moment(this.birthday);

        return Math.abs(insuredBirthday.diff(moment(), 'years'));
    }
    units?: number;
}
