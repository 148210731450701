import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-card-select',
  templateUrl: './card-select.component.html',
  styleUrls: ['./card-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CardSelectComponent),
      multi: true
    }
  ]
})
export class CardSelectComponent implements ControlValueAccessor {
  @Input() Title: any;
  @Input() Options: any;
  @Input() isRequired: boolean;
  @Output() valueChanged = new EventEmitter<any>();


  value: any;

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any): void {
    this.value = value;
    this.onChange(this.value);
    this.onTouch();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Implement if needed
  }

  onSelectChange(event: any): void {
    const selectedValue = event.target.value;
    this.value = selectedValue;
    this.onChange(this.value);
    this.onTouch();
    // Emitir el valor directamente en el evento
    this.valueChanged.emit(selectedValue);
  }
  
}
