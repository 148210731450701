import * as moment from 'moment';
export class InsuranceInsured{
    name?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    last_name_2?: string;
    married_name?: string;
    gender?: number;
    identification_type?: number;
    identification_id?: string;
    relationship_status?: number;
    occupation?: string;
    profession?: string;
    education_center?: string;
    pupilage?: number;
    birthday?: any;
    nationality?: number;
    address?: string;
    country_id?: number;
    state_id?: number;
    state_district_id?: number;
    mobile?: string;
    phone1?: string;
    email?:string;
    province_code?:string;
    canton_code?:string;
    district_code?:string;

    setFullNameByParts(){
        let full_name = this.first_name.trim()

        const names = [this.middle_name, this.last_name, this.last_name_2]

        names.forEach(name => {
            if (name != null && name.length > 0) full_name = full_name + " " + name.trim()
        });

        this.name = full_name;
    }

    calcYearsOld(){
        if(!this.birthday){
            return 0;
        }

        const insuredBirthday = moment(this.birthday);

        return Math.abs(insuredBirthday.diff(moment(), 'years'));
    }
    units?: number;
}
