<div class="spinner_container" *ngIf="showLoader">
  <mat-progress-spinner class="spinner" [mode]="'indeterminate'" [value]="50"></mat-progress-spinner>
</div>
<div>
  <div *ngIf="showStep1" id="home-page" class="main-div" style="align-items: flex-start;
  flex-direction: column;
  display: contents;">
    {{titleService.setTitle("Muvit - Home")}}
    <div [ngClass]="{'background-image': !gs.discount, 'background-image-desc': gs.discount}" *ngIf="!showSide">
      <mat-icon class="hidden-mobile" style="height: 50px;
      width: 50px; position: absolute; bottom: 10px; left: 0px; right: 0px; margin: auto;">
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" stroke-width="10" class="bi bi-chevron-down" viewBox="0 0 16 16" style="color: white; display:none;">
          <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
        </svg>
      </mat-icon>
    </div>
    <div class="hamburger-menu" style="display: none;">
      <button class="menu-toggle" (click)="toggleMenu()">
        <span class="icon">
          <span class="bar" *ngIf="!isMenuActive"></span>
          <span class="bar" *ngIf="!isMenuActive"></span>
          <span class="bar" *ngIf="!isMenuActive"></span>
          <span class="close-icon" *ngIf="isMenuActive">X</span>
        </span>
      </button>
      <div class="menu" [ngClass]="{'active': isMenuActive}">
        <div class ="menu-container">
        <ul class="menu-list">
          
          <li>
            <span class="arrow-icon2">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
                <path d="M5 12h14M12 5l7 7-7 7" />
              </svg>
            </span>
            <a class="menu-element" (click) = "showTosPage()" routerLinkActive="active" rel="bookmark" target="_blank">Términos y Condiciones</a>
          </li>
          <li>
            <span class="arrow-icon2">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
                <path d="M5 12h14M12 5l7 7-7 7" />
              </svg>
            </span>
            <button class="menu-element" (click)="scrollToDiv('.center-container')">Beneficios</button>
          
          </li>
          <li>
            <span class="arrow-icon2">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
                <path d="M5 12h14M12 5l7 7-7 7" />
              </svg>
            </span>
            <button class="menu-element" (click)="scrollToDiv('.footer_v2')">Redes Sociales</button>
            <ul class="submenu">
              <li><a class="menu-element" href="https://www.facebook.com/muvit.travel" target='_blank'>Facebook</a></li>
              <li><a class="menu-element" href="https://www.instagram.com/muvit.travel/?utm_source=google&utm_medium=banner&utm_campaign=visitas_trafico_icono_pagina_web" target='_blank'>Instagram</a></li>
              <li><a class="menu-element" href="https://www.tiktok.com/@muvit.travel?_t=8atH4Jl8OFq&_r=1" target='_blank'>TikTok</a></li>
              <li><a class="menu-element" href="https://bit.ly/44Kmw4T" target='_blank'>YouTube</a></li>
            </ul>
          </li>
          
        </ul>
        
        </div>
      </div>
    </div>
    <div class="muvit-logo">
      <img class="header-logo" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit_logo.svg" alt="muvit logo">
    </div>
    
    <div class="hidden-desktop" style="position: absolute; right: 40px; top: 25px; display: flex; align-items: center; cursor:pointer; z-index: 20;">
      <button (click)="showBlogPage()" routerLinkActive="active" rel="bookmark" target="_blank" class="p-terms" style="background-color: transparent; color: white; margin-left: 0px; margin-bottom: 0px; margin-top: 2px; margin-right: 40px; font-size: 16px;  border: 2px solid white; border-radius: 15px; width: 110px;" matTooltip="Blog Muvit" id="blogbtn">
    
        BLOG
    </button>
    
      
    
    <img style="height: 34px; width: 34px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/mundo.svg" alt="world"  (click)="openLanCur()">
    <!-- <svg style="color: white;" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
      <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
    </svg> -->
    <p style="color: white; margin-left: 5px; margin-bottom: 0px; margin-top: 2px; margin-right: 40px; font-weight: bold;"  (click)="openLanCur()">{{language.value}}/{{currency.value}}</p>
    <p class="p-terms" style="color: white; margin-left: 0px; margin-bottom: 0px; margin-top: 2px; margin-right: 40px; font-size: 18px;" matTooltip="Términos y condiciones" id="tycbtn2">
      <a class="a-footer" (click) = "showTosPage()" routerLinkActive="active" rel="bookmark" target="_blank">
        <span style="color: #ffffff;">TYC</span></a>
    </p>
      
    <a style="margin-right: 15px;" href="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/muvit_documents/FAQ_muvit.pdf" target="_blank" id="divfaq5">
      <img class="icon-color" style="height: 50px; width: 50px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/FAQS3.svg" alt="faqs" id="divfaq6">
    </a> 
    </div>
    <div class="hidden-mobile" style="position: absolute; right: 40px; top: 25px; display: flex; align-items: center; cursor:pointer; z-index: 20;">
  
      <button (click)="showBlogPage()" routerLinkActive="active" rel="bookmark" target="_blank" class="p-terms" style="background-color: transparent; color: white; margin-left: 0px; margin-bottom: 0px; margin-top: 3px; margin-right: -30px; font-size: 15px;  border: 2px solid white; border-radius: 15px; width: 80px;" matTooltip="Blog Muvit" id="blogbtn">
        
          BLOG
      </button>
      
        
      
      
    </div>
    

    <div *ngIf="showContent" class="container-base marg-mob" [@fadeInOut]>
     
      <div class="row" style="margin: 0; width: 100%;" *ngIf="!showSide">
        <div class="col-12">
          <div class="background">    
            <h2 style="color: white; " class="title-header">Viaja tranquilo, cotiza tu <b class="title-bold">asistencia de viaje</b></h2>
            <h2 style="color: white; font-size: 16px; justify-self: center; width: 55%; font-weight: lighter;" class="title-header ti-r">Trabajamos para ti. Realizamos reembolsos en 24 horas por percances, sin copago, y puedes elegir proveedores médicos, incluso fuera de la red establecida.</h2>
            <div class="content form-space hidden-desktop" >
              <form class="full-form"  #muvitquote="ngForm" (ngSubmit)="onSubmit()" style="font-family: 'rubik';" >
                <div style="width: 100%; min-height: 95px; display: flex;">
                  <div style="width:25%; height: 95px;">
                    <div style="display: flex; align-items: center; border-right: solid #CCCCCC 1px;">
            <div class="first-col" style="width: 10px !important; min-width: 10px !important;margin: inherit;">
            </div>
            <div class="full-col" style="height: 95px;">
              <mat-form-field style="min-width: 150px; height: inherit;" id="yearlystartdatehome">
                <mat-datepicker #endPicker></mat-datepicker>
                <input MatIconSuffix matInput [matDatepicker]="pickerTravelStartingDate" id="YearlyTravelStartingDateHome" readonly (click)="pickerTravelStartingDate.open()"
                  placeholder="{{ 'Fecha Ida' | translate }}"  [(ngModel)]="gs.currentInsuranceInfo.travel_starting_date"
                  name="labelTravelStartingDate" #labelTravelStartingDate="ngModel" style="padding-top: 20px;" [min]="travelStartMinDate" (ngModelChange)="onChangeTravelStartDate()">
                  <mat-datepicker-toggle matPrefix [for]="pickerTravelStartingDate" style="height: 25px;  ">
                  <mat-icon matDatepickerToggleIcon>
                    <img class="icon-color" style="height: 25px; position: absolute; bottom: 0px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/fecha_inicio.svg" alt="dates">
                  </mat-icon>
                  </mat-datepicker-toggle>
                <mat-datepicker #pickerTravelStartingDate></mat-datepicker>
              </mat-form-field>
              </div>
                    </div>
                  </div>
                  <div style="width: 25%; height: 95px;">
                    <div style="display: flex; align-items: center; border-right: solid #CCCCCC 1px; height: 95px;">
                    <div class="first-col" style="width: 10px !important; min-width: 10px !important;margin: inherit;">
                    </div>
                      <div class="full-col" style="height: inherit;">
                        <mat-form-field style="min-width: 150px; height: inherit;" id="YearlyEndDatehome">
                          <input MatIconSuffix matInput [matDatepicker]="pickerTravelEndDate" id="labelTravelEndDate" readonly (click)="pickerTravelEndDate.open()"
                            placeholder="{{ 'Fecha Vuelta' | translate }}" [(ngModel)]="gs.currentInsuranceInfo.travel_end_date"
                            name="labelTravelEndDate" style="min-width: 100px;  padding-top: 20px;" #labelTravelEndDate="ngModel" [min]="travelMinEndDate" [max]="travelMaxEndDate" [disabled]="!gs.currentInsuranceInfo.travel_starting_date || showContent">
                            <mat-datepicker-toggle matPrefix [for]="pickerTravelEndDate" [disabled]="showContent">
                              <mat-icon matDatepickerToggleIcon>
                                <img class="icon-color" style="height: 25px; position: absolute; bottom: 0px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/fecha_final.svg" alt="dates">
                              </mat-icon>
                            </mat-datepicker-toggle>
                          <mat-datepicker #pickerTravelEndDate></mat-datepicker>
                        </mat-form-field>
                        <div style="width: 100%;"></div>
                        <span style="display: flex; justify-content: flex-end; " class="info-btn" matTooltip="Mínimo 4 días" [matTooltipPosition]="'below'" >
                          <img style="height: 18px; display: grid;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/info.svg" alt="info">
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style="width:30%; height: 95px;">
                    <div style="display: flex; align-items: center; height: 93px;">
                      <div class="first-col" style="width: 10px !important; min-width: 10px !important;margin: inherit;">
                        
                      </div>
                      <mat-icon>
                        <img class="icon-color" style="height: 22px;display: flex; justify-content: flex-end;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/email.svg" alt="dates">
                      </mat-icon>
                      <input type="email" class="form-control input-email" id="emailYearly"  placeholder="Correo electrónico *" required [ngClass]="{'invalid-email': email.touched && !email.valid}" 
                      [(ngModel)]="emailHolder" name="email"  #email="ngModel" 
                      style="display: grid; margin: auto; width: 90%; border-bottom: 1px #f4066f solid; border-top: none; border-right: none; border-left: none; border-radius: 0px" 
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" id="Yearlyemailhome">
                      
                        </div>
                  </div>
                  <div style="width:20%; height: 95px;">
                    <div style="display:flex; align-items: center; justify-content: end; height: 100%;">
                      <button class="btn quote" (click)="quoteSubmit()" id="btnQuoteYearlyHome" style="width: 75%;">Cotizar</button>
                    </div>
                  </div>
                </div>
              
              </form>
            </div>

            <!-- vista movil del formulario -->
            <div class="content form-space hidden-mobile" >
              <form class="full-form"  #holderformuvit="ngForm" (ngSubmit)="onSubmit()" style="font-family: 'rubik';" >
                <div style="width: 100%; min-height: 85px; ">
                  
                  <!-- <div style="width: 100%; height: 85px;" (click)="sDates()">
                    <div style="display: flex; align-items: center; border-bottom: solid #cccccc 1px; height: 85px;" id="dates" name="dates">
                      <div class="first-col">
                        <img class="icon-color" style="height: 27px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/fechas.svg" alt="dates">
                      </div>
                      <div class="form-select second-col" style="border: 0px; background-color: white; display: flex; align-items: center; justify-content: initial;" id="toggle">
                        <p style="font-family: Rubik; margin: 0px; width: 100%; text-align: initial;"><span *ngIf="gs.currentInsuranceInfo.travel_starting_date && gs.currentInsuranceInfo.travel_end_date ; else notDates">{{gs.currentInsuranceInfo.travel_starting_date | date:'shortDate'}} - {{gs.currentInsuranceInfo.travel_end_date | date:'shortDate'}}</span><ng-template #notDates>Introduce las fechas</ng-template></p>
                        <div style="text-align: end;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="fa-solid fa-chevron-down" viewBox="0 0 16 16" style="height: 10px; color:black">
                            <g fill="none" stroke="black">
                              <path fill-rule="evenodd" stroke-width="2" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div style="width:100%; height: 85px;">
                    <div style="display: flex; align-items: center; border-bottom: solid #CCCCCC 1px;">
            <div class="first-col" style="width: 10px !important; min-width: 10px !important;margin: inherit;">
            </div>
            <div class="full-col" style="height: 85px;">
              <mat-form-field style="min-width: 100px; height: inherit;" id="yearlystartdatehomeMobile">
                <mat-datepicker #endPicker2></mat-datepicker>
                <input MatIconSuffix matInput [matDatepicker]="pickerTravelStartingDate2" id="labelTravelStartingDate" readonly (click)="pickerTravelStartingDate.open()"
                  placeholder="{{ 'Fecha Ida' | translate }}"  [(ngModel)]="gs.currentInsuranceInfo.travel_starting_date"
                  name="labelTravelStartingDate" #labelTravelStartingDate="ngModel" [min]="travelStartMinDate" (ngModelChange)="onChangeTravelStartDate()">
                  <mat-datepicker-toggle matPrefix [for]="pickerTravelStartingDate2" style="height: 45px; ">
                  <mat-icon matDatepickerToggleIcon>
                    <img class="icon-color" style="height: 25px; position: absolute; bottom: 0px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/fecha_inicio.svg" alt="dates">
                  </mat-icon>
                  </mat-datepicker-toggle>
                <mat-datepicker #pickerTravelStartingDate2></mat-datepicker>
              </mat-form-field>
              </div>
                    </div>
                  </div>
                  <div style="width: 100%; height: 85px;">
                    <div style="display: flex; align-items: center; border-bottom: solid #CCCCCC 1px; height: 95px;">
                    <div class="first-col" style="width: 10px !important; min-width: 10px !important;margin: inherit;">
                    </div>
                      <div class="full-col" style="height: inherit;">
                        <mat-form-field style="min-width: 150px; height: inherit;" id="YearlyEndDatehomeMobile">
                          <input MatIconSuffix matInput [matDatepicker]="pickerTravelEndDate2" id="labelTravelEndDate" readonly (click)="pickerTravelEndDate.open()"
                            placeholder="{{ 'Fecha Vuelta' | translate }}" [(ngModel)]="gs.currentInsuranceInfo.travel_end_date"
                            name="labelTravelEndDate" #labelTravelEndDate="ngModel" [min]="travelMinEndDate" [max]="travelMaxEndDate" [disabled]="!gs.currentInsuranceInfo.travel_starting_date">
                            <mat-datepicker-toggle matPrefix [for]="pickerTravelEndDate2">
                              <mat-icon matDatepickerToggleIcon>
                                <img class="icon-color" style="height: 25px; position: absolute; bottom: 0px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/fecha_final.svg" alt="dates">
                              </mat-icon>
                            </mat-datepicker-toggle>
                          <mat-datepicker #pickerTravelEndDate2></mat-datepicker>
                        </mat-form-field>
                        <div style="width: 100%;"></div>
                        <span style="display: flex; justify-content: flex-end; " class="info-btn" matTooltip="Máximo 30 días" [matTooltipPosition]="'below'" >
                          <img style="height: 18px; display: grid;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/info.svg" alt="info">
                        </span>
                      </div>
                    </div>
                  </div>
                  
                  <div style="display: flex; align-items: center; border-bottom: solid  #cccccc  1px; height: 85px;" id="dates" name="dates">
                    <div class="first-col" style="margin-left: 8px;">
                      <img class="icon-color" style="height: 27px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/email.svg" alt="dates">
                    </div>
                  <input type="email" class="form-control input-email" id="emailYearlyMobile"  placeholder="Correo electrónico *" required [ngClass]="{'invalid-email': email.touched && !email.valid}" 
                  [(ngModel)]="emailHolder" name="email"  #email="ngModel" 
                  style="display: grid; margin: auto; width: 95%; border-bottom: 0px #f4066f solid; border-top: none; border-right: none; border-left: none; border-radius: 0px;" 
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                </div>

                  <div style="width:100%; height: 85px;">
                    <div style="display:flex; align-items: center; height: 100%;">
                      <button class="btn quote" (click)="quoteSubmit()" id="btnquoteYearlyHomeMobile">Cotizar</button>
                    </div>
                  </div>
                </div>
              
              </form>
            </div>
          </div>
        </div>
      
      </div>
        
    </div>
    <div *ngIf="!showContent" class="container-base" [@fadeInOut]>
     
      <div class="row" style="margin: 0; width: 100%;" *ngIf="!showSide">
        <div class="col-12">
          <div class="background">    
            <h2 style="color: white; " class="title-header t2-mob">Viaja tranquilo, cotiza tu <b class="title-bold">asistencia de viaje</b></h2>
            <h2 style="color: white; font-size: 16px; justify-self: center; width: 55%; font-weight: lighter;" class="title-header ti-r">Trabajamos para ti. Realizamos reembolsos en 24 horas por percances, sin copago, y puedes elegir proveedores médicos, incluso fuera de la red establecida.</h2>
            <div class="content form-space hidden-desktop" >
              <form class="full-form"  #muvitquote="ngForm" (ngSubmit)="onSubmit()" style="font-family: 'rubik';" >
                <div style="width: 100%; min-height: 95px; display: flex;">
                  <div style="width:20%; height: 95px;">
                    <div style="display: flex; align-items: center; border-right: solid #CCCCCC 1px;">
            <div class="first-col" style="width: 10px !important; min-width: 10px !important;margin: inherit;">
            </div>
            <div class="full-col" style="height: 95px;">
              <mat-form-field style="min-width: 100px; height: inherit;" id="DailyStartingDateHome">
                <mat-datepicker #endPicker></mat-datepicker>
                <input MatIconSuffix matInput [matDatepicker]="pickerTravelStartingDate" id="labelTravelStartingDate" readonly (click)="pickerTravelStartingDate.open()"
                  placeholder="{{ 'Fecha Ida' | translate }}"  [(ngModel)]="gs.currentInsuranceInfo.travel_starting_date"
                  name="labelTravelStartingDate" #labelTravelStartingDate="ngModel" style="padding-top: 20px;" [min]="travelStartMinDate" (ngModelChange)="onChangeTravelStartDate()">
                  <mat-datepicker-toggle matPrefix [for]="pickerTravelStartingDate" style="height: 25px;  ">
                  <mat-icon matDatepickerToggleIcon>
                    <img class="icon-color" style="height: 25px; position: absolute; bottom: 0px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/fecha_inicio.svg" alt="dates">
                  </mat-icon>
                  </mat-datepicker-toggle>
                <mat-datepicker #pickerTravelStartingDate></mat-datepicker>
              </mat-form-field>
              </div>
                    </div>
                  </div>
                  <div style="width: 20%; height: 95px;">
                    <div style="display: flex; align-items: center; border-right: solid #CCCCCC 1px; height: 95px;">
                    <div class="first-col" style="width: 10px !important; min-width: 10px !important;margin: inherit;">
                    </div>
                      <div class="full-col" style="height: inherit; padding-right: 25px;" >
                        <mat-form-field style="min-width: 150px; height: inherit;" id="DailyEndDateHome">
                          <input MatIconSuffix matInput [matDatepicker]="pickerTravelEndDate" id="labelTravelEndDate" readonly (click)="pickerTravelEndDate.open()"
                            placeholder="{{ 'Fecha Vuelta' | translate }}" [(ngModel)]="gs.currentInsuranceInfo.travel_end_date"
                            name="labelTravelEndDate" #labelTravelEndDate="ngModel" style="min-width: 100px; padding-top: 20px;" [min]="travelMinEndDate" [max]="travelMaxEndDate" [disabled]="!gs.currentInsuranceInfo.travel_starting_date || showContent">
                            <mat-datepicker-toggle matPrefix [for]="pickerTravelEndDate" [disabled]="showContent">
                              <mat-icon matDatepickerToggleIcon>
                                <img class="icon-color" style="height: 25px; position: absolute; bottom: 0px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/fecha_final.svg" alt="dates">
                              </mat-icon>
                            </mat-datepicker-toggle>
                          <mat-datepicker #pickerTravelEndDate></mat-datepicker>
                        </mat-form-field>
                        <div style="width: 100%;"></div>
                        <span style="display: flex; justify-content: flex-end; " class="info-btn" matTooltip="Mínimo 4 días" [matTooltipPosition]="'below'" >
                          <img style="height: 18px; display: grid;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/info.svg" alt="info">
                        </span>
                      </div>
                    </div>
                  </div>



                  <div style="width:45%; height: 95px; ">
                    <div style="display: flex; align-items: center; height: 93px;">
                      <div class="first-col" style="width: 20px !important; min-width: 10px !important;margin: inherit;"></div>
                        
                          <mat-icon>
                            <img class="icon-color" style="height: 22px;display: flex; justify-content: flex-end;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/email.svg" alt="dates">
                          </mat-icon>
                          <input type="email" class="form-control input-email" id="emailDailyHome"  placeholder="Correo electrónico *" required [ngClass]="{'invalid-email': email.touched && !email.valid}" 
                          [(ngModel)]="emailHolder" name="email"  #email="ngModel" 
                          style="display: grid; margin: auto; width: 75%; border-bottom: 1px #f4066f solid; border-top: none; border-right: none; border-left: none; border-radius: 0px; margin-left: 5px; margin-right: 10px;" 
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" >
                      </div>
                  </div>

                  <div  style="width:45%; height: 95px; border-left: solid #CCCCCC 1px;">
                    <div style="display: flex; align-items: center; height: 93px;">
                      <div class="first-col" style="width: 10px !important; min-width: 10px !important;margin: inherit;"></div>
                        <div style="width:100%; height: 85px;" (click)="showPersons()" >
                        <div style="display: flex; align-items: center; height: 85px; " id="persons" name="persons">
                          <div class="first-col" style="height: 80px;" (click)="sPersons()">
                            <img class="icon-color" style="height: 27px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/personas.svg" alt="personas">
                          </div>
                          <div class="form-select second-col" style="border: 0px; background-color: white; display: flex; align-items: center; justify-content: initial;  border-bottom: #f4066f 1px solid; height: 40px; border-radius: 0px;" (click)="sPersons()">
                            <p style="margin: 0px; width: 100%; text-align: initial;"><span *ngIf="totalPersons > 0">{{totalPersons}}</span>  Personas</p>
                            <div style="text-align: end;">
                              
                            </div>
                          </div>
                          
                        </div>
                        <div class="side-page" id="persons"  *ngIf="showPerson" style="background-color: white;">
                          <!-- <div class="back-icon" (click)="goBack(3)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16" style="height: 20px; color:#ff056f;">
                              <g fill="none" stroke="currentColor">
                              <path stroke-width="2" d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                              </g>
                            </svg>
                          </div> -->
                          
                        
                          <div id="person-form" class="persons-box-mob" style="width: max-content; border-radius: 5px; border: 1px solid #cccccc; border-top: 0px">
                            <div class="row persons-mob" style=" border-top: solid #C5C5C5 1px;margin-left: 0px; margin-right: 0px; padding-top: 10px;">
                              <div class="col-6">
                                <h3 class="title-persons">Adultos</h3>
                                <p class="subtitle-persons">Hasta 64 años</p>
                              </div>
                              <div class="col-6">
                                <div style="text-align: center; ">
                                  <button class="button-persons" [disabled]="adults == 0" (click)="addPerson(0,1)">-</button>
                                  <b>{{adults}}</b>
                                  <button class="button-persons" (click)="addPerson(1,1)">+</button>
                                </div>
                              </div>
                            </div>
                            <div class="row persons-mob" style=" border-top: solid #C5C5C5 1px;margin-left: 0px; margin-right: 0px; padding-top: 10px;" >
                              <div class="col-6" style="padding-right: 0px;">
                                <h3 class="title-persons">Adultos Mayores</h3>
                                <p class="subtitle-persons">Desde 65 años</p>
                              </div>
                              <div class="col-6">
                                <div style="text-align: center; ">
                                  <button class="button-persons" [disabled]="olds == 0" (click)="addPerson(0,2)">-</button>
                                  <b>{{olds}}</b>
                                  <button class="button-persons" (click)="addPerson(1,2)">+</button>
                                </div>
                              </div>
                            </div>
                            <div class="row persons-mob" style=" border-top: solid #C5C5C5 1px; margin-left: 0px; margin-right: 0px; padding-top: 10px;">
                              <div class="col-6">
                                <h3 class="title-persons">Niños/as</h3>
                                <p class="subtitle-persons">0-17 años</p>
                              </div>
                              <div class="col-6">
                                <div style="text-align: center; ">
                                  <button class="button-persons" [disabled]="kids == 0" (click)="addPerson(0,3)">-</button>
                                  <b>{{kids}}</b>
                                  <button class="button-persons" [disabled]="adults == 0 && olds == 0" (click)="addPerson(1,3)">+</button>
                                </div>
                              </div>
                            </div>
                            <div class="row" style="position: relative;height:75px; display:flex; align-items: center; border-top: solid #cccccc 1px; ;  bottom: 0px; left: 0px; right: 0px; width: 100%; margin-right: 0px; margin-left: 0px; justify-content: end; z-index: 1;">
                              <!-- <button class="btn apply-persons" [disabled]="disableApply" (click)="confirmPersons()" id="btnapplypersons">Agregar</button> -->
                            </div>
                          </div>
                        </div>
                        </div>
                          
                      </div>
                  </div>



                  <div style="width:30%; height: 95px;">
                    <div style="display:flex; align-items: center; justify-content: end; height: 100%;">
                      <button class="btn quote" (click)="quoteSubmit()" id="btnquoteDaily" style="width: 75%;">Cotizar</button>
                    </div>
                  </div>
                </div>
              
              </form>
            </div>

            <!-- vista movil del formulario -->
            <div class="content form-space hidden-mobile" >
              <form class="full-form"  #holderformuvit="ngForm" (ngSubmit)="onSubmit()" style="font-family: 'rubik';" >
                <div style="width: 100%; min-height: 85px; ">
                  <!-- <div style="width:100%; height: 85px;" (click)="sDestiny()" >
                    <div style="display: flex; align-items: center; border-bottom: solid #C5C5C5 1px; height: 70px;" name="destiny" id="destiny">
                      <div class="first-col">
                        <img class="icon-color" style="height: 27px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/destino.svg" alt="destino">
                      </div>
                      <div class="form-select second-col" style="border: 0px; background-color: white; display: flex; align-items: center; justify-content: initial;" id="toggle">
                        <p style="font-family: Rubik; margin: 0px; width: 100%; text-align: initial;"><span *ngIf="destiny.name == null; else yesDestiny">Destino</span><ng-template #yesDestiny>{{destiny.name}}</ng-template></p>
                        <div style="text-align: end;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="fa-solid fa-chevron-down" viewBox="0 0 16 16" style="height: 10px; color:black">
                            <g fill="none" stroke="black">
                              <path fill-rule="evenodd" stroke-width="2" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div style="width: 100%; height: 85px;" (click)="sDates()">
                    <div style="display: flex; align-items: center; border-bottom: solid #cccccc 1px; height: 85px;" id="dates" name="dates">
                      <div class="first-col">
                        <img class="icon-color" style="height: 27px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/fechas.svg" alt="dates">
                      </div>
                      <div class="form-select second-col" style="border: 0px; background-color: white; display: flex; align-items: center; justify-content: initial;" id="toggle">
                        <p style="font-family: Rubik; margin: 0px; width: 100%; text-align: initial;"><span *ngIf="gs.currentInsuranceInfo.travel_starting_date && gs.currentInsuranceInfo.travel_end_date ; else notDates">{{gs.currentInsuranceInfo.travel_starting_date | date:'shortDate'}} - {{gs.currentInsuranceInfo.travel_end_date | date:'shortDate'}}</span><ng-template #notDates>Introduce las fechas</ng-template></p>
                        <div style="text-align: end;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="fa-solid fa-chevron-down" viewBox="0 0 16 16" style="height: 10px; color:black">
                            <g fill="none" stroke="black">
                              <path fill-rule="evenodd" stroke-width="2" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div style="width:100%; height: 85px;">
                    <div style="display: flex; align-items: center; border-bottom: solid #CCCCCC 1px;">
            <div class="first-col" style="width: 10px !important; min-width: 10px !important;margin: inherit;">
            </div>
            <div class="full-col" style="height: 85px;">
              <mat-form-field style="min-width: 100px; height: inherit;" id="DailyStartingDateHomeMobile">
                <mat-datepicker #endPicker2></mat-datepicker>
                <input MatIconSuffix matInput [matDatepicker]="pickerTravelStartingDate2" id="labelTravelStartingDate" readonly (click)="pickerTravelStartingDate.open()"
                  placeholder="{{ 'Fecha Ida' | translate }}"  [(ngModel)]="gs.currentInsuranceInfo.travel_starting_date"
                  name="labelTravelStartingDate" #labelTravelStartingDate="ngModel" [min]="travelStartMinDate" (ngModelChange)="onChangeTravelStartDate()" style="margin-top: 20px;">
                  <mat-datepicker-toggle matPrefix [for]="pickerTravelStartingDate2" style="height: 25px; ">
                  <mat-icon matDatepickerToggleIcon>
                    <img class="icon-color" style="height: 25px; position: absolute; bottom: 2px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/fecha_inicio.svg" alt="dates">
                  </mat-icon>
                  </mat-datepicker-toggle>
                <mat-datepicker #pickerTravelStartingDate2></mat-datepicker>
              </mat-form-field>
              </div>
                    </div>
                  </div>
                  <div style="width: 100%; height: 85px;">
                    <div style="display: flex; align-items: center; border-bottom: solid #CCCCCC 1px; height: 95px;">
                    <div class="first-col" style="width: 10px !important; min-width: 10px !important;margin: inherit;">
                    </div>
                      <div class="full-col" style="height: inherit;">
                        <mat-form-field style="min-width: 150px; height: inherit;" id="DailyEndDateHomeMobile">
                          <input MatIconSuffix matInput [matDatepicker]="pickerTravelEndDate2" id="labelTravelEndDate" readonly (click)="pickerTravelEndDate.open()"
                            placeholder="{{ 'Fecha Vuelta' | translate }}" [(ngModel)]="gs.currentInsuranceInfo.travel_end_date" style="margin-top: 20px; min-width: 105px;"
                            name="labelTravelEndDate" #labelTravelEndDate="ngModel" [min]="travelMinEndDate" [max]="travelMaxEndDate" [disabled]="!gs.currentInsuranceInfo.travel_starting_date">
                            <mat-datepicker-toggle matPrefix [for]="pickerTravelEndDate2">
                              <mat-icon matDatepickerToggleIcon>
                                <img class="icon-color" style="height: 25px; position: absolute; bottom: 2px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/fecha_final.svg" alt="dates">
                              </mat-icon>
                            </mat-datepicker-toggle>
                          <mat-datepicker #pickerTravelEndDate2></mat-datepicker>
                        </mat-form-field>
                        <div style="width: 100%;"></div>
                        <span style="display: flex; justify-content: flex-end; " class="info-btn" matTooltip="Máximo 30 días" [matTooltipPosition]="'below'" >
                          <img style="height: 18px; display: grid;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/info.svg" alt="info">
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style="width:100%; height: 85px;" (click)="sPersons()" >
                    <div style="display: flex; align-items: center; height: 85px; border-bottom: solid #cccccc 1px;" id="personsMobile" name="persons">
                      <div class="first-col" style="margin-left: 8px;" (click)="showPersons()">
                        <img class="icon-color" style="height: 27px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/personas.svg" alt="personas">
                      </div>
                      <div class="form-select second-col" style="border: 0px; background-color: white; display: flex; align-items: center; justify-content: initial;" (click)="showPersons()">
                        <p style="margin: 0px; width: 100%; text-align: initial;"><span *ngIf="totalPersons > 0">{{totalPersons}}</span>  Personas</p>
                        <div style="text-align: end;">
                         
                        </div>
                      </div>
                      
                    </div>
                    <div *ngIf="persons" id="person-formMobile"  class="persons-box" style="position: relative; z-index: 10;">
                      <div>
                        <div  class="row" style="height:50px; display:flex; align-items: center;">
                          <div class="col-6">
                            <h3 class="title-persons">Adultos</h3>
                            <p class="subtitle-persons">Hasta 64 años</p>
                          </div>
                          <div class="col-6">
                            <div>
                              <button class="button-persons" [disabled]="adults == 0" (click)="addPerson(0,1)">-</button>
                              <b>{{adults}}</b>
                              <button class="button-persons" (click)="addPerson(1,1)">+</button>
                            </div>
                          </div>
                        </div>
                        <div class="row" style="height:50px; display:flex; align-items: center;">
                          <div class="col-6" style="padding-right: 0px;">
                            <h3 class="title-persons">Adultos Mayores</h3>
                            <p class="subtitle-persons">Desde 65 años</p>
                          </div>
                          <div class="col-6">
                            <div>
                              <button class="button-persons" [disabled]="olds == 0" (click)="addPerson(0,2)">-</button>
                              <b>{{olds}}</b>
                              <button class="button-persons" (click)="addPerson(1,2)">+</button>
                            </div>
                          </div>
                        </div>
                        <div class="row" style="height:50px; display:flex; align-items: center;">
                          <div class="col-6">
                            <h3 class="title-persons">Niños/as</h3>
                            <p class="subtitle-persons">0-17 años</p>
                          </div>
                          <div class="col-6">
                            <div>
                              <button class="button-persons" [disabled]="kids == 0" (click)="addPerson(0,3)">-</button>
                              <b>{{kids}}</b>
                              <button class="button-persons" [disabled]="adults == 0 && olds == 0" (click)="addPerson(1,3)">+</button>
                            </div>
                          </div>
                        </div>
                        <div class="row" style="height:80px; display:flex; align-items: center;">
                          <div class="col-6"></div>
                          <div class="col-6">
                            <div>
                              <!-- <button class="btn apply-persons" [disabled]="disableApply" (click)="confirmPersons()" id="btnapplypersons">Aplicar2</button> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="display: flex; align-items: center; border-bottom: solid  #cccccc  1px; height: 85px;" id="dates" name="dates">
                    <div class="first-col" style="margin-left: 8px;">
                      <img class="icon-color" style="height: 27px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/email.svg" alt="dates">
                    </div>
                  <input type="email" class="form-control input-email" id="email"  placeholder="Correo electrónico *" required [ngClass]="{'invalid-email': email.touched && !email.valid}" 
                  [(ngModel)]="emailHolder" name="email"  #email="ngModel" 
                  style="display: grid; margin: auto; width: 95%; border-bottom: 0px #f4066f solid; border-top: none; border-right: none; border-left: none; border-radius: 0px;" 
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                </div>

                  <div style="width:100%; height: 85px; position: relative ; z-index: 1;">
                    <div style="display:flex; align-items: center; height: 100%;">
                      <button class="btn quote" (click)="quoteSubmit()" id="btnquote">Cotizar</button>
                    </div>
                  </div>
                </div>
              
              </form>
            </div>
          </div>
        </div>
      
      </div>
        
    </div>

    <!-- class="slide-in slider-page" -->
    <div class="side-page" id="destiny"  *ngIf="showDestiny">
      <div class="back-icon" (click)="goBack(1)">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16" style="height: 20px; color:#ff056f;">
          <g fill="none" stroke="currentColor">
          <path stroke-width="2" d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
          </g>
        </svg>
      </div>
      <div class="muvit-top">
        <img class="header-logo" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit_logo.svg" alt="muvit logo">
      </div>
      <div style="display: flex; align-items: center; border-bottom: solid #C5C5C5 1px; height: 70px;" name="destiny" id="destiny">
        <div class="f-col">
          <img class="icon-color" style="height: 47px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/destino.svg" alt="destino">
        </div>
        <div class="form-select second-col" style="border: 0px; background-color: white; display: flex; align-items: center; justify-content: initial;" id="toggle">
          <p style="font-family: Rubik; margin: 0px; width: 100%; text-align: initial; font-size: 22px;">Destino</p>
        </div>
      </div>
      <div class="center-content group-spacing" style="padding: 15px;">
        <div class="container-left">
          <mat-select class="form-control" id="destiny" [(ngModel)]="destiny" name="destiny" style="font-family: 'rubik';" placeholder="Selecciona tu destino">
            <mat-option *ngFor="let country of countries" [value]="country">{{country.name}}</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="row" style="height:105px; display:flex; align-items: center; border-top: solid #C5C5C5 1px; position: absolute; bottom: 0px; left: 0px; right: 0px; width: 100%; margin-right: 0px; margin-left: 0px; justify-content: center;">
        <button class="btn apply-persons" [disabled]="!destiny" (click)="confirm()" id="btnapplydates">Aplicar</button>
      </div>
    </div>
    <div class="side-page" id="dates"  *ngIf="showDates">
      <div class="back-icon" (click)="goBack(2)">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16" style="height: 20px; color:#ff056f;">
          <g fill="none" stroke="currentColor">
          <path stroke-width="2" d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
          </g>
        </svg>
      </div>
      <div class="muvit-top">
        <img class="header-logo" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit_logo.svg" alt="muvit logo">
      </div>
      <div style="display: flex; align-items: center; border-bottom: solid #C5C5C5 1px; height: 70px;" name="dates" id="dates">
        <div class="f-col">
          <img class="icon-color" style="height: 47px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/fechas.svg" alt="fechas">
        </div>
        <div class="form-select second-col" style="border: 0px; background-color: white; display: flex; align-items: center; justify-content: initial;" id="toggle">
          <p style="font-family: Rubik; margin: 0px; width: 100%; text-align: initial; font-size: 22px;">Fechas</p>
        </div>
        <div class="form-select second-col" style="border: 0px; background-color: white; display: flex; align-items: center; justify-content: end; margin-right: 15px; width: fit-content;">
          <img style="height: 18px; display: grid; margin-right: 5px; margin-bottom: 2px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/info.svg" alt="info">
          <p style="font-family: Rubik; margin: 0px; width: 100%; text-align: initial; font-size: 14px; white-space: nowrap;">Mínimo 4 días</p>
        </div>
      </div>
      <div class="row dates-mob">      
        <div class="col-6" style="display: flex; justify-content: center; border-right: solid 1px #C5C5C5;">
          <!-- <p *ngIf="gs.currentInsuranceInfo.travel_starting_date" style="font-size: 13px; color: black; margin-bottom: 3px;">Fecha Ida</p> -->
          <mat-form-field class="form-control" style="height: 99px; margin-top: 0px; border: none; min-width: 100%; position: inherit;" [ngClass]="{'selected-picker': startDatePick}"	>
                <input matInput [matDatepicker]="pickerTravelStartingDate" id="labelTravelStartingDate" readonly (click)="pickerTravelStartingDate.open(); changeColor(0)"
                  placeholder="{{ 'Fecha Ida' | translate }}"  [(ngModel)]="gs.currentInsuranceInfo.travel_starting_date"
                  name="labelTravelStartingDate" #labelTravelStartingDate="ngModel" [min]="travelStartMinDate" (ngModelChange)="onChangeTravelStartDate()" id="startDateMob" class="startDateMob">
                  <mat-datepicker-toggle matPrefix [for]="pickerTravelStartingDate" style="height: 25px; display: none;">
                  </mat-datepicker-toggle>
                <mat-datepicker touchUi="true" #pickerTravelStartingDate panelClass="datepickerDate"></mat-datepicker>
      
          </mat-form-field>
        </div>
        <div class="col-6" style="display: flex; justify-content: center;">
          <mat-form-field class="form-control" style="height: 99px; margin-top: 0px; border: none; min-width: 100%;" [ngClass]="{'selected-picker': endDatePick}">
            <input matInput [matDatepicker]="pickerTravelEndDate" id="labelTravelStartingDate" readonly (click)="pickerTravelEndDate.open(); changeColor(1)"
              [(ngModel)]="gs.currentInsuranceInfo.travel_end_date" placeholder="Fecha Vuelta" class="place-h endDateMob"
              name="labelTravelEndDate" #labelTravelEndDate="ngModel" [min]="travelMinEndDate" [max]="travelMaxEndDate" [disabled]="!gs.currentInsuranceInfo.travel_starting_date || showContent" (ngModelChange)="onChangeTravelEndDate()">
              <mat-datepicker-toggle matPrefix [for]="pickerTravelEndDate" style="height: 25px; display: none;" [disabled]="showContent">
              </mat-datepicker-toggle>
            <mat-datepicker touchUi="true" #pickerTravelEndDate panelClass="datepickerDate"></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="row" style="height:105px; display:flex; align-items: center; position: absolute; bottom: 0px; left: 0px; right: 0px; width: 100%; margin-right: 0px; margin-left: 0px; justify-content: center;">
        <button class="btn apply-persons" [disabled]="!gs.currentInsuranceInfo.travel_starting_date || !gs.currentInsuranceInfo.travel_end_date" (click)="confirm()" id="btnapplydates">Aplicar</button>
      </div>
      
    </div>
    
    <div *ngIf="!isScreenWidthLessThan790;" style="display: flex; justify-content: center; align-items: center; flex-direction: column; width: 100%; margin-top: 50px;">
      <div class="image-text">Algunos de nuestros clientes</div>
      <div class="image-container">
          
          <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/logos_v3.webp" alt="Logo Image">
        </div>
    </div>
    <div *ngIf="isScreenWidthLessThan790;" style="display: flex; justify-content: center; align-items: center; flex-direction: column; width: 100%; margin-top: -50px;">
      <div class="image-text">Algunos de nuestros clientes</div>
      <div class="image-container">
          
          <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/logos_v3.webp" alt="Logo Image">
        </div>
    </div>
      <div *ngIf="!isScreenWidthLessThan790;" style="display: flex; justify-content: center; align-items: center; position: relative ;  margin-top: 2%; padding: 0;" id="switchplantype">
        <div >
          <span class="slider-text" style="color: #5b5b5b; font-size: 18px;  left: 0; margin-right: 10px;">Elige la modalidad de tu plan</span>
        </div>
        <div *ngIf="showContent">
        <span class="slider-text" style="color: #5b5b5b; font-size: 18px; font-weight: bold; left: 0; margin-right: 10px;">Diario</span>
      </div>
      <div *ngIf="!showContent">
        <span class="slider-text" style="color: #f4066f; font-size: 18px; font-weight: bold; left: 0; margin-right: 10px;">Diario</span>
      </div>
        <label  class="switch" id="ToggleTypeOfPlan">
          <input  type="checkbox" [(ngModel)]="showContent" (ngModelChange)="taggleShowContent($event)" id="SwitchPlanType">
          <div class="slider round">
            
          </div>
        </label>
        <div *ngIf="!showContent">
          <span class="slider-text" style="color: #5b5b5b; font-size: 18px; font-weight: bold; left: 0; margin-left: 10px;">Anual</span>
        </div>
        <div *ngIf="showContent">
          <span class="slider-text" style="color: #C01DBF; font-size: 18px; font-weight: bold; left: 0; margin-left: 10px;">Anual</span>
        </div>
      </div>
      <div *ngIf="isScreenWidthLessThan790;" style="display: flex; justify-content: center; align-items: center; position: relative ; padding-right: 0%; margin-top: 5%; flex-direction: column;" id="switchplantype">
        <div >
          <span class="slider-text" style="color: #5b5b5b; font-size: 16px;  left: 0; margin-right: 10px;">Elige la modalidad de tu plan</span>
        </div>
        <div style="display: flex; flex-direction: row; margin-top: 5%; align-items: center;">
        <div *ngIf="showContent">
        <span class="slider-text" style="color: #5b5b5b; font-size: 14px; font-weight: bold; left: 0; margin-right: 10px;">Diario</span>
      </div>
      <div *ngIf="!showContent">
        <span class="slider-text" style="color: #f4066f; font-size: 14px; font-weight: bold; left: 0; margin-right: 10px;">Diario</span>
      </div>
        <label  class="switch" id="ToggleTypeOfPlanMobile">
          <input  type="checkbox" [(ngModel)]="showContent" (ngModelChange)="taggleShowContent($event)" id="SwitchPlanTypeMobile">
          <div class="slider round">
            <!-- <span class="slider-text">{{ showContent ? 'Diario' : 'Anual' }}</span> -->
          </div>
        </label>
        <div *ngIf="!showContent">
          <span class="slider-text" style="color: #5b5b5b; font-size: 14px; font-weight: bold; left: 0; margin-left: 10px;">Anual</span>
        </div>
        <div *ngIf="showContent">
          <span class="slider-text" style="color: #C01DBF; font-size: 14px; font-weight: bold; left: 0; margin-left: 10px;">Anual</span>
        </div>
      </div>
      </div>
    
    
      <div *ngIf="isScreenWidthLessThan790; else elseBlock">
        <div class="center-container">
           
          <div *ngIf="showContent" style="width: 100%; height: 100%;" [@fadeInOut]>
            <swiper-container class="custom-scrollbar" space-between="20" slides-per-view="1"  pagination="true" style=" 
                --swiper-pagination-bullet-width:25px;
                --swiper-pagination-bullet-height: 2px;
                --swiper-pagination-bullet-border-radius: 10%;
                --swiper-pagination-color: #C01DBF;
               
            ">
               <swiper-slide> 
                <div class="table-container">
                  <div class="table-title" style="border-color: #C01DBF;">
                    <div class="upper-elements">
                      <div class="text-header">
                      <span class="plan">PLAN</span>
                      <span class="bold-viajero">VIAJERO 150K</span>
                    </div>
                      
                    </div>
                      <span class="subtitle">Cobertura Mundial</span>
                    </div>
                     
                    <div class="table-wrapper">
                <table class="modern-table">
                  <thead>
                    <tr>
                      <th class="header-cell">Cobertura</th>
                      <th class="header-cell">Monto</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let plan of plansYearly3">
                      <td>{{ plan.coverage }}</td>
                      <td>{{ plan.amount }}</td>
                      
                    </tr>
                    <tr>
                        <td class="last-cell"> Y mas....</td>
                        <td class="last-cell"></td>
                    </tr>
                    <tr class="button-last">
                      <td colspan="2">
                        <button (click)="gotoTop()" class="last-row-button" style="background-color: #C01DBF;" id="quoteYearlyPlan150K">Cotizar</button>
                      </td>
                      </tr>
                  </tbody>
                </table>
              </div>
                </div>
            </swiper-slide>
            <swiper-slide> 
              <div class="table-container">
                <div class="table-title" style="border-color: #C01DBF;">
                  <div class="upper-elements">
                    <div class="text-header">
                    <span class="plan">PLAN</span>
                    <span class="bold-viajero">VIAJERO 100K</span>
                  </div>
                    
                  </div>
                    <span class="subtitle">Cobertura Mundial</span>
                  </div>
                   
                  <div class="table-wrapper">
              <table class="modern-table">
                <thead>
                  <tr>
                    <th class="header-cell">Cobertura</th>
                    <th class="header-cell">Monto</th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let plan of plansYearly2">
                    <td>{{ plan.coverage }}</td>
                    <td>{{ plan.amount }}</td>
                    
                  </tr>
                  <tr>
                      <td class="last-cell"> Y mas....</td>
                      <td class="last-cell"></td>
                  </tr>
                  <tr class="button-last">
                    <td colspan="2">
                      <button (click)="gotoTop()" class="last-row-button" style="background-color: #C01DBF;" id="quoteYearlyPlan100K">Cotizar</button>
                    </td>
                    </tr>
                </tbody>
              </table>
            </div>
              </div>
          </swiper-slide>
            <swiper-slide>
                <div class="table-container">
                  <div class="table-title" style="border-color: #C01DBF;">
                    <div class="upper-elements">
                      <div class="text-header">
                      <span class="plan">PLAN</span>
                      <span class="bold-viajero">VIAJERO 50K</span>
                    </div>
                      
                    </div>
                      <span class="subtitle">Cobertura Mundial</span>
                    </div>
                    <div class="table-wrapper">
                <table class="modern-table">
                  <thead>
                    <tr>
                      <th class="header-cell">Cobertura</th>
                      <th class="header-cell">Monto</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let plan of plansYearly">
                      <td>{{ plan.coverage }}</td>
                      <td>{{ plan.amount }}</td>
                      
                    </tr>
                    <tr>
                        <td class="last-cell"> Y mas....</td>
                        <td class="last-cell"></td>
                    </tr>
                    <tr class="button-last">
                      <td colspan="2">
                        <button (click)="gotoTop()" class="last-row-button" style="background-color: #C01DBF;" id="quoteYearlyPlan50K">Cotizar</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
                </div>
            </swiper-slide>
          </swiper-container>
        </div>
          <div *ngIf="!showContent && !gs.discount" style="width: 100%; height: 100%;" [@fadeInOut]>
            <swiper-container class="custom-scrollbar" space-between="20" slides-per-view="1"  pagination="true" style=" 
                --swiper-pagination-bullet-width:25px;
                --swiper-pagination-bullet-height: 2px;
                --swiper-pagination-bullet-border-radius: 10%;
                --swiper-pagination-color: #FC146F;
               
            ">
            <swiper-slide>
              <div class="table-container">
                <div class="table-title">
                  <div class="upper-elements">
                    <div class="text-header">
                    <span class="plan">PLAN</span>
                    <span class="bold-viajero">VIAJERO BÁSICO</span>
                  </div>
                    
                  </div>
                    <span class="subtitle">Cobertura solo a LATAM</span>
                  </div>
                  <div class="table-wrapper">
              <table class="modern-table">
                <thead>
                  <tr>
                    <th class="header-cell">Cobertura</th>
                    <th class="header-cell">Monto</th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let plan of plans3">
                    <td>{{ plan.coverage }}</td>
                    <td>{{ plan.amount }}</td>
                    
                  </tr>
                  <tr>
                      <td class="last-cell"> Y mas....</td>
                      <td class="last-cell"></td>
                  </tr>
                  <tr class="button-last">
                    <td colspan="2">
                      <button (click)="gotoTop()" class="last-row-button" id="quoteDailyPlanBasic">Cotizar</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
              </div>
          </swiper-slide>
              
            <swiper-slide> 
              <div class="table-container">
                <div class="table-title">
                  <div class="upper-elements">
                    <div class="text-header">
                    <span class="plan">PLAN</span>
                    <span class="bold-viajero">VIAJERO CASUAL</span>
                  </div>
                    
                  </div>
                    <span class="subtitle">Cobertura Mundial</span>
                  </div>
                   
                  <div class="table-wrapper">
              <table class="modern-table">
                <thead>
                  <tr>
                    <th class="header-cell">Cobertura</th>
                    <th class="header-cell">Monto</th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let plan of plans3">
                    <td>{{ plan.coverage }}</td>
                    <td>{{ plan.amount }}</td>
                    
                  </tr>
                  <tr>
                      <td class="last-cell"> Y mas....</td>
                      <td class="last-cell"></td>
                  </tr>
                  <tr class="button-last">
                    <td colspan="2">
                      <button (click)="gotoTop()" class="last-row-button" id="quoteDailyPlanCasual">Cotizar</button>
                    </td>
                    </tr>
                </tbody>
              </table>
            </div>
              </div>
          </swiper-slide>
          <swiper-slide> 
            <div class="table-container">
              <div class="table-title">
                <div class="upper-elements">
                  <div class="text-header">
                  <span class="plan">PLAN</span>
                  <span class="bold-viajero">VIAJERO PRO</span>
                </div>
                  
                </div>
                  <span class="subtitle">Cobertura Mundial</span>
                </div>
                 
                <div class="table-wrapper">
            <table class="modern-table">
              <thead>
                <tr>
                  <th class="header-cell">Cobertura</th>
                  <th class="header-cell">Monto</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let plan of plans">
                  <td>{{ plan.coverage }}</td>
                  <td>{{ plan.amount }}</td>
                  
                </tr>
                <tr>
                    <td class="last-cell"> Y mas....</td>
                    <td class="last-cell"></td>
                </tr>
                <tr class="button-last">
                  <td colspan="2">
                    <button (click)="gotoTop()" class="last-row-button" id="quoteDailyPlanPro">Cotizar</button>
                  </td>
                  </tr>
              </tbody>
            </table>
          </div>
            </div>
        </swiper-slide>
          </swiper-container>
        </div>
        <div *ngIf="!showContent && gs.discount" style="width: 100%; height: 100%;" [@fadeInOut]>
          <swiper-container class="custom-scrollbar" space-between="20" slides-per-view="1"  pagination="true" style=" 
              --swiper-pagination-bullet-width:25px;
              --swiper-pagination-bullet-height: 2px;
              --swiper-pagination-bullet-border-radius: 10%;
              --swiper-pagination-color: #FC146F;
             
          ">
          <swiper-slide>
            <div class="table-container">
              <div class="table-title">
                <div class="upper-elements">
                  <div class="text-header">
                  <span class="plan">PLAN</span>
                  <span class="bold-viajero">VIAJERO PRO</span>
                </div>
                  
                </div>
                  <span class="subtitle">Cobertura Mundial</span>
                </div>
                <div class="table-wrapper">
            <table class="modern-table">
              <thead>
                <tr>
                  <th class="header-cell">Cobertura</th>
                  <th class="header-cell">Monto</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let plan of plans4">
                  <td>{{ plan.coverage }}</td>
                  <td>{{ plan.amount }}</td>
                  
                </tr>
                <tr>
                    <td class="last-cell"> Y mas....</td>
                    <td class="last-cell"></td>
                </tr>
                <tr class="button-last">
                  <td colspan="2">
                    <button (click)="gotoTop()" class="last-row-button" id="quoteDailyPlanProDiscount">Cotizar</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
            </div>
        </swiper-slide>
            
          <swiper-slide> 
            <div class="table-container">
              <div class="table-title">
                <div class="upper-elements">
                  <div class="text-header">
                  <span class="plan">PLAN</span>
                  <span class="bold-viajero">VIAJERO ESTANDAR</span>
                </div>
                  
                </div>
                  <span class="subtitle">Cobertura Mundial</span>
                </div>
                 
                <div class="table-wrapper">
            <table class="modern-table">
              <thead>
                <tr>
                  <th class="header-cell">Cobertura</th>
                  <th class="header-cell">Monto</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let plan of plans5">
                  <td>{{ plan.coverage }}</td>
                  <td>{{ plan.amount }}</td>
                  
                </tr>
                <tr>
                    <td class="last-cell"> Y mas....</td>
                    <td class="last-cell"></td>
                </tr>
                <tr class="button-last">
                  <td colspan="2">
                    <button (click)="gotoTop()" class="last-row-button" id="quoteDailyPlanStandarDiscount">Cotizar</button>
                  </td>
                  </tr>
              </tbody>
            </table>
          </div>
            </div>
        </swiper-slide>
        <swiper-slide> 
          <div class="table-container">
            <div class="table-title">
              <div class="upper-elements">
                <div class="text-header">
                <span class="plan">PLAN</span>
                <span class="bold-viajero">VIAJERO PLUS</span>
              </div>
                
              </div>
                <span class="subtitle">Cobertura Mundial</span>
              </div>
               
              <div class="table-wrapper">
          <table class="modern-table">
            <thead>
              <tr>
                <th class="header-cell">Cobertura</th>
                <th class="header-cell">Monto</th>
                
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let plan of plans6">
                <td>{{ plan.coverage }}</td>
                <td>{{ plan.amount }}</td>
                
              </tr>
              <tr>
                  <td class="last-cell"> Y mas....</td>
                  <td class="last-cell"></td>
              </tr>
              <tr class="button-last">
                <td colspan="2">
                  <button (click)="gotoTop()" class="last-row-button" id="quoteDailyPlanPlusDiscount">Cotizar</button>
                </td>
                </tr>
            </tbody>
          </table>
        </div>
          </div>
      </swiper-slide>
        </swiper-container>
      </div>

        </div>












        <div class="benefits-container" #eleb>
            <div class="card-responsive">
                <div class="content-responsive">
                <div class="svg-container-responsive">
                    <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/shopping-cart_833314.webp" class="svg-icon-responsive"/>
                </div>
                <div class="separator"></div>
                <div class="description-responsive">
                  <p>
                    Compra digital <span style="color: #f4066f;">100%</span>
                    confiable, con certificación
                    PCI, ISO 9001, ISO/IEC <br>27001
                  </p>
                </div>
              </div>
              
            </div>
              
        </div>
        <div class="benefits-container">
            <div class="card-responsive">
                <div class="content-responsive">
                <div class="svg-container-responsive">
                    <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/trust_4371345.webp" class="svg-icon-responsive"/>
                </div>
                <div class="separator"></div>
                <div class="description-responsive">
                  <p>Son más de <span style="color: #f4066f;"> 1,500</span><br>
                      viajeros que han confiado en la<br>
                        asistencia de viajes con mayor cobertura</p>
                </div>
              </div>
              
            </div>
              
        </div>
        <div class="benefits-container">
            <div class="card-responsive">
                <div class="content-responsive">
                <div class="svg-container-responsive">
                    <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/Group-352.webp" class="svg-icon-responsive"/>
                </div>
                <div class="separator"></div>
                <div class="description-responsive">
                  <p>Somos una marca con más de <span style="color: #f4066f;">
                    700 </span>
                   colaboradores trabajando para brindarte la mejor experiencia</p>
                </div>
              </div>
              
            </div>
              
        </div>
        <div class="benefits-container">
            <div class="card-responsive">
                <div class="content-responsive">
                <div class="svg-container-responsive">
                    <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/customer-support_295.webp" class="svg-icon-responsive"/>
                </div>
                <div class="separator"></div>
                <div class="description-responsive">
                  <p>
                    Hemos asistido a nivel 
                     <span style="color: #f4066f;">global</span><br> países como:
                    EEUU, Japón, Costa Rica, México,<br>
                    España, Francia, Irlanda, Israel,<br>
                    Croacia
                  </p>
                </div>
              </div>
              
            </div>
              
        </div>
        <div class="benefits-container">
            <div class="card-responsive">
                <div class="content-responsive">
                <div class="svg-container-responsive">
                    <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/chat_8327128.webp" class="svg-icon-responsive"/>
                </div>
                <div class="separator"></div>
                <div class="description-responsive">
                  <p>
                    Nuestra línea de atención al cliente
                    te responde en menos de </p> <p style="color: #FC066F;">20 segundos</p>
                 
                </div>
              </div>
              
            </div>
              
        </div>
        <div class="benefits-container">
            <div class="card-responsive">
                <div class="content-responsive">
                <div class="svg-container-responsive">
                    <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/booking_2664673.webp" class="svg-icon-responsive"/>
                </div>
                <div class="separator"></div>
                <div class="description-responsive">
                  <p>Gestiona la compra de tu<br>
                    asistencia de viaje en solo<span style="color: #f4066f;">
                    2 </span> minutos</p>
                </div>
              </div>
              
            </div>
              
        </div>
        <div class="articles-title">
          <div class="pre-title-articles">
            ¿Buscas tips para tus viajes?
          </div>
          <div class="title-articles">
            ¡Conoce nuestro Blog!
          </div>
        </div>
        <div class="articles">
          <div class="sub-articles" >
            <!-- Sub articles de la página actual -->
            <swiper-container class="sub-articles2" slides-per-view="1"  pagination="true" style=" 
            --swiper-pagination-bullet-width:15px;
            --swiper-pagination-bullet-height: 15px;
            --swiper-pagination-bullet-horizontal-gap: 7px;
            --swiper-pagination-color: #ff056f;
           
        ">
        <ng-container >
        <swiper-slide class="article-item" *ngFor="let article of listOfArticles" id="toArticles">
          <!-- Contenido de los sub articles -->
          <img class="article-item-img" [src]="article.cover" [alt]="article.altPhoto" (click)="toArticle(article)"/>
          <div class="text-container">
            <p class="title-article">{{article.title}}</p>
            <!-- <button class="link-article" (click)="toArticle(article)">Leer Más</button> -->
            <img class="arrow-purple" (click)="toArticle(article)" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-right-circle-pink.svg" />
          </div>
          <!-- Agrega cualquier otro contenido de los sub articles aquí -->
        </swiper-slide>
        </ng-container>
      </swiper-container>
      </div>

      
            
          
        </div>
        <div class="reviews-title">
          <div class="title-review">
            Comentarios de Viajeros Destacados
          </div>
        </div>
        <div class="reviews">
          <ng-container>
              <swiper-container class="review-grid" slides-per-view="1"  pagination="true" style=" 
              --swiper-pagination-bullet-width:15px;
              --swiper-pagination-bullet-height: 15px;
              --swiper-pagination-bullet-horizontal-gap: 14px;
              --swiper-pagination-color: #FF9900;
            
          ">
                  <swiper-slide class="testimony">
                      <div class="testimonial">
                          <div class="user-info">
                            <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/reviews/Seguro_de_viaje_Muvit_1.webp" alt="User 1" class="user-photo">
                            <div class="title-user">
                              <div class="review-title">
                                {{ user_rating[0].title }}
                              </div>
                              <div class="rating">
                                <ng-container *ngFor="let _ of getArray(user_rating[0].rating); let i = index">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" fill="#FFCF38" class="bi bi-star-fill" viewBox="0 0 16 16" style="margin-right: 8%;"> 
                                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> 
                                  </svg>
                                </ng-container>
                                <ng-container *ngFor="let _ of getArray(5 - user_rating[0].rating); let i = index">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" fill="#605f5f" class="bi bi-star-fill" viewBox="0 0 16 16" style="margin-right: 8%;"> 
                                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> 
                                  </svg>
                                </ng-container>
                              </div>
                            </div>
                            </div>
                          <div class="review">
                          <p class="review-text">
                              {{ user_rating[0].review }}
                          </p>
                          <p class="review-text" style="font-weight: bold;">
                              - {{ user_rating[0].user }} -
                          </p>
                      </div>
                        </div>
                  </swiper-slide>
                  <swiper-slide class="testimony">
                      <div class="testimonial">
                          <div class="user-info">
                            <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/reviews/Seguro_de_viaje_Muvit_2.webp" alt="User 1" class="user-photo">
                            <div class="title-user">
                              <div class="review-title">
                                {{ user_rating[1].title }}
                              </div>
                              <div class="rating">
                                <ng-container *ngFor="let _ of getArray(user_rating[1].rating); let i = index">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" fill="#FFCF38" class="bi bi-star-fill" viewBox="0 0 16 16" style="margin-right: 8%;"> 
                                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> 
                                  </svg>
                                </ng-container>
                                <ng-container *ngFor="let _ of getArray(5 - user_rating[1].rating); let i = index">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" fill="#605f5f" class="bi bi-star-fill" viewBox="0 0 16 16" style="margin-right: 8%;"> 
                                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> 
                                  </svg>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                          
                          <div class="review">
                          <p class="review-text">
                              {{ user_rating[1].review }}
                          </p>
                          <p class="review-text" style="font-weight: bold;">
                              - {{ user_rating[1].user }} -
                          </p>
                      </div>
                        </div>
                  </swiper-slide>
                  <swiper-slide class="testimony">
                      <div class="testimonial">
                          <div class="user-info">
                            <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/reviews/Seguro_de_viaje_Muvit_3.webp" alt="User 1" class="user-photo">
                            <div class="title-user">
                              <div class="review-title">
                                {{ user_rating[2].title }}
                              </div>
                              <div class="rating">
                                <ng-container *ngFor="let _ of getArray(user_rating[2].rating); let i = index">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" fill="#FFCF38" class="bi bi-star-fill" viewBox="0 0 16 16" style="margin-right: 8%;"> 
                                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> 
                                  </svg>
                                </ng-container>
                                <ng-container *ngFor="let _ of getArray(5 - user_rating[2].rating); let i = index">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" fill="#605f5f" class="bi bi-star-fill" viewBox="0 0 16 16" style="margin-right: 8%;"> 
                                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> 
                                  </svg>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                          <div class="review">
                          <p class="review-text">
                              {{ user_rating[2].review }}
                          </p>
                          <p class="review-text" style="font-weight: bold;">
                            - {{ user_rating[2].user }} -
                          </p>
                      </div>
                        </div>
                  </swiper-slide>
              </swiper-container>
          
              
      
          </ng-container>
        </div>
      </div>
    
      <ng-template #elseBlock>
        
      <div class="center-container">
        <div *ngIf="showContent" style="width: 100%; height: 100%;" [@slideRight]>
          
          <swiper-container class="custom-scrollbar" space-between="15" slides-per-view="3" pagination="true" style="height: fit-content!important;">
            <swiper-slide class="table-item"> 
                <div class="table-container">
                    <div class="table-title" style="border-color: #C01DBF;">
                      <div class="upper-elements" >
                        <div class="text-header">
                        <span class="plan">PLAN</span>
                        <span class="bold-viajero">50K</span>
                      </div>
                        <div class="svg-arrow-icon">
                        <span class="arrow-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M5 12h14M12 5l7 7-7 7" />
                          </svg>
                        </span>
                      </div>
                      </div>
                        <span class="subtitle">Cobertura Mundial</span>
                      </div>
                     
                    <div class="table-wrapper">
                <table class="modern-table">
                  <thead>
                    <tr>
                      <th class="header-cell">Cobertura</th>
                      <th class="header-cell">Monto</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let plan of plansYearly">
                      <td>{{ plan.coverage }}</td>
                      <td>{{ plan.amount }}</td>
                      
                    </tr>
                    <tr>
                        <td class="last-cell"> Y mas....</td>
                        <td class="last-cell"></td>
                    </tr>
                    <tr class="button-last">
                      <td colspan="2">
                        <button (click)="gotoTop()" class="last-row-button" style="background-color: #C01DBF;" id="quoteYearlyPlan50K">Cotizar</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
                </div>
            </swiper-slide>
            <swiper-slide class="table-item">
                <div class="table-container">
                  <div class="table-title" style="border-color: #C01DBF;">
                    <div class="upper-elements">
                      <div class="text-header">
                      <span class="plan">PLAN</span>
                      <span class="bold-viajero">100K</span>
                    </div>
                      <div class="svg-arrow-icon">
                      <span class="arrow-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
                          <path d="M5 12h14M12 5l7 7-7 7" />
                        </svg>
                      </span>
                    </div>
                    </div>
                      <span class="subtitle">Cobertura Mundial</span>
                    </div>
                     
                    <div class="table-wrapper">
                <table class="modern-table">
                  <thead>
                    <tr>
                      <th class="header-cell">Cobertura</th>
                      <th class="header-cell">Monto</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let plan of plansYearly2">
                      <td>{{ plan.coverage }}</td>
                      <td>{{ plan.amount }}</td>
                      
                    </tr>
                    <tr>
                        <td class="last-cell"> Y mas....</td>
                        <td class="last-cell"></td>
                    </tr>
                    <tr class="button-last">
                      <td colspan="2">
                        <button (click)="gotoTop()" class="last-row-button " style="background-color: #C01DBF;" id="quoteYearlyPlan100K">Cotizar</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
                </div>
            </swiper-slide>
            <swiper-slide class="table-item">
                <div class="table-container">
                  <div class="table-title" style="border-color: #C01DBF;">
                    <div class="upper-elements">
                      <div class="text-header">
                      <span class="plan">PLAN</span>
                      <span class="bold-viajero">150K </span>
                    </div>
                      <div class="svg-arrow-icon">
                      <span class="arrow-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
                          <path d="M5 12h14M12 5l7 7-7 7" />
                        </svg>
                      </span>
                    </div>
                    </div>
                      <span class="subtitle">Cobertura Mundial</span>
                    </div>
                    <div class="table-wrapper">
                <table class="modern-table">
                  <thead>
                    <tr>
                      <th class="header-cell">Cobertura</th>
                      <th class="header-cell">Monto</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let plan of plansYearly3">
                      <td>{{ plan.coverage }}</td>
                      <td>{{ plan.amount }}</td>
                      
                    </tr>
                    <tr>
                        <td class="last-cell"> Y mas....</td>
                        <td class="last-cell"></td>
                    </tr>
                    <tr class="button-last">
                        <td colspan="2">
                          <button (click)="gotoTop()" class="last-row-button" style="background-color: #C01DBF;" id="quoteYearlyPlan150K">Cotizar</button>
                        </td>
                      </tr>
                  </tbody>
                </table>
              </div>
                </div>
            </swiper-slide>
          </swiper-container>
        </div>  
         
     <div *ngIf="!showContent && !gs.discount" style="width: 100%; height: 100%;" [@slideRight] >
    
          <swiper-container class="custom-scrollbar" space-between="15" slides-per-view="3" pagination="true">
            <swiper-slide class="table-item">
              <div class="table-container">
                <div class="table-title">
                  <div class="upper-elements">
                    <div class="text-header">
                    <span class="plan">PLAN</span>
                    <span class="bold-viajero">VIAJERO BÁSICO </span>
                  </div>
                    <div class="svg-arrow-icon">
                    <span class="arrow-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
                        <path d="M5 12h14M12 5l7 7-7 7" />
                      </svg>
                    </span>
                  </div>
                  </div>
                    <span class="subtitle">Cobertura solo a LATAM</span>
                  </div>
                  <div class="table-wrapper">
              <table class="modern-table">
                <thead>
                  <tr>
                    <th class="header-cell">Cobertura</th>
                    <th class="header-cell">Monto</th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let plan of plans3">
                    <td>{{ plan.coverage }}</td>
                    <td>{{ plan.amount }}</td>
                    
                  </tr>
                  <tr>
                      <td class="last-cell"> Y mas....</td>
                      <td class="last-cell"></td>
                  </tr>
                  <tr class="button-last">
                      <td colspan="2">
                        <button (click)="gotoTop()" class="last-row-button" id="quoteDailyPlanBasic">Cotizar</button>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
              </div>
          </swiper-slide>
            <swiper-slide class="table-item">
                <div class="table-container">
                  <div class="table-title">
                    <div class="upper-elements">
                      <div class="text-header">
                      <span class="plan">PLAN</span>
                      <span class="bold-viajero">VIAJERO CASUAL</span>
                    </div>
                      <div class="svg-arrow-icon">
                      <span class="arrow-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
                          <path d="M5 12h14M12 5l7 7-7 7" />
                        </svg>
                      </span>
                    </div>
                    </div>
                      <span class="subtitle">Cobertura Mundial</span>
                    </div>
                     
                    <div class="table-wrapper">
                <table class="modern-table">
                  <thead>
                    <tr>
                      <th class="header-cell">Cobertura</th>
                      <th class="header-cell">Monto</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let plan of plans2">
                      <td>{{ plan.coverage }}</td>
                      <td>{{ plan.amount }}</td>
                      
                    </tr>
                    <tr>
                        <td class="last-cell"> Y mas....</td>
                        <td class="last-cell"></td>
                    </tr>
                    <tr class="button-last">
                      <td colspan="2">
                        <button (click)="gotoTop()" class="last-row-button" id="quoteDailyPlanCasual">Cotizar</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
                </div>
            </swiper-slide>
            
            <swiper-slide class="table-item"> 
              <div class="table-container">
                  <div class="table-title">
                    <div class="upper-elements">
                      <div class="text-header">
                      <span class="plan">PLAN</span>
                      <span class="bold-viajero">VIAJERO PRO</span>
                    </div>
                      <div class="svg-arrow-icon">
                      <span class="arrow-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
                          <path d="M5 12h14M12 5l7 7-7 7" />
                        </svg>
                      </span>
                    </div>
                    </div>
                      <span class="subtitle">Cobertura Mundial</span>
                    </div>
                   
                  <div class="table-wrapper">
              <table class="modern-table">
                <thead>
                  <tr>
                    <th class="header-cell">Cobertura</th>
                    <th class="header-cell">Monto</th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let plan of plans">
                    <td>{{ plan.coverage }}</td>
                    <td>{{ plan.amount }}</td>
                    
                  </tr>
                  <tr>
                      <td class="last-cell"> Y mas....</td>
                      <td class="last-cell"></td>
                  </tr>
                  <tr class="button-last">
                    <td colspan="2">
                      <button (click)="gotoTop()" class="last-row-button" id="quoteDailyPlanPro">Cotizar</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
              </div>
          </swiper-slide>
          </swiper-container>
        </div>
        <div *ngIf="!showContent && gs.discount" style="width: 100%; height: 100%;" [@slideRight] >
          
          <swiper-container class="custom-scrollbar" space-between="15" slides-per-view="3" pagination="true">
            <swiper-slide class="table-item"> 
                <div class="table-container">
                    <div class="table-title">
                      <div class="upper-elements">
                        <div class="text-header">
                        <span class="plan">PLAN</span>
                        <span class="bold-viajero">VIAJERO PRO</span>
                      </div>
                        <div class="svg-arrow-icon">
                        <span class="arrow-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M5 12h14M12 5l7 7-7 7" />
                          </svg>
                        </span>
                      </div>
                      </div>
                        <span class="subtitle">Cobertura Mundial</span>
                      </div>
                     
                    <div class="table-wrapper">
                <table class="modern-table">
                  <thead>
                    <tr>
                      <th class="header-cell">Cobertura</th>
                      <th class="header-cell">Monto</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let plan of plans4">
                      <td>{{ plan.coverage }}</td>
                      <td>{{ plan.amount }}</td>
                      
                    </tr>
                    <tr>
                        <td class="last-cell"> Y mas....</td>
                        <td class="last-cell"></td>
                    </tr>
                    <tr class="button-last">
                      <td colspan="2">
                        <button (click)="gotoTop()" class="last-row-button" id="quoteDailyPlanProDiscount">Cotizar</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
                </div>
            </swiper-slide>
            <swiper-slide class="table-item">
                <div class="table-container">
                  <div class="table-title">
                    <div class="upper-elements">
                      <div class="text-header">
                      <span class="plan">PLAN</span>
                      <span class="bold-viajero">VIAJERO ESTANDAR</span>
                    </div>
                      <div class="svg-arrow-icon">
                      <span class="arrow-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
                          <path d="M5 12h14M12 5l7 7-7 7" />
                        </svg>
                      </span>
                    </div>
                    </div>
                      <span class="subtitle">Cobertura Mundial</span>
                    </div>
                     
                    <div class="table-wrapper">
                <table class="modern-table">
                  <thead>
                    <tr>
                      <th class="header-cell">Cobertura</th>
                      <th class="header-cell">Monto</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let plan of plans5">
                      <td>{{ plan.coverage }}</td>
                      <td>{{ plan.amount }}</td>
                      
                    </tr>
                    <tr>
                        <td class="last-cell"> Y mas....</td>
                        <td class="last-cell"></td>
                    </tr>
                    <tr class="button-last">
                      <td colspan="2">
                        <button (click)="gotoTop()" class="last-row-button" id="quoteDailyPlanStandarDiscount">Cotizar</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
                </div>
            </swiper-slide>
            <swiper-slide class="table-item">
                <div class="table-container">
                  <div class="table-title">
                    <div class="upper-elements">
                      <div class="text-header">
                      <span class="plan">PLAN</span>
                      <span class="bold-viajero">VIAJERO PLUS </span>
                    </div>
                      <div class="svg-arrow-icon">
                      <span class="arrow-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
                          <path d="M5 12h14M12 5l7 7-7 7" />
                        </svg>
                      </span>
                    </div>
                    </div>
                      <span class="subtitle">Cobertura solo Mundial</span>
                    </div>
                    <div class="table-wrapper">
                <table class="modern-table">
                  <thead>
                    <tr>
                      <th class="header-cell">Cobertura</th>
                      <th class="header-cell">Monto</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let plan of plans6">
                      <td>{{ plan.coverage }}</td>
                      <td>{{ plan.amount }}</td>
                      
                    </tr>
                    <tr>
                        <td class="last-cell"> Y mas....</td>
                        <td class="last-cell"></td>
                    </tr>
                    <tr class="button-last">
                        <td colspan="2">
                          <button (click)="gotoTop()" class="last-row-button" id="quoteDailyPlanPlusDiscount">Cotizar</button>
                        </td>
                      </tr>
                  </tbody>
                </table>
              </div>
                </div>
            </swiper-slide>
          </swiper-container>
        </div>
      </div>
      <div class="benefits-container" #eleb2>
        <ng-container >
       <swiper-container class="grid-container" pagination="true" pagination-clickable="true" slides-per-view="3" grid-rows="2"
       space-between="10">
        <swiper-slide class="item">
            <div class="grid-item">
                <div class="svg-container">
                  <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/icons/circle-compra-digital.svg" class="svg-icon"/>
                    <!-- Your SVG content here -->
                  
                </div>
                <div class="description">
                  <p>Compra digital </p><span style="color: #f4066f;">100% </span>
                    <p>confiable, con certificación<br>
                    PCI, ISO 9001, ISO/IEC 27001</p>
                </div>
              </div>
              
              
        </swiper-slide>
        <swiper-slide class="item">
            <div class="grid-item">
                <div class="svg-container">
                   <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/icons/circle-asistencia-call.svg" class="svg-icon"/>
                </div>
                <div class="description">
                  <p>
                    Hemos asistido a nivel&nbsp;
                    <span style="color: #f4066f;">global</span>
                  </p>
                  <p>
                    &nbsp;países como:
                    EEUU, Japón, Costa Rica, México,
                    España, Francia, Irlanda, Israel,
                    Croacia
                  </p>
                  
                    
                </div>
              </div>
        </swiper-slide>
        <swiper-slide class="item">
            <div class="grid-item">
                <div class="svg-container">
                    <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/icons/circle-viajeros-confiando.svg" class="svg-icon"/>
                </div>
                <div class="description">
                    <p>Son más de</p> <span style="color: #f4066f;"> 1,500 </span>
                       <p> viajeros que han confiado en la
                        asistencia de viajes con mayor cobertura</p>
                </div>
              </div>
        </swiper-slide>
        <swiper-slide class="item">
            <div class="grid-item">
                <div class="svg-container">
                    <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/icons/circle-asistencia-chat.svg" class="svg-icon"/>
                </div>
                <div class="description">
                  <p>
                    Nuestra línea de atención al cliente
                    te responde en menos de </p> <p style="color: #FC066F;">20 segundos</p>
                </div>
              </div>
        </swiper-slide>
        <swiper-slide class="item">
            <div class="grid-item">
                <div class="svg-container">
                    <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/icons/circle-colaboradores.svg" class="svg-icon"/>
                </div>
                <div class="description">
                  <p>Somos una marca con más de </p><span style="color: #f4066f;">
                    700 </span>
                   <p>colaboradores trabajando para brindarte la mejor experiencia</p>
                </div>
              </div>
        </swiper-slide>
        <swiper-slide class="item">
            <div class="grid-item">
                <div class="svg-container">
                    <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/icons/circle-gestion-asistencia.svg" class="svg-icon"/>
                </div>
                <div class="description">
                    <p>Gestiona la compra de tu<br>
                        asistencia de viaje en solo</p><span style="color: #f4066f;">
                        2 </span> <p>minutos</p>
                </div>
              </div>
        </swiper-slide>
        
        
       </swiper-container>
       
        </ng-container>
    
      </div>
      <div class="articles-title">
        <div class="pre-title-articles">
          ¿Buscas tips para tus viajes?
        </div>
        <div class="title-articles">
          ¡Conoce nuestro Blog!
        </div>
      </div>
      <div class="articles">
        <div class="sub-articles" >
          <!-- Sub articles de la página actual -->
          <div class="article-item" *ngFor="let article of articles[currentPageIndex].subArticles" [@slideRight] id="toArticle">
            <!-- Contenido de los sub articles -->
            <img class="article-item-img" [src]="article.cover" [alt]="article.altPhoto" (click)="toArticle(article)"/>
            <div class="text-container">
              <p class="title-article">{{article.title}}</p>
              <button class="link-article" (click)="toArticle(article)">Leer Más</button>
              <img class="arrow-purple" (click)="toArticle(article)" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-right-circle-pink.svg" />
            </div>
            <!-- Agrega cualquier otro contenido de los sub articles aquí -->
          </div>
        </div>
  
        <div class="page-div">
          <button  (click)="prevPage()" class="page-btn" [disabled]="currentPageIndex === 0"> 
            <img *ngIf="currentPageIndex === 0" class="arrow-page-left"  src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-left.svg" />
            <img *ngIf="!(currentPageIndex === 0)" class="arrow-page-left"  src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-left-active-pink.svg" />
            Anterior </button>
          
          <!-- Números de página -->
        <div class="page-numbers">
          <ng-container *ngFor="let pageNumber of getPageNumbersToShow()">
            <div
              class="page-number"
              [ngClass]="{ 'current-page': pageNumber === currentPageIndex + 1 }"
              (click)="goToPage(pageNumber - 1)"
            >
              {{ pageNumber }}
          </div>
          </ng-container>
        </div>
        <button (click)="nextPage()" class="page-btn" [disabled]="currentPageIndex === articles.length - 1">
          Siguiente
          <img *ngIf="currentPageIndex === articles.length - 1" class="arrow-page-right"  src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-right.svg" />
          <img *ngIf="!(currentPageIndex === articles.length - 1)" class="arrow-page-right"  src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-right-active-pink.svg" />
        </button>
        </div>
      </div>
      <div class="reviews-title">
        <div class="title-review">
          Comentarios de Viajeros Destacados
        </div>
      </div>
      <div class="reviews">
        <ng-container>
            <swiper-container class="review-grid" slides-per-view="1"  pagination="true" style=" 
            --swiper-pagination-bullet-width:15px;
            --swiper-pagination-bullet-height: 15px;
            --swiper-pagination-bullet-horizontal-gap: 14px;
            --swiper-pagination-color: #FF9900;
          
        ">
                <swiper-slide class="testimony">
                    <div class="testimonial">
                        <div class="user-info">
                          <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/reviews/Seguro_de_viaje_Muvit_1.webp" alt="User 1" class="user-photo">
                          <div class="title-user">
                            <div class="review-title">
                              {{ user_rating[0].title }}
                            </div>
                            <div class="rating">
                              <ng-container *ngFor="let _ of getArray(user_rating[0].rating); let i = index">
                                <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" fill="#FFCF38" class="bi bi-star-fill" viewBox="0 0 16 16" style="margin-right: 8%;"> 
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> 
                                </svg>
                              </ng-container>
                              <ng-container *ngFor="let _ of getArray(5 - user_rating[0].rating); let i = index">
                                <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" fill="#605f5f" class="bi bi-star-fill" viewBox="0 0 16 16" style="margin-right: 8%;"> 
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> 
                                </svg>
                              </ng-container>
                            </div>
                          </div>
                          </div>
                        <div class="review">
                        <p class="review-text">
                            {{ user_rating[0].review }}
                        </p>
                        <p class="review-text" style="font-weight: bold;">
                            - {{ user_rating[0].user }} -
                        </p>
                    </div>
                      </div>
                </swiper-slide>
                <swiper-slide class="testimony">
                    <div class="testimonial">
                        <div class="user-info">
                          <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/reviews/Seguro_de_viaje_Muvit_2.webp" alt="User 1" class="user-photo">
                          <div class="title-user">
                            <div class="review-title">
                              {{ user_rating[1].title }}
                            </div>
                            <div class="rating">
                              <ng-container *ngFor="let _ of getArray(user_rating[1].rating); let i = index">
                                <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" fill="#FFCF38" class="bi bi-star-fill" viewBox="0 0 16 16" style="margin-right: 8%;"> 
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> 
                                </svg>
                              </ng-container>
                              <ng-container *ngFor="let _ of getArray(5 - user_rating[1].rating); let i = index">
                                <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" fill="#605f5f" class="bi bi-star-fill" viewBox="0 0 16 16" style="margin-right: 8%;"> 
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> 
                                </svg>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                        
                        <div class="review">
                        <p class="review-text">
                            {{ user_rating[1].review }}
                        </p>
                        <p class="review-text" style="font-weight: bold;">
                            - {{ user_rating[1].user }} -
                        </p>
                    </div>
                      </div>
                </swiper-slide>
                <swiper-slide class="testimony">
                    <div class="testimonial">
                        <div class="user-info">
                          <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/reviews/Seguro_de_viaje_Muvit_3.webp" alt="User 1" class="user-photo">
                          <div class="title-user">
                            <div class="review-title">
                              {{ user_rating[2].title }}
                            </div>
                            <div class="rating">
                              <ng-container *ngFor="let _ of getArray(user_rating[2].rating); let i = index">
                                <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" fill="#FFCF38" class="bi bi-star-fill" viewBox="0 0 16 16" style="margin-right: 8%;"> 
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> 
                                </svg>
                              </ng-container>
                              <ng-container *ngFor="let _ of getArray(5 - user_rating[2].rating); let i = index">
                                <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" fill="#605f5f" class="bi bi-star-fill" viewBox="0 0 16 16" style="margin-right: 8%;"> 
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> 
                                </svg>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                        <div class="review">
                        <p class="review-text">
                            {{ user_rating[2].review }}
                        </p>
                        <p class="review-text" style="font-weight: bold;">
                          - {{ user_rating[2].user }} -
                        </p>
                    </div>
                      </div>
                </swiper-slide>
            </swiper-container>
        
            
    
        </ng-container>
      </div>
    </ng-template>


    <div class="fixed-container">
      <img (click)="gotoTop()" class="fixed-button" id="stickyQuoteBtn" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/boton-cotizar.svg"/>
          <!-- <svg xmlns="http://www.w3.org/2000/svg"  fill="white" class="bi bi-chevron-compact-up" viewBox="0.5 5 15 5"> 
              <path d="M7.776 5.553a.5.5 0 0 1 .448 0l6 3a.5.5 0 1 1-.448.894L8 6.56 2.224 9.447a.5.5 0 1 1-.448-.894l6-3z"/> 
          </svg> -->
          
        
    </div>
    
    
  </div>
  <!-- ---------------------------------------------------------------------------------PLANES------------------------------------------------------------------------------------------------- -->
 
  <div class="header-bg" *ngIf="showStep2">
    <div class="hamburger-menu" style="width: 2%; display: none;">
      <button class="menu-toggle" (click)="toggleMenu()">
        <span class="icon">
          <span class="bar" *ngIf="!isMenuActive"></span>
          <span class="bar" *ngIf="!isMenuActive"></span>
          <span class="bar" *ngIf="!isMenuActive"></span>
          <span class="close-icon" *ngIf="isMenuActive">X</span>
        </span>
      </button>
      <div class="menu" [ngClass]="{'active': isMenuActive}">
        <div class ="menu-container">
        <ul class="menu-list">
          
          <li>
            <span class="arrow-icon2">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
                <path d="M5 12h14M12 5l7 7-7 7" />
              </svg>
            </span>
            <a class="menu-element" (click) = "showTosPage()" routerLinkActive="active" rel="bookmark" target="_blank">Términos y Condiciones</a>
          </li>
          <li>
            <span class="arrow-icon2">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
                <path d="M5 12h14M12 5l7 7-7 7" />
              </svg>
            </span>
            <button class="menu-element" >Beneficios</button>
          
          </li>
          <li>
            <span class="arrow-icon2">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
                <path d="M5 12h14M12 5l7 7-7 7" />
              </svg>
            </span>
            <button class="menu-element" (click)="scrollToDiv('.footer_v2')">Redes Sociales</button>
            <ul class="submenu">
              <li><a class="menu-element" href="https://www.facebook.com/muvit.travel" target='_blank'>Facebook</a></li>
              <li><a class="menu-element" href="https://www.instagram.com/muvit.travel/?utm_source=google&utm_medium=banner&utm_campaign=visitas_trafico_icono_pagina_web" target='_blank'>Instagram</a></li>
              <li><a class="menu-element" href="https://www.tiktok.com/@muvit.travel?_t=8atH4Jl8OFq&_r=1" target='_blank'>TikTok</a></li>
              <li><a class="menu-element" href="https://bit.ly/44Kmw4T" target='_blank'>YouTube</a></li>
            </ul>
          </li>
          
        </ul>
        
        </div>
      </div>
    </div>
    <div class="muvit-logo" *ngIf="showStep2">
      <img class="header-logo" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit_logo.svg" alt="muvit logo" (click)="goToHome()" id="btnlogomuvit20">
    </div>
    
    <div class="hidden-desktop" *ngIf="showStep2" style="position: absolute; right: 40px; top: 25px; display: flex; align-items: center; cursor:pointer; z-index: 20;">
      
      <button (click)="showBlogPage()" routerLinkActive="active" rel="bookmark" target="_blank" class="p-terms" style="background-color: transparent; color: white; margin-left: 0px; margin-bottom: 0px; margin-top: 2px; margin-right: 40px; font-size: 16px;  border: 2px solid white; border-radius: 15px; width: 110px;" matTooltip="Blog Muvit" id="blogbtn">
        
          BLOG
      </button>
      
        
      
      <img style="height: 34px; width: 34px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/mundo.svg" alt="world"  (click)="openLanCur()">
      <!-- <svg style="color: white;" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
        <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
      </svg> -->
      <p style="color: white; margin-left: 5px; margin-bottom: 0px; margin-top: 2px; margin-right: 40px; font-weight: bold;"  (click)="openLanCur()">{{language.value}}/{{currency.value}}</p>
      <p class="p-terms" style="color: white; margin-left: 0px; margin-bottom: 0px; margin-top: 2px; margin-right: 40px; font-size: 18px;" matTooltip="Términos y condiciones">
        <a class="a-footer" (click) = "showTosPage()" routerLinkActive="active" rel="bookmark" target="_blank" id="tycbtn">
          <span style="color: #ffffff;">TYC</span></a>
      </p>
        
      <a style="margin-right: 15px;" href="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/muvit_documents/FAQ_muvit.pdf" target="_blank" id="divfaq5">
        <img class="icon-color" style="height: 50px; width: 50px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/FAQS3.svg" alt="faqs" id="divfaq6">
      </a> 
    </div>
    <div class="hidden-mobile" *ngIf="showStep2" style="position: absolute; right: 40px; top: 25px; display: flex; align-items: center; cursor:pointer; z-index: 20;">
      
      <button (click)="showBlogPage()" routerLinkActive="active" rel="bookmark" target="_blank" class="p-terms" style="background-color: transparent; color: white; margin-left: 0px; margin-bottom: 0px; margin-top: 3px; margin-right: -30px; font-size: 15px;  border: 2px solid white; border-radius: 15px; width: 80px;" matTooltip="Blog Muvit" id="blogbtn">
        
          BLOG
      </button>
      
        
      
      
    </div>
    
    </div>
  <div *ngIf="!isScreenWidthLessThan790 && showStep2;" style="display: flex; justify-content: center; align-items: center; position: relative ; padding-right: 10%; margin-top: 50px;" id="switchplantype2">
    <div >
      <span class="slider-text" style="color: #5b5b5b; font-size: 18px;  left: 0; margin-right: 10px;">Elige la modalidad de tu plan</span>
    </div>
    <div *ngIf="showContent">
    <span class="slider-text" style="color: #5b5b5b; font-size: 18px; font-weight: bold; left: 0; margin-right: 10px;">Diario</span>
  </div>
  <div *ngIf="!showContent">
    <span class="slider-text" style="color: #f4066f; font-size: 18px; font-weight: bold; left: 0; margin-right: 10px;">Diario</span>
  </div>
    <label  class="switch" id="ToggleTypeOfPlanStep2">
      <input  type="checkbox" [(ngModel)]="showContent" (ngModelChange)="taggleShowContent($event)" id="SwitchPlanTypeStep2">
      <div class="slider round">
        <!-- <span class="slider-text">{{ showContent ? 'Diario' : 'Anual' }}</span> -->
      </div>
    </label>
    <div *ngIf="!showContent">
      <span class="slider-text" style="color: #5b5b5b; font-size: 18px; font-weight: bold; left: 0; margin-left: 10px;">Anual</span>
    </div>
    <div *ngIf="showContent">
      <span class="slider-text" style="color: #C01DBF; font-size: 18px; font-weight: bold; left: 0; margin-left: 10px;">Anual</span>
    </div>
  </div>
  <div class="header-plans hidden-desktop" *ngIf="showStep2" style="margin-top: 20px; margin-bottom: 25px; box-shadow: 2px 0px 10px 0px #c5c5c5; margin: 50px; border-radius: 20px; background-color: #FAFAFA; width: 90%;">
    {{titleService.setTitle("Muvit - Planes")}}
    
    <div class="eigth-part" id="btnlogomuvit1">
      <div style="width: 100%; display: flex; flex-direction: row; align-items: center; height: 100%; justify-content: center;">
        <img  style="height: 22px; margin-right: 25px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/edit.svg" alt="personas">
        <p style="font-size: 16px; font-weight: 500; letter-spacing: 1px; font-family: 'Rubik'; color: #f4066f; margin-top: 15px;"> Edita tus datos</p>
          
        
      </div>
    </div>
    <!-- <div class="eigth-part">
      <div style="display: flex; align-items: center; height: 100%;" name="destiny" id="destiny">
        <div class="first-section">
          <img class="icon-color" style="height: 23px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/destino.svg" alt="destiny">
        </div>
        <div class="form-select second-section" style="border: 0px; background-color: white; display: flex; align-items: center; justify-content: initial;" id="toggle">
          <p style="font-family: Rubik; margin: 0px; width: 100%; text-align: initial; font-size: 14px;"><span *ngIf="destiny.name == null; else yesDestiny">Destino</span><ng-template #yesDestiny>{{destiny.name}}</ng-template></p>
        </div>
      </div>
    </div> -->
    <div class="eigth-part">
      <mat-form-field id="StartingDateStep2">
      <mat-datepicker #endPicker></mat-datepicker>
      <input MatIconSuffix matInput [matDatepicker]="pickerTravelStartingDate" id="labelTravelStartingDate" readonly (click)="pickerTravelStartingDate.open()"
        placeholder="{{ 'Fecha Ida' | translate }}"  [(ngModel)]="gs.currentInsuranceInfo.travel_starting_date"
        name="labelTravelStartingDate" #labelTravelStartingDate="ngModel" [min]="travelStartMinDate" (ngModelChange)="onChangeTravelStartDate()">
        <mat-datepicker-toggle matPrefix [for]="pickerTravelStartingDate" style="height: 25px; ">
        <mat-icon matDatepickerToggleIcon>
          <img class="icon-color" style="height: 25px; position: absolute; bottom: 15px; margin-right: 10px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/fecha_inicio.svg" alt="dates">
        </mat-icon>
        </mat-datepicker-toggle>
      <mat-datepicker #pickerTravelStartingDate></mat-datepicker>
    </mat-form-field>
    </div>
    <div class="eigth-part">
      <mat-form-field id="EndDateStep2">
      <input class="date-picker-input" MatIconSuffix matInput [matDatepicker]="pickerTravelEndDate" id="labelTravelEndDate" readonly (click)="pickerTravelEndDate.open()"
                            placeholder="{{ 'Fecha Vuelta' | translate }}" [(ngModel)]="gs.currentInsuranceInfo.travel_end_date"
                            name="labelTravelEndDate" #labelTravelEndDate="ngModel" style="border: none; background: transparent; min-width: 285px;" [min]="travelMinEndDate" [max]="travelMaxEndDate" (ngModelChange)="onChangeDates()" [disabled]="!gs.currentInsuranceInfo.travel_starting_date || showContent">
                            <mat-datepicker-toggle matPrefix [for]="pickerTravelEndDate" [disabled]="showContent">
                              <mat-icon matDatepickerToggleIcon>
                                <img class="icon-color" style="height: 25px; position: absolute; bottom: 10px; margin-right: 10px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/fecha_final.svg" alt="dates">
                              </mat-icon>
                            </mat-datepicker-toggle>
                          <mat-datepicker #pickerTravelEndDate ></mat-datepicker>
                        </mat-form-field>
                        </div>
    <div class="eigth-part" style="display: flex; flex-direction: row;">
      
      <div class="first-section">
        <img class="icon-color" style="height: 23px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/email.svg" alt="personas">
      </div>
      <div style="display: flex; flex-direction: column;">
      <div class="form-select second-section"  style="background-color: transparent; display: flex; align-items: center; justify-content: initial;" id="toggle">
        <!-- Utilizar el atributo type con el valor "email" y agregar una expresión regular para validar el formato -->
        <input id="EmailChangeStep2" type="email" [(ngModel)]="emailHolder" [ngClass]="{'invalid-email': !isEmailValid}" (input)="onEmailInput($event)" placeholder="Enter email" style="background-color: transparent; font-family: Rubik; margin: 0px; width: 100%; text-align: initial; font-size: 18px; border:0px; border-bottom: 1px solid #f4066f;" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$">
      
        <!-- Mostrar un mensaje de error cuando el correo electrónico no es válido -->
      
        <!-- Opcionalmente, puedes mantener las condiciones anteriores utilizando ngIf
        <ng-container *ngIf="gs.currentInsuranceInfo['accompanisttable'] && gs.currentInsuranceInfo.accompanisttable.length > 0">
          {{1 + gs.currentInsuranceInfo.accompanisttable.length}} Personas
        </ng-container> -->

      </div>
      <span *ngIf="!isEmailValid" class="error-message-email">correo electrónico es inválido</span>

    </div>
      
      
      
      
    </div>
    <div class="eigth-part" style="border-right: 0;">
      <div style="width:100%; height: 85px;" (click)="showPersons()" *ngIf="!showContent" id="PersonsStep2">
        <div style="display: flex; align-items: center; height: 85px; " id="persons" name="persons">
          <div class="first-col" style="height: 80px;" (click)="sPersonsS2()">
            <img class="icon-color" style="height: 27px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/personas.svg" alt="personas">
          </div>
          <div class="form-select second-col" style="border: 0px; background-color: white; display: flex; align-items: center; justify-content: initial;  border-bottom: #f4066f 1px solid; height: 40px; background-color: transparent; border-radius: 0px;" (click)="sPersonsS2()">
            <p style="margin: 0px; width: 100%; text-align: initial;"><span *ngIf="totalPersons > 0">{{totalPersons}}</span>  Personas</p>
            <div style="text-align: end;">
             
            </div>
          </div>
          
        </div>
        <div class="side-page" id="persons"  *ngIf="showPersonS2" style="background-color: white; z-index: 100; position: relative;">
          <!-- <div class="back-icon" (click)="goBack(3)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16" style="height: 20px; color:#ff056f;">
              <g fill="none" stroke="currentColor">
              <path stroke-width="2" d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
              </g>
            </svg>
          </div> -->
          
        
          <div id="person-form" class="persons-box-mob" style="width: 265px; border-radius: 5px; border: 1px solid #cccccc; border-top: 0px; z-index: 100; position: relative;">
            <div class="row persons-mob" style=" border-top: solid #C5C5C5 1px;margin-left: 0px; margin-right: 0px; padding-top: 10px;">
              <div class="col-6">
                <h3 class="title-persons">Adultos</h3>
                <p class="subtitle-persons">Hasta 64 años</p>
              </div>
              <div class="col-6">
                <div style="text-align: center; ">
                  <button class="button-persons" [disabled]="adults == 0" (click)="addPerson(0,1)">-</button>
                  <b>{{adults}}</b>
                  <button class="button-persons" (click)="addPerson(1,1)">+</button>
                </div>
              </div>
            </div>
            <div class="row persons-mob" style=" border-top: solid #C5C5C5 1px;margin-left: 0px; margin-right: 0px; padding-top: 10px;" >
              <div class="col-6" style="padding-right: 0px;">
                <h3 class="title-persons">Adultos Mayores</h3>
                <p class="subtitle-persons">Desde 65 años</p>
              </div>
              <div class="col-6">
                <div style="text-align: center; ">
                  <button class="button-persons" [disabled]="olds == 0" (click)="addPerson(0,2)">-</button>
                  <b>{{olds}}</b>
                  <button class="button-persons" (click)="addPerson(1,2)">+</button>
                </div>
              </div>
            </div>
            <div class="row persons-mob" style=" border-top: solid #C5C5C5 1px; margin-left: 0px; margin-right: 0px; padding-top: 10px;">
              <div class="col-6">
                <h3 class="title-persons">Niños/as</h3>
                <p class="subtitle-persons">0-17 años</p>
              </div>
              <div class="col-6">
                <div style="text-align: center; ">
                  <button class="button-persons" [disabled]="kids == 0" (click)="addPerson(0,3)">-</button>
                  <b>{{kids}}</b>
                  <button class="button-persons" [disabled]="adults == 0 && olds == 0" (click)="addPerson(1,3)">+</button>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        </div>
   
   
   
      </div>






  </div>
  <div *ngIf="showStep2" style="height: calc( 100% - 113px );">
    
    <!-- <div *ngIf="!isScreenWidthLessThan790" style="display: flex; justify-content: center; align-items: center; margin-top: 2%;">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="showContent">
        <div class="slider round">
          <span class="slider-text">{{ showContent ? 'Diario' : 'Anual' }}</span>
        </div>
      </label>
    </div> -->
  <div *ngIf="!showContent">
      <div class="hidden-mobile">
        <div class="muvit-logo-plans" style="display: none;" id="btnlogomuvit4">
          <img style="height: 55px; position: absolute; left: 0px; right: 0px; margin: auto; display: none;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/muvit.svg" alt="muvit logo" (click)="returnToHome()" id="btnlogomuvit5">
        </div>
        <div *ngIf="isScreenWidthLessThan790;" style="display: flex; justify-content: center; align-items: center; position: relative ; padding-right: 0%; margin-top: 10%; flex-direction: column;" id="switchplantype2">
          <div >
            <span class="slider-text" style="color: #5b5b5b; font-size: 18px;  left: 0; margin-right: 10px;">Elige la modalidad de tu plan</span>
          </div>
          <div style="display: flex; flex-direction: row;">
          <div *ngIf="showContent">
          <span class="slider-text" style="color: #5b5b5b; font-size: 18px; font-weight: bold; left: 0; margin-right: 10px;">Diario</span>
        </div>
        <div *ngIf="!showContent">
          <span class="slider-text" style="color: #f4066f; font-size: 18px; font-weight: bold; left: 0; margin-right: 10px;">Diario</span>
        </div>
          <label  class="switch" id="ToggleTypeOfPlanStep2Mobile">
            <input  type="checkbox" [(ngModel)]="showContent" (ngModelChange)="taggleShowContent($event)" id="SwitchPlanTypeStep2Mobile">
            <div class="slider round">
              <!-- <span class="slider-text">{{ showContent ? 'Diario' : 'Anual' }}</span> -->
            </div>
          </label>
          <div *ngIf="!showContent">
            <span class="slider-text" style="color: #5b5b5b; font-size: 18px; font-weight: bold; left: 0; margin-left: 10px;">Anual</span>
          </div>
          <div *ngIf="showContent">
            <span class="slider-text" style="color: #C01DBF; font-size: 18px; font-weight: bold; left: 0; margin-left: 10px;">Anual</span>
          </div>
        </div>
        </div>
        <div style=" margin-top: 40px; margin-right: 25px; margin-left: 25px; display: flex; justify-content: center;">
          <div class="row" style="box-shadow: 1px 0px 5px 0px #c5c5c5; border-radius: 10px; max-width: 380px; width: 100%;">
           
           
            <div class="col-12 border-bottom-grey" style="height: 75px;  padding-right: 0px; padding-left: 0px; display: flex; flex-direction: column;">
              
              <div style="width: 100%; display: flex; flex-direction: row; align-items: center; height: 100%; justify-content: left;">
                <img  style="height: 25px; margin-right: 12px; margin-left: 20px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/edit.svg" alt="personas">
                <p style="font-size: 16px; font-weight: 500; letter-spacing: 1px; font-family: 'Rubik'; color: #f4066f; margin-top: 15px;"> Edita tus datos</p>
                  
                
              </div>
           
            
            </div>
            
            
            
            <div class="col-12 border-bottom-grey" style="height: 75px;  padding-right: 0px; padding-left: 0px; display: flex; flex-direction: row-reverse; justify-content: start;">
              <mat-datepicker #endPicker></mat-datepicker>
              <input MatIconSuffix matInput [matDatepicker]="pickerTravelStartingDate" id="labelTravelStartingDate" readonly (click)="pickerTravelStartingDate.open()"
                placeholder="{{ 'Fecha Ida' | translate }}"  [(ngModel)]="gs.currentInsuranceInfo.travel_starting_date"
                name="labelTravelStartingDate" #labelTravelStartingDate="ngModel" [min]="travelStartMinDate" (ngModelChange)="onChangeTravelStartDate()" style="margin-left: 12px; border: none;">
                <mat-datepicker-toggle matPrefix [for]="pickerTravelStartingDate" style="height: 25px; margin-top: 12px; border: none">
                <mat-icon matDatepickerToggleIcon>
                  <img class="icon-color" style="height: 25px; position: absolute;  margin-left: 10px; margin-top: 0px; " src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/fecha_inicio.svg" alt="dates">
                </mat-icon>
                </mat-datepicker-toggle>
              <mat-datepicker #pickerTravelStartingDate></mat-datepicker>   
            </div>
            <div class="col-12 border-bottom-grey" style="height: 75px;  padding-right: 0px; padding-left: 0px; display: flex; flex-direction: row-reverse; justify-content: start;">
              <input MatIconSuffix matInput [matDatepicker]="pickerTravelEndDate" id="labelTravelEndDate" readonly (click)="pickerTravelEndDate.open()"
              placeholder="{{ 'Fecha Vuelta' | translate }}" [(ngModel)]="gs.currentInsuranceInfo.travel_end_date"
              name="labelTravelEndDate" #labelTravelEndDate="ngModel" [min]="travelMinEndDate" [max]="travelMaxEndDate" (ngModelChange)="onChangeDates()" [disabled]="!gs.currentInsuranceInfo.travel_starting_date || showContent" style="margin-left: 12px; border: none;">
              <mat-datepicker-toggle matPrefix [for]="pickerTravelEndDate" [disabled]="showContent">
                <mat-icon matDatepickerToggleIcon>
                  <img class="icon-color" style="height: 25px; position: absolute; margin-right: 15px; margin-top: 12px; margin-left: 10px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/fecha_final.svg" alt="dates">
                </mat-icon>
              </mat-datepicker-toggle>
            <mat-datepicker #pickerTravelEndDate></mat-datepicker>
            </div>
            <div class="col-12 border-bottom-grey" style="height: 75px;  padding-right: 0px; padding-left: 0px; display: flex; flex-direction: column;">
              <div style="display: flex; align-items: center; height: 75px; width: 100%;" id="persons" name="persons">
                <div class="first-col" style="margin-left: 8px;" (click)="showPersons()">
                  <img class="icon-color" style="height: 27px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/personas.svg" alt="personas">
                </div>
                <div class="form-select second-col" style="border: 0px; background-color: white; display: flex; align-items: center; justify-content: initial;" (click)="showPersons()">
                  <p style="margin: 0px; width: 100%; text-align: initial;"><span *ngIf="totalPersons > 0">{{totalPersons}}</span>  Personas</p>
                  <div style="text-align: end;">
                    
                  </div>
                </div>
                
              </div>
              <div *ngIf="persons" id="person-form"  class="persons-box" style="position: relative; z-index: 10;">
                <div>
                  <div  class="row" style="height:50px; display:flex; align-items: center;">
                    <div class="col-6">
                      <h3 class="title-persons">Adultos</h3>
                      <p class="subtitle-persons">Hasta 64 años</p>
                    </div>
                    <div class="col-6">
                      <div>
                        <button class="button-persons" [disabled]="adults == 0" (click)="addPerson(0,1)">-</button>
                        <b>{{adults}}</b>
                        <button class="button-persons" (click)="addPerson(1,1)">+</button>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="height:50px; display:flex; align-items: center;">
                    <div class="col-6" style="padding-right: 0px;">
                      <h3 class="title-persons">Adultos Mayores</h3>
                      <p class="subtitle-persons">Desde 65 años</p>
                    </div>
                    <div class="col-6">
                      <div>
                        <button class="button-persons" [disabled]="olds == 0" (click)="addPerson(0,2)">-</button>
                        <b>{{olds}}</b>
                        <button class="button-persons" (click)="addPerson(1,2)">+</button>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="height:50px; display:flex; align-items: center;">
                    <div class="col-6">
                      <h3 class="title-persons">Niños/as</h3>
                      <p class="subtitle-persons">0-17 años</p>
                    </div>
                    <div class="col-6">
                      <div>
                        <button class="button-persons" [disabled]="kids == 0" (click)="addPerson(0,3)">-</button>
                        <b>{{kids}}</b>
                        <button class="button-persons" [disabled]="adults == 0 && olds == 0" (click)="addPerson(1,3)">+</button>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="height:80px; display:flex; align-items: center;">
                    <div class="col-6"></div>
                    <div class="col-6">
                      <div>
                        <!-- <button class="btn apply-persons" [disabled]="disableApply" (click)="confirmPersons()" id="btnapplypersons">Aplicar2</button> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
           
            </div>
           
            <div class="col-12" style="height: 75px;  padding-right: 0px; padding-left: 0px;">
              <div style="display: flex; align-items: center; height: 100%;" name="persons" id="persons">
                <div class="first-section">
                  <img class="icon-color" style="height: 26px; margin-left: 20px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/email.svg" alt="personas">
                </div>
                <div class="form-select second-section" style="border: 0px; background-color: white; display: flex; align-items: center; justify-content: initial; flex-direction: column;" id="toggle">
                  <input type="email" [(ngModel)]="emailHolder" [ngClass]="{'invalid-email': !isEmailValid}" (input)="onEmailInput($event)" placeholder="Enter email" style="font-family: Rubik; margin: 0px; width: 90%; text-align: initial; font-size: 18px; border:0px; border-bottom: 1px solid #f4066f;" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$">
                  <span *ngIf="!isEmailValid" class="error-message-email">correo electrónico es inválido</span>

                </div>

              </div>
            </div>
  
          </div>
        </div>
       
        
      </div>
      <div class="row" style="margin-right: 0px; margin-left: 0px; height: 100%;">
        <div class="col-lg-4 border-r-plan bottom-plan" style="padding-right: 0px; padding-left: 0px; " >
          <!-- Plan casual -->
          <div id="plan1Card" class="division-plan-b" *ngIf="!isScreenWidthLessThan959" style="margin-top: -20px; padding-top: 30px; margin-bottom:-50px; display: grid; place-items: center; width: 100%; padding-left: 25px; padding-right: 25px; height: 330px;" order="">


            <div class="plan-background" id="planCasualCard" (click)="selectPlan(2) ; rightAndBack()" [ngClass]="{'opacity-plan': selectedPlan == 1 || selectedPlan == 3, 'plan-background-casual': gs.discount, 'plan-background-casual-no-desc': !gs.discount}">
              <div class="row top-row" style="height: 70%; justify-content: space-around; flex-direction: column; align-items: center;" id="planCasualCard-Title">
                <div class="row" style="justify-content: center; flex-wrap: nowrap; width: 100%;" id="planCasualCard-TitleDiv2">
                  <div style="height: 100%; display: flex; align-items: center; justify-content: center; text-align: center;" id="planCasualCard-TitleText">
                    <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 22px;" id="divcasualplan5">PLAN <b style="font-weight: 600; letter-spacing: 1px; font-size: 22px;">VIAJERO {{titulos[1]}}</b>
                      <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg> -->
                    </p>
                  </div>
                </div>
                <div *ngIf="gs.discount" class="row" style="justify-content: center; flex-wrap: nowrap; width: 100%;" id="planCasualCardDiscount">
                  <div style="height: 100%; display: flex; align-items: center; left: 55px; width:40%; justify-content: center;
                  position: relative;" id="planCasualCardDiscount-PrevPrice">
                    <p class="white" style="margin-bottom: 0px!important; font-size: 18px; text-decoration: line-through;" id="divcasualplan5">USD &nbsp;{{fullPrice[5] | number : '1.2-2'}}
                      <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg> -->
                    </p>
                  </div>
                  <div style="height: 100%; position: relative; left: 75px; top: -4px; text-align: center; width:30%;" id="planCasualCardDiscount-Percentage">
                    <p class="white" style="margin-bottom: 0px!important; font-size: 14px; " id="divcasualplan5">Hasta <br> <strong> 40% OFF</strong>
                      <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg> -->
                    </p>
                  </div>
                </div>
                <div class="row" style="width: 65%;" id="planCasualCardDiscount-Price">
                  <div style="display: grid; width: 100%; height: 100%; place-items: center;" id="planCasualCardDiscount-Price-Number"> 
                    <div [ngClass]="{'opacity-plan-amount': selectedPlan == 3 || selectedPlan == 1 , 'plan-selected-amount': selectedPlan == 2 || selectedPlan == 4}" id="divcasualplan9" style="height: 45px;">
                      <p [ngClass]="{'white': selectedPlan == 3 || selectedPlan == 1 , 'pink': selectedPlan == 2 || selectedPlan == 4}" class="center-plan-title" id="divcasualplan10"><span class="total-responsive" style="font-size: 24px; letter-spacing: 1px;">Total</span> <b style="font-weight: 800; font-size: 24px; letter-spacing: 1px;"><span class="currency-responsive">&nbsp;USD</span>&nbsp;{{fullPrice[1] | number : '1.2-2'}}</b></p>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="row " style="height: 30%; margin-right: 0px !important; margin-left: 0px !important;" id="divcasualplan11">
                <div class="col" id="divcasualplan12">
                  <div style="height: 100%; display: flex; align-items: center;" id="divcasualplan13">
                    <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 16px; margin-left:10px" id="divcasualplan14">Máximo global <br> <span style="font-weight: 600; font-size: 16px; letter-spacing: 1px;">USD {{coverage[1] | number:'1.0':'en-US' }}</span></p>
                    <div style="position: absolute; right: 15px; " id="divcasualplan15">
                      <p class="white total-responsive" style="display: flex; align-items: center; font-size: 12px;" id="divcasualplan16">Plan Recomendado 

                        <img class="icon-color" style="height: 42px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan17">
                      </p>
                      <img class="icon-color icon-only" style="height: 22px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan18">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="plan1CardMobile" class="division-plan-b" *ngIf="isScreenWidthLessThan959" style="padding-top: 10px; margin-top: 15px; margin-bottom:0px; display: grid; place-items: center; width: 100%; padding-left: 25px; padding-right: 25px; height: 230px;" order="">


            <div class="plan-background" id="planCasualCardMobile" (click)="selectPlan(2) ; rightAndBack()" [ngClass]="{'opacity-plan': selectedPlan == 1 || selectedPlan == 3, 'plan-background-casual': gs.discount, 'plan-background-casual-no-desc': !gs.discount}">
              <div class="row top-row" style="height: 70%; justify-content: space-around; flex-direction: column; align-items: center;" id="divcasualplan2">
                <div class="row" style="justify-content: center; flex-wrap: nowrap; width: 100%;" id="divcasualplan3">
                  <div style="height: 100%; display: flex; align-items: center; justify-content: center; text-align: center;" id="divcasualplan4">
                    <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 18px;" id="divcasualplan5">PLAN <b style="font-weight: 600; letter-spacing: 1px; font-size: 18px;">VIAJERO {{titulos[1]}}</b>
                      <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg> -->
                    </p>
                  </div>
                </div>
                <div *ngIf="gs.discount" class="row" style="justify-content: space-between; flex-wrap: nowrap; width: 100%;" id="planCasualCardDiscountMobile">
                  <div style="height: 100%; display: flex; align-items: center; width: 40%; left: 105px;
                  position: relative;" id="divcasualplan4">
                    <p class="white" style="margin-bottom: 0px!important; font-size: 16px; text-decoration: line-through;" id="divcasualplan5">USD &nbsp;{{fullPrice[5] | number : '1.2-2'}}
                      <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg> -->
                    </p>
                  </div>
                  <div style="height: 100%; position: relative;  top: -4px; text-align: center;" id="divcasualplan4">
                    <p class="white" style="margin-bottom: 0px!important; font-size: 13px; " id="divcasualplan5">Hasta <br> <strong> 40% OFF</strong>
                      <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg> -->
                    </p>
                  </div>
                </div>
                <div class="row" style="width: 65%;" id="divcasualplan7">
                  <div style="display: grid; width: 100%; height: 100%; place-items: center;" id="divcasualplan8"> 
                    <div [ngClass]="{'opacity-plan-amount': selectedPlan == 3 || selectedPlan == 1 , 'plan-selected-amount': selectedPlan == 2 || selectedPlan == 4}" id="divcasualplan9" style="height: 35px;">
                      <p [ngClass]="{'white': selectedPlan == 3 || selectedPlan == 1 , 'pink': selectedPlan == 2 || selectedPlan == 4}" class="center-plan-title" id="divcasualplan10"><span class="total-responsive" style="font-size: 18px; letter-spacing: 1px;">Total</span> <b style="font-weight: 800; font-size: 18px; letter-spacing: 1px;"><span class="currency-responsive">&nbsp;USD</span>&nbsp;{{fullPrice[1] | number : '1.2-2'}}</b></p>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="row " style="height: 30%; margin-right: 0px !important; margin-left: 0px !important;" id="divcasualplan11">
                <div class="col" id="divcasualplan12">
                  <div style="height: 100%; display: flex; align-items: center;" id="divcasualplan13">
                    <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 16px; margin-left:10px" id="divcasualplan14">Máximo global <br> <span style="font-weight: 600; font-size: 16px; letter-spacing: 1px;">USD {{coverage[1] | number:'1.0':'en-US' }}</span></p>
                    <div style="position: absolute; right: 15px; " id="divcasualplan15">
                      <p class="white total-responsive" style="display: flex; align-items: center; font-size: 12px;" id="divcasualplan16">Plan Recomendado 

                        <img class="icon-color" style="height: 42px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan17">
                      </p>
                      <img class="icon-color icon-only" style="height: 42px !important; margin-left: 4px; bottom: -20px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan18">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="hidden-mobile" style="display: flex; justify-content: center; position: relative; padding-right: 25px; padding-left: 25px;" *ngIf="selectedPlan == 2 && mobileSelected">
            <div style="width: 95%; border-radius: 15px;  max-width: 380px; margin-top: -42px; position: relative;">

              <mat-table #table [dataSource]="dataSource" style="border-radius: 0px 0px 15px 15px; padding-top: 44px; background: #DEDEDE; ">

                <!-- Detail Column -->
                <ng-container matColumnDef="detail">
                  <mat-cell *matCellDef="let element; let i = index;"> <p *ngIf="i == 0; else notIndexCero" style="font-weight: 900; margin-bottom: 0px;">{{element.detail}}</p><ng-template #notIndexCero>{{element.detail}}</ng-template></mat-cell>
                </ng-container>
            
                <!-- Value Column -->
                <ng-container matColumnDef="value">
                  <mat-cell style="text-align: end; font-size: 10px;" *matCellDef="let element"> {{element.value}} </mat-cell>
                </ng-container>
            
                <mat-row *matRowDef="let row; columns: displayedColumns;" ></mat-row>
              </mat-table>
              <div class="header-plan-details">
                <h3 style="font-size: 15px; width: 100%; margin-bottom: 0px;">PLAN <b style="font-weight: 800; font-size: 15px;">VIAJERO {{ titulos[selectedPlan - 1] }}</b></h3>
                <div style="display: flex;">
                  <!-- <div style="margin: 0 4px; display: grid;">
                    <img class="icon-color" style="height: 27px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/share.svg" alt="destiny">
                    <p style="font-size: 10px; color: #5b5b5b; text-align: center; margin-bottom: 0px;">Compartir</p>
                  </div> -->
                  <div style="margin: 0 4px; display: grid;" id="divprintquote">
                    <img (click)="getMuvitQuote()" class="icon-color" style="height: 27px; cursor : pointer;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/imprimirpdf.svg" id="pdfQuotePlan1Mobile" alt="destiny">
                    <p style="font-size: 10px; color: #5b5b5b; text-align: center; margin-bottom: 0px; display: none;">Imprimir</p>
                  </div>
                </div>
              </div>
              <div class="row" style="border-radius: 15px; border: solid 1px #c5c5c5; background: white; margin: 15px; color: #5b5b5b">
                <div class="col-6 border-bottom-grey"  style="height: 56px; border-right: solid 1px #c5c5c5; display: flex; align-items: center; justify-content: center;">
                  <p style="margin-bottom: 0px;">{{gs.currentInsuranceInfo.travel_starting_date |date:'dd/MM/yyyy' }} </p>
                </div>
                <div class="col-6 border-bottom-grey"  style="height: 56px; display: flex; align-items: center; justify-content: center;">
                  <p style="margin-bottom: 0px;">{{gs.currentInsuranceInfo.travel_end_date | date:'dd/MM/yyyy'}}</p>
                </div>
              
                <!-- <div class="col-12 border-bottom-grey" style="height: 56px;">
                  <p class="buy-detail-square">{{destiny.name}}</p>
                </div> -->
                <div class="col-12 border-bottom-grey" style="height: 56px;">
                  <p class="buy-detail-square" style="font-size: 12px;">{{totalPersons}} Persona(s) (<span *ngIf="adults >= 1"> &nbsp;{{adults}} adultos </span> ,<span *ngIf="olds >= 1"> &nbsp;{{olds}} adulto(s) Mayores </span> <span *ngIf="totalAdults == 1">{{totalAdults}} adulto </span> <span *ngIf="kids > 1"> &nbsp;y {{kids}} niños</span> <span *ngIf="kids ==1"> &nbsp;y {{kids}} niño</span>)</p>
                </div>
                <div class="col-12" style="height: 56px; border: #f4066f 1px solid; border-radius: 0px 0px 15px 15px;">
                  <p class="buy-detail-square" style="color: black; display: flex; flex-direction: column; text-align: center;">Total cotizado <span style="font-weight: 600; font-size: 22px; margin-left: 10px; min-width: 161px; max-width: 219px; line-height: 1.1;"> USD {{fullPrice[selectedPlan -1] | number : '1.2-2'}}</span></p>
                </div>
              </div>
              <div style="margin: 15px;">
                <div style="display:flex; align-items: center; height: 100%;">
                  <button class="btn buy white" (click)="buyPlan()" style="width: 100%;" id="buyPlan1Mobile">Comprar</button>
                </div>
              </div>

            </div>
          </div>
          <!-- Plan basico -->
          <div class="division-plan"  *ngIf="!isScreenWidthLessThan959" style="padding-top: 30px; margin-bottom:-50px; display: grid; place-items: center; width: 100%; padding-left: 25px; padding-right: 25px; height: 330px;"id="plan2Card" order="">
            <div class="plan-background" (click)="selectPlan(3); rightAndBack()" [ngClass]="{'opacity-plan': selectedPlan == 2 || selectedPlan == 1 , 'plan-background-basic': gs.discount, 'plan-background-basic-no-desc': !gs.discount}" id="planBasicCard">
              <div class="row top-row" style="height: 70%; justify-content: space-around; flex-direction: column; align-items: center;" id="divcasualplan2">
                <div class="row" style="justify-content: center; flex-wrap: nowrap; width: 100%;" id="divcasualplan3">
                  <div style="height: 100%; display: flex; align-items: center; justify-content: center; text-align: center;" id="divcasualplan4">
                    <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 22px;" id="divcasualplan5">PLAN <b style="font-weight: 600; letter-spacing: 1px; font-size: 22px;">VIAJERO {{titulos[2]}}</b>
                      <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg> -->
                    </p>
                  </div>
                </div>
                <div *ngIf="gs.discount" class="row" style="justify-content: center; flex-wrap: nowrap; width: 100%;" id="planBasicCardDiscount">
                  <div style="height: 100%; display: flex; align-items: center; position: relative; left: 55px; width:40%; justify-content: center;" id="divcasualplan4">
                    <p class="white" style="margin-bottom: 0px!important; font-size: 18px; text-decoration: line-through;" id="divcasualplan5">USD &nbsp;{{fullPrice[4] | number : '1.2-2'}}
                      <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg> -->
                    </p>
                  </div>

                  <div style="height: 100%; position: relative; left: 75px; top: -4px; text-align: center; width:30%;" id="divcasualplan4">
                    <p class="white" style="margin-bottom: 0px!important; font-size: 14px; " id="divcasualplan5">Hasta <br> <strong> 50% OFF</strong>
                      <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg> -->
                    </p>
                  </div>
                </div>
                
                <div class="row" style="width: 65%;" id="divcasualplan7">
                  <div style="display: grid; width: 100%; height: 100%; place-items: center;" id="divcasualplan8"> 
                    <div [ngClass]="{'opacity-plan-amount': selectedPlan == 2 || selectedPlan == 1 , 'plan-selected-amount': selectedPlan == 3 || selectedPlan == 4}" id="divbasicplan10" style="height: 45px;">
                      <p  [ngClass]="{'white': selectedPlan == 2 || selectedPlan == 1 , 'pink': selectedPlan == 3 || selectedPlan == 4}" class="center-plan-title" id="divbasicplan11"><span class="total-responsive" id="divbasicplan18"style="font-size: 24px; letter-spacing: 1px;">Total</span> <b style="font-weight: 800; font-size: 24px; letter-spacing: 1px;"><span class="currency-responsive" id="divbasicplan18">&nbsp;USD</span>&nbsp;{{fullPrice[2] | number : '1.2-2'}}</b></p>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="row " style="height: 30%; margin-right: 0px !important; margin-left: 0px !important;" id="divcasualplan11">
                <div class="col" id="divcasualplan12">
                  <div style="height: 100%; display: flex; align-items: center;" id="divcasualplan13">
                    <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 16px; margin-left:10px" id="divcasualplan14">Máximo global <br> <span style="font-weight: 600; font-size: 16px; letter-spacing: 1px;">USD {{coverage[2] | number:'1.0':'en-US' }}</span></p>
                    <!-- <div style="position: absolute; right: 15px; " id="divcasualplan15">
                      <p class="white total-responsive" style="display: flex; align-items: center; font-size: 12px;" id="divcasualplan16">Plan Recomendado 

                        <img class="icon-color" style="height: 42px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan17">
                      </p>
                      <img class="icon-color icon-only" style="height: 22px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan18">
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="plan2CardMobile" class="division-plan"  *ngIf="isScreenWidthLessThan959" style="padding-top: 10px; margin-top: 15px; margin-bottom:0px; display: grid; place-items: center; width: 100%; padding-left: 25px; padding-right: 25px; height: 230px;" order="">
            <div class="plan-background" (click)="selectPlan(3); rightAndBack()" [ngClass]="{'opacity-plan': selectedPlan == 2 || selectedPlan == 1 , 'plan-background-basic': gs.discount, 'plan-background-basic-no-desc': !gs.discount}" id="planBasicCardMobile">
              <div class="row top-row" style="height: 70%; justify-content: space-around; flex-direction: column; align-items: center;" id="divcasualplan2">
                <div class="row" style="justify-content: center; flex-wrap: nowrap; width: 100%;" id="divcasualplan3">
                  <div style="height: 100%; display: flex; align-items: center; justify-content: center; text-align: center;" id="divcasualplan4">
                    <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 18px;" id="divcasualplan5">PLAN <b style="font-weight: 600; letter-spacing: 1px; font-size: 18px;">VIAJERO {{titulos[2]}}</b>
                      <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg> -->
                    </p>
                  </div>
                </div>
                <div *ngIf="gs.discount" class="row" style="justify-content: space-between; flex-wrap: nowrap; width: 100%;" id="planBasicCardDiscountMobile">
                  <div style="height: 100%; display: flex; align-items: center; position: relative; left: 95px; width:40%; justify-content: center;" id="divcasualplan4">
                    <p class="white" style="margin-bottom: 0px!important; font-size: 16px; text-decoration: line-through;" id="divcasualplan5">USD &nbsp;{{fullPrice[4] | number : '1.2-2'}}
                      <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg> -->
                    </p>
                  </div>

                  <div style="height: 100%; position: relative;  top: -4px; text-align: center;" id="divcasualplan4">
                    <p class="white" style="margin-bottom: 0px!important; font-size: 13px; " id="divcasualplan5">Hasta <br> <strong> 50% OFF</strong>
                      <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg> -->
                    </p>
                  </div>
                </div>
                
                <div class="row" style="width: 65%;" id="divcasualplan7">
                  <div style="display: grid; width: 100%; height: 100%; place-items: center;" id="divcasualplan8"> 
                    <div [ngClass]="{'opacity-plan-amount': selectedPlan == 2 || selectedPlan == 1 , 'plan-selected-amount': selectedPlan == 3 || selectedPlan == 4}" id="divbasicplan10" style="height: 35px;">
                      <p  [ngClass]="{'white': selectedPlan == 2 || selectedPlan == 1 , 'pink': selectedPlan == 3 || selectedPlan == 4}" class="center-plan-title" id="divbasicplan11"><span class="total-responsive" id="divbasicplan18"style="font-size: 18px; letter-spacing: 1px;">Total</span> <b style="font-weight: 800; font-size: 18px; letter-spacing: 1px;"><span class="currency-responsive" id="divbasicplan18">USD</span>&nbsp;{{fullPrice[2] | number : '1.2-2'}}</b></p>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="row " style="height: 30%; margin-right: 0px !important; margin-left: 0px !important;" id="divcasualplan11">
                <div class="col" id="divcasualplan12">
                  <div style="height: 100%; display: flex; align-items: center;" id="divcasualplan13">
                    <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 16px; margin-left:10px" id="divcasualplan14">Máximo global <br> <span style="font-weight: 600; font-size: 16px; letter-spacing: 1px;">USD {{coverage[2] | number:'1.0':'en-US' }}</span></p>
                    <!-- <div style="position: absolute; right: 15px; " id="divcasualplan15">
                      <p class="white total-responsive" style="display: flex; align-items: center; font-size: 12px;" id="divcasualplan16">Plan Recomendado 

                        <img class="icon-color" style="height: 42px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan17">
                      </p>
                      <img class="icon-color icon-only" style="height: 22px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan18">
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="hidden-mobile" style="display: flex; justify-content: center; position: relative; padding-right: 25px; padding-left: 25px;" *ngIf="selectedPlan == 3 && mobileSelected">
            <div style="width: 95%; border-radius: 15px;  max-width: 380px; margin-top: -42px; position: relative;">

              <mat-table #table [dataSource]="dataSource" style="border-radius: 0px 0px 15px 15px; padding-top: 44px; background: #DEDEDE;">

                <!-- Detail Column -->
                <ng-container matColumnDef="detail">
                  <mat-cell *matCellDef="let element; let i = index;"> <p *ngIf="i == 0; else notIndexCero" style="font-weight: 900; margin-bottom: 0px;">{{element.detail}}</p><ng-template #notIndexCero>{{element.detail}}</ng-template></mat-cell>
                </ng-container>
            
                <!-- Value Column -->
                <ng-container matColumnDef="value">
                  <mat-cell style="text-align: end;" *matCellDef="let element"> {{element.value}} </mat-cell>
                </ng-container>
            
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
              <div class="header-plan-details">
                <h3 style="font-size: 15px; width: 100%; margin-bottom: 0px;">PLAN <b style="font-weight: 800; font-size: 15px;">VIAJERO {{ titulos[selectedPlan - 1] }}</b></h3>
                <div style="display: flex;">
                  <!-- <div style="margin: 0 4px; display: grid;">
                    <img class="icon-color" style="height: 27px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/share.svg" alt="destiny">
                    <p style="font-size: 10px; color: #5b5b5b; text-align: center; margin-bottom: 0px;">Compartir</p>
                  </div> -->
                  <div style="margin: 0 4px; display: grid;" id="divprintquote">
                    <img (click)="getMuvitQuote()" class="icon-color" style="height: 27px; cursor : pointer;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/imprimirpdf.svg" id="pdfQuotePlan2Mobile" alt="destiny">
                    <p style="font-size: 10px; color: #5b5b5b; text-align: center; margin-bottom: 0px; display: none;">Imprimir</p>
                  </div>
                </div>
              </div>
              <div class="row" style="border-radius: 15px; border: solid 1px #c5c5c5; background: white; margin: 15px; color: #5b5b5b">
                <div class="col-6 border-bottom-grey"  style="height: 56px; border-right: solid 1px #c5c5c5; display: flex; align-items: center; justify-content: center;">
                  <p style="margin-bottom: 0px;">{{gs.currentInsuranceInfo.travel_starting_date |date:'dd/MM/yyyy' }} </p>
                </div>
                <div class="col-6 border-bottom-grey"  style="height: 56px; display: flex; align-items: center; justify-content: center;">
                  <p style="margin-bottom: 0px;">{{gs.currentInsuranceInfo.travel_end_date | date:'dd/MM/yyyy'}}</p>
                </div>
              
                <!-- <div class="col-12 border-bottom-grey" style="height: 56px;">
                  <p class="buy-detail-square">{{destiny.name}}</p>
                </div> -->
                <div class="col-12 border-bottom-grey" style="height: 56px;">
                  <p class="buy-detail-square" style="font-size: 12px;">{{totalPersons}} Persona(s) (<span *ngIf="adults >= 1"> &nbsp;{{adults}} adultos </span> ,<span *ngIf="olds >= 1"> &nbsp;{{olds}} adulto(s) Mayores </span> <span *ngIf="totalAdults == 1">{{totalAdults}} adulto </span> <span *ngIf="kids > 1"> &nbsp;y {{kids}} niños</span> <span *ngIf="kids ==1"> &nbsp;y {{kids}} niño</span>)</p>
                </div>
                <div class="col-12" style="height: 56px; border: #f4066f 1px solid; border-radius: 0px 0px 15px 15px;">
                  <p class="buy-detail-square" style="color: black; display: flex; flex-direction: column; text-align: center;">Total cotizado <span style="font-weight: 600; font-size: 22px; margin-left: 10px; min-width: 161px; max-width: 219px; line-height: 1.1;"> USD {{fullPrice[selectedPlan -1] | number : '1.2-2'}}</span></p>
                </div>
              </div>
              <div style="margin: 15px;">
                <div style="display:flex; align-items: center; height: 100%;">
                  <button class="btn buy white" (click)="buyPlan()" style="width: 100%;" id="buyPlan2Mobile">Comprar</button>
                </div>
              </div>

            </div>
          </div>
          <!-- Plan pro -->
          <div id="plan3Card" class="division-plan-last" *ngIf="!isScreenWidthLessThan959" style="padding-top: 30px; margin-bottom:-50px; display: grid; place-items: center; width: 100%; padding-left: 25px; padding-right: 25px; height: 330px;" order="">
            <div id="planProCard" class="plan-background " (click)="selectPlan(1); rightAndBack()" [ngClass]="{'opacity-plan': selectedPlan == 2 || selectedPlan == 3 , 'plan-background-pro': gs.discount, 'plan-background-pro-no-desc': !gs.discount}" >
              <div class="row top-row" style="height: 70%; justify-content: space-around; flex-direction: column; align-items: center;" id="divcasualplan2">
                <div class="row" style="justify-content: center; flex-wrap: nowrap; width: 100%;" id="divcasualplan3">
                  <div style="height: 100%; display: flex; align-items: center;" id="divcasualplan4">
                    <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 22px;" id="divcasualplan5">PLAN <b style="font-weight: 600; letter-spacing: 1px; font-size: 22px;">VIAJERO {{titulos[0]}}</b>
                      <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg> -->
                    </p>
                  </div>
                </div>
                <div *ngIf="gs.discount" class="row" style="justify-content: center; flex-wrap: nowrap; width: 100%;" id="planProCardDiscount">
                  <div style="height: 100%; display: flex; align-items: center; position: relative; left: 55px; width:40%; justify-content: center;" id="divcasualplan4">
                    <p class="white" style="margin-bottom: 0px!important; font-size: 18px; text-decoration: line-through;" id="divcasualplan5">USD &nbsp;{{fullPrice[3] | number : '1.2-2'}}
                      <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg> -->
                    </p>
                  </div>
                  <div style="height: 100%; position: relative; left: 75px; top: -4px; text-align: center; width:30%;" id="divcasualplan4">
                    <p class="white" style="margin-bottom: 0px!important; font-size: 14px; " id="divcasualplan5">Hasta <br> <strong> 35% OFF</strong>
                      <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg> -->
                    </p>
                  </div>
                </div>
                <div class="row" style="width: 65%;" id="divcasualplan7">
                  <div style="display: grid; width: 100%; height: 100%; place-items: center;" id="divcasualplan8"> 
                    <div [ngClass]="{'opacity-plan-amount': selectedPlan == 3 || selectedPlan == 2 , 'plan-selected-amount': selectedPlan == 1 || selectedPlan == 4}" id="divproplan10" style="height: 45px;">
                      <p  [ngClass]="{'white': selectedPlan == 3 || selectedPlan == 2 , 'pink': selectedPlan == 1 || selectedPlan == 4}"  class="center-plan-title" id="divproplan11"><span class="total-responsive" id="divproplan12"style="font-size: 24px; letter-spacing: 1px;">Total</span> <b style="font-weight: 800; font-size: 24px; letter-spacing: 1px;"><span class="currency-responsive" id="divproplan14">&nbsp;USD</span>&nbsp;{{fullPrice[0] | number : '1.2-2'}}</b></p>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="row " style="height: 30%; margin-right: 0px !important; margin-left: 0px !important;" id="divcasualplan11">
                <div class="col" id="divcasualplan12">
                  <div style="height: 100%; display: flex; align-items: center;" id="divcasualplan13">
                    <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 16px; margin-left:10px" id="divcasualplan14">Máximo global <br> <span style="font-weight: 600; font-size: 16px; letter-spacing: 1px;">USD {{coverage[0] | number:'1.0':'en-US' }}</span></p>
                    <!-- <div style="position: absolute; right: 15px; " id="divcasualplan15">
                      <p class="white total-responsive" style="display: flex; align-items: center; font-size: 12px;" id="divcasualplan16">Plan Recomendado 

                        <img class="icon-color" style="height: 42px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan17">
                      </p>
                      <img class="icon-color icon-only" style="height: 22px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan18">
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="plan3CardMobile" class="division-plan-last" *ngIf="isScreenWidthLessThan959" style="padding-top: 10px; margin-top: 15px; margin-bottom:0px; display: grid; place-items: center; width: 100%; padding-left: 25px; padding-right: 25px; height: 230px;" order="">
            <div class="plan-background " (click)="selectPlan(1); rightAndBack()" [ngClass]="{'opacity-plan': selectedPlan == 2 || selectedPlan == 3 , 'plan-background-pro': gs.discount, 'plan-background-pro-no-desc': !gs.discount}" id="planProCardMobile">
              <div class="row top-row" style="height: 70%; justify-content: space-around; flex-direction: column; align-items: center;" id="divcasualplan2">
                <div class="row" style="justify-content: center; flex-wrap: nowrap; width: 100%;" id="divcasualplan3">
                  <div style="height: 100%; display: flex; align-items: center; justify-content: center; text-align: center;" id="divcasualplan4">
                    <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 18px;" id="divcasualplan5">PLAN <b style="font-weight: 600; letter-spacing: 1px; font-size: 18px;">VIAJERO {{titulos[0]}}</b>
                      <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg> -->
                    </p>
                  </div>
                </div>
                <div *ngIf="gs.discount" class="row" style="justify-content: space-between; flex-wrap: nowrap; width: 100%;" id="planProCardDiscountMobile">
                  <div style="height: 100%; display: flex; align-items: center; position: relative; left: 95px; width:40%; justify-content: center;" id="divcasualplan4">
                    <p class="white" style="margin-bottom: 0px!important; font-size: 16px; text-decoration: line-through;" id="divcasualplan5">USD &nbsp;{{fullPrice[3] | number : '1.2-2'}}
                      <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg> -->
                    </p>
                  </div>
                  <div style="height: 100%; position: relative;  top: -4px; text-align: center;" id="divcasualplan4">
                    <p class="white" style="margin-bottom: 0px!important; font-size: 13px; " id="divcasualplan5">Hasta <br> <strong> 35% OFF</strong>
                      <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg> -->
                    </p>
                  </div>
                </div>
                <div class="row" style="width: 65%;" id="divcasualplan7">
                  <div style="display: grid; width: 100%; height: 100%; place-items: center;" id="divcasualplan8"> 
                    <div [ngClass]="{'opacity-plan-amount': selectedPlan == 3 || selectedPlan == 2 , 'plan-selected-amount': selectedPlan == 1 || selectedPlan == 4}" id="divproplan10" style="height: 35px;">
                      <p  [ngClass]="{'white': selectedPlan == 3 || selectedPlan == 2 , 'pink': selectedPlan == 1 || selectedPlan == 4}"  class="center-plan-title" id="divproplan11"><span class="total-responsive" id="divproplan12"style="font-size: 18px; letter-spacing: 1px;">Total</span> <b style="font-weight: 800; font-size: 18px; letter-spacing: 1px;"><span class="currency-responsive" id="divproplan14">USD</span>&nbsp;{{fullPrice[0] | number : '1.2-2'}}</b></p>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="row " style="height: 30%; margin-right: 0px !important; margin-left: 0px !important;" id="divcasualplan11">
                <div class="col" id="divcasualplan12">
                  <div style="height: 100%; display: flex; align-items: center;" id="divcasualplan13">
                    <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 16px; margin-left:10px" id="divcasualplan14">Máximo global <br> <span style="font-weight: 600; font-size: 16px; letter-spacing: 1px;">USD {{coverage[0] | number:'1.0':'en-US' }}</span></p>
                    <!-- <div style="position: absolute; right: 15px; " id="divcasualplan15">
                      <p class="white total-responsive" style="display: flex; align-items: center; font-size: 12px;" id="divcasualplan16">Plan Recomendado 

                        <img class="icon-color" style="height: 42px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan17">
                      </p>
                      <img class="icon-color icon-only" style="height: 22px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan18">
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="hidden-mobile" style="display: flex; justify-content: center; position: relative; padding-right: 25px; padding-left: 25px;" *ngIf="selectedPlan == 1 && mobileSelected">
            <div style="width: 95%; border-radius: 15px;  max-width: 380px; margin-top: -42px; position: relative;">

              <mat-table #table [dataSource]="dataSource" style="border-radius: 0px 0px 15px 15px; padding-top: 44px; background: #DEDEDE;">

                <!-- Detail Column -->
                <ng-container matColumnDef="detail">
                  <mat-cell *matCellDef="let element; let i = index;"> <p *ngIf="i == 0; else notIndexCero" style="font-weight: 900; margin-bottom: 0px;">{{element.detail}}</p><ng-template #notIndexCero>{{element.detail}}</ng-template></mat-cell>
                </ng-container>
            
                <!-- Value Column -->
                <ng-container matColumnDef="value">
                  <mat-cell style="text-align: end;" *matCellDef="let element"> {{element.value}} </mat-cell>
                </ng-container>
            
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
              <div class="header-plan-details">
                <h3 style="font-size: 15px; width: 100%; margin-bottom: 0px;">PLAN <b style="font-weight: 800; font-size: 15px;">VIAJERO {{ titulos[selectedPlan - 1] }}</b></h3>
                <div style="display: flex;">
                  <!-- <div style="margin: 0 4px; display: grid;">
                    <img class="icon-color" style="height: 27px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/share.svg" alt="destiny">
                    <p style="font-size: 10px; color: #5b5b5b; text-align: center; margin-bottom: 0px;">Compartir</p>
                  </div> -->
                  <div style="margin: 0 4px; display: grid;" id="divprintquote">
                    <img (click)="getMuvitQuote()" class="icon-color" style="height: 27px; cursor : pointer;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/imprimirpdf.svg" id="pdfQuotePlan3Mobile" alt="destiny">
                    <p style="font-size: 10px; color: #5b5b5b; text-align: center; margin-bottom: 0px; display: none;">Imprimir</p>
                  </div>
                </div>
              </div>
              <div class="row" style="border-radius: 15px; border: solid 1px #c5c5c5; background: white; margin: 15px; color: #5b5b5b">
                <div class="col-6 border-bottom-grey"  style="height: 56px; border-right: solid 1px #c5c5c5; display: flex; align-items: center; justify-content: center;">
                  <p style="margin-bottom: 0px;">{{gs.currentInsuranceInfo.travel_starting_date |date:'dd/MM/yyyy' }} </p>
                </div>
                <div class="col-6 border-bottom-grey"  style="height: 56px; display: flex; align-items: center; justify-content: center;">
                  <p style="margin-bottom: 0px;">{{gs.currentInsuranceInfo.travel_end_date | date:'dd/MM/yyyy'}}</p>
                </div>
              
                <!-- <div class="col-12 border-bottom-grey" style="height: 56px;">
                  <p class="buy-detail-square">{{destiny.name}}</p>
                </div> -->
                <div class="col-12 border-bottom-grey" style="height: 56px;">
                  <p class="buy-detail-square" style="font-size: 12px;">{{totalPersons}} Persona(s) (<span *ngIf="adults >= 1"> &nbsp;{{adults}} adultos </span> ,<span *ngIf="olds >= 1"> &nbsp;{{olds}} adulto(s) Mayores </span> <span *ngIf="totalAdults == 1">{{totalAdults}} adulto </span> <span *ngIf="kids > 1"> &nbsp;y {{kids}} niños</span> <span *ngIf="kids ==1"> &nbsp;y {{kids}} niño</span>)</p>
                </div>
                <div class="col-12" style="height: 56px; border: #f4066f 1px solid; border-radius: 0px 0px 15px 15px;">
                  <p class="buy-detail-square" style="color: black; display: flex; flex-direction: column; text-align: center;">Total cotizado <span style="font-weight: 600; font-size: 22px; margin-left: 10px; min-width: 161px; max-width: 219px; line-height: 1.1;"> USD {{fullPrice[selectedPlan -1] | number : '1.2-2'}}</span></p>
                </div>
              </div>
              <div style="margin: 15px;">
                <div style="display:flex; align-items: center; height: 100%;">
                  <button class="btn buy white" (click)="buyPlan()" style="width: 100%;" id="buyPlan3Mobile">Comprar</button>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!-- [@slideRight]="true" -->
        <div class="col-lg-8 hidden-desktop" [@slideRight]="true">
          <!-- <div style="margin: 45px 45px 25px 45px;" [@animationPlan]="position" (@animationPlan.start) = "hideAnimation($event)" (@animationPlan.done) = "showAnimation($event)"> -->
        <!-- [@moveRightAndBack2]="!moveback ? true : false" -->
        <div style="/*margin: 45px 45px 25px 45px;*/ transition: all 750ms ease-in-out;"  [class.move-right]="animate" >
        <div style="width: 56%; display: flex; min-height: 65px; border-radius: 10px; background: white; border: #f4066f 3px solid;  flex-direction: row; justify-content: space-between;">
          <h3 style="font-size: 24px; margin-right: 12px; margin-left:20px  ; margin-top: 15px;"> PLAN <b style="font-weight: 600;">VIAJERO {{ titulos[selectedPlan - 1] }}</b></h3>
          <div *ngIf="selectedPlan == 2" style=" justify-items: end; display: grid; text-align: right;">
            <div style="background-color: #ff056f; border-radius: 28px; width: 130px; height: 28px; display: flex; margin-left: -5px; margin-top: 15px; margin-right: 10px;">
              <div style="height: 100%; display: flex; align-items: center; margin: 0px 5px;">
                <img class="icon-color" style="height: 20px !important; margin-left: 4px; color: white; " src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star">
              </div>
              <div style="display: flex; align-items: center; color: white; font-size: 12px; margin: 0px 4px;">Recomendado</div>
            </div>
          </div>
        </div>
            <div style="display: flex;">
              <div style="width: 58%; margin-right: 22px; display: grid; place-items: center; margin-top: -8; z-index: -1;">
                <div style="width: 100%; border-radius: 10px; box-shadow: 2px 0px 5px 0px #c5c5c5;  max-width: 900px;">
                  
                  <mat-table #table [dataSource]="dataSource" style=" overflow: hidden !important;">

                    <!-- Detail Column -->
                    <ng-container matColumnDef="detail">
                      <mat-cell *matCellDef="let element; let i = index;" style="border: none;"> <p *ngIf="i == 0; else notIndexCero" style="font-weight: 600; margin-bottom: 0px;">{{element.detail}}</p><ng-template #notIndexCero>{{element.detail}}</ng-template></mat-cell>
                    </ng-container>
                
                    <!-- Value Column -->
                    <ng-container matColumnDef="value">
                      <mat-cell style="text-align: end; border: none;" *matCellDef="let element"> {{element.value}} </mat-cell>
                    </ng-container>
                
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>
                  <div class="header-plan-details" style="justify-content: center; border: none; margin-top: 20px; margin-bottom: 20px;">


                    <div style="display: flex; width: 100%;  flex-direction: column; border-radius: 10px; border:#c5c5c5 2px solid">
                        <p class="coverage-text" style="font-weight: normal; margin-top: 15px; font-size: 20px; text-align: center; margin-bottom: 0px; width: 100%; line-height: 1.15;">MÁXIMO GLOBAL</p>
                        <span style="font-weight: 600; font-size: 28px; margin-bottom: 0px; text-align: center; min-width: 182px;"> USD {{coverage[selectedPlan -1] | number:'1.0':'en-US' }}</span>
                        <br>
                  </div>

                  </div>
                </div>
              </div>
              <div style="width: 43%; margin-left: 22px;">
                <div style="width: 85%; border-radius: 15px; border: solid 0px #c5c5c5; background: #FAFAFA; max-width: 720px; box-shadow: 2px 0px 5px 0px #c5c5c5c5;">  
                  <div class="header-plan-details" style="border-bottom: 0;">
                                        <p style=" font-size: 20px; margin-bottom: 0px; width: 100%; margin-left: 10px;">Detalle de Compra</p>
                    <div style="display: flex;">
                      <!-- <div style="margin: 0 8px; display: grid;">
                        <img class="icon-color" style="height: 27px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/share.svg" alt="destiny">
                        <p style="font-size: 10px; color: #5b5b5b; text-align: center; margin-bottom: 0px;">Compartir</p>
                      </div> -->
                      <div style="margin: 0 8px; display: grid;">
                        <img (click)="getMuvitQuote()" id="divprintquote" class="icon-color" style="height: 37px; cursor: pointer;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/imprimirpdf.svg" id="pdfQuotePlan" alt="destiny">
                        <p style="font-size: 0px; color: #5b5b5b; text-align: center; margin-bottom: 0px;">Imprimir</p>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="border-radius: 15px; border: solid 1px #c5c5c5; background: white; margin: 15px; color: #5b5b5b">
                    <div class="col-6 border-bottom-grey"  style="height: 99px; border-right: solid 1px #c5c5c5;">
                      <p style="margin-top: 10px;"><b>Desde:</b></p>
                      <p style="text-transform: uppercase;">{{gs.currentInsuranceInfo.travel_starting_date | date: 'dd/MM/yyyy'}}</p>
                    </div>
                    <div class="col-6 border-bottom-grey"  style="height: 99px;">
                      <p style="margin-top: 10px;"><b>Hasta:</b></p>
                      <p style="text-transform: uppercase;">{{gs.currentInsuranceInfo.travel_end_date | date: 'dd/MM/yyyy' }}</p>
                    </div>
                  
                    <!-- <div class="col-12 border-bottom-grey" style="height: 99px;">
                      <p class="buy-detail-square">{{destiny.name}}</p>
                    </div> -->
                    <div class="col-12 border-bottom-grey" style="height: 99px;">
                      <p class="buy-detail-square" style="font-size: 12px;">{{totalPersons}} Persona(s) (<span *ngIf="adults >= 1"> &nbsp;{{adults}} adultos </span> ,<span *ngIf="olds >= 1"> &nbsp;{{olds}} adulto(s) Mayores </span> <span *ngIf="totalAdults == 1">{{totalAdults}} adulto </span> <span *ngIf="kids > 1"> &nbsp;y {{kids}} niños</span> <span *ngIf="kids ==1"> &nbsp;y {{kids}} niño</span>)</p>
                    </div>
                    <div class="col-12" style="height: 99px; border: #f4066f 2px solid; border-radius: 10px;">
                      <p class="buy-detail-square" style="color: black; display: flex; flex-direction: column;">Total cotizado <span style="font-weight: 600; font-size: 24px; margin-left: 10px; line-height: 1.15; margin-top: 5px; "> USD {{fullPrice[selectedPlan -1] | number : '1.2-2'}}</span></p>
                    </div>
                  </div>
                  <div style="margin: 21px 30px; padding-bottom: 15px; ">
                    <div style="display:flex; align-items: center; height: 100%;">
                      <button class="btn buy" id="buyPlanDaily" (click)="buyPlan()" style="height: 60px;" [disabled]="!isEmailValid">Comprar</button>
                    </div>
                  </div>

                  
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
  </div>
  <div *ngIf="showContent">
    <div class="hidden-mobile" >
      <div class="muvit-logo-plans" style="display: none;" id="btnlogomuvit4">
        <img style="height: 55px; position: absolute; left: 0px; right: 0px; margin: auto; display: none;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/muvit.svg" alt="muvit logo" (click)="returnToHome()" id="btnlogomuvit5">
      </div>
      <div *ngIf="isScreenWidthLessThan790;" style="display: flex; justify-content: center; align-items: center; position: relative ; padding-right: 0%; margin-top: 10%; flex-direction: column;" id="switchplantype2">
        <div >
          <span class="slider-text" style="color: #5b5b5b; font-size: 18px;  left: 0; margin-right: 10px;">Elige la modalidad de tu plan</span>
        </div>
        <div style="display: flex; flex-direction: row;">
        <div *ngIf="showContent">
        <span class="slider-text" style="color: #5b5b5b; font-size: 18px; font-weight: bold; left: 0; margin-right: 10px;">Diario</span>
      </div>
      <div *ngIf="!showContent">
        <span class="slider-text" style="color: #f4066f; font-size: 18px; font-weight: bold; left: 0; margin-right: 10px;">Diario</span>
      </div>
        <label  class="switch" id="ToggleTypeOfPlanStep2MobileYearly">
          <input  type="checkbox" [(ngModel)]="showContent" (ngModelChange)="taggleShowContent($event)" id="SwitchPlanTypeStep2MobileYearly">
          <div class="slider round">
            <!-- <span class="slider-text">{{ showContent ? 'Diario' : 'Anual' }}</span> -->
          </div>
        </label>
        <div *ngIf="!showContent">
          <span class="slider-text" style="color: #5b5b5b; font-size: 18px; font-weight: bold; left: 0; margin-left: 10px;">Anual</span>
        </div>
        <div *ngIf="showContent">
          <span class="slider-text" style="color: #C01DBF; font-size: 18px; font-weight: bold; left: 0; margin-left: 10px;">Anual</span>
        </div>
      </div>
      </div>
      <div style=" margin-top: 40px; margin-right: 25px; margin-left: 25px; display: flex; justify-content: center;">
        <div class="row" style="box-shadow: 1px 0px 5px 0px #c5c5c5; border-radius: 10px; max-width: 380px; width: 100%;">
         
         
          <div class="col-12 border-bottom-grey" style="height: 75px;  padding-right: 0px; padding-left: 0px; display: flex; flex-direction: column;">
            
            <div style="width: 100%; display: flex; flex-direction: row; align-items: center; height: 100%; justify-content: left;">
              <img  style="height: 25px; margin-right: 12px; margin-left: 20px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/edit.svg" alt="personas">
              <p style="font-size: 16px; font-weight: 500; letter-spacing: 1px; font-family: 'Rubik'; color: #f4066f; margin-top: 15px;"> Edita tus datos</p>
                
              
            </div>
         
          
          </div>
          
          
          
          <div class="col-12 border-bottom-grey" style="height: 75px;  padding-right: 0px; padding-left: 0px; display: flex; flex-direction: row-reverse; justify-content: start;">
            <mat-datepicker #endPicker></mat-datepicker>
            <input MatIconSuffix matInput [matDatepicker]="pickerTravelStartingDate" id="labelTravelStartingDate" readonly (click)="pickerTravelStartingDate.open()"
              placeholder="{{ 'Fecha Ida' | translate }}"  [(ngModel)]="gs.currentInsuranceInfo.travel_starting_date"
              name="labelTravelStartingDate" #labelTravelStartingDate="ngModel" [min]="travelStartMinDate" (ngModelChange)="onChangeTravelStartDate()" style="margin-left: 12px; border:none">
              <mat-datepicker-toggle matPrefix [for]="pickerTravelStartingDate" style="height: 25px; margin-top: 12px; ">
              <mat-icon matDatepickerToggleIcon>
                <img class="icon-color" style="height: 25px; position: absolute;  margin-left: 10px; margin-top: 0px; " src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/fecha_inicio.svg" alt="dates">
              </mat-icon>
              </mat-datepicker-toggle>
            <mat-datepicker #pickerTravelStartingDate></mat-datepicker>   
          </div>
          <div class="col-12 border-bottom-grey" style="height: 75px;  padding-right: 0px; padding-left: 0px; display: flex; flex-direction: row-reverse; justify-content: start;">
            <input MatIconSuffix matInput [matDatepicker]="pickerTravelEndDate" id="labelTravelEndDate" readonly (click)="pickerTravelEndDate.open()"
            placeholder="{{ 'Fecha Vuelta' | translate }}" [(ngModel)]="gs.currentInsuranceInfo.travel_end_date"
            name="labelTravelEndDate" #labelTravelEndDate="ngModel" [min]="travelMinEndDate" [max]="travelMaxEndDate" (ngModelChange)="onChangeDates()" [disabled]="!gs.currentInsuranceInfo.travel_starting_date || showContent" style="margin-left: 12px; border:none">
            <mat-datepicker-toggle matPrefix [for]="pickerTravelEndDate" [disabled]="showContent">
              <mat-icon matDatepickerToggleIcon>
                <img class="icon-color" style="height: 25px; position: absolute; margin-right: 15px; margin-top: 12px; margin-left: 10px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/fecha_final.svg" alt="dates">
              </mat-icon>
            </mat-datepicker-toggle>
          <mat-datepicker #pickerTravelEndDate></mat-datepicker>
          </div>
          
         
          <div class="col-12" style="height: 75px;  padding-right: 0px; padding-left: 0px;">
            <div style="display: flex; align-items: center; height: 100%;" name="persons" id="persons">
              <div class="first-section">
                <img class="icon-color" style="height: 26px; margin-left: 20px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/email.svg" alt="personas">
              </div>
              <div class="form-select second-section" style="border: 0px; background-color: white; display: flex; align-items: center; justify-content: initial; flex-direction: column;" id="toggle">
                <input type="email" [(ngModel)]="emailHolder" [ngClass]="{'invalid-email': !isEmailValid}" (input)="onEmailInput($event)" placeholder="Enter email" style="font-family: Rubik; margin: 0px; width: 90%; text-align: initial; font-size: 18px; border:0px; border-bottom: 1px solid #f4066f;" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$">
                <span *ngIf="!isEmailValid" class="error-message-email">correo electrónico es inválido</span>

              </div>

            </div>
          </div>

        </div>
      </div>
     
      <!-- <div *ngIf="isScreenWidthLessThan790" style="display: flex; justify-content: center; align-items: center; margin-top: 2%;">
        <label class="switch">
          <input type="checkbox" [(ngModel)]="showContent">
          <div class="slider round">
            <span class="slider-text">{{ showContent ? 'Diario' : 'Anual' }}</span>
          </div>
        </label>
      </div> -->
    </div>
    <div class="row" style="margin-right: 0px; margin-left: 0px; height: 100%;">
      <div class="col-lg-4 border-r-plan bottom-plan" [@fadeInOut] style="padding-right: 0px; padding-left: 0px;" >
        <!-- Plan casual -->
        <div class="division-plan-b" *ngIf="!isScreenWidthLessThan959" style="margin-top: -20px; padding-top: 30px; margin-bottom:-50px; display: grid; place-items: center; width: 100%; padding-left: 25px; padding-right: 25px; height: 330px;"id="plan1CardYearly" order="">
          <div class="plan-background plan-background-casual-no-desc" id="plan100kCardYearly" (click)="selectPlan(2) ; rightAndBack()" [ngClass]="{'opacity-plan': selectedPlan == 1 || selectedPlan == 3}">
            <div class="row top-row" style="height: 70%; justify-content: space-around; flex-direction: column; align-items: center;" id="divcasualplan2">
              <div class="row" style="justify-content: center; flex-wrap: nowrap; width: 100%;" id="divcasualplan3">
                <div style="height: 100%; display: flex; align-items: center; justify-content: center; text-align: center;" id="divcasualplan4">
                  <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 22px;" id="divcasualplan5">PLAN <b style="font-weight: 600; letter-spacing: 1px; font-size: 22px;">VIAJERO {{titulosYearly[1]}}</b>
                    <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg> -->
                  </p>
                </div>
              </div>
              
              <div class="row" style="width: 65%;" id="divcasualplan7">
                <div style="display: grid; width: 100%; height: 100%; place-items: center;" id="divcasualplan8"> 
                  <div [ngClass]="{'opacity-plan-amount': selectedPlan == 3 || selectedPlan == 1 , 'plan-selected-amount': selectedPlan == 2 || selectedPlan == 4}" id="divcasualplan9" style="height: 45px;">
                    <p [ngClass]="{'white': selectedPlan == 3 || selectedPlan == 1 , 'purple': selectedPlan == 2 || selectedPlan == 4}" class="center-plan-title" id="divcasualplan10"><span class="total-responsive" style="font-size: 24px; letter-spacing: 1px;">Total</span> <b style="font-weight: 800; font-size: 24px; letter-spacing: 1px;"><span class="currency-responsive">&nbsp;USD</span>&nbsp;{{fullPriceYearly[1] | number : '1.2-2'}}</b></p>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div class="row " style="height: 30%; margin-right: 0px !important; margin-left: 0px !important;" id="divcasualplan11">
              <div class="col" id="divcasualplan12">
                <div style="height: 100%; display: flex; align-items: center;" id="divcasualplan13">
                  <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 16px; margin-left:10px" id="divcasualplan14">Máximo global <br> <span style="font-weight: 600; font-size: 16px; letter-spacing: 1px;">USD {{coverageYearly[1] | number:'1.0':'en-US' }}</span></p>
                  <div style="position: absolute; right: 15px; " id="divcasualplan15">
                    <p class="white total-responsive" style="display: flex; align-items: center; font-size: 12px;" id="divcasualplan16">Plan Recomendado 

                      <img class="icon-color" style="height: 42px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan17">
                    </p>
                    <img class="icon-color icon-only" style="height: 22px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan18">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="division-plan-b" *ngIf="isScreenWidthLessThan959" style="padding-top: 30px; margin-bottom: 0px; display: grid; place-items: center; width: 100%; padding-left: 25px; padding-right: 25px; height: 230px;"id="plan1CardYearly" order="">
          <div class="plan-background plan-background-casual-no-desc" id="plan100kCardYearlyMobile" (click)="selectPlan(2) ; rightAndBack()" [ngClass]="{'opacity-plan': selectedPlan == 1 || selectedPlan == 3}">
            <div class="row top-row" style="height: 70%; justify-content: space-around; flex-direction: column; align-items: center;" id="divcasualplan2">
              <div class="row" style="justify-content: center; flex-wrap: nowrap; width: 100%;" id="divcasualplan3">
                <div style="height: 100%; display: flex; align-items: center; justify-content: center; text-align: center;" id="divcasualplan4">
                  <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 18px;" id="divcasualplan5">PLAN <b style="font-weight: 600; letter-spacing: 1px; font-size: 18px;">VIAJERO {{titulosYearly[1]}}</b>
                    <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg> -->
                  </p>
                </div>
              </div>
              
              <div class="row" style="width: 65%;" id="divcasualplan7">
                <div style="display: grid; width: 100%; height: 100%; place-items: center;" id="divcasualplan8"> 
                  <div [ngClass]="{'opacity-plan-amount': selectedPlan == 3 || selectedPlan == 1 , 'plan-selected-amount': selectedPlan == 2 || selectedPlan == 4}" id="divcasualplan9" style="height: 45px;">
                    <p [ngClass]="{'white': selectedPlan == 3 || selectedPlan == 1 , 'purple': selectedPlan == 2 || selectedPlan == 4}" class="center-plan-title" id="divcasualplan10"><span class="total-responsive" style="font-size: 18px; letter-spacing: 1px;">Total</span> <b style="font-weight: 800; font-size: 18px; letter-spacing: 1px;"><span class="currency-responsive">&nbsp;USD</span>&nbsp;{{fullPriceYearly[1] | number : '1.2-2'}}</b></p>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div class="row " style="height: 30%; margin-right: 0px !important; margin-left: 0px !important;" id="divcasualplan11">
              <div class="col" id="divcasualplan12">
                <div style="height: 100%; display: flex; align-items: center;" id="divcasualplan13">
                  <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 16px; margin-left:10px" id="divcasualplan14">Máximo global <br> <span style="font-weight: 600; font-size: 16px; letter-spacing: 1px;">USD {{coverageYearly[1] | number:'1.0':'en-US' }}</span></p>
                  <div style="position: absolute; right: 15px; " id="divcasualplan15">
                    <p class="white total-responsive" style="display: flex; align-items: center; font-size: 12px;" id="divcasualplan16">Plan Recomendado 

                      <img class="icon-color" style="height: 42px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan17">
                    </p>
                    <img class="icon-color icon-only" style="height: 42px !important; margin-left: 4px; bottom: -20px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan18">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hidden-mobile" style="display: flex; justify-content: center; position: relative; padding-right: 25px; padding-left: 25px;" *ngIf="selectedPlan == 2 && mobileSelected">
          <div style="width: 95%; border-radius: 15px;  max-width: 380px; margin-top: -42px; position: relative;">

            <mat-table #table [dataSource]="dataSource" style="border-radius: 0px 0px 15px 15px; padding-top: 44px; background: #DEDEDE;">

              <!-- Detail Column -->
              <ng-container matColumnDef="detail">
                <mat-cell *matCellDef="let element; let i = index;"> <p *ngIf="i == 0; else notIndexCero" style="font-weight: 900; margin-bottom: 0px;">{{element.detail}}</p><ng-template #notIndexCero>{{element.detail}}</ng-template></mat-cell>
              </ng-container>
          
              <!-- Value Column -->
              <ng-container matColumnDef="value">
                <mat-cell style="text-align: end;" *matCellDef="let element"> {{element.value}} </mat-cell>
              </ng-container>
          
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <div class="header-plan-details">
              <h3 style="font-size: 15px; width: 100%; margin-bottom: 0px;">PLAN <b style="font-weight: 800; font-size: 15px;">VIAJERO {{ titulosYearly[selectedPlan - 1] }}</b></h3>
              <div style="display: flex;">
                <!-- <div style="margin: 0 4px; display: grid;">
                  <img class="icon-color" style="height: 27px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/share.svg" alt="destiny">
                  <p style="font-size: 10px; color: #5b5b5b; text-align: center; margin-bottom: 0px;">Compartir</p>
                </div> -->
                <div style="margin: 0 4px; display: grid;" id="divprintquote">
                  <img (click)="getMuvitQuote()" class="icon-color" style="height: 27px; cursor : pointer;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/imprimirpdfYear.svg" alt="destiny" id="pdfQuotePlan1YearlyMobile">
                  <p style="font-size: 10px; color: #5b5b5b; text-align: center; margin-bottom: 0px; display: none;">Imprimir</p>
                </div>
              </div>
            </div>
            <div class="row" style="border-radius: 15px; border: solid 1px #c5c5c5; background: white; margin: 15px; color: #5b5b5b">
              <div class="col-6 border-bottom-grey"  style="height: 56px; border-right: solid 1px #c5c5c5; display: flex; align-items: center; justify-content: center;">
                <p style="margin-bottom: 0px;">{{gs.currentInsuranceInfo.travel_starting_date |date:'dd/MM/yyyy' }} </p>
              </div>
              <div class="col-6 border-bottom-grey"  style="height: 56px; display: flex; align-items: center; justify-content: center;">
                <p style="margin-bottom: 0px;">{{gs.currentInsuranceInfo.travel_end_date | date:'dd/MM/yyyy'}}</p>
              </div>
            
              <!-- <div class="col-12 border-bottom-grey" style="height: 56px;">
                <p class="buy-detail-square">{{destiny.name}}</p>
              </div> -->
              <div class="col-12 border-bottom-grey" style="height: 56px;">
                <p class="buy-detail-square">1 Personas (<span *ngIf="totalAdults > 1">1 adultos </span> <span *ngIf="totalAdults == 1">1 adulto </span> <span *ngIf="kids > 1"> &nbsp;y {{kids}} niños</span> <span *ngIf="kids ==1"> &nbsp;y {{kids}} niño</span>)</p>
              </div>
              <div class="col-12" style="height: 56px; border: #C01DBF 1px solid; border-radius: 0px 0px 15px 15px;">
                <p class="buy-detail-square" style="color: black; display: flex; flex-direction: column; text-align: center;">Total cotizado <span style="font-weight: 600; font-size: 22px; margin-left: 10px; min-width: 161px; max-width: 219px; line-height: 1.1;"> USD {{fullPriceYearly[selectedPlan -1] | number : '1.2-2'}}</span></p>
              </div>
            </div>
            <div style="margin: 15px;">
              <div style="display:flex; align-items: center; height: 100%;">
                <button class="btn buy white" (click)="buyPlan()" style="width: 100%; background: #C01DBF;" id="buyPlan1YearlyMobile">Comprar</button>
              </div>
            </div>

          </div>
        </div>
        <!-- Plan basico -->
        <div class="division-plan" *ngIf="!isScreenWidthLessThan959" style="padding-top: 30px; margin-bottom:-50px; display: grid; place-items: center; width: 100%; padding-left: 25px; padding-right: 25px; height: 330px;"id="plan2CardYearly" order="">
          <div class="plan-background plan-background-basic-no-desc" (click)="selectPlan(3); rightAndBack()" [ngClass]="{'opacity-plan': selectedPlan == 2 || selectedPlan == 1}" id="plan50kCard">
            <div class="row top-row" style="height: 70%; justify-content: space-around; flex-direction: column; align-items: center;" id="divcasualplan2">
              <div class="row" style="justify-content: center; flex-wrap: nowrap; width: 100%;" id="divcasualplan3">
                <div style="height: 100%; display: flex; align-items: center; justify-content: center; align-items: center;" id="divcasualplan4">
                  <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 22px;" id="divcasualplan5">PLAN <b style="font-weight: 600; letter-spacing: 1px; font-size: 22px;">VIAJERO {{titulosYearly[2]}}</b>
                    <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg> -->
                  </p>
                </div>
              </div>
              
              
              <div class="row" style="width: 65%;" id="divcasualplan7">
                <div style="display: grid; width: 100%; height: 100%; place-items: center;" id="divcasualplan8"> 
                  <div [ngClass]="{'opacity-plan-amount': selectedPlan == 2 || selectedPlan == 1 , 'plan-selected-amount': selectedPlan == 3 || selectedPlan == 4}" id="divbasicplan10" style="height: 45px;">
                    <p  [ngClass]="{'white': selectedPlan == 2 || selectedPlan == 1 , 'purple': selectedPlan == 3 || selectedPlan == 4}" class="center-plan-title" id="divbasicplan11"><span class="total-responsive" id="divbasicplan18"style="font-size: 24px; letter-spacing: 1px;">Total</span> <b style="font-weight: 800; font-size: 24px; letter-spacing: 1px;"><span class="currency-responsive" id="divbasicplan18">USD</span>&nbsp;{{fullPriceYearly[2] | number : '1.2-2'}}</b></p>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div class="row " style="height: 30%; margin-right: 0px !important; margin-left: 0px !important;" id="divcasualplan11">
              <div class="col" id="divcasualplan12">
                <div style="height: 100%; display: flex; align-items: center;" id="divcasualplan13">
                  <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 16px; margin-left:10px" id="divcasualplan14">Máximo global <br> <span style="font-weight: 600; font-size: 16px; letter-spacing: 1px;">USD {{coverageYearly[2] | number:'1.0':'en-US' }}</span></p>
                  <!-- <div style="position: absolute; right: 15px; " id="divcasualplan15">
                    <p class="white total-responsive" style="display: flex; align-items: center; font-size: 12px;" id="divcasualplan16">Plan Recomendado 

                      <img class="icon-color" style="height: 42px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan17">
                    </p>
                    <img class="icon-color icon-only" style="height: 22px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan18">
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="division-plan" *ngIf="isScreenWidthLessThan959" style="padding-top: 30px; margin-bottom: 0px; display: grid; place-items: center; width: 100%; padding-left: 25px; padding-right: 25px; height: 230px;"id="plan2CardYearlyMobile" order="">
          <div class="plan-background plan-background-basic-no-desc" (click)="selectPlan(3); rightAndBack()" [ngClass]="{'opacity-plan': selectedPlan == 2 || selectedPlan == 1}" id="plan50kCardMobile">
            <div class="row top-row" style="height: 70%; justify-content: space-around; flex-direction: column; align-items: center;" id="divcasualplan2">
              <div class="row" style="justify-content: center; flex-wrap: nowrap; width: 100%;" id="divcasualplan3">
                <div style="height: 100%; display: flex; align-items: center; justify-content: center; text-align: center;" id="divcasualplan4">
                  <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 18px;" id="divcasualplan5">PLAN <b style="font-weight: 600; letter-spacing: 1px; font-size: 18px;">VIAJERO {{titulosYearly[2]}}</b>
                    <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg> -->
                  </p>
                </div>
              </div>
              
              
              <div class="row" style="width: 65%;" id="divcasualplan7">
                <div style="display: grid; width: 100%; height: 100%; place-items: center;" id="divcasualplan8"> 
                  <div [ngClass]="{'opacity-plan-amount': selectedPlan == 2 || selectedPlan == 1 , 'plan-selected-amount': selectedPlan == 3 || selectedPlan == 4}" id="divbasicplan10" style="height: 35px;">
                    <p  [ngClass]="{'white': selectedPlan == 2 || selectedPlan == 1 , 'purple': selectedPlan == 3 || selectedPlan == 4}" class="center-plan-title" id="divbasicplan11"><span class="total-responsive" id="divbasicplan18"style="font-size: 18px; letter-spacing: 1px;">Total</span> <b style="font-weight: 800; font-size: 18px; letter-spacing: 1px;"><span class="currency-responsive" id="divbasicplan18">USD</span>&nbsp;{{fullPriceYearly[2] | number : '1.2-2'}}</b></p>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div class="row " style="height: 30%; margin-right: 0px !important; margin-left: 0px !important;" id="divcasualplan11">
              <div class="col" id="divcasualplan12">
                <div style="height: 100%; display: flex; align-items: center;" id="divcasualplan13">
                  <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 16px; margin-left:10px" id="divcasualplan14">Máximo global <br> <span style="font-weight: 600; font-size: 16px; letter-spacing: 1px;">USD {{coverageYearly[2] | number:'1.0':'en-US' }}</span></p>
                  <!-- <div style="position: absolute; right: 15px; " id="divcasualplan15">
                    <p class="white total-responsive" style="display: flex; align-items: center; font-size: 12px;" id="divcasualplan16">Plan Recomendado 

                      <img class="icon-color" style="height: 42px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan17">
                    </p>
                    <img class="icon-color icon-only" style="height: 22px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan18">
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="hidden-mobile" style="display: flex; justify-content: center; position: relative; padding-right: 25px; padding-left: 25px;" *ngIf="selectedPlan == 3 && mobileSelected">
          <div style="width: 95%; border-radius: 15px;  max-width: 380px; margin-top: -42px; position: relative;">

            <mat-table #table [dataSource]="dataSource" style="border-radius: 0px 0px 15px 15px; padding-top: 44px; background: #DEDEDE;">

              <!-- Detail Column -->
              <ng-container matColumnDef="detail">
                <mat-cell *matCellDef="let element; let i = index;"> <p *ngIf="i == 0; else notIndexCero" style="font-weight: 900; margin-bottom: 0px;">{{element.detail}}</p><ng-template #notIndexCero>{{element.detail}}</ng-template></mat-cell>
              </ng-container>
          
              <!-- Value Column -->
              <ng-container matColumnDef="value">
                <mat-cell style="text-align: end;" *matCellDef="let element"> {{element.value}} </mat-cell>
              </ng-container>
          
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <div class="header-plan-details">
              <h3 style="font-size: 15px; width: 100%; margin-bottom: 0px;">PLAN <b style="font-weight: 800; font-size: 15px;">VIAJERO {{ titulosYearly[selectedPlan - 1] }}</b></h3>
              <div style="display: flex;">
                <!-- <div style="margin: 0 4px; display: grid;">
                  <img class="icon-color" style="height: 27px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/share.svg" alt="destiny">
                  <p style="font-size: 10px; color: #5b5b5b; text-align: center; margin-bottom: 0px;">Compartir</p>
                </div> -->
                <div style="margin: 0 4px; display: grid;" id="divprintquote">
                  <img (click)="getMuvitQuote()" class="icon-color" style="height: 27px; cursor : pointer;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/imprimirpdfYear.svg" alt="destiny" id="pdfQuotePlan2YearlyMobile">
                  <p style="font-size: 10px; color: #5b5b5b; text-align: center; margin-bottom: 0px; display: none;">Imprimir</p>
                </div>
              </div>
            </div>
            <div class="row" style="border-radius: 15px; border: solid 1px #c5c5c5; background: white; margin: 15px; color: #5b5b5b">
              <div class="col-6 border-bottom-grey"  style="height: 56px; border-right: solid 1px #c5c5c5; display: flex; align-items: center; justify-content: center;">
                <p style="margin-bottom: 0px;">{{gs.currentInsuranceInfo.travel_starting_date |date:'dd/MM/yyyy' }} </p>
              </div>
              <div class="col-6 border-bottom-grey"  style="height: 56px; display: flex; align-items: center; justify-content: center;">
                <p style="margin-bottom: 0px;">{{gs.currentInsuranceInfo.travel_end_date | date:'dd/MM/yyyy'}}</p>
              </div>
            
              <!-- <div class="col-12 border-bottom-grey" style="height: 56px;">
                <p class="buy-detail-square">{{destiny.name}}</p>
              </div> -->
              <div class="col-12 border-bottom-grey" style="height: 56px;">
                <p class="buy-detail-square">1 Personas (<span *ngIf="totalAdults > 1">1 adultos </span> <span *ngIf="totalAdults == 1">1 adulto </span> <span *ngIf="kids > 1"> &nbsp;y {{kids}} niños</span> <span *ngIf="kids ==1"> &nbsp;y {{kids}} niño</span>)</p>
              </div>
              <div class="col-12" style="height: 56px; border: #C01DBF 1px solid; border-radius: 0px 0px 15px 15px;">
                <p class="buy-detail-square" style="color: black; display: flex; flex-direction: column; text-align: center;">Total cotizado <span style="font-weight: 600; font-size: 22px; margin-left: 10px; min-width: 161px; max-width: 219px; line-height: 1.1;"> USD {{fullPriceYearly[selectedPlan -1] | number : '1.2-2'}}</span></p>
              </div>
            </div>
            <div style="margin: 15px;">
              <div style="display:flex; align-items: center; height: 100%;">
                <button class="btn buy white" (click)="buyPlan()" style="width: 100%; background: #C01DBF;" id="buyPlan2YearlyMobile">Comprar</button>
              </div>
            </div>

          </div>
        </div>
        <!-- Plan pro -->
        <div class="division-plan-last" *ngIf="!isScreenWidthLessThan959" style="padding-top: 30px; margin-bottom:-50px; display: grid; place-items: center; width: 100%; padding-left: 25px; padding-right: 25px; height: 330px;"id="plan3CardYearly" order="">
          <div class="plan-background plan-background-pro-no-desc" (click)="selectPlan(1); rightAndBack()" [ngClass]="{'opacity-plan': selectedPlan == 2 || selectedPlan == 3}" id="plan150kCardYearly">
            <div class="row top-row" style="height: 70%; justify-content: space-around; flex-direction: column; align-items: center;" id="divcasualplan2">
              <div class="row" style="justify-content: center; flex-wrap: nowrap; width: 100%;" id="divcasualplan3">
                <div style="height: 100%; display: flex; align-items: center; justify-content: center; text-align: center;" id="divcasualplan4">
                  <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 22px;" id="divcasualplan5">PLAN <b style="font-weight: 600; letter-spacing: 1px; font-size: 22px;">VIAJERO {{titulosYearly[0]}}</b>
                    <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg> -->
                  </p>
                </div>
              </div>
              
              <div class="row" style="width: 65%;" id="divcasualplan7">
                <div style="display: grid; width: 100%; height: 100%; place-items: center;" id="divcasualplan8"> 
                  <div [ngClass]="{'opacity-plan-amount': selectedPlan == 3 || selectedPlan == 2 , 'plan-selected-amount': selectedPlan == 1 || selectedPlan == 4}" id="divproplan10" style="height: 45px;">
                    <p  [ngClass]="{'white': selectedPlan == 3 || selectedPlan == 2 , 'purple': selectedPlan == 1 || selectedPlan == 4}"  class="center-plan-title" id="divproplan11"><span class="total-responsive" id="divproplan12"style="font-size: 24px; letter-spacing: 1px;">Total</span> <b style="font-weight: 800; font-size: 24px; letter-spacing: 1px;"><span class="currency-responsive" id="divproplan14">USD</span>&nbsp;{{fullPriceYearly[0] | number : '1.2-2'}}</b></p>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div class="row " style="height: 30%; margin-right: 0px !important; margin-left: 0px !important;" id="divcasualplan11">
              <div class="col" id="divcasualplan12">
                <div style="height: 100%; display: flex; align-items: center;" id="divcasualplan13">
                  <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 16px; margin-left:10px" id="divcasualplan14">Máximo global <br> <span style="font-weight: 600; font-size: 16px; letter-spacing: 1px;">USD {{coverageYearly[0] | number:'1.0':'en-US' }}</span></p>
                  <!-- <div style="position: absolute; right: 15px; " id="divcasualplan15">
                    <p class="white total-responsive" style="display: flex; align-items: center; font-size: 12px;" id="divcasualplan16">Plan Recomendado 

                      <img class="icon-color" style="height: 42px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan17">
                    </p>
                    <img class="icon-color icon-only" style="height: 22px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan18">
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="division-plan-last" *ngIf="isScreenWidthLessThan959" style="padding-top: 30px; margin-bottom:0px; display: grid; place-items: center; width: 100%; padding-left: 25px; padding-right: 25px; height: 230px;"id="plan3CardYearlyMobile" order="">
          <div class="plan-background plan-background-pro-no-desc" (click)="selectPlan(1); rightAndBack()" [ngClass]="{'opacity-plan': selectedPlan == 2 || selectedPlan == 3}" id="plan150kCardYearlyMobile">
            <div class="row top-row" style="height: 70%; justify-content: space-around; flex-direction: column; align-items: center;" id="divcasualplan2">
              <div class="row" style="justify-content: center; flex-wrap: nowrap; width: 100%;" id="divcasualplan3">
                <div style="height: 100%; display: flex; align-items: center; justify-content: center; text-align: center;" id="divcasualplan4">
                  <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 18px;" id="divcasualplan5">PLAN <b style="font-weight: 600; letter-spacing: 1px; font-size: 18px;">VIAJERO {{titulosYearly[0]}}</b>
                    <!-- <img style="height: 16px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_planes.svg" alt="plan-arrow" id="divcasualplan6"> -->
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg> -->
                  </p>
                </div>
              </div>
              
              <div class="row" style="width: 65%;" id="divcasualplan7">
                <div style="display: grid; width: 100%; height: 100%; place-items: center;" id="divcasualplan8"> 
                  <div [ngClass]="{'opacity-plan-amount': selectedPlan == 3 || selectedPlan == 2 , 'plan-selected-amount': selectedPlan == 1 || selectedPlan == 4}" id="divproplan10" style="height: 35px;">
                    <p  [ngClass]="{'white': selectedPlan == 3 || selectedPlan == 2 , 'purple': selectedPlan == 1 || selectedPlan == 4}"  class="center-plan-title" id="divproplan11"><span class="total-responsive" id="divproplan12"style="font-size: 18px; letter-spacing: 1px;">Total</span> <b style="font-weight: 800; font-size: 18px; letter-spacing: 1px;"><span class="currency-responsive" id="divproplan14">USD</span>&nbsp;{{fullPriceYearly[0] | number : '1.2-2'}}</b></p>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div class="row " style="height: 30%; margin-right: 0px !important; margin-left: 0px !important;" id="divcasualplan11">
              <div class="col" id="divcasualplan12">
                <div style="height: 100%; display: flex; align-items: center;" id="divcasualplan13">
                  <p class="white" style="margin-bottom: 0px!important; letter-spacing: 1px; font-size: 16px; margin-left:10px" id="divcasualplan14">Máximo global <br> <span style="font-weight: 600; font-size: 16px; letter-spacing: 1px;">USD {{coverageYearly[0] | number:'1.0':'en-US' }}</span></p>
                  <!-- <div style="position: absolute; right: 15px; " id="divcasualplan15">
                    <p class="white total-responsive" style="display: flex; align-items: center; font-size: 12px;" id="divcasualplan16">Plan Recomendado 

                      <img class="icon-color" style="height: 42px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan17">
                    </p>
                    <img class="icon-color icon-only" style="height: 22px !important; margin-left: 4px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star" id="divcasualplan18">
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hidden-mobile" style="display: flex; justify-content: center; position: relative; padding-right: 25px; padding-left: 25px;" *ngIf="selectedPlan == 1 && mobileSelected">
          <div style="width: 95%; border-radius: 15px;  max-width: 380px; margin-top: -42px; position: relative;">

            <mat-table #table [dataSource]="dataSource" style="border-radius: 0px 0px 15px 15px; padding-top: 44px; background: #DEDEDE;">

              <!-- Detail Column -->
              <ng-container matColumnDef="detail">
                <mat-cell *matCellDef="let element; let i = index;"> <p *ngIf="i == 0; else notIndexCero" style="font-weight: 900; margin-bottom: 0px;">{{element.detail}}</p><ng-template #notIndexCero>{{element.detail}}</ng-template></mat-cell>
              </ng-container>
          
              <!-- Value Column -->
              <ng-container matColumnDef="value">
                <mat-cell style="text-align: end;" *matCellDef="let element"> {{element.value}} </mat-cell>
              </ng-container>
          
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <div class="header-plan-details">
              <h3 style="font-size: 15px; width: 100%; margin-bottom: 0px;">PLAN <b style="font-weight: 800; font-size: 15px;">VIAJERO {{ titulosYearly[selectedPlan - 1] }}</b></h3>
              <div style="display: flex;">
                <!-- <div style="margin: 0 4px; display: grid;">
                  <img class="icon-color" style="height: 27px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/share.svg" alt="destiny">
                  <p style="font-size: 10px; color: #5b5b5b; text-align: center; margin-bottom: 0px;">Compartir</p>
                </div> -->
                <div style="margin: 0 4px; display: grid;" id="divprintquote">
                  <img (click)="getMuvitQuote()" class="icon-color" style="height: 27px; cursor : pointer;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/imprimirpdfYear.svg" alt="destiny" id="pdfQuotePlan3YearlyMobile">
                  <p style="font-size: 10px; color: #5b5b5b; text-align: center; margin-bottom: 0px; display: none;">Imprimir</p>
                </div>
              </div>
            </div>
            <div class="row" style="border-radius: 15px; border: solid 1px #c5c5c5; background: white; margin: 15px; color: #5b5b5b">
              <div class="col-6 border-bottom-grey"  style="height: 56px; border-right: solid 1px #c5c5c5; display: flex; align-items: center; justify-content: center;">
                <p style="margin-bottom: 0px;">{{gs.currentInsuranceInfo.travel_starting_date |date:'dd/MM/yyyy' }} </p>
              </div>
              <div class="col-6 border-bottom-grey"  style="height: 56px; display: flex; align-items: center; justify-content: center;">
                <p style="margin-bottom: 0px;">{{gs.currentInsuranceInfo.travel_end_date | date:'dd/MM/yyyy'}}</p>
              </div>
            
              <!-- <div class="col-12 border-bottom-grey" style="height: 56px;">
                <p class="buy-detail-square">{{destiny.name}}</p>
              </div> -->
              <div class="col-12 border-bottom-grey" style="height: 56px;">
                <p class="buy-detail-square">1 Personas (<span *ngIf="totalAdults > 1">1 adultos </span> <span *ngIf="totalAdults == 1">1 adulto </span> <span *ngIf="kids > 1"> &nbsp;y {{kids}} niños</span> <span *ngIf="kids ==1"> &nbsp;y {{kids}} niño</span>)</p>
              </div>
              <div class="col-12" style="height: 56px; border: #C01DBF 1px solid; border-radius: 0px 0px 15px 15px;">
                <p class="buy-detail-square" style="color: black; display: flex; flex-direction: column; text-align: center;">Total cotizado <span style="font-weight: 600; font-size: 22px; margin-left: 10px; min-width: 161px; max-width: 219px; line-height: 1.1;"> USD {{fullPriceYearly[selectedPlan -1] | number : '1.2-2'}}</span></p>
              </div>
            </div>
            <div style="margin: 15px;">
              <div style="display:flex; align-items: center; height: 100%;">
                <button class="btn buy white" (click)="buyPlan()" style="width: 100%; background: #C01DBF;" id="buyPlan3YearlyMobile">Comprar</button>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- [@slideRight]="true" -->
      <div class="col-lg-8 hidden-desktop" [@slideRight]="true">
        <!-- <div style="margin: 45px 45px 25px 45px;" [@animationPlan]="position" (@animationPlan.start) = "hideAnimation($event)" (@animationPlan.done) = "showAnimation($event)"> -->
      <!-- [@moveRightAndBack2]="!moveback ? true : false" -->
      <div style="/*margin: 45px 45px 25px 45px;*/ transition: all 750ms ease-in-out;"  [class.move-right]="animate" >
      <div style="width: 56%; display: flex; min-height: 65px; border-radius: 10px; border: #C01DBF 3px solid; background: white; flex-direction: row; justify-content: space-between;">
        <h3 style="font-size: 24px; margin-right: 12px; margin-left:20px ; margin-top: 15px;"> PLAN <b style="font-weight: 600;">VIAJERO {{ titulosYearly[selectedPlan - 1] }}</b></h3>
        <div *ngIf="selectedPlan == 2" style=" justify-items: end; display: grid; text-align: right;">
          <div style="background-color: #C01DBF; border-radius: 28px; width: 130px; height: 28px; display: flex; margin-right: 10px; margin-top:15px;">
            <div style="height: 100%; display: flex; align-items: center; margin: 0px 5px;">
              <img class="icon-color" style="height: 20px !important; margin-left: 4px; color: white;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/star.svg" alt="star">
            </div>
            <div style="display: flex; align-items: center; color: white; font-size: 12px; margin: 0px 4px;">Recomendado</div>
          </div>
        </div>
      </div>
          <div style="display: flex;">
            <div style="width: 58%; margin-right: 22px; display: grid; place-items: center; margin-top: -8px; z-index: -1;">
              <div style="width: 100%; border-radius: 10px; box-shadow: 2px 0px 5px 0px #c5c5c5;  max-width: 900px;">
                
                <mat-table #table [dataSource]="dataSource" style=" overflow: hidden !important;">

                  <!-- Detail Column -->
                  <ng-container matColumnDef="detail">
                    <mat-cell *matCellDef="let element; let i = index;" style="border: none;"> <p *ngIf="i == 0; else notIndexCero" style="font-weight: 600; margin-bottom: 0px;">{{element.detail}}</p><ng-template #notIndexCero>{{element.detail}}</ng-template></mat-cell>
                  </ng-container>
              
                  <!-- Value Column -->
                  <ng-container matColumnDef="value">
                    <mat-cell style="text-align: end; border: none;" *matCellDef="let element"> {{element.value}} </mat-cell>
                  </ng-container>
              
                  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <div class="header-plan-details" style="justify-content: center; border: none; margin-top: 20px; margin-bottom: 20px;">


                  <div style="display: flex; width: 100%; flex-direction: column; border-radius: 10px; border:#c5c5c5 2px solid">
                      <p class="coverage-text" style="font-weight: normal; margin-top: 15px; font-size: 20px; text-align: center; margin-bottom: 0px; width: 100%; line-height: 1.15;">MÁXIMO GLOBAL</p>
                      <span style="font-weight: 600; font-size: 28px; margin-bottom: 0px; text-align: center; min-width: 182px;"> USD {{coverageYearly[selectedPlan -1] | number:'1.0':'en-US' }}</span>
                      <br>
                </div>

                </div>
              </div>
            </div>
            <div style="width: 43%; margin-left: 22px;">
              <div style="width: 85%; border-radius: 15px; border: solid 0px #c5c5c5; background: #FAFAFA; max-width: 720px; box-shadow: 2px 0px 5px 0px #c5c5c5c5;">  
                <div class="header-plan-details" style="border-bottom: 0;">
                                      <p style=" font-size: 20px; margin-bottom: 0px; width: 100%; margin-left: 10px;">Detalle de Compra</p>
                  <div style="display: flex;">
                    <!-- <div style="margin: 0 8px; display: grid;">
                      <img class="icon-color" style="height: 27px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/share.svg" alt="destiny">
                      <p style="font-size: 10px; color: #5b5b5b; text-align: center; margin-bottom: 0px;">Compartir</p>
                    </div> -->
                    <div style="margin: 0 8px; display: grid;">
                      <img (click)="getMuvitQuote()" id="divprintquote" class="icon-color" style="height: 37px; cursor: pointer;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/imprimirpdfYear.svg" alt="destiny" id="pdfQuotePlanYearly">
                      <p style="font-size: 0px; color: #5b5b5b; text-align: center; margin-bottom: 0px;">Imprimir</p>
                    </div>
                  </div>
                </div>
                <div class="row" style="border-radius: 15px; border: solid 1px #c5c5c5; background: white; margin: 15px; color: #5b5b5b">
                  <div class="col-6 border-bottom-grey"  style="height: 99px; border-right: solid 1px #c5c5c5;">
                    <p style="margin-top: 10px;"><b>Desde:</b></p>
                    <p style="text-transform: uppercase;">{{gs.currentInsuranceInfo.travel_starting_date | date:'dd/MM/yyyy' }}</p>
                  </div>
                  <div class="col-6 border-bottom-grey"  style="height: 99px;">
                    <p style="margin-top: 10px;"><b>Hasta:</b></p>
                    <p style="text-transform: uppercase;">{{gs.currentInsuranceInfo.travel_end_date | date:'dd/MM/yyyy' }} </p>
                  </div>
                
                  <!-- <div class="col-12 border-bottom-grey" style="height: 99px;">
                    <p class="buy-detail-square">{{destiny.name}}</p>
                  </div> -->
                  <div class="col-12 border-bottom-grey" style="height: 99px;">
                    <p class="buy-detail-square">1 Personas (<span *ngIf="totalAdults > 1">1 adultos </span> <span *ngIf="totalAdults == 1">1 adulto </span> <span *ngIf="kids > 1"> &nbsp;y {{kids}} niños</span> <span *ngIf="kids ==1"> &nbsp;y {{kids}} niño</span>)</p>
                  </div>
                  <div class="col-12" style="height: 99px; border: #C01DBF 2px solid; border-radius: 10px;">
                    <p class="buy-detail-square" style="color: black; display: flex; flex-direction: column;">Total cotizado <span style="font-weight: 600; font-size: 24px; margin-left: 10px; line-height: 1.15; "> USD {{fullPriceYearly[selectedPlan -1] | number : '1.2-2'}}</span></p>
                  </div>
                </div>
                <div style="margin: 21px 30px; padding-bottom: 15px; ">
                  <div style="display:flex; align-items: center; height: 100%;">
                    <button class="btn buy" id="buyPlanYearly" style="background: #C01DBF; height: 60px;" (click)="buyPlan()" [disabled]="!isEmailValid">Comprar</button>
                  </div>
                </div>

                
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

  <div *ngIf="showStep3">
    <app-muvit-insurance-holder
      [productForLocation]="PRODUCT_FOR_LOCATION"
      [stepProduct]="stepProduct"
      [countries]="countries"
      [personsJson]="personsJson"
      (returnToHome)="returnToHome()"
      >
    </app-muvit-insurance-holder>
  </div>
  <div *ngIf="showStep4">
    <app-muvit-payment-form
    [productForLocation]="PRODUCT_FOR_LOCATION"
    [titulos]="titulos" 
    [primas]="primas"
    [personsJson]="personsJson"
    [coverage]="coverage"
    [coverageYearly]="coverageYearly"
    [titulosYearly] ="titulosYearly"
    [destiny]="destiny"
    (returnToHome)="returnToHome()"
    >
    </app-muvit-payment-form>
  </div>
  <div *ngIf="showStep5">
    <app-muvit-thank-you
    [productForLocation]="PRODUCT_FOR_LOCATION"
    [titulos]="titulos"
    (returnToHome)="returnToHome()"
    >
    </app-muvit-thank-you>
  </div>
  
</div>





<div class="row" style="margin: 0;">
  <div class="footer_v2">
    <div class="row" >
      <div class="col-12 col-sm-4 footer-column-center" style="margin-top: 25px;">
        <img class="footer-logo_v2" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit_logo.svg" alt="muvit logo" style="cursor: pointer;" routerLink="./public/muvit" id="btnlogomuvit21">
  </div>
      <div class="col-12 col-sm-8 footer-column-end" style="margin-top: 25px; flex-direction: column;">
        <div class="row" style="justify-content: end;">
          <div class="col-12 footer-column-start">
            <button class="btn footer-button" id="btnfacebook1">
              <a href="https://www.facebook.com/muvit.travel" target='_blank' id="btnfacebook2">
                <img class="icon-color_v2" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/facebook (1).svg" alt="facebook" id="btnfacebook3">
              </a>
            </button>
            <button class="btn footer-button" id="btninstagram1">
              <a href="https://www.instagram.com/muvit.travel/?utm_source=google&utm_medium=banner&utm_campaign=visitas_trafico_icono_pagina_web" target='_blank' id="btninstagram2">
                <img class="icon-color_v2" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/instagram.svg" alt="instagram" id="btninstagram3">
              </a>
            </button>
            <button class="btn footer-button" id="btnyoutube1">
              <a href="https://bit.ly/44Kmw4T" target='_blank' id="btnyoutube2">
                <img class="icon-color_v2" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/youtube.svg" alt="youtube" id="btnyoutube3">
              </a>
            </button>
            <button class="btn footer-button" id="btntiktok1">
              <a href="https://www.tiktok.com/@muvit.travel?_t=8atH4Jl8OFq&_r=1" target='_blank' id="btntiktok2">
                <img class="icon-color_v2" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/tiktok.svg" alt="tiktok" id="btntiktok3">
              </a>
            </button>
            <button class="btn footer-button" id="shareButton" (click)="openShareDialog()">
              <img class="icon-color_v2" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/share_.svg" alt="share link" id="shareButtonImg">
            </button>
          </div>
            </div>
        <div class="row" style="margin-top: 15px; justify-content: end;">
          <div class="col-12 social-media_v2">
            <p class="p-footer">9a. Avenida 19-61 zona 10, nivel 11. </p>
            <p class="p-footer p-terms">
              <a class="a-footer" (click) = "showTosPage()" routerLinkActive="active" target="_blank" rel="bookmark">
                <span>Términos y condiciones</span></a>
              </p>
            
            </div>
          </div>
          
        </div>
        
          </div>
          <div class="row" style="justify-content: center;">
            <div class="row" style="margin-top: 15px; justify-content: end;">
              <div class="col-12 social-media_v2">
                
                <p class="p-footer">Muvit 2023. Todos los derechos reservados</p>
                </div>
              </div>
          </div>
          
    <!-- <div class="row" style="margin-top: 15px; justify-content: end;">
      <div class="col-6 social-media_v2">
        <p class="p-footer">Términos y condiciones</p>
        </div>
      <div class="col-6 social-media_v2">
        <p class="p-footer">Muvit 2023. Todos los derechos reservados</p>
    </div>
    </div> -->
            </div>
</div>
<!-- <button >Preubas</button> -->
