import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ImageLoadingService } from 'src/app/services/image-loading.service';
import { NavbarServiceService } from '../navbar-service.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { deviceDetectorService } from '../../services/device-detector.service';
import { ApiService } from 'src/app/api/api.service';
import { GlobalService } from 'src/app/services/global.service';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX(-100%)' })),
      state('right', style({ transform: 'translateX(100%)' })),
      transition('* => *', animate('2000ms ease-in-out'))
    ])
  ]
})
export class HomePageComponent implements OnInit, OnDestroy, AfterViewInit {
  whatsappicon = 'whatsapp-seguro-de-viaje-01.svg';
  deviceInfoSubscription: Subscription;

  deviceInfo: any;
  showLoader : boolean;

  constructor(
    private imageLoadingService: ImageLoadingService, 
    private navBar: NavbarServiceService, 
    private el: ElementRef, 
    private device: deviceDetectorService, 
    public navbarService: NavbarServiceService,
    public api: ApiService,
    public gs: GlobalService,
    private activatedRoute: ActivatedRoute, private title: Title) 
    {
    this.title.setTitle(this.activatedRoute.title['destination']['_value']['title'])

    this.deviceInfoSubscription = this.device.deviceType.subscribe(deviceType => {

      this.deviceInfo = deviceType;
    })
  }
  

  ngOnInit(): void {
    this.showLoader = true;
    this.imageLoadingService.checkImagesLoaded(this.el.nativeElement);
    this.navBar.isEmailVisible = true;
    this.navBar.isButtonVisible = true;
    this.navBar.isPersonsEditable = true
    this.navBar.isPriceShowerVisible = false
    this.navBar.isPlanSelectorVisible = false
    this.navBar.isDatePickerEditable = true
    if(!this.gs.plans){
      const payload = {
        "product_code" : 'BAM_MUVIT_DIARIO'
      }
      this.api.enrollAsys.getPlans(payload).promise()
          .then(response => {
            this.showLoader = false;
            if(response){
              this.gs.plans = response;
            }else {
            }
          })
          .catch(e => {
            console.log('Error obteniendo los planes');
          })
      }
  }
  ngOnDestroy() {
    if (this.deviceInfoSubscription) {
      this.deviceInfoSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {

    // if(this.showGiveAway){
    //   setTimeout(() => {
    //     this.openDialogAdGiveaway();
    //   }, 1000);
    // }


  }

  facebookicon = 'facebook-seguro-de-viaje-01.svg'
  instagramicon = 'instagram-seguro-de-viaje-01.svg'

  Maletas = [{
    'description': 'Pérdida de maletas hasta $1,200'
  }, {
    'description': 'Atraso de maletas hasta $100'
  }
  ]
  Hotel = [{
    'description': 'Gastos de hotel por convalecencia hasta $1,000'
  }, {
    'description': 'Gastos de hotel para un familiar $1000'
  }
  ]

  Viaje = [{
    'description': 'Cancelación de viaje hasta  $450 '
  }, {
    'description': 'Interrupción de viaje hasta $450'
  }, {
    'description': 'Demora de viaje hasta $100'
  }]

  Salud = [{
    'description': 'Gastos médicos por enfermedad y accidente hasta  $150,000 '
  }, {
    'description': 'Ambulancia (aérea, marítima o terrestre) hasta $50,000'
  }, {
    'description': 'Gastos odontológicos por accidente hasta $500'
  }]


  Comments = [
    {
      'name': 'Patty Melz',
      'comment': ' Es la primera vez que compro una asistencia de viaje, la compra en la página web fue fácil y la atención muy personalizada, después de evaluar precios y beneficios Muvit fue la mejor opción, es mejor ir siempre de viaje preparados.',
      'photo': 'Seguro_de_viaje_Muvit_3.webp',
      'social': this.instagramicon
    },

    {
      'name': 'Melisa Benavides',
      'comment': 'Compré Muvit para mi viaje al Caribe, ¡y aunque no me pasó nada! me fui tranquila sin arriesgar mi viaje de descanso y desconexión por algún percance. Gracias Muvit 😊 ',
      'photo': 'Seguro_de_viaje_Muvit_2.webp',
      'social': this.instagramicon
    },
    {
      'name': 'Ricardo Piral',
      'comment': ' Adquirir Muvit fue tan sencillo y nos dio tanta tranquilidad a mí y mi familia en nuestro viaje a México. Viajar con el respaldo de Muvit realmente hace la diferencia, se ajustó a nuestras necesidades y presupuesto.',
      'photo': 'Seguro_de_viaje_Muvit_1.webp',
      'social': this.instagramicon
    },
    {
      'name': 'Andrea Ramos',
      'comment': 'Tuve una infección en mi viaje, me coordinaron el medico y la entrega de mi medicamento en menos de 1 hora con Muvit. No tuve que hacer uso de mi seguro, todo fue bastante ágil y no pague nada de deducible.',
      'photo': '',
      'social': this.whatsappicon
    },
  ]
}
