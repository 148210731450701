import { Injectable } from '@angular/core';
import { RequestParams } from './request-params'
import { ConfigService } from '../config/config.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class RequestserviceService {

  constructor(private http: HttpClient,private config: ConfigService) { }

  observableFact(requestParams: RequestParams): Observable<any> {
    const options: any = {};
    const header_content_type_json = { "Content-Type": "application/json" };
    
    options.params = requestParams.requestParams;
    let httpRequest;
    if (requestParams.requestMethod === 'GET') {
      httpRequest = this.http.get(requestParams.requestUrl, options);

    } else if (requestParams.requestMethod === 'POST') {
      if (requestParams.requestFile) {
        httpRequest = this.http.post(
          requestParams.requestUrl,
          requestParams.requestFile,
          options
        );
      }else{
        options.headers = { ...options.headers, ...header_content_type_json };
        httpRequest = this.http.post(
          requestParams.requestUrl,
          JSON.stringify(requestParams.requestPayload),
          options
        );
      }
    } else if (requestParams.requestMethod === 'PUT') {
      options.headers = { ...options.headers, ...header_content_type_json };
      httpRequest = this.http.put(
        requestParams.requestUrl,
        JSON.stringify(requestParams.requestPayload),
        options
      );
    } else if (requestParams.requestMethod === 'DELETE') {
      options.headers = { ...options.headers, ...header_content_type_json };
      httpRequest = this.http.delete(requestParams.requestUrl, options);
    }
    return httpRequest;
  }

  post(): RequestParams {
    return new RequestParams(p => this.observableFact(p)).post();
  }
  
  get(): RequestParams {
    return new RequestParams(p => this.observableFact(p)).get();
  }
  
  put(): RequestParams {
    return new RequestParams(p => this.observableFact(p)).put();
  }

  delete(): RequestParams {
    return new RequestParams(p => this.observableFact(p)).delete();
  }

}
