<div class="card-container">
    <div class="title-divider">Acompañante {{this.numeroAc}}
    
    </div> 
    <div class="extra-price" *ngIf="isAdded">
        <div class="price">
        <span class="extra-price-title">Costo adicional: </span>
        <span class="extra-price-value">USD {{((this.dataConstructor.ExtraPrices[numeroAc - 1])).toFixed(3)}}</span>
    </div>
        <div class="warning-price" style="font-size: 10px;" *ngIf="noBirthdate">Selecciona la Fecha de Cumpleaños</div>
    </div>
    <div class="card">
        <form class="card-form" [formGroup]="clientForm">          
            
            <app-card-input [inputTitle]="'Nombre 1'" [isRequired]="true"
                class='form-inputs' formControlName="first_name"
                (valueChange)="setFormFieldValue('first_name', $event)"></app-card-input>
            <app-card-input [inputTitle]="'Nombre 2'" [isRequired]="true"
                class='form-inputs' formControlName="middle_name"
                (valueChange)="setFormFieldValue('middle_name', $event)"></app-card-input>

            <app-card-input [inputTitle]="'Apellido 1'" [isRequired]="true"
                class='form-inputs' formControlName="last_name"
                (valueChange)="setFormFieldValue('last_name', $event)"></app-card-input>
            <app-card-input [inputTitle]="'Apellido 2'" [isRequired]="true"
                class='form-inputs' formControlName="last_name_2"
                (valueChange)="setFormFieldValue('last_name_2', $event)"></app-card-input>
            <app-card-select [Title]="'Género'" [isRequired]="true"
                class='form-inputs' [Options]="this.genreOptions"
                formControlName="genre"
                (valueChange)="setFormFieldValue('genre', $event)"></app-card-select>
            <app-card-datepicker [inputTitle]="'Fecha de nacimiento'"
                [isRequired]="true" class='form-inputs'
                formControlName="birthday" (valueChange)="isChangeDate($event)"
                (valueChanged)="setFormFieldValue('birthday', $event)"></app-card-datepicker>
            <app-card-select [Title]="'Parentesco'" [isRequired]="true"
                class='form-inputs2' [Options]="filteredKinshipOptions"
                formControlName="relationship"
                (valueChange)="setFormFieldValue('relationship', $event)"></app-card-select>

            <app-card-input [inputTitle]="'No. Pasaporte'" [isRequired]="true"
                class='form-inputs2' formControlName="passport"
                (valueChange)="setFormFieldValue('passport', $event)"></app-card-input>
            <!-- <app-card-input [inputTitle]="'No. DPI'" [isRequired]="false"
                class='form-inputs' formControlName="dpi"
                (valueChange)="setFormFieldValue('dpi', $event)"></app-card-input> -->

        </form>
        <div class="buttonsOptions">
            <button (click)="onAddFormButtonClick()" *ngIf="isLastForm && this.dataConstructor.formsQuantity < 15" id="addBeneficiary"><i class="fa-solid fa-user-plus" style="color: #ffffff;"></i></button>
            <button (click)="onRemoveFormButtonClick()" id="removeBeneficiary"><i class="fas fa-trash-alt" style="color: #ffffff;"></i></button>
        </div>
    </div>
  
</div>