<div class="container">
  <div class="row">
    <div class="col-lg-2 alignedback" id="btnback1">
      <img *ngIf="step != 5" class="back-button icon-color" style="height: 20px;" (click)="goBack()" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/flecha_back.svg" alt="back" id="btnback2">
      <!-- <button *ngIf="step !=5" class="back-button" ><i class="fa fa-angle-left" aria-hidden="true" style="padding-right: 10px;"></i>REGRESAR</button> -->
      <div class="hidden-mobile" style="display: initial; margin: auto;">
        <img class="icon-color" style="height: 32px; cursor: pointer;" (click)="returnHome()" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/muvit.svg" alt="personas" id="btnlogomuvit7">
      </div>
      <div class="hidden-mobile" style="position: absolute; right: 10px;" id="divfaq1">
        <a href="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/muvit_documents/FAQ_muvit.pdf" target="_blank" id="divfaq2">
          <img class="icon-color" style="height: 32px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/faqs1.svg" alt="faqs" id="divfaq3">
        </a> 
        
      </div>
    </div>
      <div class="col-lg-8">


        <div class="row numeric">
          <div class="col-lg-12">
            <div class="mdl-card__supporting-text center">
              <div class="mdl-stepper-horizontal-alternative">
                <div [ngClass]="{'active-step':step > 2}" class="mdl-stepper-step">
                  <div class="mdl-stepper-circle">
                    <div *ngIf="step > 3" class="inside-check">
                      <svg style="height: 22px; width: 22px; color: white;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                      </svg>
                    </div>
                    <div style=" height: 100%; display: flex; align-items: center;">
                      <div *ngIf="step == 3" class="inside-circle"> </div>
                    </div>
                    
                  </div>
                  <div class="mdl-stepper-title">
                    <span>Datos Personales</span>
                  </div>
                  
                  <div class="mdl-stepper-bar-left"></div>
                  <div class="mdl-stepper-bar-right"></div>
                </div>

                <div [ngClass]="{'active-step':step > 3}" class="mdl-stepper-step">
                  <div class="mdl-stepper-circle">
                    <div *ngIf="step > 4" class="inside-check">
                      <svg style="height: 22px; width: 22px; color: white;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                      </svg>
                    </div>
                    <div style=" height: 100%; display: flex; align-items: center;">
                      <div *ngIf="step == 4" class="inside-circle"> </div>
                    </div>
                  </div>
                  <div class="mdl-stepper-title">
                    <span>Datos de Tarjeta</span>
                  </div>
                  
                  <div class="mdl-stepper-bar-left"></div>
                  <div class="mdl-stepper-bar-right"></div>
                </div>

                <div [ngClass]="{'active-step':step > 4}" class="mdl-stepper-step" >
                  <div class="mdl-stepper-circle">
                    <div *ngIf="step > 4" class="inside-check">
                      <svg style="height: 22px; width: 22px; color: white;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                      </svg>
                    </div>
                  </div>
                  <div class="mdl-stepper-title">
                    <span>Listo</span>
                  </div>
                  
                  <div class="mdl-stepper-bar-left"></div>
                  <div class="mdl-stepper-bar-right"></div>
                </div>

                <!-- <div [ngClass]="{'active-step':step > 4}" class="mdl-stepper-step" *ngIf="tipoPlan == 'Familiar'">
                  <div class="mdl-stepper-circle"><span>{{payerIsDifferent? 4: 3}}</span></div>
                  <div class="mdl-stepper-title">
                    
                  </div>
                  
                  <div class="mdl-stepper-bar-left"></div>
                  <div class="mdl-stepper-bar-right"></div>
                </div>

                <div [ngClass]="{'active-step':step > 5}" class="mdl-stepper-step"
                  *ngIf="gs.currentInsuranceInfo.product_code != 'BNCR_MI_COMPRA_PROTEGIDA' && gs.currentInsuranceInfo.product_code != 'BNCR_RENTA_DIARIA_POR_HOSPITALIZACION' &&
                  gs.currentInsuranceInfo.product_code != 'COOPENAE_RENTA_DIARIA_POR_HOSPITALIZACION' && gs.currentInsuranceInfo.product_code != 'BNCR_FRE_VOLUNTARIO' &&
                  gs.currentInsuranceInfo.product_code != 'CREDIX_FRAUDE_ROBO_EXTRAVIO'">
                  <div class="mdl-stepper-circle"><span>{{payerIsDifferent? (tipoPlan == 'Familiar' ? 5 : 4) : (tipoPlan=='Familiar' ? 4 : 3) }}</span></div>
                  <div class="mdl-stepper-title">
                    
                  </div>
                  
                  <div class="mdl-stepper-bar-left"></div>
                  <div class="mdl-stepper-bar-right"></div>
                </div> -->

              </div>

            </div>

          </div>
        </div>

    </div>
    <div class="col-lg-2">
      <div class="hidden-desktop" style="position: absolute; left: 0px; top: 33px;" id="divfaq4">
        <a href="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/muvit_documents/FAQ_muvit.pdf" target="_blank" id="divfaq5">
          <img class="icon-color" style="height: 32px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/faqs1.svg" alt="faqs" id="divfaq6">
        </a> 
      </div>
    </div>
  </div>
</div>
