import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ConfigService } from '../config/config.service';

@Injectable()
export class GoogleService {

  constructor(private router: Router,
              private config: ConfigService) {}

  // Google Tag Manager
  initGtmScript(idTagManager: string) {    
    const gtmScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${idTagManager}');`;

    const scriptElement = document.createElement('script');
    scriptElement.innerHTML = gtmScript;
    scriptElement.id = 'gtm-script-'+idTagManager;
    document.head.appendChild(scriptElement);
  }

  removeGtmScript(idTagManager: string) {
    const scriptElement = document.getElementById('gtm-script-'+idTagManager);
    if (scriptElement) {
      scriptElement.remove();
    }
  }

  // Google Analytics
  loadGAnalyticScript(idGoogleAnalytics: string) {    
    const gtagScript = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${idGoogleAnalytics}');`;

    const scriptElement = document.createElement('script');
    scriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${idGoogleAnalytics}`;
    scriptElement.id = 'gtag-script-' + idGoogleAnalytics;
    document.head.appendChild(scriptElement);

    const inlineScriptElement = document.createElement('script');
    inlineScriptElement.innerHTML = gtagScript;
    inlineScriptElement.id = 'gtag-inline-script';
    document.head.appendChild(inlineScriptElement);
  }

  removeGAnalyticScript(idGoogleAnalytics: string) {
    const scriptElement = document.getElementById('gtag-script-'+idGoogleAnalytics);
    if (scriptElement) {
      scriptElement.remove();
    }
  }

  // Google Ads
  loadGAdsScript(idGoogleAds: string) {    
    const gtagScript = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${idGoogleAds}');`;

    const scriptElement = document.createElement('script');
    scriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${idGoogleAds}`;
    scriptElement.id = 'gad-script-' + idGoogleAds;
    document.head.appendChild(scriptElement);

    const inlineScriptElement = document.createElement('script');
    inlineScriptElement.innerHTML = gtagScript;
    inlineScriptElement.id = 'gtag-inline-script';
    document.head.appendChild(inlineScriptElement);
  }

  removeGAdsScript(idGoogleAds: string) {
    const scriptElement = document.getElementById('gad-script-'+idGoogleAds);
    if (scriptElement) {
      scriptElement.remove();
    }
  }

  //Hotjar
  addHotjarScript() {    
    const gtmScript = `  
        (function(h,o,t,j,a,r){    
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};    
            h._hjSettings={hjid:3521329,hjsv:6};    
            a=o.getElementsByTagName('head')[0];    
            r=o.createElement('script');r.async=1;    
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;    
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

    const scriptElement = document.createElement('script');
    scriptElement.innerHTML = gtmScript;
    scriptElement.id = 'gtm-script-hotjar';
    document.head.appendChild(scriptElement);
  }
}