
<!--<div class="header-bg" style="padding-top: 65px;">
<div class="hamburger-menu" style="width: 2%; display: none;">
  <button class="menu-toggle" (click)="toggleMenu()">
    <span class="icon">
      <span class="bar" *ngIf="!isMenuActive"></span>
      <span class="bar" *ngIf="!isMenuActive"></span>
      <span class="bar" *ngIf="!isMenuActive"></span>
      <span class="close-icon" *ngIf="isMenuActive">X</span>
    </span>
  </button>
  <div class="menu" [ngClass]="{'active': isMenuActive}">
    <div class ="menu-container">
    <ul class="menu-list">
      
      <li>
        <span class="arrow-icon2">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
            <path d="M5 12h14M12 5l7 7-7 7" />
          </svg>
        </span>
        <a class="menu-element" (click) = "showTosPage()" routerLinkActive="active" rel="bookmark" target="_blank">Términos y Condiciones</a>
      </li>
      <li>
        <span class="arrow-icon2">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
            <path d="M5 12h14M12 5l7 7-7 7" />
          </svg>
        </span>
        <button class="menu-element" >Beneficios</button>
      
      </li>
      <li>
        <span class="arrow-icon2">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
            <path d="M5 12h14M12 5l7 7-7 7" />
          </svg>
        </span>
        <button class="menu-element" (click)="scrollToDiv('.footer_v2')">Redes Sociales</button>
        <ul class="submenu">
          <li><a class="menu-element" href="https://www.facebook.com/muvit.travel" target='_blank'>Facebook</a></li>
          <li><a class="menu-element" href="https://www.instagram.com/muvit.travel/?utm_source=google&utm_medium=banner&utm_campaign=visitas_trafico_icono_pagina_web" target='_blank'>Instagram</a></li>
          <li><a class="menu-element" href="https://www.tiktok.com/@muvit.travel?_t=8atH4Jl8OFq&_r=1" target='_blank'>TikTok</a></li>
          <li><a class="menu-element" href="https://bit.ly/44Kmw4T" target='_blank'>YouTube</a></li>
        </ul>
      </li>
      
    </ul>
    
    </div>
  </div>
</div>
<div class="muvit-logo">
  <img class="header-logo" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit_logo.svg" alt="muvit logo" (click)="goToHome()" id="btnlogomuvit22">
</div>

<div class="hidden-desktop" style="position: absolute; right: 40px; top: 25px; display: flex; align-items: center; cursor:pointer; z-index: 20;">
  
  <button (click)="showBlogPage()" routerLinkActive="active" rel="bookmark" target="_blank" class="p-terms" style="background-color: transparent; color: white; margin-left: 0px; margin-bottom: 0px; margin-top: 2px; margin-right: 40px; font-size: 16px;  border: 2px solid white; border-radius: 15px; width: 110px;" matTooltip="Blog Muvit" id="blogbtn2">
    
      BLOG
  </button>
  
    
  
  <img style="height: 34px; width: 34px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/mundo.svg" alt="world"  (click)="openLanCur()">
   <svg style="color: white;" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
    <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
  </svg> 
  <p style="color: white; margin-left: 5px; margin-bottom: 0px; margin-top: 2px; margin-right: 40px; font-weight: bold;"  (click)="openLanCur()">{{language.value}}/{{currency.value}}</p>
  <p class="p-terms" style="color: white; margin-left: 0px; margin-bottom: 0px; margin-top: 2px; margin-right: 40px; font-size: 18px;" matTooltip="Términos y condiciones">
    <a class="a-footer" (click) = "showTosPage()" routerLinkActive="active" rel="bookmark" target="_blank">
      <span style="color: #ffffff;">TYC</span></a>
  </p>
    
  <a style="margin-right: 15px;" href="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/muvit_documents/FAQ_muvit.pdf" target="_blank" id="divfaq5">
    <img class="icon-color" style="height: 50px; width: 50px;" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/FAQS3.svg" alt="faqs" id="divfaq6">
  </a> 
</div>
<div class="hidden-mobile" style="position: absolute; right: 40px; top: 25px; display: flex; align-items: center; cursor:pointer; z-index: 20;">
  
  <button (click)="showBlogPage()" routerLinkActive="active" rel="bookmark" target="_blank" class="p-terms" style="background-color: transparent; color: white; margin-left: 0px; margin-bottom: 0px; margin-top: 3px; margin-right: -30px; font-size: 15px;  border: 2px solid white; border-radius: 15px; width: 80px;" matTooltip="Blog Muvit" id="blogbtn2">
    
      BLOG
  </button>
  
    
  
  
</div>

</div> -->

<div class="body"*ngIf="!showArticle">
  <div class="grid-container" *ngIf="!isScreenWidthLessThan1100">
    <div class="item-1">
        <!-- Contenido del primer div (70%) -->
        <div class="blog-title">
        ¿Necesitas tips, consejos y recomendaciones para tus próximos viajes?

        </div>
        <div class="blog-subtitle">
          Entonces este blog es para ti. ¡Bienvenidos viajeros!
    
          </div>
    </div>
    <div class="item-2">
       
        
    </div>
  </div>

  <div class="grid-container-r" *ngIf="isScreenWidthLessThan1100">
    <div class="item-2-r" >
       
        <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/blog/viaja-con-muvit-r.png" style="width: 100%;"/>
    </div>
    <div class="item-1-r">
        <!-- Contenido del primer div (70%) -->
        <div class="blog-title">
        ¿Necesitas tips, consejos y recomendaciones para tus próximos viajes?

        </div>
        <div class="blog-subtitle" style="font-size: 12px;">
          Entonces este blog es para ti. ¡Bienvenidos viajeros!
    
          </div>
        <!-- <div class="blog-subtitle">
          Entonces este blog es para ti. ¡Bienvenidos viajeros!
    
          </div> -->
    </div>
    
  </div>



  <div class="content-blog" *ngIf="!isScreenWidthLessThan1100">


    <div class="articles">
      <div class="main-article">
        <!-- Main article de la página actual -->
        <div class="card-img" style="background: url({{articles[currentPageIndex].mainArticle.cover}});"  (click)="toArticle(articles[currentPageIndex].mainArticle)">
          <!-- <img class="main-article-img" [src]="articles[currentPageIndex].mainArticle.cover"  [alt]="articles[currentPageIndex].mainArticle.altPhoto"/> -->
        </div>
        
        <div class="main-article-content0">
          <h2 class="title">{{ articles[currentPageIndex].mainArticle.title }}</h2>

          <div class="buttonPrincipal">
          <button class="link" (click)="toArticle(articles[currentPageIndex].mainArticle)">Leer Más</button>
          <img class="arrow" (click)="toArticle(articles[currentPageIndex].mainArticle)" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-right-circle-white.svg" />

          </div>
        </div>
      </div>
    
      <div class="sub-articles">
        <!-- Sub articles de la página actual -->
        <div class="article-item" *ngFor="let article of articles[currentPageIndex].subArticles">
          <!-- Contenido de los sub articles -->
          <img class="article-item-img" [src]="article.cover" [alt]="article.altPhoto" (click)="toArticle(article)"/>
          <div class="text-container">
            <p class="title-article">{{article.title}}</p>
            <button class="link-article" (click)="toArticle(article)">Leer Más</button>
            <img class="arrow-purple" (click)="toArticle(article)" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-right-circle-purple.svg" />
          </div>
          <!-- Agrega cualquier otro contenido de los sub articles aquí -->
        </div>
      </div>

      <div class="page-div">
        <button  (click)="prevPage()" class="page-btn" [disabled]="currentPageIndex === 0"> 
          <img *ngIf="currentPageIndex === 0" class="arrow-page-left"  src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-left.svg" />
          <img *ngIf="!(currentPageIndex === 0)" class="arrow-page-left"  src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-left-active.svg" />
          Anterior </button>
        
        <!-- Números de página -->
      <div class="page-numbers">
        <ng-container *ngFor="let pageNumber of getPageNumbersToShow()">
          <div
            class="page-number"
            [ngClass]="{ 'current-page': pageNumber === currentPageIndex + 1 }"
            (click)="goToPage(pageNumber - 1)"
          >
            {{ pageNumber }}
        </div>
        </ng-container>
      </div>
      <button (click)="nextPage()" class="page-btn" [disabled]="currentPageIndex === articles.length - 1">
        Siguiente
        <img *ngIf="currentPageIndex === articles.length - 1" class="arrow-page-right"  src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-right.svg" />
        <img *ngIf="!(currentPageIndex === articles.length - 1)" class="arrow-page-right"  src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-right-active.svg" />
      </button>
      </div>
    </div>
    
    <!-- Botones de paginación -->

    
    





  </div>

  <div class="content-blog-mobile" *ngIf="isScreenWidthLessThan1100">


    <div class="articles">
      <div class="main-article" (click)="toArticle(articles[currentPageIndex].mainArticle)" style="background-image: url({{articles[currentPageIndex].mainArticle.cover}}); display: flex; justify-content: end; background-size: cover; background-position: top center !important; cursor: pointer;" >
        <!-- Main article de la página actual -->
        <div class="main-article-content" (click)="toArticle(articles[currentPageIndex].mainArticle)" style="width: fit-content !important; padding: 5px; background-color: white; justify-content: center !important; min-height: 95px !important;">
          <h2 class="title" style="height: fit-content !important; padding: 0 !important; color: black; margin: 0; text-align: center; height: 100% !important; display: flex; justify-content: center; align-items: center;">{{ articles[currentPageIndex].mainArticle.title }}</h2>
          <!-- <button class="link" (click)="toArticle(articles[currentPageIndex].mainArticle)">Leer Más</button> -->
          <div style="width: 0; overflow: inherit;" >
            <div (click)="toArticle(articles[currentPageIndex].mainArticle)">           <img  src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-right-circle-white.svg"  style="width: 30px !important; position: relative; top: -50px; "/>
            </div>
          </div>
        </div>
      </div>
    
      <div class="sub-articles-mobile">
        <!-- Sub articles de la página actual -->
        <div class="article-item" *ngFor="let article of articles[currentPageIndex].subArticles">
          <!-- Contenido de los sub articles -->
          <img class="article-item-img" [src]="article.cover" [alt]="article.altPhoto"/>
          <div class="link-container-mobile">
            <img class="arrow-purple" (click)="toArticle(article)" src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-right-circle-white.svg" />

          </div>
          <div class="text-container">
            <p class="title-article">{{article.title}}</p>
            <!-- <button class="link-article" (click)="toArticle(article)">Leer Más</button> -->
          </div>
          <!-- Agrega cualquier otro contenido de los sub articles aquí -->
        </div>
      </div>

      <div class="page-div">
        <button  (click)="prevPage()" class="page-btn" [disabled]="currentPageIndex === 0"> 
          <img *ngIf="currentPageIndex === 0" class="arrow-page-left"  src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-left.svg" />
          <img *ngIf="!(currentPageIndex === 0)" class="arrow-page-left"  src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-left-active.svg" />
          Anterior </button>
        
        <!-- Números de página -->
      <div class="page-numbers">
        <ng-container *ngFor="let pageNumber of getPageNumbersToShow()">
          <div
            class="page-number"
            [ngClass]="{ 'current-page': pageNumber === currentPageIndex + 1 }"
            (click)="goToPage(pageNumber - 1)"
          >
            {{ pageNumber }}
        </div>
        </ng-container>
      </div>
      <button (click)="nextPage()" class="page-btn" [disabled]="currentPageIndex === articles.length - 1">
        Siguiente
        <img *ngIf="currentPageIndex === articles.length - 1" class="arrow-page-right"  src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-right.svg" />
        <img *ngIf="!(currentPageIndex === articles.length - 1)" class="arrow-page-right"  src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/muvit/arrow-right-active.svg" />
      </button>
      </div>
    </div>
    
    <!-- Botones de paginación -->

    
    




    

  </div>
    
</div>






<div *ngIf="showArticle">
  <muvit-article [selectedArticle]="selectedArticle" [listOfArticles]="listOfArticles"></muvit-article>
</div>
<div class="body" style="background-color: white;">
  <div class="reviews-title">
    <div class="title-review">
      Comentarios de Viajeros Destacados
    </div>
  </div>
  <div class="reviews">
    <ng-container>
        <swiper-container class="review-grid" slides-per-view="1"  pagination="true" style=" 
        --swiper-pagination-bullet-width:15px;
        --swiper-pagination-bullet-height: 15px;
        --swiper-pagination-bullet-horizontal-gap: 14px;
        --swiper-pagination-color: #FF9900;
      
    ">
            <swiper-slide class="testimony">
                <div class="testimonial">
                    <div class="user-info">
                      <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/reviews/Seguro_de_viaje_Muvit_1.webp" alt="User 1" class="user-photo">
                      <div class="title-user">
                        <div class="review-title">
                          {{ user_rating[0].title }}
                        </div>
                        <div class="rating">
                          <ng-container *ngFor="let _ of getArray(user_rating[0].rating); let i = index">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="#FFCF38" class="bi bi-star-fill" viewBox="0 0 16 16" style="margin-right: 8%;"> 
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> 
                            </svg>
                          </ng-container>
                          <ng-container *ngFor="let _ of getArray(5 - user_rating[0].rating); let i = index">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="#605f5f" class="bi bi-star-fill" viewBox="0 0 16 16" style="margin-right: 8%;"> 
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> 
                            </svg>
                          </ng-container>
                        </div>
                      </div>
                      </div>
                    <div class="review">
                    <p class="review-text">
                        {{ user_rating[0].review }}
                    </p>
                    <p class="review-text" style="font-weight: bold;">
                        - {{ user_rating[0].user }} -
                    </p>
                </div>
                  </div>
            </swiper-slide>
            <swiper-slide class="testimony">
                <div class="testimonial">
                    <div class="user-info">
                      <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/reviews/Seguro_de_viaje_Muvit_2.webp" alt="User 1" class="user-photo">
                      <div class="title-user">
                        <div class="review-title">
                          {{ user_rating[1].title }}
                        </div>
                        <div class="rating">
                          <ng-container *ngFor="let _ of getArray(user_rating[1].rating); let i = index">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="#FFCF38" class="bi bi-star-fill" viewBox="0 0 16 16" style="margin-right: 8%;"> 
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> 
                            </svg>
                          </ng-container>
                          <ng-container *ngFor="let _ of getArray(5 - user_rating[1].rating); let i = index">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="#605f5f" class="bi bi-star-fill" viewBox="0 0 16 16" style="margin-right: 8%;"> 
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> 
                            </svg>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    
                    <div class="review">
                    <p class="review-text">
                        {{ user_rating[1].review }}
                    </p>
                    <p class="review-text" style="font-weight: bold;">
                        - {{ user_rating[1].user }} -
                    </p>
                </div>
                  </div>
            </swiper-slide>
            <swiper-slide class="testimony">
                <div class="testimonial">
                    <div class="user-info">
                      <img src="https://storage.googleapis.com/muvit-microsite-prod.appspot.com/assets/reviews/Seguro_de_viaje_Muvit_3.webp" alt="User 1" class="user-photo">
                      <div class="title-user">
                        <div class="review-title">
                          {{ user_rating[2].title }}
                        </div>
                        <div class="rating">
                          <ng-container *ngFor="let _ of getArray(user_rating[2].rating); let i = index">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="#FFCF38" class="bi bi-star-fill" viewBox="0 0 16 16" style="margin-right: 8%;"> 
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> 
                            </svg>
                          </ng-container>
                          <ng-container *ngFor="let _ of getArray(5 - user_rating[2].rating); let i = index">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="#605f5f" class="bi bi-star-fill" viewBox="0 0 16 16" style="margin-right: 8%;"> 
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> 
                            </svg>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div class="review">
                    <p class="review-text">
                        {{ user_rating[2].review }}
                    </p>
                    <p class="review-text" style="font-weight: bold;">
                      - {{ user_rating[2].user }} -
                    </p>
                </div>
                  </div>
            </swiper-slide>
        </swiper-container>
    
        

    </ng-container>
  </div>
</div>


<app-footer *ngIf="this.device.isDesktop()"></app-footer>
<app-footer-mobile *ngIf="this.device.isMobile()"></app-footer-mobile>
