import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-warning-card-muvit',
  templateUrl: './warning-card-muvit.component.html',
  styleUrls: ['./warning-card-muvit.component.css']
})
export class WarningCardMuvitComponent implements OnInit {

  warningMessage = ""
  cancel = ""
  accept = ""
  newMessage = ""
  desc = ""
  continueValue:boolean
  constructor(public dialogRef: MatDialogRef<WarningCardMuvitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    
    this.warningMessage = this.data.warning;
    if("cancel" in this.data)this.cancel = this.data.cancel
    if("accept" in this.data)this.accept = this.data.accept
    if("newMessage" in this.data)this.newMessage = this.data.newMessage
    if("description" in this.data)this.desc = this.data.description
    

  }
  continue(value){
    if(value == 1){
      this.continueValue = true
    }
    else{this.continueValue = false}
    this.dialogRef.close(this.continueValue)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isString(val): boolean { return typeof val === 'string'; }

}
