import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Route, Router } from '@angular/router';
import * as moment from 'moment';
import { ApiService } from '../../../api/api.service';
import { GenericInsurance } from '../../../models/generic-insurance';
import { InsuranceHolder } from '../../../models/insurance-holder';
import { InsuranceInsured } from '../../../models/insurance-insured';
import { InsurancePayment } from '../../../models/insurance-payment';
import { GlobalService } from '../../../services/global.service';
import * as forge from 'node-forge';
// import { AddCaptchaComponent } from '../../add-captcha/add-captcha.component';
import { NgForm } from '@angular/forms';
import { getMicroFormError } from '../../../global/cybersource'
import { Title } from '@angular/platform-browser';
import { ConfigService } from '../../../config/config.service';

declare var Flex: any;
interface obj {
  viewValue: string;
  value: string;
}
@Component({
  selector: 'app-muvit-payment-form',
  templateUrl: './muvit-payment-form.component.html',
  styleUrls: ['./muvit-payment-form.component.css',
    '../muvit-insurance-holder/muvit-insurance-holder.component.css',
    '../muvit-public.component.css'
  ]
})
export class MuvitPaymentFormComponent implements OnInit {

  @Input() PRODUCT_FOR_LOCATION: string;
  @Input() titulos: Array<string>;
  @Input() titulosYearly: Array<string>;
  @Input() destiny: Object = { "name": null };
  @Input() primas: Array<Array<number>>;
  @Input() coverage: Array<number>;
  @Input() coverageYearly: Array<number>;
  @Input() totalPersons: object;
  // @Output() newPersonsJson: object;
  @Output("returnToHome") returnToHome: EventEmitter<any> = new EventEmitter();
  personsJson: any;

  inssuranceInformation?: GenericInsurance;
  insuranceHolder?: InsuranceHolder;
  insuranceInsured?: InsuranceInsured;
  insurancePaymentInfo?: InsurancePayment;
  savedInfo?: any;
  errorMessage: string;
  currentURL = ""
  cardNumber = ""
  cardName = ""
  cardExpDate = ""
  cardCVV = ""
  promoCode = ""
  prima = 0
  title = ""
  tipoPlan: any;
  plan = 0
  payerIsDifferent: any;
  config = ""
  showLoader = false;
  pressed = false
  visa = false
  master = false
  amex = false
  disBtn = false
  ventaMuvit = "ventaMuvit"
  visaConst = /^4[0-9]{12}(?:[0-9]{3})?$/;
  masterConst = /^5[1-5][0-9]{14}$/;
  isCardFilled = false
  isCVVFilled = false

  primaPiv = 0;
  oldsHolder = 0;
  adultsHolder = 0;
  olds = 0;
  adults = 0;
  kids = 0;
  totalAdults = 0;
  invalidDate = true

  pk: any = null;
  @ViewChild('g-recaptcha-response') recaptchaField: ElementRef;
  // @ViewChild(AddCaptchaComponent) gCaptcha: AddCaptchaComponent;
  @ViewChild('paymentform') paymentformmuvit: NgForm;

  microform: any;
  token: String;
  cardFlexComponentIsLoaded: boolean = false;
  cvvFlexComponentIsLoaded: boolean = false;

  private router: Router
  constructor(
    private injector: Injector,
    private httpClient: HttpClient,
    public gs: GlobalService,
    private api: ApiService,
    private dateAdapter: DateAdapter<Date>,
    private titleService: Title,
    private conf: ConfigService
  ) {
    this.router = this.injector.get<Router>(Router);
    this.dateAdapter.setLocale('en-GB');
    this.personsJson = { "adults": null, "olds": null, "kids": null, "totalPersons": null, "totalAdults": null };
  }

  ngOnInit() {
    if (!("travel_starting_date" in this.gs.currentInsuranceInfo)) {
      this.router.navigate([this.gs.currentSiteUrl], { queryParams: { step: null }, queryParamsHandling: 'merge' });
    }
    this.titleService.setTitle("Muvit - Datos Pago");
    this.gotoTop()
    this.plan = this.gs.currentInsuranceInfo.insurance_plan_id

    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.chosePlanPrima()
    this.currentURL = this.router.url
    this.cardFlexComponentIsLoaded = false
    this.cvvFlexComponentIsLoaded = false
    this.currentURL = this.getMainRoute(this.currentURL)
    this.parseURL(this.currentURL)
    this.inssuranceInformation = new GenericInsurance();
    this.insuranceHolder = new InsuranceHolder();
    this.insuranceInsured = new InsuranceInsured();
    this.insurancePaymentInfo = new InsurancePayment();


    let myStyles = {
      'input': {
        'font-size': '1rem',
        'font-family': 'system-ui',
        'font-weight': '400'
      },
      '::placeholder': {
        'color': '#000000'
      },
      ':hover': {
        'border-color': '#3dbfef'
      }
    }

    this.api.cybersource.getFlex().promise().then(
      res => {
        let flex = new Flex(res.jwt)
        this.microform = flex.microform({ styles: myStyles });
        var number = this.microform.createField('number', { placeholder: 'Número de tarjeta de crédito *' });
        var securityCode = this.microform.createField('securityCode', { placeholder: 'Código de seguridad *', maxLength: 4 });
        let self = this;
        number.on('change', (data) => {
          this.isCardFilled = data.couldBeValid
          if (data.card)
            this.verifyCybersourceCardBrand(data.card)
        })
        number.on('load', (data) => {
          this.cardFlexComponentIsLoaded = true
        })
        securityCode.on('change', (data) => {
          this.isCVVFilled = data.couldBeValid
        })
        securityCode.on('load', (data) => {
          this.cvvFlexComponentIsLoaded = true
        })
        number.load('#number-container');
        securityCode.load('#securityCode-container');

      }
    )
    // this.initForm()
    if ("holder" in this.gs.currentInsuranceInfo) {
      // We have items
      this.savedInfo = this.gs.currentInsuranceInfo;
      if (this.savedInfo != null && this.savedInfo.insurance_plan_id) {
        // this.planNumber = this.savedInfo.insurance_plan_id;
      }
    } else {
      // No items
      this.router.navigate([this.gs.currentSiteUrl], { queryParams: { step: null }, queryParamsHandling: 'merge' });
    }
  }

  buyMuvit() {
    if (this.invalidDate) {
      return
    }


    try {

     
      //
      /*   if(this.gs.isYearly){
            if(this.gs.region == 1){
              this.gs.internal_product_code += "DOMESTIC"
            }
            else if(this.gs.region == 2){
              this.gs.internal_product_code += "US"
            }
           else if(this.gs.region == 3){
              this.gs.internal_product_code += "LATAM"
            }
           else if(this.gs.region == 4){
              this.gs.internal_product_code += "ROW"
            }
          } */
      //
      //
      this.showLoader = true
      this.disBtn = true
      let nowDate = Date.now();
      let datepipe = new DatePipe('en-US');
      this.gs.payment_info.payment_type = 1;


      this.savedInfo = this.gs.currentInsuranceInfo
      this.savedInfo.holder.address = this.savedInfo.holder.origin
      this.savedInfo.insured.address = this.savedInfo.insured.origin
      this.inssuranceInformation.product_code = this.gs.internal_product_code;
      this.inssuranceInformation.date_issued = datepipe.transform(nowDate, 'yyyy-MM-dd')
      this.inssuranceInformation.policy_number = "";
      this.inssuranceInformation.holder = this.savedInfo.holder;
      this.inssuranceInformation.insured = this.savedInfo.insured;


   
      this.inssuranceInformation.date_effective = this.savedInfo.date_effective;
      this.inssuranceInformation.creation_address = "online-page";
      //Set temp empty values
      this.inssuranceInformation.observation = "";
      this.inssuranceInformation.insured_firm = "";
      this.inssuranceInformation.holder_firm = "";
      this.inssuranceInformation.call_id = this.savedInfo.call_id;
      this.inssuranceInformation.travel_starting_date = this.savedInfo.travel_starting_date;
      this.inssuranceInformation.travel_end_date = this.savedInfo.travel_end_date;
      if (!("accompanisttable" in this.savedInfo)) {
        this.inssuranceInformation.accompanisttable = [];
      }
      else {
        this.inssuranceInformation.accompanisttable = this.savedInfo.accompanisttable
      }

      this.inssuranceInformation.accompanisttable = this.inssuranceInformation.accompanisttable.map(acc => {
        return acc;
      });

      let tempCardNum = this.cardNumber.replace(/\s/g, '');

      if (tempCardNum.length > 16) {
        tempCardNum = tempCardNum.substring(0, tempCardNum.length - 1);
      }

      if (this.cardExpDate.length > 5) {
        this.cardExpDate = this.cardExpDate.substring(0, this.cardExpDate.length - 1);
      }
      // clean name from weird accents
      this.cardName = this.cardName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

      //encryption test
      //this.insurancePaymentInfo.payment_name = this.encryptData(this.cardName)
      this.insurancePaymentInfo.payment_name = this.cardName;
      // this.insurancePaymentInfo.cvv = this.encryptData(this.cardCVV)
      this.insurancePaymentInfo.expiration_date = this.encryptData(this.cardExpDate)
      // this.insurancePaymentInfo.payment_number = this.encryptData(this.normalizeField(tempCardNum).replace(/\s/g, ''))
      this.insurancePaymentInfo.payment_type = this.gs.payment_info.payment_type
      this.insurancePaymentInfo.premium = this.prima

      delete this.insurancePaymentInfo.frequency;
      delete this.insurancePaymentInfo.policy_charge;

      this.inssuranceInformation.self_insurance = true;
      this.insurancePaymentInfo.payment_responsable = this.savedInfo.holder;
      this.inssuranceInformation.payment_info = this.insurancePaymentInfo;
      // this.inssuranceInformation.captcha_token = this.gCaptcha.getCaptchaToken();
      this.inssuranceInformation.days_of_travel = this.gs.currentInsuranceInfo.days_of_travel;
      //
      //
      // return
      let formattedExpDateForCybersource = this.cardExpDate.split('/')
      let expirationDateYearFormattedForCybersource = '20' + formattedExpDateForCybersource[1]
      var options = {
        expirationMonth: formattedExpDateForCybersource[0],
        expirationYear: expirationDateYearFormattedForCybersource
      }

    } catch (error) {
      console.error("ERROR ON CATCH 309", error)
      this.showLoader = false
      // this.gs.openDialogMuvit(error);
    }

    let createCybersourceCardToken = new Promise((res, rej) => {
      this.microform.createToken(options, function (err, token) {
        if (err) {
          // handle error
          rej(err)
        } else {
          // At this point you may pass the token back to your server as you wish.
          // In this example we append a hidden input to the form and submit it.
          res(token)
        }
      })
    }
    )
    createCybersourceCardToken.then(
      cardToken => {
        this.inssuranceInformation.payment_info.payment_token = cardToken.toString()
        this.api.enrollAsys.post(this.inssuranceInformation)
          .promise().then(resp => {
            this.showLoader = false;
            this.pressed = false;
            this.disBtn = false
            if (resp.status == 200 && resp.data.policyNumber !== '') {
              this.showLoader = false

              this.gs.currentInsuranceInfo.policy_number = resp.data.policyNumber;
              this.gs.currentInsuranceInfo.transaction = resp.data.transaction;

              let datasend = {
                id: localStorage.getItem('lead'),
                policy_id: resp.data.policyNumber,
                step: 3
              }
              this.api.landingLeads.updateStatus_v3(datasend).promise().then(
                () => {
                });
              localStorage.removeItem('lead')
              try {
                this.sendDataToGTM();
              }
              catch (error) {

              }


              this.router.navigate([this.gs.currentSiteUrl], { queryParams: { step: 5 }, queryParamsHandling: 'merge' })
            } else {
              //Show error
              if ('message' in resp) {
                this.gs.openDialogMuvit(resp.message);
              } else {
                this.gs.openDialogMuvit(this.errorMessage);
              }
            }
          }, error => {
            this.disBtn = false
            this.showLoader = false;
            this.pressed = false;
          })
          .catch(e => {
            this.pressed = false;
            this.showLoader = false;
            this.disBtn = false
            this.gs.openDialogMuvit(this.errorMessage);
          })
      }
    ).catch(
      err => {
        let errorLocation = '';
        for (let i = 0; i < err.details.length; i++) {
          const errorDetail = err.details[i];
          errorLocation = errorDetail.location
          break
        }
        this.showLoader = false;
        this.pressed = false;
        this.disBtn = false
        this.gs.openDialogMuvit(getMicroFormError(errorLocation))
      }
    )


  }
  gotoTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  encryptData(text) {
    this.pk = this.gs.SC_public_PEM_key
    let public_key = forge.pki.publicKeyFromPem(this.pk);
    let encrypted = public_key.encrypt(text, "RSA-OAEP", {
      md: forge.md.sha256.create(),
      mgf1: forge.mgf.mgf1.create(forge.md.sha256.create()) // ---> Cambio ---> Pendiente
    });
    var base64 = forge.util.encode64(encrypted);
    return base64
  }

  normalizeField(field) {
    return field.normalize("NFD")
  }

  chosePlanPrima() {
    let planId = this.gs.currentInsuranceInfo.insurance_plan_id
    let primaPiv = 0;
    this.oldsHolder = 0;
    this.adultsHolder = 0;
    this.olds = 0;
    this.adults = 0;
    this.kids = 0;
    this.totalAdults = 0

    let days_of_travel = moment(this.gs.currentInsuranceInfo.travel_end_date).diff(moment(this.gs.currentInsuranceInfo.travel_starting_date), "days")
    days_of_travel += 1; // +1
    this.gs.currentInsuranceInfo.holder.age = moment().diff(moment(this.gs.currentInsuranceInfo.holder.birthday), "years");

    this.gs.currentInsuranceInfo.days_of_travel = days_of_travel;
    if (this.gs.currentInsuranceInfo.holder.age <= 64) {
      this.adultsHolder += 1
      this.totalAdults += 1
      this.gs.currentInsuranceInfo.holder.units = this.primas[planId - 1][0] * days_of_travel;
    }
    else if (this.gs.currentInsuranceInfo.holder.age >= 65) {
      this.oldsHolder += 1
      this.totalAdults += 1
      this.gs.currentInsuranceInfo.holder.units = this.primas[planId - 1][1] * days_of_travel;
    }
    if (!("accompanisttable" in this.gs.currentInsuranceInfo)) {
      this.gs.currentInsuranceInfo.accompanisttable = [];
    }

    this.gs.currentInsuranceInfo.accompanisttable = this.gs.currentInsuranceInfo.accompanisttable.map(acc => {
      if (!acc.birthday) {
      }
      return acc;
    });

    for (let a of this.gs.currentInsuranceInfo.accompanisttable) {
      a.age = moment().diff(moment(a.birthday), "years");
      //
      if (a.age <= 17) {
        this.kids += 1
        a.units = this.primas[planId + 5][0] * days_of_travel
      }
      else if (a.age <= 64) {
        this.adults += 1
        this.totalAdults += 1
        a.units = this.primas[planId + 2][0] * days_of_travel;
      }
      else {
        this.olds += 1
        this.totalAdults += 1
        a.units = this.primas[planId + 2][1] * days_of_travel;
      }
    }
    let fullPriceYearly = [304.99, 234.99, 154.99]
    this.prima = primaPiv
    localStorage.setItem("final_price", this.prima.toString())

    return primaPiv

  }
  getMainRoute(url) {
    let paths = url.split("?")
    return paths[0]
  }
  parseURL(url) {
    let path = url.split("/")
    for (let i = 0; i < path.length; i++) {
      const element = path[i];
      if (element == "public") {
        this.config = "public"
      } else if (element == "private") {
        this.config = "private"
      }
    }
  }

  verifyCybersourceCardBrand(cards) {
    this.visa = false
    this.master = false
    this.amex = false
    cards.forEach(card => {
      if (card.name === 'visa') {
        this.visa = true
        this.master = false
        this.amex = false
      } else if (card.name == 'mastercard') {
        this.visa = false
        this.master = true
        this.amex = false
      } else if (card.name == 'amex') {
        this.visa = false
        this.master = false
        this.amex = true
      }
    });
  }

  verifyCardBrand() {
    if (this.cardNumber.charAt(0) == '4') {
      this.visa = true
      this.master = false
      this.amex = false
    }
    else if (this.cardNumber.charAt(0) == '5') {
      this.visa = false
      this.master = true
      this.amex = false
    }
    else if (this.cardNumber.charAt(0) == '3') {
      this.visa = false
      this.master = false
      this.amex = true

    }
    //else if(this.cardNumber.match(this.masterConst)){
    //   this.visa = false
    //   this.master= true
    // }
    else if (this.cardNumber.charAt(0) != '4' && this.cardNumber.charAt(0) != '5') {
      this.visa = false;
      this.master = false;
      this.amex = false
    }
  }

  onSubmit() {

  }
  returnToHome2() {
    this.returnToHome.emit();
    this.router.navigate(
      [this.gs.currentSiteUrl], { queryParams: { step: 1 }, queryParamsHandling: 'merge' }
    );
  }
  verifyExpiration() {

    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    // let curMonth = month.toString()
    let curYear = year % 100
    // if(curMonth.length == 1) curMonth='0'+curMonth

    let strSplit = this.cardExpDate.split('')
    let strMonth = strSplit[0] + strSplit[1]
    let strYear = strSplit[3] + strSplit[4]
    let numMonth = Number(strMonth)
    let numYear = Number(strYear)
    if (this.cardExpDate.length > 4) {
      if (numMonth > 12) {
        this.invalidDate = true
        return
      }
      if (curYear == numYear) {
        if (numMonth >= month) {
          this.invalidDate = false
        }
        else {
          this.invalidDate = true
        }
      }
      else if (curYear < numYear) {
        this.invalidDate = false
      }
      else {
        this.invalidDate = true
      }
    }
    else {
      this.invalidDate = true
    }

  }

  isPaymentValid() {
    return this.isCVVFilled && this.isCardFilled
  }

  sendDataToGTM() {
    let labelJson: any;
    /**
     * ({
        'items': [{
        'item_id': 'NumeroPoliza + correlativo',
        'item_name': 'T-Shirt',
        'price': 11.99,
        'quantity': 1
        },{
        'item_id': 'NumeroPoliza +  correlativo',
        'item_name': 'Socks',
        'price': 9.99,
        'quantity': 1
        }]
        })
     */
    labelJson = {
      items: [{
        'item_id': this.gs.currentInsuranceInfo.policy_number + '_1',
        'item_name': this.gs.currentInsuranceInfo.holder.name,
        'price': this.gs.currentInsuranceInfo.holder.units,
        'quantity': 1

      }]
    }
    if (this.gs.currentInsuranceInfo.accompanisttable) {
      let companions = this.gs.currentInsuranceInfo.accompanisttable
      for (var i = 0; i < companions.length; i++) {
        let item = {
          'item_id': this.gs.currentInsuranceInfo.policy_number + '_' + (i + 2).toString(),
          'item_name': companions[i].first_name + ' ' + companions[i].last_name,
          'price': companions[i].units,
          'quantity': 1
        }
        labelJson.items.push(item)
      }
    }

    const pushData = {
      "event": "purchase",
      "currency": "USD",
      "value": localStorage.getItem('final_price'),
      "transaction_id": this.gs.currentInsuranceInfo.policy_number,
      "plan_name": `Muvit Plan ${this.titulos[this.gs.currentInsuranceInfo.insurance_plan_id - 1]}`,
      "plan_id": this.gs.currentInsuranceInfo.insurance_plan_id,
      "items": []
    };
    pushData.items.push(labelJson);
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push(pushData);
  }

}
