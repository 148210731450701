import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NavbarServiceService } from '../components/navbar-service.service';
import { DataConstructorService } from '../services/data-constructor.service';

@Injectable({
  providedIn: 'root'
})

export class workflowCheckerMobileGuard implements CanActivate {
  constructor(private router: Router, public data: DataConstructorService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const email = this.data.email;
    const countries = this.data.countries;

    if (email && countries) {
      return true;
    } else {
      this.router.navigate(['public/mobile/']); // Redirige al usuario a la página inicial si no ha llenado la información
      return false;
    }
  }
};
