<div class="dialog-container">
    <button class="close-button" mat-icon-button aria-label="close dialog" mat-dialog-close>
        <mat-icon >close</mat-icon>
    </button>
    <h2>Tu viaje, est&aacute; protegido <br> P&oacute;liza No.
        {{dataConstructor.policy_number}}</h2>

    <div class="flyInfo">
        <span style="color: #000;
font-family: Lato;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
flex-direction: row !important; gap: 8px;">
            <span style="display: flex; flex-direction: row; color: #000;
            font-family: Lato;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal; ">Plan
                {{dataConstructor.typePlan}}
                {{dataConstructor.planSelected}}</span>

            <span style="display: flex; flex-direction: row; gap: 8px;"> <b> USD
                    {{dataConstructor.finalTotal_}} </b></span>
        </span>
        <span style="flex-direction: row !important; gap: 4px;">
            <span style="flex-direction: row !important; gap: 4px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16"
                    viewBox="0 0 22 16" fill="none">
                    <g clip-path="url(#clip0_611_35394)">
                        <path
                            d="M0.401067 8.82623C0.150756 8.54463 0 8.17485 0 7.76809C0 7.66854 0.00853334 7.57183 0.0256 7.47796C0.116622 6.98872 0.426667 6.57627 0.853333 6.35156L1.39093 6.06711C1.62418 5.9448 1.88018 5.88223 2.13902 5.88223C2.4064 5.88223 2.65956 5.94765 2.88142 6.06427L4.70187 7.02L4.71609 7.02854L4.73031 7.02L6.95751 5.84525L6.96889 5.83956L6.96036 5.83387L3.49582 3.56969C3.05778 3.28525 2.77049 2.79316 2.77049 2.22996C2.77049 1.61556 3.11751 1.0808 3.62382 0.813426L4.82133 0.181959C5.0432 0.065337 5.2992 -0.00292969 5.56942 -0.00292969C5.77422 -0.00292969 5.97049 0.0368925 6.14969 0.105159L12.8484 2.7192L12.8626 2.72489L12.874 2.7192L15.8293 1.1576C16.731 0.682582 17.6412 0.440804 18.5344 0.440804C18.6425 0.440804 18.7506 0.443648 18.8558 0.452181C20.0306 0.531826 20.8014 0.941426 21.1484 1.66676C21.4983 2.39778 21.3476 3.29947 20.699 4.34907C20.1614 5.21663 19.3479 5.95618 18.3467 6.48525L9.00551 11.4175C8.64142 11.6081 8.23467 11.7105 7.8336 11.7105L3.69209 11.7276H3.6864C3.20853 11.7276 2.77902 11.5199 2.48604 11.1871L0.401067 8.82623ZM21.3333 14.9332V15.468C21.3333 15.7638 21.0944 16.0028 20.7986 16.0028H0.534756C0.238933 15.9999 0 15.761 0 15.4652V14.9304C0 14.6346 0.238933 14.3956 0.534756 14.3956H20.8014C21.0972 14.3956 21.3362 14.6346 21.3362 14.9304L21.3333 14.9332ZM1.60142 7.76809L3.68924 10.1318L7.83076 10.1148C7.97867 10.1148 8.12658 10.0778 8.26311 10.0067L17.6043 5.0744C18.3552 4.67903 18.9554 4.13858 19.3422 3.50996C19.7234 2.89271 19.7774 2.50587 19.7092 2.3608C19.638 2.21005 19.254 2.08774 18.7506 2.0536C18.6795 2.04791 18.6084 2.04791 18.5372 2.04791C17.9058 2.04791 17.2487 2.22711 16.5803 2.57983L12.965 4.48845L5.56658 1.59849L4.36907 2.22996L10.1177 5.98463L4.71893 8.83476L2.13902 7.4808L1.60142 7.76525V7.76809Z"
                            fill="black" />
                    </g>
                    <defs>
                        <clippath id="clip0_611_35394">
                            <rect width="21.3333" height="16" fill="white" />
                        </clippath>
                    </defs>
                </svg>
                {{this.dates.start}}
            </span>
            <span style="flex-direction: row !important; gap: 4px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16"
                    viewBox="0 0 20 16" fill="none">
                    <g clip-path="url(#clip0_611_35392)">
                        <path
                            d="M0.530667 7.20802C0.194667 6.92269 0 6.50402 0 6.06402V3.42135C0 2.90935 0.394667 2.01602 1.49867 2.01602C1.74133 2.01602 1.87467 2.06402 2.31467 2.17869C2.77067 2.29869 3.144 2.53069 3.32267 2.96535L4.05867 4.75202L6.456 5.37869L5.408 1.93869C5.36533 1.80002 5.344 1.65335 5.344 1.50135C5.344 1.09869 5.504 0.733352 5.76267 0.464019C6.048 0.165352 6.44267 0.00268555 6.84533 0.00268555C7.096 0.00268555 7.144 0.0293522 8.27467 0.325352C8.68 0.432019 9.016 0.698686 9.21333 1.05869L12.4213 6.93602L15.48 7.73602C16.5013 8.00269 17.384 8.48269 18.0373 9.12802C18.8293 9.90935 19.1333 10.6614 18.9413 11.368C18.752 12.056 18.112 12.552 17.0453 12.8374C15.912 13.1387 14.8373 12.9787 14.1307 12.7947L4.55733 10.2934C4.18133 10.1947 3.82667 10.008 3.54133 9.76535L0.530667 7.20802ZM20 15V15.5014C20 15.7787 19.776 16.0027 19.4987 16.0027H0.501333C0.224 16.0027 0 15.7787 0 15.5014V15C0 14.7227 0.224 14.4987 0.501333 14.4987H19.5013C19.7787 14.4987 20.0027 14.7227 20.0027 15H20ZM1.49867 6.06135L4.512 8.61335C4.632 8.71735 4.77867 8.79469 4.936 8.83469L14.5093 11.336C15.6693 11.6374 16.4453 11.4374 16.6587 11.3814C17.3067 11.208 17.4853 11.008 17.496 10.9627C17.5093 10.9174 17.4587 10.656 16.984 10.1894C16.5173 9.73069 15.8667 9.38135 15.0987 9.18135L11.4133 8.21869L7.89867 1.77602L6.848 1.50135L8.67733 7.50669L2.96 6.01335L1.93867 3.53602L1.50133 3.42135V6.06402L1.49867 6.06135Z"
                            fill="black" />
                    </g>
                    <defs>
                        <clippath id="clip0_611_35392">
                            <rect width="20" height="16" fill="white" />
                        </clippath>
                    </defs>
                </svg>
                {{this.dates.final}}
            </span>

        </span>

        <span>{{this.dataConstructor.totalPeople}} Personas</span>
    </div>

    <div class="contactInfo">
        Recibir&aacute;s la confirmaci&oacute;n de tu compra al correo: <b>
        {{this.dataConstructor.email}} </b>
    </div>


    <div class="Options">
        <button class="downloadButton" (click)="downloadPolicy()">Descargar</button>
    </div>
</div>