<div class="beneficiary-svg-container">
  <h2 class="title">Beneficios adicionales</h2>
  <div class="cards-wrapper">
    <div class="card" *ngFor="let card of cards" [style.borderTopColor]="card.color">
      <img [src]="card.icon" alt="{{ card.title }}" class="card-icon" />
      <h3 class="card-title">{{ card.title }}</h3>
      <p class="card-description">{{ card.description }}</p>
    </div>
  </div>

</div>
<img src="../../../../assets/muvitBAM/banner-Agricola.png" class="imagen" alt="Slide 2">
