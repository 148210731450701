<div class="Card-Container">
    <div class="card" [style.margin-left]="photoUrl ? '60px' : '0'">
        <div class="comment">
            <p>{{this.commentInfo}}</p>
            <div class="calification">

                <div class="stars-rating" *ngFor="let i of stars">
                    <img src="../../../../assets/home-icons/comentarios-usuarios-mejor-rating-seguros-de-viaje-01.svg" alt="">
                </div>
                <div class="rating">
                    5/5
                </div>
                
            </div>
        </div>

    </div>
</div>  

<div class="photo"
[style.background-image]="'url(../../../../assets/card/' + photoUrl + ')'"
[style.background-size]="photoUrl ? 'cover' : 'auto'"
[style.background-position]="photoUrl ? 'center' : 'auto'"
*ngIf="photoUrl != ''">
</div>



<div class="social-media" 
[style.top]="photoUrl ? (screenWidth <= 1500 ? '-295px' : '-340px') : (screenWidth <= 1500 ? '-205px' : '-220px')"
[style.left]="photoUrl ? '120px ' : '60px'">

    <img src="../../../../assets/card/{{this.socialMediaUrl}}" alt="">
</div>

<div class="name" [style.top]="photoUrl ? (screenWidth <= 1500 ? '-340px' : '-400px') : (screenWidth <= 1500 ? '-250px' : '-285px')"
[style.left]="photoUrl ? (screenWidth <= 1500 ? '170px' : '190px') : (screenWidth <= 1500 ? '115px' : '130px' )" >
{{name}}
</div>