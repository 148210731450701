<div class="card-container">
    <div class="card">
        <div class="title-divider">Completar información del viajero</div>
        <form class="card-form" [formGroup]="clientForm" (ngSubmit)="onSubmit()">
            <app-card-input [inputTitle]="'Nombre 1'" [isRequired]="true" class='form-inputs' formControlName="first_name"
                (valueChange)="setFormFieldValue('first_name', $event)"></app-card-input>
            <app-card-input [inputTitle]="'Nombre 2'" [isRequired]="true" class='form-inputs' formControlName="middle_name"
                (valueChange)="setFormFieldValue('middle_name', $event)"></app-card-input>
            <app-card-input [inputTitle]="'Apellido 1'" [isRequired]="true" class='form-inputs'
                formControlName="last_name" (valueChange)="setFormFieldValue('last_name', $event)"></app-card-input>
            <app-card-input [inputTitle]="'Apellido 2'" [isRequired]="true" class='form-inputs'
                formControlName="last_name_2" (valueChange)="setFormFieldValue('last_name_2', $event)"></app-card-input>
            <app-card-select [Title]="'Género'" [isRequired]="true" class='form-inputs' [Options]="this.genreOptions"
                formControlName="genre" (valueChange)="setFormFieldValue('genre', $event)"></app-card-select>
            <app-card-datepicker [inputTitle]="'Fecha de nacimiento'" [isRequired]="true" class='form-inputs'
                (valueChange)="isChangeDate($event)" formControlName="birthday" [isPrincipal]="true"
                (valueChanged)="setFormFieldValue('birthday', $event)"></app-card-datepicker>
            <app-card-input [inputTitle]="'No. Pasaporte'" [isRequired]="true" class='form-inputs2'
                formControlName="passport" (valueChange)="setFormFieldValue('passport', $event)"></app-card-input>

            <app-card-select [Title]="'Pa&iacute;s de Residencia'" [isRequired]="true" [Options]="this.countries"
                class='form-inputs2' formControlName="residency"
                (valueChange)="setFormFieldValue('residency', $event)"></app-card-select>
            <app-card-input [inputTitle]="'Correo electrónico'"
                [subTitle]="'Enviaremos la confirmaci&oacute;n a este correo'" [isRequired]="true"
                class='form-inputs email-input' [isEmail]="true" formControlName="email"
                (valueChange)="setFormFieldValue('email', $event)"></app-card-input>
            <app-card-phone-select [Title]="'Prefijo'" [Options]="this.prefixList"
                [InputTitle]="'N&uacute;mero de Tel&eacute;fono'" [isRequired]="true" class='form-input phone-input'
                formControlName="numberPrefix" (valueChanged)="setFormFieldValue('numberPrefix', $event)"
                (inputChange)="setFormFieldValue('phoneNumber', $event)"> </app-card-phone-select>

            <div class="checkbox-container">
                <label class="container" style="display: flex;">
                    <input type="checkbox" [checked]="TermsAndConditions"
                        (change)="TermsAndConditions = !TermsAndConditions" id="termsAndCoditions">
                    <span class="checkmark"></span>
                    <p>Acepto los  <a [routerLink]="'/public/terms-and-conditions'" [queryParamsHandling]="'preserve'" id="btnTyC">T&eacute;rminos
                        y Condiciones</a> de Muvit</p>
                </label>                
            </div>
            <hr class="title-divider">
            <app-card-input [inputTitle]="'Nit'" [isRequired]="true" class='form-inputs' formControlName="nit"
                (valueChange)="setFormFieldValue('nit', $event)"></app-card-input>
            <app-card-input [inputTitle]="'Nombre'" [isRequired]="true" class='form-inputs' formControlName="name_billing"
                (valueChange)="setFormFieldValue('name_billing', $event)"></app-card-input>
            <app-card-input [inputTitle]="'Dirección de facturación'" [isRequired]="true" class='form-inputs'
                formControlName="billingAddress" (valueChange)="setFormFieldValue('billingAddress', $event)"></app-card-input>
                

        </form>
        <div class="buttonsOptions">
            <button (click)="onAddFormButtonClick()"
                *ngIf="this.dataConstructor.formsQuantity == 0 && this.dataConstructor.typePlan != 'Anual'"
                id="addBeneficiary"><i class="fa-solid fa-user-plus" style="color: #ffffff;"></i></button>
        </div>
    </div>
</div>