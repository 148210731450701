import { Component } from '@angular/core';

@Component({
  selector: 'app-benefits-mobile',
  templateUrl: './benefits-mobile.component.html',
  styleUrl: './benefits-mobile.component.css'
})
export class BenefitsMobileComponent {
  benefitSections = [
    {
      title: 'Maletas',
      backgroundTitle:'../../../../assets/tecniseguros/iconos/background-maleta.png',
      icon: '../../../../assets/tecniseguros/iconos/maleta.png', 
      items: [
        'Pérdida de maletas hasta $1,200',
        'Atraso de maletas hasta $800'
      ],
      backgroundItems: '../../../../assets/tecniseguros/iconos/item-maleta.png'
    },
    {
      title: 'Hotel',
      backgroundTitle:'../../../../assets/tecniseguros/iconos/background-hotel.png',
      icon: '../../../../assets/tecniseguros/iconos/hotel.png', 
      items: [
        'Gastos de hotel por convalecencia hasta $800',
        'Gastos de hotel para un familiar'
      ],
      backgroundItems: '../../../../assets/tecniseguros/iconos/item-hotel.png'
    },
    {
      title: 'Viaje',
      backgroundTitle:'../../../../assets/tecniseguros/iconos/background-viaje.png',
      icon: '../../../../assets/tecniseguros/iconos/avion.png', 
      items: [
        'Perdida de vuelo hasta $3,000',
        'Regreso anticipado',
        'Asistencia legal por accidente'
      ],
      backgroundItems: '../../../../assets/tecniseguros/iconos/item-viaje.png'
    },
    {
      title: 'Salud',
      backgroundTitle:'../../../../assets/tecniseguros/iconos/background-salud.png',
      icon: '../../../../assets/tecniseguros/iconos/seguro.png', 
      items: [
        'Gastos médicos por enfermedad y accidente hasta $30,000 *Zona Schengen €30,000',
        'Ambulancia (aérea, marítima o terrestre) hasta $30,000',
        'Emergencia dental hasta $300',
        'Seguro de muerte accidental hasta $1,000'
      ],
      backgroundItems: '../../../../assets/tecniseguros/iconos/item-salud.png'
    },
  ];

}
