import { InsuranceHolder } from "./insurance-holder";
import {PayerResponsable } from "./payer-responsable";

export class InsurancePayment {
    policy_charge?:number;
    payment_type?: number;
    payment_number?: string;
    payment_name?: string;
    expiration_date?: string;
    payment_responsable?: any;
    cvv?: string;
    ip?: string;
    frequency?: string;
    authorization_number?: any;
    card_type?: string;
    is_credit_card?:boolean;
    bin?:string;
    id_cashier?: number;
    deposit_number?: number;
    deposit_date?:string;
    account_number?: string;
    account_type?:string;
    premium?:number;
    is_mail_sale?:boolean;
    file?:string;
    amount?:number;
    payment_token?: string;
    instrument_identifier_id?: string;
    payment_number_internal?: string;
    payment_number_label?: string;
    billAccNumToken?: string;
    ranking?: any;
    billAccName?: string;
    socialReason?: string;
    identificationIdCompany?: number;
    address?: string;
    email?:string;
    phone1?:any;
    last_four?:string;
}
