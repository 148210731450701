export const ERRORS = {
    'number':{
        'en':'',
        'es':'Número de tarjeta no válido.'
    },
    'securityCode':{
        'en':'',
        'es':'Verifique el código CVV de la tarjeta.'
    },
    'expirationYear':{
        'en':'',
        'es':'Fecha de vencimiento incorrecta o vencida.'
    },
    'expirationMonth':{
        'en':'',
        'es':'Fecha de vencimiento incorrecta o vencida.'
    },
}

export function getMicroFormError(errorLocation){
    const error = ERRORS[errorLocation]
    const defaultErrorMessage = 'Error validando la tarjeta. Verifique sus datos o intente de nuevo.'
    return error?error.es:defaultErrorMessage
}